import { Grid, Typography } from "@mui/material";
import Utils from "../../core/Utils";
import { getCountry } from "../../data/localSettingsActions";
const ItemDiscountComponent = ({ discount, countryInfo }) => {
  return (
    <Grid container xs={12} padding="5px 10px">
      <Grid xs={2}></Grid>
      <Grid xs={8} direction="column" className="item-comp">
        <Typography
          level="h6"
          component="h6"
          fontSize="12px"
          className="h6 m-0"
        >
          {discount.Description}
        </Typography>
        <Typography
          level="h6"
          component="h6"
          fontWeight="600"
          fontSize="14px"
          className="h6 m-0"
        >
          {discount.DescriptionDetail}
        </Typography>
      </Grid>
      <Grid xs={2}>
        <Typography
          level="h6"
          component="h6"
          fontWeight="600"
          fontSize="14px"
          className="h6 text-right"
        >
          -
          {Utils.GetCurrency(
            discount.DiscountTotal,
            !Utils.IsNullOrEmptyObject(countryInfo)
              ? countryInfo
              : getCountry(),
            2
          )}
        </Typography>
      </Grid>
    </Grid>
  );
};
export default ItemDiscountComponent;
