import TextField from "@mui/material/TextField";
import Utils from "../../../core/Utils";
import PropTypes from "prop-types";
import { OfferTypes } from "../../../core/Constants";
const CompValueEntryField = ({
  value,
  setValue,
  compType,
  maxLimit,
  ...other
}) => {
  const handleOnChange = (currentValue) => {
    if (Utils.IsNullOrEmpty(currentValue)) {
      setValue("");
    }
    const regex = /^(\d+(\.\d{0,2})?|\.\d{1,2})$/;
    var oldValue = value;
    var val = currentValue;
    if (val.startsWith(".")) {
      val = "0" + val;
    }
    if (
      val.includes(".") &&
      val.split(".")[0].length > 1 &&
      val.startsWith("0")
    ) {
      val = val.slice(1);
    } else if (!val.includes(".") && val.length > 1 && val.startsWith("0")) {
      val = val.slice(1);
    }
    if (regex.test(val)) {
      var newPrice = Utils.ConvertToFloat(val);
      switch (compType) {
        case OfferTypes.PercentDiscount:
        case "Discount":
          if (newPrice <= 100 && newPrice >= 0) {
            if (newPrice > 0) {
              setValue(newPrice);
            } else {
              setValue("");
            }
          } else {
            setValue(oldValue);
          }
          break;
        case OfferTypes.Cash:
          if (newPrice <= maxLimit && newPrice >= 0) {
            if (newPrice > 0) {
              setValue(newPrice);
            } else {
              setValue("");
            }
          } else {
            setValue(oldValue);
          }
          break;
      }
    }
  };
  return (
    <TextField
      {...other}
      value={value}
      onChange={(e) => handleOnChange(e.target.value)}
    />
  );
};

export default CompValueEntryField;
CompValueEntryField.propTypes = {
  value: PropTypes.number.isRequired,
  setValue: PropTypes.func.isRequired,
  maxLimit: PropTypes.number.isRequired,
  compType: PropTypes.string.isRequired,
};
