import React, { useState, useEffect } from "react";
import "../Style.css";
import "../bootstrap.min.css";
import {
  Button,
  Container,
  Box,
  Grid,
  Stack,
  Typography,
  Divider,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import SideBarMenuItems from "./SideBarMenuItems";
import { connectToStore } from "../data/store";
import Utils from "../core/Utils";
import { SideBarMenuNames } from "../core/Constants";
import Loader from "./common/Loader";

// Images
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import CountryHelper from "../core/CountryHelper";
import { postAsync } from "../core/serviceClient";
import { convertServiceItemObject } from "../helpers/productHelper";
import EditIcon from "@mui/icons-material/Edit";
import {
  CheckOutPage,
  CheckoutDetailsPage,
  VisitsPage,
} from "../core/PageConstants";
import UserHelper from "../core/UserHelper";

const CheckoutDetails = (props) => {
  document.title = "Checkout Details";
  const navigate = useNavigate();
  const location = useLocation();
  const [localSettings, setLocalSettings] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [totalPrice, setTotalPrice] = useState(0.0);
  const [subTotalPrice, setSubTotalPrice] = useState(0.0);
  const [tax, setTax] = useState(0);

  useEffect(() => {
    let localSetting = connectToStore();
    if (
      UserHelper.CheckPermission(CheckoutDetailsPage, navigate, localSetting)
    ) {
      setLocalSettings(localSetting);
      var visit = location.state.visit;
      if (!Utils.IsNullOrEmptyObject(visit)) {
        var tempTax = visit.taxFree ? 0 : visit.tax;
        var subTotal = visit.spend - visit.discount;
        var total =
          subTotal + tempTax + visit.tip + (visit.includeFees ? visit.fees : 0);
        setTax(tempTax);
        setSubTotalPrice(subTotal);
        setTotalPrice(total);
      }
    }
  }, []);

  //   Back
  const goBack = () => {
    navigate(VisitsPage.Path, {
      state: {
        isProfitVisible: location.state.isProfitVisible,
        isUnPaid: location.state.isUnPaid,
        withoutDateRange: location.state.withoutDateRange,
        selectedCategory: location.state.selectedCategory,
        selectedPaymentTypes: location.state.selectedPaymentTypes,
        startDT: location.state.startDT,
        endDT: location.state.endDT,
        searchText:
          location.state && location.state.searchText
            ? location.state.searchText
            : "",
      },
    });
  };

  const calculateProfit = (visit) => {
    let profit = 0;
    if (!Utils.IsNullOrEmptyObject(visit)) {
      if (!Utils.IsNullOrEmptyArray(visit.serviceItems))
        visit.serviceItems.forEach((si) => {
          profit += si.totalPrice - si.cost * si.quantity - si.discountTotal;
        });
      profit -= visit.fees;
    }
    return profit;
  };

  const editVisit = async () => {
    setIsLoading(true);
    var result = await postAsync(
      "Customer/GetCustomerPass",
      {
        Id: localSettings.business.id + location.state.visit.customerId,
        BusinessId: localSettings.business.id,
      },
      "",
      "Get"
    );
    setIsLoading(false);
    if (result.error) {
      alert(result.errorMessage);
      return;
    }
    var cus = result.data;
    var newItems = [];
    location.state.visit.serviceItems.forEach((item) => {
      var newItem = convertServiceItemObject(item);
      newItems.push(newItem);
    });
    var state = {
      ...location.state,
      navigationStack: Utils.AddElementToArray(
        location.state.navigationStack,
        CheckoutDetailsPage.Path
      ),
      visit: location.state.visit,
      checkout: true,
      pass: cus,
      serviceItems: newItems,
      vehicleDetails: location.state.visit.vehicleDetails,
      fromJobcard: false,
      fromVisit: true,
      isEdit: true,
    };
    navigate(CheckOutPage.Path, { state: state });
  };

  return (
    <Container maxWidth="false" className="bg-color p-0">
      <Loader open={isLoading} />
      <Box>
        <Grid container m={0}>
          <SideBarMenuItems selectedTab={SideBarMenuNames.Overview} />
          <Grid xs={12} className="content-sec">
            <Grid container direction="row" padding={2}>
              <Grid container className="title-sec">
                <Typography
                  level="h2"
                  component="h2"
                  fontWeight="600"
                  fontSize="2rem"
                  className="page-title text-center"
                >
                  <Button onClick={() => goBack()} className="back-btn">
                    <NavigateBeforeIcon />
                  </Button>
                  Checkout Details
                </Typography>
                <Stack alignSelf="center" paddingRight="15px">
                  <Button
                    variant="outlined"
                    className="btn btn-secondary"
                    onClick={() => editVisit()}
                  >
                    <EditIcon fontSize="large" />
                    <span className="hidden-xs ml-h">Edit</span>
                  </Button>
                </Stack>
              </Grid>
              <Grid container direction="row" justifyContent="space-between">
                <Typography
                  level="h2"
                  component="h2"
                  fontWeight="600"
                  fontSize="2rem"
                  className="page-title"
                >
                  {location.state.desc}
                </Typography>
                {!Utils.IsNullOrEmpty(location.state.visit.invoiceNumber) && (
                  <Stack direction="row">
                    <Typography
                      level="h4"
                      component="h4"
                      fontWeight="400"
                      fontSize="2rem"
                    >
                      Invoice #:&nbsp;
                    </Typography>
                    <Typography
                      level="h2"
                      component="h2"
                      fontWeight="600"
                      fontSize="2rem"
                      className="page-title text-right"
                    >
                      {location.state.visit.invoiceNumber}
                    </Typography>
                  </Stack>
                )}
              </Grid>
              <Grid container xs={12} className="mt-2">
                <Grid xs={12} spacing={2}>
                  <Grid container xs={12} padding="0 10px">
                    <Grid xs={1} className="">
                      <Typography
                        level="p"
                        className="mt-1"
                        component="p"
                        fontSize="12px"
                      >
                        #
                      </Typography>
                    </Grid>
                    <Grid xs={6}>
                      <Typography
                        level="p"
                        className="mt-1"
                        component="p"
                        fontSize="12px"
                      >
                        Description
                      </Typography>
                    </Grid>
                    <Grid xs={1} className="text-right hidden-xs">
                      <Typography
                        level="p"
                        className="mt-1"
                        component="p"
                        fontSize="12px"
                      >
                        Qty
                      </Typography>
                    </Grid>
                    <Grid xs={2} className="text-right hidden-xs">
                      <Typography
                        level="p"
                        className="mt-1"
                        component="p"
                        fontSize="12px"
                      >
                        Unit Price
                      </Typography>
                    </Grid>
                    <Grid xs={2} className="text-right hidden-xs">
                      <Typography
                        level="p"
                        className="mt-1"
                        component="p"
                        fontSize="12px"
                      >
                        Total Price
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid xs={12} spacing={2}>
                  {!Utils.IsNullOrEmptyArray(
                    location.state.visit.serviceItems
                  ) &&
                    location.state.visit.serviceItems.map((row, ind) => (
                      <Grid xs={12}>
                        <Grid
                          container
                          xs={12}
                          padding="10px"
                          className="card mt-1"
                          direction="row"
                        >
                          <Grid xs={1} lg={1} className="">
                            <Typography
                              level="h4"
                              className="mt-1"
                              component="h4"
                              fontWeight="600"
                              fontSize="16px"
                            >
                              {ind + 1}
                            </Typography>
                          </Grid>
                          <Grid xs={11} lg={6}>
                            <Typography
                              level="h4"
                              className="mt-1"
                              component="h4"
                              fontWeight="600"
                              fontSize="16px"
                            >
                              {row.description}
                            </Typography>
                            {!Utils.IsNullOrEmpty(row.descriptionDetail) && (
                              <Typography
                                level="h5"
                                className="mt-1"
                                component="h5"
                                fontSize="14px"
                              >
                                {row.descriptionDetail}
                              </Typography>
                            )}
                          </Grid>
                          <Grid xs={12} lg={1} className="text-right">
                            <Stack
                              direction="row"
                              justifyContent="end"
                              className="mt-1 mb-jc-sb"
                            >
                              <small className="visible-xs">Qty :</small>
                              <Typography
                                level="h4"
                                component="h4"
                                fontWeight="600"
                                fontSize="16px"
                              >
                                {row.quantity}
                              </Typography>
                            </Stack>
                          </Grid>
                          <Grid xs={12} lg={2} className="text-right">
                            <Stack
                              direction="row"
                              justifyContent="end"
                              className="mt-1 mb-jc-sb"
                            >
                              <small className="visible-xs">Unit Price :</small>
                              <Typography
                                level="h4"
                                component="h4"
                                fontWeight="600"
                                fontSize="16px"
                              >
                                {Utils.GetCurrency(
                                  row.price,
                                  localSettings.country
                                )}
                              </Typography>
                            </Stack>
                          </Grid>
                          <Grid xs={12} lg={2} className="text-right">
                            <Stack
                              direction="row"
                              justifyContent="end"
                              className="mt-1 mb-jc-sb"
                            >
                              <small className="visible-xs">
                                Total Price :
                              </small>
                              <Typography
                                level="h4"
                                component="h4"
                                fontWeight="600"
                                fontSize="16px"
                              >
                                {Utils.GetCurrency(
                                  row.totalPrice +
                                  Math.round(
                                    (row.totalPrice * row.taxPercent) / 100
                                  ),
                                  localSettings.country
                                )}
                              </Typography>
                            </Stack>
                            {
                              <Typography
                                level="h5"
                                className="mt-1"
                                component="h5"
                                fontSize="14px"
                              >
                                +
                                {row.taxPercent > 0
                                  ? row.taxPercent + "% Tax"
                                  : ""}
                              </Typography>
                            }
                          </Grid>
                        </Grid>
                        {row.refundPrice > 0 && (
                          <Grid
                            container
                            xs={12}
                            spacing={1}
                            padding={2}
                            direction="row"
                          >
                            <Grid xs={1}></Grid>
                            <Grid xs={6}>
                              <Typography
                                level="h4"
                                className="mt-1"
                                component="h4"
                                fontWeight="600"
                                fontSize="16px"
                              >
                                {row.description}
                              </Typography>
                              <Typography
                                level="h4"
                                className="mt-1"
                                component="h4"
                                fontWeight="600"
                                fontSize="16px"
                              >
                                *** Refund ***
                              </Typography>
                            </Grid>
                            <Grid xs={1}>
                              <Typography
                                level="h4"
                                component="h4"
                                fontSize="1.5rem"
                                fontWeight="500"
                                className="text-right"
                              >
                                {row.quantity}
                              </Typography>
                            </Grid>
                            <Grid xs={2}>
                              <Typography
                                level="h4"
                                component="h4"
                                fontSize="1.5rem"
                                fontWeight="500"
                                className="text-right"
                              >
                                {Utils.GetCurrency(
                                  row.price,
                                  localSettings.country
                                )}
                              </Typography>
                            </Grid>
                            <Grid xs={2}>
                              <Typography
                                level="h4"
                                component="h4"
                                fontSize="1.5rem"
                                fontWeight="500"
                                className="text-right"
                              >
                                -
                                {Utils.GetCurrency(
                                  row.refundPrice,
                                  localSettings.country
                                )}
                              </Typography>
                            </Grid>
                          </Grid>
                        )}
                        {row.refundPrice === 0 &&
                          !Utils.IsNullOrEmptyArray(row.discounts) &&
                          row.discounts.map((discount) => (
                            <Grid
                              container
                              xs={12}
                              spacing={1}
                              padding={2}
                              direction="row"
                            >
                              <Grid xs={1}></Grid>
                              <Grid xs={6}>
                                <Typography
                                  level="h4"
                                  component="h4"
                                  fontSize="1.5rem"
                                  fontWeight="500"
                                  className="text-right"
                                >
                                  {discount.description}
                                </Typography>
                                <Typography
                                  level="h4"
                                  component="h4"
                                  fontSize="1.5rem"
                                  fontWeight="500"
                                  className="text-right"
                                >
                                  {discount.descriptionDetail}
                                </Typography>
                              </Grid>
                              <Grid xs={1} className="text-right"></Grid>
                              <Grid xs={2} className="text-right"></Grid>
                              <Grid xs={2} className="text-right">
                                <Typography
                                  level="h4"
                                  className="mt-1"
                                  component="h4"
                                  fontWeight="600"
                                  fontSize="16px"
                                >
                                  -
                                  {Utils.GetCurrency(
                                    discount.discountTotal,
                                    localSettings.country
                                  )}
                                </Typography>
                              </Grid>
                            </Grid>
                          ))}
                      </Grid>
                    ))}
                </Grid>

                <Grid flex="1" spacing={2} className="mt-2">
                  <Grid container xs={12} padding="10px">
                    <Grid xs={8} className=""></Grid>
                    <Grid xs={4} className="text-right">
                      <Typography
                        level="h4"
                        component="h4"
                        fontWeight="600"
                        fontSize="16px"
                      >
                        <small>Total:</small>
                        {Utils.GetCurrency(
                          CountryHelper.IsBusinessCountryUSA(
                            localSettings.country
                          )
                            ? subTotalPrice
                            : totalPrice
                        )}
                      </Typography>
                      {CountryHelper.IsBusinessCountryUSA(
                        localSettings.country
                      ) && (
                          <Typography
                            level="h4"
                            component="h4"
                            fontWeight="600"
                            fontSize="16px"
                          >
                            <small>Tax:</small>
                            {Utils.GetCurrency(tax, localSettings.country)}
                          </Typography>
                        )}
                      <Typography
                        level="h4"
                        component="h4"
                        fontWeight="600"
                        fontSize="16px"
                      >
                        <small>Tips:</small>
                        {location.state &&
                          location.state.visit &&
                          Utils.GetCurrency(
                            location.state.visit.tip,
                            localSettings.country
                          )}
                      </Typography>
                      {location.state &&
                        location.state.visit &&
                        location.state.visit.includeFees && (
                          <Typography
                            level="h4"
                            component="h4"
                            fontWeight="600"
                            fontSize="16px"
                          >
                            <small>Fees:</small>
                            {location.state &&
                              location.state.visit &&
                              Utils.GetCurrency(
                                location.state.visit.fees,
                                localSettings.country
                              )}
                          </Typography>
                        )}
                      <Divider className="mt-1" />
                    </Grid>
                    <Grid
                      xs={12}
                      className="text-right hidden-xs"
                      container
                      direction="row"
                      justifyContent="space-between"
                    >
                      <Typography
                        level="p"
                        className="mt-1"
                        component="p"
                        fontSize="12px"
                      >
                        Payment Received By:
                        {location.state &&
                          location.state.visit &&
                          location.state.visit.paymentReceivedBy}
                      </Typography>
                      <Typography
                        level="h4"
                        component="h4"
                        fontWeight="600"
                        fontSize="24px"
                      >
                        {Utils.GetCurrency(totalPrice, localSettings.country)}
                      </Typography>
                    </Grid>
                    {location.state && location.state.isProfitVisible && (
                      <Typography
                        level="h4"
                        component="h4"
                        fontWeight="600"
                        fontSize="16px"
                      >
                        <small>Profit:</small>
                        {location.state &&
                          location.state.visit &&
                          Utils.GetCurrency(
                            calculateProfit(location.state.visit),
                            localSettings.country
                          )}
                      </Typography>
                    )}
                    {location.state &&
                      location.state.visit &&
                      location.state.visit.refundAmount > 0 && (
                        <Grid
                          xs={12}
                          className="text-right hidden-xs"
                          container
                          direction="row"
                          justifyContent="space-between"
                        >
                          <Typography
                            level="p"
                            className="mt-1"
                            component="p"
                            fontSize="12px"
                          >
                            Refund issued '
                            {location.state &&
                              location.state.visit &&
                              location.state.visit.refundReason}
                            '
                          </Typography>
                          <Typography
                            variant="h5"
                            className="mt-1"
                            fontWeight="600"
                          >
                            {location.state &&
                              location.state.visit &&
                              Utils.GetCurrency(
                                location.state.visit.refundAmount,
                                localSettings.country
                              )}
                          </Typography>
                        </Grid>
                      )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default CheckoutDetails;
