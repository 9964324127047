import Dialog from "@mui/material/Dialog";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { DialogActions, DialogContentText, Paper } from "@mui/material";
import Assign_Icon from "../images/assign_user.png";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React, { useEffect, useState } from "react";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import Utils from "../core/Utils";
import { ConfirmDialog } from "./common/ConfirmDialog";
import {
  getAuthentication,
  getBranch,
  getBusiness,
  getCampaigns,
  getCountry,
  getProducts,
  setBusiness as setUpdatedBusiness,
  setBranch as setUpdatedBranch,
  getMakeModels,
  getEnterpriseId,
  getUser,
} from "../data/localSettingsActions";
import { getGeocodeAddress, postAsync } from "../core/serviceClient";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import ShareIcon from "@mui/icons-material/Share";
import EmailIcon from "@mui/icons-material/Email";
import SmsIcon from "@mui/icons-material/Sms";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import Cash_Icon from "../images/cash.png";
import Card_Icon from "../images/creditCard.png";
import Netbanking_Icon from "../images/Netbanking.png";
import Upi_Icon from "../images/Upi.png";
import Split_Icon from "../images/partial-paid.png";
import {
  BenefitTypes,
  BusinessCategories,
  CampaignProductCategories,
  CampaignSegmentTypes,
  CampaignTypes,
  ClassificationTypes,
  ConfirmTypes,
  India,
  OfferClassificationTypes,
  OfferTypes,
  PartialPaymentErrorTypes,
  PassEventTypes,
  PaymentMethodTypes,
  PaymentTypes,
  ServiceItemTypes,
  SideBarMenuNames,
  VisitStatuses,
  VisitTypeEnum,
} from "../core/Constants";
import {
  convertServiceItemObject,
  getConsumables,
  getLabours,
  getServiceItemFromProduct,
  getServices,
  getSpares,
} from "../helpers/productHelper";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import QRCode from "react-qr-code";
import CountryHelper from "../core/CountryHelper";
import BusinessHelper from "../core/BusinessHelper";
import moment from "moment";
import SideBarMenuItems from "./SideBarMenuItems";
import Loader from "./common/Loader";
import MessagePopup from "./common/MessagePopUp";
import PaymentLink_Icon from "../images/paymentlink.png";
import NumericTextField from "./common/controls/NumericTextField";
import UserHelper from "../core/UserHelper";
import {
  CheckOutPage,
  CustomersPage,
  JobCardDetailsPage,
  LoginPage,
  OverviewPage,
  AMCPlanPage,
  PaymentPage,
  VisitsPage,
  PaymentsPage,
} from "../core/PageConstants";
import AddressField from "./common/controls/AddressField";
import { CanRaisePaymentLink } from "../core/ModuleHelper";
import AmountTypography from "./common/controls/AmountTypography";
import ComplimentaryPopup from "./common/Complimentary";
import ItemDiscountComponent from "./common/ItemDiscountComponent";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

function TaxComponent({ currentTax, country }) {
  return (
    <Typography
      level="h6"
      component="h6"
      fontWeight="600"
      fontSize="12px"
      className="h6 m-0"
    >
      Tax:
      {Utils.GetCurrency(currentTax, country, 2)}
    </Typography>
  );
}

const partialPayActions = {
  FullPay: "FullPay",
  PartialPay: "PartialPay",
  PartialPayWithoutCustomer: "PartialPayWithoutCustomer",
  ProcessingPartialPay: "ProcessingPartialPay",
  Close: "Close",
  Book: "Book",
};

const customerSaveType = {
  Error: "Error",
  Success: "Success",
};

const Payment = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [isNavigateToLogin, setIsNavigateToLogin] = useState(false);

  //Alert Hooks
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertTitle, setAlertTitle] = useState("");

  //Loader hook
  const [isLoading, setIsLoading] = useState(false);

  const setAlert = (msg) => {
    setAlertMessage(msg);
    setShowAlert(true);
  };

  const [masterServiceItemsList, setMasterServiceItemsList] = useState([]);
  const [pass, setPass] = useState({});
  const [visit, setVisit] = useState({});
  const [vehicleDetails, setVehicleDetails] = useState({});
  const [amcPurchase, setAMCPurchase] = useState(false);
  const [productsInventory, setProductsInventory] = useState([]);
  const [anonymous, setAnonymous] = useState(false);
  const [branch, setBranch] = useState({});
  const [business, setBusiness] = useState({});
  const [openAddressSelector, setOpenAddressSelector] = useState(false);
  const [openPaymentSelector, setOpenPaymentSelector] = useState(false);
  const [paymentType, setPaymentType] = useState("");
  const [serviceItems, setServiceItems] = useState([]);
  const [orderComplimentaryList, setOrderComplimentaryList] = useState([]);
  const [itemComplimentaryList, setItemComplimentaryList] = useState([]);
  const [orderLevelDiscounts, setOrderLevelDiscounts] = useState([]);
  const [compType, setCompType] = useState("Discount");
  const [compValue, setCompValue] = useState(0);
  const [countryInfo, setCountryInfo] = useState({});
  const [serviceItemTotalTax, setServiceItemTotalTax] = useState(0);
  const [existItemComp, setExistItemComp] = useState({});
  const [ComplimentaryModal, openComplimentaryModal] = useState(false);
  const [orginalTotal, setOrginalTotal] = useState(0);
  const [serviceItemTotalPrice, setServiceItemTotalPrice] = useState(0);
  const [tipAmount, setTipAmount] = useState(0);
  const [tipModal, openTipModal] = useState(false);
  const [paymentTypeUpiModal, openPaymentTypeUpiModal] = useState(false);
  const [paymentTypeCashModal, openPaymentTypeCashModal] = useState(false);
  const [paymentTypeCardModal, openPaymentTypeCardModal] = useState(false);
  const [csProductName, setCsProductName] = useState("");
  const [isOpened, setIsOpened] = useState(false);
  const [reverseCharge, setReverseCharge] = useState("");
  const [vehicleMake, setVehicleMake] = useState("");
  const [vehicleModel, setVehicleModel] = useState("");
  const [vehicleFuelType, setVehicleFuelType] = useState("");
  const [vehicleMakeModelList, setVehicleMakeModelList] = useState([]);
  const [vehicleMakeItem, setVehicleMakeItem] = useState([]);
  const [sendInvoiceEnable, setSendInvoiceEnable] = useState(false);
  const [offersViewModel, setOffersViewModel] = useState([]);
  const [applicableServiceItems, setApplicableServiceItems] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [subTotal, setSubTotal] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [tax, setTax] = useState(0);
  const [shippingFromDefaultAddress, setShippingFromDefaultAddress] =
    useState("");
  const [
    shippingFromAddressDefaultEnabled,
    setShippingFromAddressDefaultEnabled,
  ] = useState(true);
  const [shippingToAddressDefaultEnabled, setShippingToAddressDefaultEnabled] =
    useState(true);
  const [shippingToDefaultAddress, setShippingToDefaultAddress] = useState("");
  const [shippingFromNewAddress, setShippingFromNewAddress] = useState("");
  const [shippingToNewAddress, setShippingToNewAddress] = useState("");
  const [shippingToEmailId, setShippingToEmailId] = useState("");
  const [shippingToPhoneNumber, setShippingToPhoneNumber] = useState("");
  const [shippingToStateCode, setShippingToStateCode] = useState("");
  const [shippingFromEmailId, setShippingFromEmailId] = useState("");
  const [shippingFromPhoneNumber, setShippingFromPhoneNumber] = useState("");
  const [shippingFromStateCode, setShippingFromStateCode] = useState("");
  const [shippingFromAddressObject, setShippingFromAddressObject] =
    useState("");
  const [shippingToAddressObject, setShippingToAddressObject] = useState("");
  const [reverseChargeApplicable, setReverseChargeApplicable] = useState(false);
  const [autoPay, setAutoPay] = useState(false);
  const [cash, setCash] = useState(false);
  const [payNow, setPayNow] = useState(false);
  const [payTerminal, setPayTerminal] = useState(false);
  const [cardPay, setCardPay] = useState(false);
  const [upi, setUPI] = useState(false);
  const [netBanking, setNetBanking] = useState(false);
  const [payLater, setPayLater] = useState(false);
  const [nameOnCard, setNameOnCard] = useState("");
  const [lastFourDigits, setLastFourDigits] = useState("");
  const [showCardDetails, setShowCardDetails] = useState(false);
  const [showUPIDetails, setShowUPIDetails] = useState(false);
  const [cardDetailsDisabled, setCardDetailsDisabled] = useState(true);
  const [upiValue, setUPIValue] = useState("");
  const [comments, setComments] = useState("");
  const [paymentReceivedBy, setPaymentReceivedBy] = useState(
    getUser().userName
  );
  const [isSalon, setIsSalon] = useState(false);
  const [serviceStaffs, setServiceStaffs] = useState([]);
  const [openStaffSelection, setOpenStaffSelection] = useState(false);
  const [selectedServiceItem, setSelectedServiceItem] = useState({});
  const [showReceiptOptions, setShowReceiptOptions] = useState(false);
  const [customerMobileNumber, setCustomerMobileNumber] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [customerEmailId, setCustomerEmailId] = useState("");
  const [customerAddress, setCustomerAddress] = useState("");
  const [smsSent, setSMSSent] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [isAutomobileWithoutVehicle, setIsAutomobileWithoutVehicle] =
    useState(false);
  const [vehicleNumber, setVehicleNumber] = useState("");
  const [customMade, setCustomMade] = useState(false);
  const [vehicleCustomMake, setVehicleCustomMake] = useState("");
  const [vehicleCustomModel, setVehicleCustomModel] = useState("");
  const [addressDetails_Address, setAddresDetails_Address] = useState("");
  const [addressDetails_City, setAddresDetails_City] = useState("");
  const [addressDetails_State, setAddresDetails_State] = useState("");
  const [addressDetails_Pincode, setAddressDetails_Pincode] = useState("");
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
  const [invoiceLabelsList, setInvoiceLabelsList] = useState([]);
  const [invoiceLabel, setInvoiceLabel] = useState();
  const [selectedInvoiceLabel, setSelectedInvoicelabel] = useState({});
  const [paidAmount, setPaidAmount] = useState(0);
  const [isBusinessCountryIndia, setIsBusinessCountryIndia] = useState(false);
  const [isBusinessCountryUAE, setIsBusinessCountryUAE] = useState(false);
  const [isBusinessCountryUS, setIsBusinessCountryUS] = useState(false);
  const [addressISOCode, setAddressISOCode] = useState({});
  const [activeMenu, setActiveMenu] = useState("");
  const [maxPrice, setMaxPrice] = useState(0);

  //Partial pay hooks
  const [isPartialPaySelected, setIsPartialPaySelected] = useState(false);
  const [showConfirmDialogForPartialPay, setShowConfirmDialogForPartialPay] =
    useState(false);
  const [showPartialPayEntryDialog, setShowPartialPayEntryDialog] =
    useState(false);
  const [processingPartialPay, setProcessingPartialPay] = useState(false);
  const [
    showCustomerSetupDialogForPartialPay,
    setShowCustomerSetupDialogForPartialPay,
  ] = useState(false);
  const [outstandingAmount, setOutstandingAmount] = useState(0);
  const [paidByCustomerAmountString, setPaidByCustomerAmountString] =
    useState("");
  const [paidByCustomerAmount, setPaidByCustomerAmount] = useState(0);
  const [balanceAmount, setBalanceAmount] = useState(0);
  const [
    isPartialPayProceedButtonDisabled,
    setIsPartialPayProceedButtonDisabled,
  ] = useState(true);
  const [isCustomerSaveDisabled, setIsCustomerSaveDisabled] = useState(true);

  //Payment links hooks
  const [paymentLink, setPaymentLink] = useState(false);
  const [showPaymentLinkDetails, setShowPaymentLinkDetails] = useState(false);
  const [settlementAccount, setSettlementAccount] = useState({});
  const [settlementAccounts, setSettlementAccounts] = useState([]);
  const [paymentLinkAmount, setPaymentLinkAmount] = useState("");
  const [paymentLinkMobileNumber, setPaymentLinkMobileNumber] = useState("");
  const [selecteSettleAccountName, setSelecteSettleAccountName] = useState("");
  const [paymentLinkDisabled, setPaymentLinkDisabled] = useState(true);
  const [paymentLinkSelected, setPaymentLinkSelected] = useState(false);

  useEffect(() => {
    checkAuthentication();
  }, []);
  const checkAuthentication = async () => {
    if (UserHelper.CheckPermission(PaymentPage, navigate)) {
      setIsLoading(true);
      var bus = getBusiness();
      var _processingPartialPay = location.state.partialPayment;
      var customerPass = location.state.pass;
      var _anonymous =
        Utils.IsNullOrEmptyObject(customerPass) || customerPass.isBusiness;
      var menu =
        !Utils.IsNullOrEmptyObject(location.state) &&
          !Utils.IsNullOrEmpty(location.state.activeMenu)
          ? location.state.activeMenu
          : _anonymous
            ? SideBarMenuNames.CheckOut
            : location.state && location.state.fromVisit
              ? SideBarMenuNames.Overview
              : location.state && location.state.fromJobcard
                ? SideBarMenuNames.JobCard
                : location.state && location.state.fromAMCPlan
                  ? SideBarMenuNames.AMCPlan
                  : SideBarMenuNames.Customers;
      setActiveMenu(menu);
      var countryDetails = getCountry();
      setIsBusinessCountryIndia(
        CountryHelper.IsBusinessCountryIndia(countryDetails)
      );
      setIsBusinessCountryUAE(
        CountryHelper.IsBusinessCountryUAE(countryDetails)
      );
      setIsBusinessCountryUS(
        CountryHelper.IsBusinessCountryUSA(countryDetails)
      );
      var currentVisit = location.state.visit;
      var currentBranch = getBranch();
      var initialInvoiceLabel = currentBranch.invoiceLabels.filter(
        (i) => i.taxIncluded && i.active
      )[0];
      var previouslySelectedLabel = currentBranch.invoiceLabels.find((i) => i.active && i.description == location.state.selectedLabel);
      if (previouslySelectedLabel != undefined)
        initialInvoiceLabel = previouslySelectedLabel;
      var payout = currentBranch.payout;
      setSettlementAccounts(payout);
      var salon = BusinessHelper.IsSalon(bus);
      if (salon) {
        var staffs = await getServiceStaffs();
        if (!Utils.IsNullOrEmptyArray(staffs)) {
          setServiceStaffs(staffs);
        }
      }
      setIsSalon(salon);
      setBusiness(bus);
      setBranch(currentBranch);
      setCountryInfo(countryDetails);
      setAddressISOCode(countryDetails.ISOCode);
      setPass(customerPass);
      setVehicleMakeModelList(getMakeModels());
      var automobileWithoutVehicle = false;
      if (_processingPartialPay) {
        setIsPartialPaySelected(true);
        automobileWithoutVehicle =
          bus.category === BusinessCategories.Automobile &&
          Utils.IsNullOrEmptyObject(currentVisit.vehicleDetails);
        setIsAutomobileWithoutVehicle(automobileWithoutVehicle);
        var currentServiceItems = [];
        currentVisit.serviceItems.forEach((item) => {
          var sItem = convertServiceItemObject(item, true);
          currentServiceItems.push(sItem);
        });
        setMasterServiceItemsList(
          currentServiceItems.map((item) => ({
            ...item,
            Discounts: item.Discounts.map((discount) => ({ ...discount })),
          }))
        );
        setServiceItems(currentServiceItems);
        setVisit(currentVisit);
        setProcessingPartialPay(true);
        setShowPartialPayEntryDialog(true);
        setIsLoading(false);
      } else {
        var selectedItems = location.state.serviceItems.map((item) => ({
          ...item,
          Discounts: item.Discounts.map((discount) => ({ ...discount })),
        }));
        if (!Utils.IsNullOrEmptyArray(location.state.itemComps)) {
          var comps = location.state.itemComps.map((comp) => ({ ...comp }));
          setItemComplimentaryList(comps);
        }
        setSelectedInvoicelabel(initialInvoiceLabel);
        setInvoiceLabel(JSON.stringify(initialInvoiceLabel));
        setInvoiceLabelsList(
          currentBranch.invoiceLabels.filter((i) => i.active)
        );

        setCampaigns(getCampaigns());
        setProductsInventory(getProducts());
        setVehicleDetails(location.state.vehicleDetails);
        setMasterServiceItemsList(
          selectedItems.map((item) => ({
            ...item,
            Discounts: item.Discounts.map((discount) => ({ ...discount })),
          }))
        );
        setServiceItems(selectedItems);
        setVisit(currentVisit);
        setAnonymous(_anonymous);
        automobileWithoutVehicle =
          bus.category === BusinessCategories.Automobile &&
          Utils.IsNullOrEmptyObject(location.state.vehicleDetails);
        setIsAutomobileWithoutVehicle(automobileWithoutVehicle);
        setIsLoading(false);
      }
    }
  };

  const onChangeInvoiceLabel = (value) => {
    setInvoiceLabel(value);
    var newInvoiceLabel = JSON.parse(value);
    setSelectedInvoicelabel(newInvoiceLabel);
  };

  useEffect(() => {
    recalculateTax();
  }, [selectedInvoiceLabel]);

  const recalculateTax = () => {
    if (
      Utils.IsNullOrEmptyObject(selectedInvoiceLabel) ||
      Utils.IsNullOrEmptyArray(serviceItems)
    ) {
      return;
    }
    var tempServiceItems = [...serviceItems];
    if (!selectedInvoiceLabel.taxIncluded) {
      tempServiceItems.forEach((item) => {
        item.TaxAmount = 0;
        item.TotalPriceIncludingTax = item.TotalPrice - item.DiscountTotal;
      });
    } else {
      tempServiceItems.forEach((item) => {
        item.TaxAmount =
          ((item.TotalPrice - item.DiscountTotal) * item.TaxPercent) / 100;
        item.TotalPriceIncludingTax =
          item.TotalPrice - item.DiscountTotal + item.TaxAmount;
      });
    }
    setServiceItems(tempServiceItems);
  };

  const getServiceStaffs = async () => {
    var serviceStaffs = [];
    var result = await postAsync("Account/GetUsers", { ServiceStaff: true });
    if (!result.error) {
      result.data.userProfiles.forEach((user) => {
        var serviceStaff = {
          Id: user.id,
          Name: user.userName,
        };
        serviceStaffs.push(serviceStaff);
      });
    }
    return serviceStaffs;
  };

  useEffect(() => {
    loadPaymentPageSegment1();
  }, [masterServiceItemsList]);

  const loadPaymentPageSegment1 = async () => {
    if (Utils.IsNullOrEmptyArray(serviceItems)) {
      return;
    }
    setIsLoading(true);
    var tempServiceItems = [...serviceItems];
    var tempOfferModels = [...offersViewModel];
    var tempOrderLevelDiscounts = [...orderLevelDiscounts];
    var tempApplicableoffers = [];
    if (
      !anonymous &&
      !Utils.IsNullOrEmptyObject(vehicleDetails) &&
      !Utils.IsNullOrEmpty(vehicleDetails.number)
    ) {
      var data = await loadAMCPlans(tempOfferModels, productsInventory);
      if (!Utils.IsNullOrEmptyObject(data)) {
        tempOfferModels = data.offers;
        tempApplicableoffers = data.applicableItems;
      }
    }
    tempOfferModels = await loadCampaignsAndEvents(
      tempOfferModels,
      productsInventory
    );
    if (!Utils.IsNullOrEmptyArray(tempOfferModels)) {
      for (var i = 0; i < tempOfferModels.length; i++) {
        var data = processOffer(
          tempOfferModels[i],
          tempServiceItems,
          tempOrderLevelDiscounts,
          tempApplicableoffers
        );
        tempOfferModels[i] = data.offer;
        tempServiceItems = data.serviceItems;
        tempOrderLevelDiscounts = data.orderDiscounts;
      }
    }
    tempServiceItems = calculateBiggestDiscount(
      tempServiceItems,
      tempOfferModels
    );
    setServiceItems(tempServiceItems);
    tempOrderLevelDiscounts = recalculateOrderDiscounts(
      tempServiceItems,
      tempOrderLevelDiscounts
    );
    tempOfferModels = recalculateOfferValues(tempOfferModels);
    setOffersViewModel(tempOfferModels);
    setOrderLevelDiscounts(tempOrderLevelDiscounts);
    setIsLoading(false);
  };

  const recalculateOrderDiscounts = (
    tempServiceItems,
    tempOrderLevelDiscounts
  ) => {
    if (Utils.IsNullOrEmptyArray(tempOrderLevelDiscounts)) {
      return tempOrderLevelDiscounts;
    }
    tempOrderLevelDiscounts.forEach((orderDiscount) => {
      var filteredDiscounts = [];
      tempServiceItems.forEach((serviceItem) => {
        serviceItem.Discounts.forEach((disc) => {
          if (disc.OrderLevel && disc.CampaignId === orderDiscount.CampaignId) {
            filteredDiscounts.push(disc);
          }
        });
      });
      if (!Utils.IsNullOrEmptyArray(filteredDiscounts)) {
        orderDiscount.DiscountTotal = filteredDiscounts
          .map((i) => i.DiscountTotal)
          .reduce((a, b) => a + b);
      }
    });
    return tempOrderLevelDiscounts;
  };

  const recalculateOfferValues = (tempOfferModels) => {
    if (Utils.IsNullOrEmptyArray(tempOfferModels)) {
      return tempOfferModels;
    }
    tempOfferModels
      .filter(
        (o) =>
          o.DiscountType !== OfferTypes.Free &&
          o.BenefitType !== BenefitTypes.Free
      )
      .forEach((offer) => {
        var selectedDiscounts = [];
        switch (offer.OffersClassificationType) {
          case OfferClassificationTypes.Campaign:
            serviceItems.forEach((serviceItem) => {
              if (!Utils.IsNullOrEmptyArray(serviceItem.Discounts)) {
                serviceItem.Discounts.forEach((discount) => {
                  if (discount.CampaignId === offer.CampaignId) {
                    selectedDiscounts.push(discount);
                  }
                });
              }
            });
            if (!Utils.IsNullOrEmptyArray(selectedDiscounts)) {
              if (selectedDiscounts.length === 1) {
                offer.DiscountTotal = selectedDiscounts[0].DiscountTotal;
              } else {
                offer.DiscountTotal = selectedDiscounts
                  .map((d) => d.DiscountTotal)
                  .reduce((a, b) => a + b);
              }
            }
            break;
          case OfferClassificationTypes.AMC:
            serviceItems.forEach((serviceItem) => {
              if (!Utils.IsNullOrEmptyArray(serviceItem.Discounts)) {
                serviceItem.Discounts.forEach((discount) => {
                  if (
                    discount.CampaignId === offer.CampaignId &&
                    discount.BenefitId === offer.BenefitId
                  ) {
                    selectedDiscounts.push(discount);
                  }
                });
              }
            });
            if (!Utils.IsNullOrEmptyArray(selectedDiscounts)) {
              if (selectedDiscounts.length === 1) {
                offer.DiscountTotal = selectedDiscounts[0].DiscountTotal;
              } else {
                offer.DiscountTotal = selectedDiscounts
                  .map((d) => d.DiscountTotal)
                  .reduce((a, b) => a + b);
              }
            }
            break;
        }
      });
    return tempOfferModels;
  };

  useEffect(() => {
    recalculateTotals();
  }, [serviceItems, tipAmount, visit]);

  const calculateBiggestDiscount = (tempServiceItems, tempOfferModels) => {
    if (
      Utils.IsNullOrEmptyArray(tempServiceItems) ||
      tempServiceItems.every(
        (c) => Utils.IsNullOrEmptyArray(c.Discounts) || processingPartialPay
      )
    ) {
      return tempServiceItems;
    }
    tempServiceItems.forEach((serviceItem) => {
      if (serviceItem.Discounts.filter((d) => !d.Complimentary).length > 1) {
        var serviceItemLevelDiscounts = serviceItem.Discounts.filter(
          (d) =>
            !d.OrderLevel &&
            !d.Complimentary &&
            d.CampaignType !== CampaignTypes.Event
        );

        //if service item level discounts available
        //Getting biggest discount for an item among the service item level discounts
        if (!Utils.IsNullOrEmptyArray(serviceItemLevelDiscounts)) {
          var biggestDiscount = GetBiggestDiscount(
            serviceItemLevelDiscounts,
            serviceItem.ProductId,
            tempOfferModels
          );
          //remove other item level offers
          serviceItem.Discounts = serviceItem.Discounts.filter(
            (d) => d.OrderLevel || d.Complimentary
          );
          //Add Biggest Offer
          serviceItem.Discounts.push(biggestDiscount);
          serviceItem.DiscountTotal = biggestDiscount.DiscountTotal;
        }

        //Recalculate order level discounts
        serviceItem.Discounts.filter((d) => d.OrderLevel).forEach(
          (orderLevelDiscount) => {
            if (!Utils.IsNullOrEmpty(orderLevelDiscount.CampaignId)) {
              var offerValue = 0;
              var offer = tempOfferModels.find(
                (o) => o.CampaignId === orderLevelDiscount.CampaignId
              );
              if (!Utils.IsNullOrEmptyObject(offer)) {
                var totalSum = tempServiceItems
                  .map((i) => i.TotalPrice)
                  .reduce((a, b) => a + b);
                var totalDiscountSum = tempServiceItems
                  .map((i) => i.DiscountTotal)
                  .reduce((a, b) => a + b);
                var updatedValue =
                  (offer.DiscountValue / (totalSum - totalDiscountSum)) * 100;
                offerValue =
                  offer.DiscountType === OfferTypes.Cash
                    ? updatedValue
                    : offer.DiscountValue;
                orderLevelDiscount.DiscountTotal =
                  ((serviceItem.TotalPrice - serviceItem.DiscountTotal) *
                    offerValue) /
                  100;
              }
            }
          }
        );
        serviceItem.DiscountTotal = serviceItem.Discounts.map(
          (c) => c.DiscountTotal
        ).reduce((a, b) => a + b);
        //Recalculate item level complimentary
        serviceItem.Discounts.filter(
          (d) => !d.OrderLevel && d.Complimentary
        ).forEach((comp) => {
          var totalSum = tempServiceItems
            .map((i) => i.TotalPrice)
            .reduce((a, b) => a + b);
          var totalDiscountSum = tempServiceItems
            .map((i) => i.DiscountTotal)
            .reduce((a, b) => a + b);
          var updatedValue =
            (comp.DiscountValue / (totalSum - totalDiscountSum)) * 100;
          var offerValue =
            comp.DiscountType === OfferTypes.Cash
              ? updatedValue
              : comp.DiscountValue;
          comp.DiscountTotal =
            ((serviceItem.TotalPrice - serviceItem.DiscountTotal) *
              offerValue) /
            100;
        });
        serviceItem.DiscountTotal = serviceItem.Discounts.map(
          (c) => c.DiscountTotal
        ).reduce((a, b) => a + b);
      } else {
        if (
          serviceItem.Discounts.some(
            (d) =>
              d.DiscountType !== OfferTypes.Free &&
              d.BenefitType !== BenefitTypes.Free
          )
        ) {
          var offerValue = 0;
          var offer = tempOfferModels.find(
            (o) => o.CampaignId === serviceItem.Discounts[0].CampaignId
          );
          if (!Utils.IsNullOrEmptyObject(offer)) {
            if (serviceItem.Discounts[0].OrderLevel) {
              var totalSum = tempServiceItems
                .map((i) => i.TotalPrice)
                .reduce((a, b) => a + b);
              var filteredItems = tempServiceItems.filter(
                (s) => s.ProductId !== serviceItem.ProductId
              );
              var totalDiscountSum = Utils.IsNullOrEmptyArray(filteredItems)
                ? 0
                : filteredItems
                  .map((d) => d.DiscountTotal)
                  .reduce((a, b) => a + b);
              var updatedValue =
                (offer.DiscountValue / (totalSum - totalDiscountSum)) * 100;
              offerValue =
                offer.DiscountType === OfferTypes.Cash
                  ? updatedValue
                  : offer.DiscountValue;
              serviceItem.Discounts[0].DiscountTotal =
                (serviceItem.TotalPrice * offerValue) / 100;
            } else {
              offerValue =
                offer.DiscountType === OfferTypes.Cash ||
                  offer.BenefitType === BenefitTypes.FlatDiscount
                  ? offer.DiscountValue
                  : (serviceItem.TotalPrice * offer.DiscountValue) / 100;
              serviceItem.Discounts[0].DiscountTotal = offerValue;
            }
          }
          serviceItem.DiscountTotal = Utils.IsNullOrEmptyArray(
            serviceItem.Discounts
          )
            ? 0
            : serviceItem.Discounts.map((c) => c.DiscountTotal).reduce(
              (a, b) => a + b
            );
        }
      }
    });
    tempServiceItems = recalculateTaxAmountOnDiscountApply(tempServiceItems);
    return tempServiceItems;
  };

  const GetBiggestDiscount = (discounts, productId, tempOfferModels) => {
    var serviceItem = masterServiceItemsList.find(
      (s) => s.ProductId === productId
    );
    discounts.forEach((discount) => {
      var offer =
        discount.OffersClassificationType === OfferClassificationTypes.AMC
          ? tempOfferModels.find(
            (o) =>
              o.Selected &&
              o.CampaignId === discount.CampaignId &&
              o.BenefitId === discount.BenefitId
          )
          : tempOfferModels.find(
            (o) => o.Selected && o.CampaignId == discount.CampaignId
          );
      if (!Utils.IsNullOrEmptyObject(offer)) {
        switch (offer.OffersClassificationType) {
          case OfferClassificationTypes.Campaign:
            switch (discount.DiscountType) {
              case OfferTypes.Cash:
                discount.DiscountTotal = offer.DiscountValue;
                break;
              case OfferTypes.PercentDiscount:
                discount.DiscountTotal =
                  (serviceItem.TotalPrice * offer.DiscountValue) / 100;
                break;
            }
            break;
          case OfferClassificationTypes.AMC:
            switch (discount.BenefitType) {
              case BenefitTypes.DiscountPercentage:
                discount.DiscountTotal =
                  (serviceItem.TotalPrice * offer.DiscountValue) / 100;
                break;
              case BenefitTypes.FlatDiscount:
                discount.DiscountTotal = offer.DiscountValue;
                break;
            }
            break;
        }
      }
    });
    var sortedArray = discounts.sort((a, b) => {
      return a.DiscountTotal - b.DiscountTotal;
    });
    return sortedArray[sortedArray.length - 1];
  };

  const recalculateTotals = () => {
    if (Utils.IsNullOrEmptyArray(serviceItems)) {
      setSubTotal(0);
      setTax(0);
      setTotalPrice(0);
      setOutstandingAmount(0);
      setBalanceAmount(0);
      return;
    }
    var discountTotal = serviceItems
      .map((c) => c.DiscountTotal)
      .reduce((a, b) => a + b);
    var subTotal = serviceItems
      .map((c) => c.TotalPrice)
      .reduce((a, b) => a + b);
    subTotal = subTotal - discountTotal;
    setSubTotal(subTotal);
    var tax = serviceItems.map((c) => c.TaxAmount).reduce((a, b) => a + b);
    setTax(tax);
    var grandTotal = subTotal + tax + tipAmount;
    setTotalPrice(grandTotal);
    var isVisitEmpty = Utils.IsNullOrEmptyObject(visit);
    var tempPaidAmount = isVisitEmpty ? 0 : visit.paidAmount;
    var tempRequestedAmount = isVisitEmpty ? 0 : visit.requestedAmount;
    var outstanding = grandTotal - tempPaidAmount - tempRequestedAmount;
    setOutstandingAmount(Utils.ConvertToFloat(outstanding.toFixed(2)));
    setBalanceAmount(outstanding);
  };

  const loadCampaignsAndEvents = async (tempOfferModels, allProducts) => {
    //Events
    var tier = anonymous
      ? null
      : business.tiers.filter((c) => c.level == pass.tierLevel)[0];
    if (!Utils.IsNullOrEmptyObject(tier)) {
      if (!Utils.IsNullOrEmptyArray(pass.events)) {
        pass.events.forEach((passEvent) => {
          if (!Utils.IsNullOrEmptyObject(passEvent)) {
            var total = 0.0;
            var offerValue = 0.0;
            var type = "";
            var value = "";
            switch (passEvent.offerType) {
              case OfferTypes.Points:
                total = passEvent.offerValue / tier.pointsRedemptionRatio;
                offerValue = passEvent.offerValue / tier.pointsRedemptionRatio;
                type = passEvent.offerValue + " Points";
                value = passEvent.offerValue.tostring();
                break;

              case OfferTypes.Cash:
                total = passEvent.offerValue;
                offerValue = passEvent.offerValue;
                type = passEvent.offerValue + " Cash";
                value = passEvent.offerValue.toString();
                break;

              case OfferTypes.PercentDiscount:
                total = (serviceItemTotalPrice * passEvent.offerValue) / 100;
                offerValue = passEvent.offerValue;
                type = "Discount " + passEvent.offerValue + "%";
                value = (
                  (serviceItemTotalPrice * passEvent.offerValue) /
                  100
                ).toString();
                break;

              case OfferTypes.Multiplier:
                total =
                  (serviceItemTotalPrice / tier.pointsEarnRatio) *
                  passEvent.offerValue;
                offerValue =
                  (serviceItemTotalPrice / tier.pointsEarnRatio) *
                  passEvent.offerValue;
                type = passEvent.offerValue + " x Points";
                value = (
                  (serviceItemTotalPrice / tier.pointsEarnRatio) *
                  passEvent.offerValue
                ).toString();
                break;

              default:
                total = 0;
                offerValue = 0;
                type = "";
                value = "Free";
                break;
            }
            var offerModel = createEventOffer(
              Utils.CreateGuid(),
              type,
              value,
              passEvent.type,
              offerValue,
              total,
              passEvent.offerType !== OfferTypes.Multiplier,
              passEvent.offerType
            );
            if (!Utils.IsNullOrEmptyObject(offerModel)) {
              tempOfferModels.push(offerModel);
            }
          }
        });
      }

      //Campaigns
      if (
        !Utils.IsNullOrEmptyArray(campaigns) &&
        campaigns.some(
          (c) =>
            c.type != CampaignTypes.Advertisement &&
            (Utils.IsNullOrEmptyArray(c.segments) ||
              c.segments.some(
                (c) =>
                  c.type === CampaignSegmentTypes.Tier && c.value === tier.level
              ) ||
              c.segments.some(
                (c) =>
                  c.type === CampaignSegmentTypes.Location &&
                  c.value === pass.locality
              ))
        ) === true
      ) {
        var filteredCampaigns = campaigns
          .filter(
            (c) =>
              c.type != CampaignTypes.Advertisement &&
              (Utils.IsNullOrEmptyArray(c.segments) ||
                c.segments.some(
                  (c) =>
                    c.type == CampaignSegmentTypes.Tier && c.value == tier.level
                ) ||
                c.segments.some(
                  (c) =>
                    c.type == CampaignSegmentTypes.Location &&
                    c.value == pass.locality
                ))
          )
          .sort((a, b) => (a.type > b.type ? 1 : 0));
        tempOfferModels = await processCampaigns(
          tempOfferModels,
          filteredCampaigns,
          tier.pointsEarnRatio,
          tier.pointsRedemptionRatio,
          allProducts
        );
      }
      // if (pass.pointsAccrued - pass.pointsExpired - pass.pointsRedeemed > 0) {
      // 	var ofTotal = (pass.pointsAccrued - pass.pointsExpired - pass.pointsRedeemed) / tier.pointsRedemptionRatio
      // 	var ofVal = pass.pointsAccrued - pass.pointsExpired - pass.pointsRedeemed
      // 	var type = ofVal + " Points"
      // 	var value = ofTotal.toString()
      // 	var pointOffer = createPointsOffer('RedeemPoints', ofTotal, ofVal, type, value)
      // 	if (!Utils.IsNullOrEmptyObject(pointOffer)) {
      // 		tempOfferModels.push(pointOffer)
      // 	}
      // }
    } else if (
      campaigns.some(
        (c) => c.type != CampaignTypes.Advertisement && c.anonymous === true
      )
    ) {
      var anonymousCampaigns = campaigns.filter(
        (c) => c.type != CampaignTypes.Advertisement && c.anonymous === true
      );
      tempOfferModels = await processCampaigns(
        tempOfferModels,
        anonymousCampaigns,
        0,
        0,
        allProducts
      );
    }
    return tempOfferModels;
  };

  const createPointsOffer = (desc, offerTotal, offerValue, type, value) => {
    return createOfferModel(
      "",
      "",
      CampaignTypes.Advertisement,
      OfferTypes.Points,
      BenefitTypes.DiscountPercentage,
      offerValue,
      desc,
      OfferClassificationTypes.AMC,
      true,
      "",
      true,
      offerTotal,
      type,
      value,
      true
    );
  };

  const createEventOffer = (
    id,
    type,
    value,
    eventType,
    offerValue,
    total,
    canRedeem,
    offerType
  ) => {
    return createOfferModel(
      id,
      "",
      CampaignTypes.Event,
      offerType,
      BenefitTypes.DiscountPercentage,
      offerValue,
      eventType,
      OfferClassificationTypes.Campaign,
      false,
      eventType,
      canRedeem,
      total,
      type,
      value
    );
  };

  const processCampaigns = async (
    tempviewmodels,
    filteredCampaigns,
    pointsEarnRatio,
    pointsRedemptionRatio,
    allProducts
  ) => {
    var camps = filteredCampaigns.filter((c) =>
      Utils.CreateMomentFromString(Utils.GetUTC()).isBefore(c.end)
    );
    if (Utils.IsNullOrEmptyArray(camps)) {
      return tempviewmodels;
    }
    for (var i = 0; i < camps.length; i++) {
      var campaign = camps[i];
      if (
        (campaign.offerType === OfferTypes.Points ||
          campaign.offerType === OfferTypes.Multiplier) &&
        (pointsEarnRatio <= 0 || pointsRedemptionRatio <= 0)
      ) {
        continue;
      }
      if (campaign.type == CampaignTypes.VisitXGetY && campaign.xValue > 1) {
        var visits = [];
        if (!anonymous && !Utils.IsNullOrEmptyObject(pass)) {
          var visitParams = [
            business.id,
            branch.branchId,
            pass.customerId,
            Utils.CreateMomentFromString(campaign.start).format(),
            Utils.CreateMomentFromString(campaign.end).format(),
          ];
          var visitResult = await postAsync(
            "Customer/GetCustomerVisit",
            {},
            "",
            "GetAll",
            "AllVisitsByCustomerByPeriod",
            visitParams
          );
          if (!visitResult.error) {
            visits = visitResult.data;
          }
        }
        if (
          Utils.IsNullOrEmptyArray(visits) ||
          visits.length + 1 < campaign.xValue
        ) {
          continue;
        }
      } else if (campaign.type == CampaignTypes.BuyXGetY) {
        var matchedCount = 0;
        for (
          var productIndex = 0;
          productIndex < campaign.productsX.length;
          productIndex++
        ) {
          var product = campaign.productsX[productIndex];
          var filterData = [];
          if (
            product.category === CampaignProductCategories.Item &&
            (serviceItems.some(
              (c) =>
                c.productId === product.value || c.productCode === product.value
            ) == false ||
              Utils.NumberSafety(
                serviceItems.filter(
                  (c) =>
                    c.productId === product.value ||
                    c.productCode === product.value
                )[0].quantity
              ) < Math.max(product.quantity, campaign.xValue))
          ) {
            continue;
          }

          filterData = serviceItems.filter(
            (c) => c.productClass === product.value
          );
          if (
            product.category === CampaignProductCategories.Class &&
            (serviceItems.some((c) => c.productClass === product.value) ===
              false ||
              (!Utils.IsNullOrEmptyArray(filterData) &&
                filterData.map((d) => d.quantity).reduce((a, b) => a + b) <
                Math.max(product.quantity, campaign.xValue)))
          ) {
            continue;
          }

          filterData = serviceItems.filter(
            (c) => c.productFineline === product.value
          );
          if (
            product.category === CampaignProductCategories.Fineline &&
            (serviceItems.some((c) => c.productFineline === product.value) ===
              false ||
              (!Utils.IsNullOrEmptyArray(filterData) &&
                filterData.map((d) => d.quantity).reduce((a, b) => a + b) <
                Math.max(product.quantity, campaign.xValue)))
          ) {
            continue;
          }

          filterData = serviceItems.filter(
            (c) => c.productDepartment === product.value
          );
          if (
            product.category === CampaignProductCategories.Department &&
            (serviceItems.some((c) => c.productDepartment === product.value) ===
              false ||
              (!Utils.IsNullOrEmptyArray(filterData) &&
                filterData.map((d) => d.quantity).reduce((a, b) => a + b) <
                Math.max(product.quantity, campaign.xValue)))
          ) {
            continue;
          }

          matchedCount += 1;
        }
        if (
          (campaign.productXAny && matchedCount <= 0) ||
          (campaign.productXAny == false &&
            matchedCount < campaign.productsX.length)
        ) {
          continue;
        }
      } else if (
        campaign.type == CampaignTypes.SpendXGetY &&
        campaign.xValue > 0 &&
        serviceItems.map((c) => c.TotalPrice).reduce((a, b) => a + b) <
        campaign.xValue
      ) {
        continue;
      }
      var total = 0;
      var offerValue = 0;
      var type = "";
      var value = "";
      switch (campaign.offerType) {
        case OfferTypes.Points:
          if (pointsRedemptionRatio > 0) {
            total = campaign.offerValue / pointsRedemptionRatio;
            offerValue = campaign.offerValue / pointsRedemptionRatio;
            type = campaign.offerValue + " Points";
            value = campaign.offerValue.toString();
          }
          break;

        case OfferTypes.Cash:
          total = campaign.offerValue;
          offerValue = campaign.offerValue;
          type = campaign.offerValue + " Cash";
          value = campaign.offerValue.toString();
          break;

        case OfferTypes.PercentDiscount:
          if (
            campaign.type == CampaignTypes.BuyXGetY &&
            !Utils.IsNullOrEmptyArray(campaign.productsY)
          ) {
            var yTotal = 0;
            campaign.productsY.forEach((product) => {
              var filteredItems = serviceItems.filter(
                (s) =>
                  s.productCode === product.value ||
                  s.productId == product.value
              );
              yTotal += !Utils.IsNullOrEmptyArray(filteredItems)
                ? filteredItems.map((s) => s.TotalPrice).reduce((a, b) => a + b)
                : 0;
            });
            total = (yTotal * campaign.offerValue) / 100;
            value = ((yTotal * campaign.offerValue) / 100).toString();
          } else {
            total = (serviceItemTotalPrice * campaign.offerValue) / 100;
            value = (
              (serviceItemTotalPrice * campaign.offerValue) /
              100
            ).toString();
          }
          offerValue = campaign.offerValue;
          type = "Discount " + campaign.offerValue + "%";
          break;

        case OfferTypes.Multiplier:
          if (pointsEarnRatio > 0) {
            total =
              (serviceItemTotalPrice / pointsEarnRatio) * campaign.offerValue;
            offerValue = campaign.offerValue;
            type = campaign.offerValue + " x Points";
            value = (
              (serviceItemTotalPrice / pointsEarnRatio) *
              campaign.offerValue
            ).toString();
          }
          break;
        default:
          total = 0;
          offerValue = 0;
          type = "";
          value = "Free";
          break;
      }
      var offerModel = createCampaignOfferModel(
        campaign.id,
        campaign.type,
        campaign.offerType != OfferTypes.Multiplier,
        campaign.name,
        total,
        campaign.offerType,
        offerValue,
        value,
        type
      );
      if (!Utils.IsNullOrEmptyObject(offerModel)) {
        tempviewmodels.push(offerModel);
      }
    }
    return tempviewmodels;
  };

  const createCampaignOfferModel = (
    id,
    campaignType,
    canRedeem,
    desc,
    offerTotal,
    offerType,
    offerValue,
    value,
    type
  ) => {
    return createOfferModel(
      id,
      "",
      campaignType,
      offerType,
      BenefitTypes.DiscountPercentage,
      offerValue,
      desc,
      OfferClassificationTypes.Campaign,
      false,
      PassEventTypes.Birthday,
      canRedeem,
      offerTotal,
      type,
      value
    );
  };

  const loadAMCPlans = async (tempOfferModels, allProducts) => {
    var utcDate = Utils.CreateMomentFromString(Utils.GetDateFromUTC());
    var currentLocalDate = Utils.CreateMomentFromString(
      Utils.GetCurrentDateFromLocal()
    );
    var result = await postAsync("AMC/GetActiveCustomerAMCPlans", {
      BusinessId: business.id,
      PassId: pass.id,
      VehicleNumber: vehicleDetails.number,
    });
    if (result.error) {
      setIsNavigateToLogin(result.isNavigateToLogin);
      setAlert(result.errorMessage);
      return;
    }
    if (Utils.IsNullOrEmptyArray(result.data)) {
      return;
    }
    var validcustomerAMCPlans = result.data.filter(
      (a) => utcDate.isAfter(a.startDT) && utcDate.isBefore(a.endDT)
    );
    var currentPlan = validcustomerAMCPlans[0];
    if (Utils.IsNullOrEmptyObject(currentPlan)) {
      return;
    }
    var validBenefits = currentPlan.benefits.filter(
      (b) =>
        (b.maximumNoOfRedemptions === 0 || b.remainingRedemptions > 0) &&
        (b.daysInBetweenRedemption === 0 ||
          Utils.IsNullOrEmptyArray(b.redemptions) ||
          currentLocalDate.isAfter(
            Utils.GetDateFromMoment(
              Utils.CreateMomentFromString(
                Utils.GetLastArrayElement(b.redemptions).redemptionDT
              ).add(b.daysInBetweenRedemption, "days")
            )
          ))
    );
    if (Utils.IsNullOrEmptyArray(validBenefits)) {
      return;
    }
    const tempApplicableItems = [...applicableServiceItems];
    validBenefits.forEach((benefit) => {
      var applicableItem = {
        BenefitId: benefit.benefitId,
        BenefitType: benefit.benefitType,
        BenefitApplicableFor: benefit.benefitApplicableFor,
        ApplicableBenefitClassificationType:
          benefit.applicableBenefitClassificationType,
        Items: [],
      };
      var newItems = [];
      switch (benefit.benefitType) {
        case BenefitTypes.Free:
          benefit.items.forEach((i) => {
            newItems.push(i.itemId);
          });
          break;
        default:
          if (
            benefit.applicableBenefitClassificationType ===
            ClassificationTypes.All
          ) {
            var allProds = getProductsByType(
              allProducts,
              benefit.benefitApplicableFor
            );
            if (!Utils.IsNullOrEmptyArray(allProds)) {
              allProds.forEach((prod) => {
                if (
                  !Utils.IsNullOrEmptyObject(
                    masterServiceItemsList.find((v) => v.ProductId === prod.id)
                  )
                ) {
                  newItems.push(prod.id);
                }
              });
            }
          } else {
            benefit.items.forEach((item) => {
              var prods = getProductsByCategoryAndDepartment(
                item,
                allProducts,
                benefit.benefitApplicableFor,
                benefit.applicableBenefitClassificationType
              );
              if (!Utils.IsNullOrEmptyArray(prods)) {
                prods.forEach((prod) => {
                  if (
                    !Utils.IsNullOrEmptyObject(
                      masterServiceItemsList.find(
                        (v) => v.ProductId === prod.id
                      )
                    )
                  ) {
                    newItems.push(prod.id);
                  }
                });
              }
            });
          }
          break;
      }
      if (!Utils.IsNullOrEmptyArray(newItems)) {
        applicableItem.Items = newItems;
      }
      tempApplicableItems.push(applicableItem);
      var offerModel = createAMC(
        currentPlan.planId,
        currentPlan.planName,
        benefit
      );
      if (
        !Utils.IsNullOrEmptyObject(offerModel) &&
        !Utils.IsNullOrEmptyArray(applicableItem.Items)
      ) {
        tempOfferModels.push(offerModel);
      }
    });
    setApplicableServiceItems(tempApplicableItems);
    return { offers: tempOfferModels, applicableItems: tempApplicableItems };
  };

  const getProductsByType = (allProducts, benefitApplicableFor) => {
    switch (benefitApplicableFor) {
      case ServiceItemTypes.Consumable:
        return getConsumables(allProducts);
      case ServiceItemTypes.Spare:
        return getSpares(allProducts);
      case ServiceItemTypes.Service:
        return getServices(allProducts);
      case ServiceItemTypes.Labour:
        return getLabours(allProducts);
      default:
        return [];
    }
  };

  const getProductsByCategoryAndDepartment = (
    itemDetails,
    allProducts,
    benefitApplicableFor,
    applicableBenefitClassificationType
  ) => {
    var productsByCategory = getProductsByType(
      allProducts,
      benefitApplicableFor
    );
    if (Utils.IsNullOrEmptyArray(productsByCategory)) {
      return null;
    }
    switch (applicableBenefitClassificationType) {
      case ClassificationTypes.All:
        return productsByCategory;
      case ClassificationTypes.Class:
        return productsByCategory.filter((p) => p.class === itemDetails.name);
      case ClassificationTypes.Department:
        return productsByCategory.filter(
          (p) => p.department === itemDetails.name
        );
      case ClassificationTypes.Item:
        return productsByCategory.filter((p) => p.id === itemDetails.itemId);
    }
  };

  const createAMC = (campaignId, planName, benefit) => {
    return createOfferModel(
      campaignId,
      benefit.benefitId,
      CampaignTypes.Advertisement,
      OfferTypes.PercentDiscount,
      benefit.benefitType,
      benefit.benefitValue,
      planName + " - " + benefit.description,
      OfferClassificationTypes.AMC,
      false
    );
  };

  const createOfferModel = (
    campaignId,
    benefitId,
    campaignType,
    discountType,
    benefitType,
    discountValue,
    desc,
    offerClassification,
    orderLevel,
    eventType = "",
    canRedeem = true,
    discountTotal = 0,
    type = "",
    value = "",
    pointsRedemption = false
  ) => {
    if (pointsRedemption) {
      return {};
    }
    if (Utils.IsNullOrEmpty(eventType)) {
      eventType = PassEventTypes.Birthday;
    }
    return {
      CampaignId: campaignId,
      BenefitId: benefitId,
      CampaignType: campaignType,
      DiscountType: discountType,
      CanRedeem: canRedeem,
      EventType: eventType,
      BenefitType: benefitType,
      OrderLevel: orderLevel,
      DiscountValue: discountValue,
      DiscountTotal: discountTotal,
      OffersClassificationType: offerClassification,
      Description: desc,
      Selected: false,
      Type: type,
      Value: value,
      PointsRedemption: pointsRedemption,
    };
  };

  // From Address
  const [isOpenedDifferentFromAddress, setIsOpenedDifferentFromAddress] =
    useState(false);
  function toggleFromAddress() {
    setShippingFromAddressDefaultEnabled(true);
    setIsOpenedDifferentFromAddress(false);
  }
  function toggleDifferentFromAddress() {
    setShippingFromAddressDefaultEnabled(false);
    setIsOpenedDifferentFromAddress(true);
  }

  const handleOfferSelection = (offer, index) => {
    if (Utils.IsNullOrEmptyObject(offer)) {
      return;
    }
    var tempOffers = [...offersViewModel];
    var tempServiceItems = [...serviceItems];
    var tempOrderLevelDiscounts = [...orderLevelDiscounts];
    var data = processOffer(
      tempOffers[index],
      tempServiceItems,
      tempOrderLevelDiscounts
    );
    tempOffers[index] = data.offer;
    tempServiceItems = data.serviceItems;
    tempOrderLevelDiscounts = data.orderDiscounts;
    tempServiceItems = calculateBiggestDiscount(tempServiceItems, tempOffers);
    setServiceItems(tempServiceItems);
    tempOrderLevelDiscounts = recalculateOrderDiscounts(
      tempServiceItems,
      tempOrderLevelDiscounts
    );
    tempOffers = recalculateOfferValues(tempOffers);
    setOffersViewModel(tempOffers);
    setOrderLevelDiscounts(tempOrderLevelDiscounts);
  };

  const getOrderLevelDiscounts = (
    tempServiceItems,
    tempOrderLevelDiscounts
  ) => {
    if (Utils.IsNullOrEmptyArray(tempServiceItems)) {
      return;
    }
    var updatedOrderLevelDiscounts = [];
    tempServiceItems.forEach((item) => {
      if (
        !Utils.IsNullOrEmptyArray(item.Discounts) &&
        !Utils.IsNullOrEmptyArray(
          item.Discounts.filter((d) => d.OrderLevel === true)
        )
      ) {
        item.Discounts.filter((d) => d.OrderLevel === true).forEach(
          (discount) => {
            updatedOrderLevelDiscounts.push(discount);
          }
        );
      }
    });
    if (Utils.IsNullOrEmptyArray(updatedOrderLevelDiscounts)) {
      return tempOrderLevelDiscounts;
    }
    //const tempOrderLevelDiscounts = [...orderLevelDiscounts]
    updatedOrderLevelDiscounts.forEach((discount) => {
      var filteredDiscounts = updatedOrderLevelDiscounts.filter(
        (d) => d.CampaignId === discount.CampaignId
      );
      if (
        Utils.IsNullOrEmptyObject(
          tempOrderLevelDiscounts.find(
            (d) => d.CampaignId === discount.CampaignId
          )
        )
      ) {
        var newDiscount = {
          CampaignId: filteredDiscounts[0].CampaignId,
          Description: filteredDiscounts[0].Description,
          DescriptionDetail: filteredDiscounts[0].DescriptionDetail,
          DiscountTotal: filteredDiscounts
            .map((i) => i.DiscountTotal)
            .reduce((a, b) => a + b),
        };
        tempOrderLevelDiscounts.push(newDiscount);
      }
    });
    return tempOrderLevelDiscounts;
    //setOrderLevelDiscounts(tempOrderLevelDiscounts)
  };

  const removeOrderLevelDiscounts = (
    tempOrderLevelDiscounts,
    tempServiceItems
  ) => {
    if (
      Utils.IsNullOrEmptyArray(tempOrderLevelDiscounts) ||
      Utils.IsNullOrEmptyArray(tempServiceItems)
    ) {
      return tempOrderLevelDiscounts;
    }
    var indexesToRemove = [];
    tempOrderLevelDiscounts.forEach((discount, i) => {
      if (
        !tempServiceItems.some(
          (s) =>
            !Utils.IsNullOrEmptyArray(s.Discounts) &&
            s.Discounts.some((d) => d.CampaignId === discount.CampaignId)
        )
      ) {
        indexesToRemove.push(i);
      }
    });
    if (!Utils.IsNullOrEmptyArray(indexesToRemove)) {
      indexesToRemove.forEach((i) => {
        tempOrderLevelDiscounts.splice(i, 1);
      });
    }
    return tempOrderLevelDiscounts;
  };

  const processOffer = (
    offer,
    tempServiceItems,
    tempOrderLevelDiscounts,
    applicableItems = []
  ) => {
    if (Utils.IsNullOrEmptyObject(offer)) {
      return {
        offer: offer,
        serviceItems: tempServiceItems,
        orderDiscounts: tempOrderLevelDiscounts,
      };
    }
    offer.Selected = !offer.Selected;
    switch (offer.OffersClassificationType) {
      case OfferClassificationTypes.Campaign:
        var campaign = campaigns.find((c) => c.id === offer.CampaignId);
        if (!Utils.IsNullOrEmptyObject(campaign)) {
          //Remove Logic
          if (!offer.Selected) {
            switch (offer.DiscountType) {
              case OfferTypes.Free:
                var removeIndexes = [];
                tempServiceItems.forEach((item, index) => {
                  if (!Utils.IsNullOrEmptyArray(item.Discounts)) {
                    if (
                      !Utils.IsNullOrEmptyObject(
                        item.Discounts.find((c) => c.CampaignId === campaign.id)
                      )
                    ) {
                      removeIndexes.push(index);
                    }
                  }
                });
                if (!Utils.IsNullOrEmptyArray(removeIndexes)) {
                  removeIndexes.forEach((i) => {
                    if (
                      masterServiceItemsList.some(
                        (s) => s.ProductId === tempServiceItems[i].ProductId
                      )
                    ) {
                      tempServiceItems[i].Discounts = [];
                      tempServiceItems[i].DiscountTotal = 0;
                    } else {
                      tempServiceItems.splice(i, 1);
                    }
                  });
                }
                break;
              default:
                tempServiceItems
                  .filter(
                    (s) =>
                      !Utils.IsNullOrEmptyArray(s.Discounts) &&
                      s.Discounts.some(
                        (d) =>
                          d.DiscountType !== OfferTypes.Free &&
                          d.BenefitType !== BenefitTypes.Free
                      )
                  )
                  .forEach((item) => {
                    var discountIndex = item.Discounts.findIndex(
                      (d) => d.CampaignId === offer.CampaignId
                    );
                    item.Discounts.splice(discountIndex, 1);
                    item.DiscountTotal = Utils.IsNullOrEmptyArray(
                      item.Discounts
                    )
                      ? 0
                      : item.Discounts.map((s) => s.DiscountTotal).reduce(
                        (a, b) => a + b
                      );
                  });
                tempServiceItems =
                  recalculateTaxAmountOnDiscountApply(tempServiceItems);
                tempOrderLevelDiscounts = removeOrderLevelDiscounts(
                  tempOrderLevelDiscounts,
                  tempServiceItems
                );
                break;
            }
          }
          //Add Logic
          else {
            switch (campaign.type) {
              case CampaignTypes.BuyXGetY:
                var isValidPurchasedItems = false;
                if (!campaign.productXAny) {
                  isValidPurchasedItems = masterServiceItemsList.every(
                    (item) => {
                      var prod = campaign.productsX.find((prodX) => {
                        switch (prodX.category) {
                          case CampaignProductCategories.Item:
                            return (
                              prodX.value === item.ProductId ||
                              prodX.value === item.ProductCode
                            );
                          case CampaignProductCategories.Fineline:
                            return prodX.value === item.ProductFineline;
                          case CampaignProductCategories.Department:
                            return prodX.value === item.ProductDepartment;
                          case CampaignProductCategories.Class:
                            return prodX.value === item.ProductClass;
                        }
                      });
                      return !Utils.IsNullOrEmptyObject(prod);
                    }
                  );
                } else {
                  isValidPurchasedItems = masterServiceItemsList.some(
                    (item) => {
                      var prod = campaign.productsX.find((prodX) => {
                        switch (prodX.category) {
                          case CampaignProductCategories.Item:
                            return (
                              prodX.value === item.ProductId ||
                              prodX.value === item.ProductCode
                            );
                          case CampaignProductCategories.Fineline:
                            return prodX.value === item.ProductFineline;
                          case CampaignProductCategories.Department:
                            return prodX.value === item.ProductDepartment;
                          case CampaignProductCategories.Class:
                            return prodX.value === item.ProductClass;
                        }
                      });
                      return !Utils.IsNullOrEmptyObject(prod);
                    }
                  );
                }
                if (isValidPurchasedItems) {
                  switch (campaign.offerType) {
                    case OfferTypes.Free:
                      campaign.productsY.forEach((prodYItem) => {
                        if (
                          !Utils.IsNullOrEmptyObject(
                            masterServiceItemsList.find((item) => {
                              switch (prodYItem.category) {
                                case CampaignProductCategories.Item:
                                  return (
                                    prodYItem.value === item.ProductId ||
                                    prodYItem.value === item.ProductCode
                                  );
                                case CampaignProductCategories.Fineline:
                                  return (
                                    prodYItem.value === item.ProductFineline
                                  );
                                case CampaignProductCategories.Department:
                                  return (
                                    prodYItem.value === item.ProductDepartment
                                  );
                                case CampaignProductCategories.Class:
                                  return prodYItem.value === item.ProductClass;
                              }
                            })
                          )
                        ) {
                          var itemIndex = tempServiceItems.findIndex((s) => {
                            switch (prodYItem.category) {
                              case CampaignProductCategories.Item:
                                return (
                                  prodYItem.value === s.ProductId ||
                                  prodYItem.value === s.ProductCode
                                );
                              case CampaignProductCategories.Fineline:
                                return prodYItem.value === s.ProductFineline;
                              case CampaignProductCategories.Department:
                                return prodYItem.value === s.ProductDepartment;
                              case CampaignProductCategories.Class:
                                return prodYItem.value === s.ProductClass;
                            }
                          });
                          tempServiceItems[itemIndex].Quantity =
                            campaign.offerValue > 0
                              ? campaign.offerValue
                              : tempServiceItems[itemIndex].Quantity;
                          var discount = {
                            CampaignId: campaign.id,
                            BenefitId: "",
                            CampaignType: campaign.type,
                            Code: tempServiceItems[itemIndex].ProductId,
                            BenefitType: "DiscountPercentage",
                            Description: campaign.name,
                            DescriptionDetail:
                              "Free " + tempServiceItems[itemIndex].Description,
                            Complimentary: true,
                            OrderLevel: false,
                            DiscountTotal:
                              tempServiceItems[itemIndex].Price *
                              tempServiceItems[itemIndex].Quantity,
                            OffersClassificationType:
                              offer.OffersClassificationType,
                            DiscountType: OfferTypes.Free,
                            DiscountValue: offer.DiscountValue,
                            Quantity: tempServiceItems[itemIndex].Quantity,
                            PointsRedemption: false,
                          };
                          tempServiceItems[itemIndex].Discounts = [];
                          tempServiceItems[itemIndex].Discounts.push(discount);
                          tempServiceItems[itemIndex].DiscountTotal =
                            discount.DiscountTotal;
                          tempServiceItems[itemIndex].TaxAmount = 0;
                        } else {
                          var serviceItem = getServiceItemFromProduct(
                            productsInventory.find((product) => {
                              switch (prodYItem.category) {
                                case CampaignProductCategories.Item:
                                  return (
                                    prodYItem.value === product.id ||
                                    prodYItem.value === product.code
                                  );
                                case CampaignProductCategories.Fineline:
                                  return prodYItem.value === product.fineline;
                                case CampaignProductCategories.Department:
                                  return prodYItem.value === product.department;
                                case CampaignProductCategories.Class:
                                  return prodYItem.value === product.class;
                              }
                            })
                          );
                          if (!Utils.IsNullOrEmptyObject(serviceItem)) {
                            serviceItem.Quantity =
                              campaign.offerValue > 0
                                ? campaign.offerValue
                                : tempServiceItems[itemIndex].Quantity;
                            serviceItem.Sequence =
                              Utils.GetLastArrayElement(tempServiceItems)
                                .Sequence + 1;
                            var discount = {
                              CampaignId: campaign.id,
                              BenefitId: "",
                              BenefitType: "DiscountPercentage",
                              CampaignType: campaign.type,
                              Code: serviceItem.ProductId,
                              Description: campaign.name,
                              Complimentary: true,
                              OrderLevel: false,
                              DescriptionDetail:
                                "Free " + serviceItem.Description,
                              DiscountTotal:
                                serviceItem.Price * serviceItem.Quantity,
                              OffersClassificationType:
                                offer.OffersClassificationType,
                              DiscountType: OfferTypes.Free,
                              DiscountValue: offer.DiscountValue,
                              Quantity: serviceItem.Quantity,
                              PointsRedemption: false,
                            };
                            serviceItem.Discounts.push(discount);
                            serviceItem.DiscountTotal = discount.DiscountTotal;
                            serviceItem.TaxAmount = 0;
                            tempServiceItems.push(serviceItem);
                          }
                        }
                      });
                      break;
                    case OfferTypes.Multiplier:
                      break;
                    case OfferTypes.PercentDiscount:
                      campaign.productsY.forEach((prodYItem) => {
                        if (
                          !Utils.IsNullOrEmptyObject(
                            masterServiceItemsList.find((item) => {
                              switch (prodYItem.category) {
                                case CampaignProductCategories.Item:
                                  return (
                                    prodYItem.value === item.ProductId ||
                                    prodYItem.value === item.ProductCode
                                  );
                                case CampaignProductCategories.Fineline:
                                  return (
                                    prodYItem.value === item.ProductFineline
                                  );
                                case CampaignProductCategories.Department:
                                  return (
                                    prodYItem.value === item.ProductDepartment
                                  );
                                case CampaignProductCategories.Class:
                                  return prodYItem.value === item.ProductClass;
                              }
                            })
                          )
                        ) {
                          var itemIndex = tempServiceItems.findIndex((s) => {
                            switch (prodYItem.category) {
                              case CampaignProductCategories.Item:
                                return (
                                  prodYItem.value === s.ProductId ||
                                  prodYItem.value === s.ProductCode
                                );
                              case CampaignProductCategories.Fineline:
                                return prodYItem.value === s.ProductFineline;
                              case CampaignProductCategories.Department:
                                return prodYItem.value === s.ProductDepartment;
                              case CampaignProductCategories.Class:
                                return prodYItem.value === s.ProductClass;
                            }
                          });
                          var discountTotal =
                            ((tempServiceItems[itemIndex].Price *
                              tempServiceItems[itemIndex].Quantity -
                              tempServiceItems[itemIndex].DiscountTotal) *
                              campaign.offerValue) /
                            100;
                          var discount = {
                            CampaignId: campaign.id,
                            BenefitId: "",
                            CampaignType: campaign.type,
                            BenefitType: "DiscountPercentage",
                            OrderLevel: false,
                            Complimentary: false,
                            Code: tempServiceItems[itemIndex].ProductId,
                            Description: campaign.name,
                            DescriptionDetail:
                              tempServiceItems[itemIndex].Description +
                              campaign.offerValue +
                              " %",
                            DiscountTotal: discountTotal,
                            OffersClassificationType:
                              offer.OffersClassificationType,
                            DiscountType: campaign.offerType,
                            DiscountValue: offer.DiscountValue,
                            Quantity: tempServiceItems[itemIndex].Quantity,
                            PointsRedemption: false,
                          };
                          tempServiceItems[itemIndex].Discounts.push(discount);
                          tempServiceItems[itemIndex].DiscountTotal =
                            tempServiceItems[itemIndex].Discounts.map(
                              (i) => i.DiscountTotal
                            ).reduce((a, b) => a + b);
                          tempServiceItems =
                            recalculateTaxAmountOnDiscountApply(
                              tempServiceItems
                            );
                        }
                      });
                      break;
                    case OfferTypes.Cash:
                      var totalSum = tempServiceItems
                        .filter(
                          (t) =>
                            Utils.IsNullOrEmptyArray(t.Discounts) ||
                            t.Discounts.some(
                              (d) => d.DiscountType !== OfferTypes.Free
                            )
                        )
                        .map((i) => i.TotalPrice)
                        .reduce((a, b) => a + b);
                      var totalDiscountSum = tempServiceItems
                        .filter(
                          (t) =>
                            Utils.IsNullOrEmptyArray(t.Discounts) ||
                            t.Discounts.some(
                              (d) => d.DiscountType !== OfferTypes.Free
                            )
                        )
                        .map((i) => i.DiscountTotal)
                        .reduce((a, b) => a + b);
                      var updatedCashValue =
                        (offer.DiscountValue / (totalSum - totalDiscountSum)) *
                        100;
                      tempServiceItems
                        .filter(
                          (t) =>
                            Utils.IsNullOrEmptyArray(t.Discounts) ||
                            t.Discounts.some(
                              (d) => d.DiscountType !== OfferTypes.Free
                            )
                        )
                        .forEach((item) => {
                          var itemTotalPrice = item.Price * item.Quantity;
                          var discountTotal =
                            ((itemTotalPrice - item.DiscountTotal) *
                              updatedCashValue) /
                            100;
                          var discount = {
                            CampaignId: campaign.id,
                            BenefitId: "",
                            Complimentary: false,
                            CampaignType: campaign.type,
                            BenefitType: "DiscountPercentage",
                            Code: "Cash Back",
                            Description: campaign.name,
                            DescriptionDetail:
                              Utils.GetCurrency(
                                offer.DiscountValue,
                                countryInfo
                              ) + " Cash Back",
                            DiscountTotal: discountTotal,
                            OffersClassificationType:
                              offer.OffersClassificationType,
                            DiscountType: campaign.offerType,
                            DiscountValue: offer.DiscountValue,
                            Quantity: item.Quantity,
                            OrderLevel: true,
                            PointsRedemption: false,
                          };
                          if (
                            item.Discounts.every(
                              (d) =>
                                d.BenefitType !== BenefitTypes.Free &&
                                d.OfferType !== OfferTypes.Free
                            )
                          ) {
                            item.Discounts.push(discount);
                          }
                          item.DiscountTotal = item.Discounts.map(
                            (i) => i.DiscountTotal
                          ).reduce((a, b) => a + b);
                        });
                      tempServiceItems =
                        recalculateTaxAmountOnDiscountApply(tempServiceItems);
                      break;
                    case OfferTypes.Points:
                      break;
                  }
                }
                break;
              case CampaignTypes.SpendXGetY:
                switch (campaign.offerType) {
                  case OfferTypes.Free:
                    if (!Utils.IsNullOrEmptyArray(campaign.productsY)) {
                      campaign.productsY.forEach((prodYItem) => {
                        if (
                          !Utils.IsNullOrEmptyObject(
                            masterServiceItemsList.find(
                              (s) =>
                                prodYItem.value === s.ProductId ||
                                prodYItem.value === s.ProductCode
                            )
                          )
                        ) {
                          var itemIndex = tempServiceItems.findIndex(
                            (s) =>
                              prodYItem.value === s.ProductId ||
                              prodYItem.value === s.ProductCode
                          );
                          tempServiceItems[itemIndex].Quantity =
                            campaign.offerValue > 0
                              ? campaign.offerValue
                              : tempServiceItems[itemIndex].Quantity;
                          var discount = {
                            CampaignId: campaign.id,
                            CampaignType: campaign.type,
                            Code: tempServiceItems[itemIndex].ProductId,
                            BenefitType: "DiscountPercentage",
                            Complimentary: false,
                            BenefitId: "",
                            Description: campaign.name,
                            DescriptionDetail:
                              "Free " + tempServiceItems[itemIndex].Description,
                            DiscountTotal:
                              tempServiceItems[itemIndex].Price *
                              tempServiceItems[itemIndex].Quantity,
                            OffersClassificationType:
                              offer.OffersClassificationType,
                            DiscountType: OfferTypes.Free,
                            DiscountValue: offer.DiscountValue,
                            Quantity: tempServiceItems[itemIndex].Quantity,
                            OrderLevel: false,
                            PointsRedemption: false,
                          };
                          tempServiceItems[itemIndex].Discounts = [];
                          tempServiceItems[itemIndex].Discounts.push(discount);
                          tempServiceItems[itemIndex].DiscountTotal =
                            discount.DiscountTotal;
                          tempServiceItems[itemIndex].TaxAmount = 0;
                        } else {
                          var serviceItem = getServiceItemFromProduct(
                            productsInventory.find(
                              (p) =>
                                p.id === prodYItem.value ||
                                p.code === prodYItem.value
                            )
                          );
                          if (!Utils.IsNullOrEmptyObject(serviceItem)) {
                            serviceItem.Sequence =
                              Utils.GetLastArrayElement(tempServiceItems)
                                .Sequence + 1;
                            serviceItem.Quantity =
                              campaign.offerValue > 0
                                ? campaign.offerValue
                                : tempServiceItems[itemIndex].Quantity;
                            var discount = {
                              CampaignId: campaign.id,
                              CampaignType: campaign.type,
                              BenefitId: "",
                              BenefitType: "DiscountPercentage",
                              Code: serviceItem.ProductId,
                              Description: campaign.name,
                              DescriptionDetail:
                                "Free " + serviceItem.Description,
                              DiscountTotal:
                                serviceItem.Price * serviceItem.Quantity,
                              OffersClassificationType:
                                offer.OffersClassificationType,
                              DiscountType: OfferTypes.Free,
                              DiscountValue: offer.DiscountValue,
                              Quantity: serviceItem.Quantity,
                              Complimentary: false,
                              OrderLevel: false,
                              PointsRedemption: false,
                            };
                            serviceItem.Discounts.push(discount);
                            serviceItem.DiscountTotal = discount.DiscountTotal;
                            serviceItem.TaxAmount = 0;
                            tempServiceItems.push(serviceItem);
                          }
                        }
                      });
                    }
                    break;
                  case OfferTypes.Cash:
                    var totalSum = tempServiceItems
                      .filter(
                        (t) =>
                          Utils.IsNullOrEmptyArray(t.Discounts) ||
                          t.Discounts.some(
                            (d) => d.DiscountType !== OfferTypes.Free
                          )
                      )
                      .map((i) => i.TotalPrice)
                      .reduce((a, b) => a + b);
                    var totalDiscountSum = tempServiceItems
                      .filter(
                        (t) =>
                          Utils.IsNullOrEmptyArray(t.Discounts) ||
                          t.Discounts.some(
                            (d) => d.DiscountType !== OfferTypes.Free
                          )
                      )
                      .map((i) => i.DiscountTotal)
                      .reduce((a, b) => a + b);
                    var updatedCashValue =
                      (offer.DiscountValue / (totalSum - totalDiscountSum)) *
                      100;
                    tempServiceItems
                      .filter(
                        (t) =>
                          Utils.IsNullOrEmptyArray(t.Discounts) ||
                          t.Discounts.some(
                            (d) => d.DiscountType !== OfferTypes.Free
                          )
                      )
                      .forEach((item) => {
                        var itemTotalPrice = item.Price * item.Quantity;
                        var discountTotal =
                          ((itemTotalPrice - item.DiscountTotal) *
                            updatedCashValue) /
                          100;
                        var discount = {
                          CampaignId: campaign.id,
                          CampaignType: campaign.type,
                          Code: "Cash Back",
                          BenefitId: "",
                          BenefitType: "DiscountPercentage",
                          Description: campaign.name,
                          DescriptionDetail:
                            Utils.GetCurrency(
                              offer.DiscountValue,
                              countryInfo
                            ) + " Cash Back",
                          DiscountTotal: discountTotal,
                          OffersClassificationType:
                            offer.OffersClassificationType,
                          DiscountType: campaign.offerType,
                          DiscountValue: offer.DiscountValue,
                          Quantity: item.Quantity,
                          OrderLevel: true,
                          PointsRedemption: false,
                          Complimentary: false,
                        };
                        if (
                          item.Discounts.every(
                            (d) =>
                              d.BenefitType !== BenefitTypes.Free &&
                              d.OfferType !== OfferTypes.Free
                          )
                        ) {
                          item.Discounts.push(discount);
                        }
                        item.DiscountTotal = item.Discounts.map(
                          (i) => i.DiscountTotal
                        ).reduce((a, b) => a + b);
                      });
                    tempServiceItems =
                      recalculateTaxAmountOnDiscountApply(tempServiceItems);
                    break;
                  case OfferTypes.PercentDiscount:
                    tempServiceItems
                      .filter(
                        (t) =>
                          Utils.IsNullOrEmptyArray(t.Discounts) ||
                          t.Discounts.some(
                            (d) => d.DiscountType !== OfferTypes.Free
                          )
                      )
                      .forEach((item) => {
                        var discountTotal =
                          ((item.Price * item.Quantity - item.DiscountTotal) *
                            offer.DiscountValue) /
                          100;
                        var discount = {
                          CampaignId: campaign.id,
                          CampaignType: campaign.type,
                          Code: "Percent Discount",
                          BenefitId: "",
                          BenefitType: "DiscountPercentage",
                          Description: campaign.name,
                          DescriptionDetail:
                            item.Description + campaign.offerValue + "%",
                          DiscountTotal: discountTotal,
                          OffersClassificationType:
                            offer.OffersClassificationType,
                          DiscountType: campaign.offerType,
                          DiscountValue: offer.DiscountValue,
                          Quantity: item.Quantity,
                          OrderLevel: true,
                          PointsRedemption: false,
                          Complimentary: false,
                        };
                        if (
                          item.Discounts.every(
                            (d) =>
                              d.BenefitType !== BenefitTypes.Free &&
                              d.OfferType !== OfferTypes.Free
                          )
                        ) {
                          item.Discounts.push(discount);
                        }
                        item.DiscountTotal = item.Discounts.map(
                          (i) => i.DiscountTotal
                        ).reduce((a, b) => a + b);
                      });
                    tempServiceItems =
                      recalculateTaxAmountOnDiscountApply(tempServiceItems);
                    break;
                }
                break;
              case CampaignTypes.VisitXGetY:
                switch (campaign.offerType) {
                  case OfferTypes.Free:
                    if (!Utils.IsNullOrEmptyArray(campaign.productsY)) {
                      campaign.productsY.forEach((prodYItem) => {
                        if (
                          !Utils.IsNullOrEmptyObject(
                            masterServiceItemsList.find(
                              (s) =>
                                prodYItem.value === s.ProductId ||
                                prodYItem.value === s.ProductCode
                            )
                          )
                        ) {
                          var itemIndex = tempServiceItems.findIndex(
                            (s) =>
                              prodYItem.value === s.ProductId ||
                              prodYItem.value === s.ProductCode
                          );
                          tempServiceItems[itemIndex].Quantity =
                            campaign.offerValue > 0
                              ? campaign.offerValue
                              : tempServiceItems[itemIndex].Quantity;
                          var discount = {
                            CampaignId: campaign.id,
                            CampaignType: campaign.type,
                            BenefitId: "",
                            BenefitType: "DiscountPercentage",
                            Code: tempServiceItems[itemIndex].ProductId,
                            Description: campaign.name,
                            DescriptionDetail:
                              "Free " + tempServiceItems[itemIndex].Description,
                            DiscountTotal:
                              tempServiceItems[itemIndex].Price *
                              tempServiceItems[itemIndex].Quantity,
                            OffersClassificationType:
                              offer.OffersClassificationType,
                            DiscountType: OfferTypes.Free,
                            DiscountValue: offer.DiscountValue,
                            Quantity: tempServiceItems[itemIndex].Quantity,
                            OrderLevel: false,
                            PointsRedemption: false,
                            Complimentary: false,
                          };
                          tempServiceItems[itemIndex].Discounts = [];
                          tempServiceItems[itemIndex].Discounts.push(discount);
                          tempServiceItems[itemIndex].DiscountTotal =
                            discount.DiscountTotal;
                          tempServiceItems[itemIndex].TaxAmount = 0;
                        } else {
                          var serviceItem = getServiceItemFromProduct(
                            productsInventory.find(
                              (p) =>
                                p.id === prodYItem.value ||
                                p.code === prodYItem.value
                            )
                          );
                          if (!Utils.IsNullOrEmptyObject(serviceItem)) {
                            serviceItem.Sequence =
                              Utils.GetLastArrayElement(tempServiceItems)
                                .Sequence + 1;
                            serviceItem.Quantity =
                              campaign.offerValue > 0
                                ? campaign.offerValue
                                : tempServiceItems[itemIndex].Quantity;
                            var discount = {
                              CampaignId: campaign.id,
                              CampaignType: campaign.type,
                              Code: serviceItem.ProductId,
                              Description: campaign.name,
                              BenefitId: "",
                              BenefitType: "DiscountPercentage",
                              DescriptionDetail:
                                "Free " + serviceItem.Description,
                              DiscountTotal:
                                serviceItem.Price * serviceItem.Quantity,
                              OffersClassificationType:
                                offer.OffersClassificationType,
                              DiscountType: OfferTypes.Free,
                              DiscountValue: offer.DiscountValue,
                              Quantity: serviceItem.Quantity,
                              OrderLevel: false,
                              PointsRedemption: false,
                              Complimentary: false,
                            };
                            serviceItem.Discounts.push(discount);
                            serviceItem.DiscountTotal = discount.DiscountTotal;
                            serviceItem.TaxAmount = 0;
                            tempServiceItems.push(serviceItem);
                          }
                        }
                      });
                    }
                    break;
                  case OfferTypes.Cash:
                    var totalSum = tempServiceItems
                      .filter(
                        (t) =>
                          Utils.IsNullOrEmptyArray(t.Discounts) ||
                          t.Discounts.some(
                            (d) => d.DiscountType !== OfferTypes.Free
                          )
                      )
                      .map((i) => i.TotalPrice)
                      .reduce((a, b) => a + b);
                    var totalDiscountSum = tempServiceItems
                      .filter(
                        (t) =>
                          Utils.IsNullOrEmptyArray(t.Discounts) ||
                          t.Discounts.some(
                            (d) => d.DiscountType !== OfferTypes.Free
                          )
                      )
                      .map((i) => i.DiscountTotal)
                      .reduce((a, b) => a + b);
                    var updatedCashValue =
                      (offer.DiscountValue / (totalSum - totalDiscountSum)) *
                      100;
                    tempServiceItems
                      .filter(
                        (t) =>
                          Utils.IsNullOrEmptyArray(t.Discounts) ||
                          t.Discounts.some(
                            (d) => d.DiscountType !== OfferTypes.Free
                          )
                      )
                      .forEach((item) => {
                        var itemTotalPrice = item.Price * item.Quantity;
                        var discountTotal =
                          ((itemTotalPrice - item.DiscountTotal) *
                            updatedCashValue) /
                          100;
                        var discount = {
                          CampaignId: campaign.id,
                          CampaignType: campaign.type,
                          Code: "Cash Back",
                          Description: campaign.name,
                          DescriptionDetail:
                            Utils.GetCurrency(
                              offer.DiscountValue,
                              countryInfo
                            ) + " Cash Back",
                          DiscountTotal: discountTotal,
                          OffersClassificationType:
                            offer.OffersClassificationType,
                          DiscountType: campaign.offerType,
                          DiscountValue: offer.DiscountValue,
                          Quantity: item.Quantity,
                          OrderLevel: true,
                          Complimentary: false,
                        };
                        if (
                          item.Discounts.every(
                            (d) =>
                              d.BenefitType !== BenefitTypes.Free &&
                              d.OfferType !== OfferTypes.Free
                          )
                        ) {
                          item.Discounts.push(discount);
                        }
                        item.DiscountTotal = item.Discounts.map(
                          (i) => i.DiscountTotal
                        ).reduce((a, b) => a + b);
                      });
                    tempServiceItems =
                      recalculateTaxAmountOnDiscountApply(tempServiceItems);
                    break;
                  case OfferTypes.PercentDiscount:
                    tempServiceItems
                      .filter(
                        (t) =>
                          Utils.IsNullOrEmptyArray(t.Discounts) ||
                          t.Discounts.some(
                            (d) => d.DiscountType !== OfferTypes.Free
                          )
                      )
                      .forEach((item) => {
                        var discountTotal =
                          ((item.Price * item.Quantity - item.DiscountTotal) *
                            offer.DiscountValue) /
                          100;
                        var discount = {
                          CampaignId: campaign.id,
                          CampaignType: campaign.type,
                          Code: "Percent Discount",
                          Description: campaign.name,
                          DescriptionDetail:
                            item.Description + campaign.offerValue + "%",
                          DiscountTotal: discountTotal,
                          OffersClassificationType:
                            offer.OffersClassificationType,
                          DiscountType: campaign.offerType,
                          DiscountValue: offer.DiscountValue,
                          Quantity: item.Quantity,
                          OrderLevel: true,
                          Complimentary: false,
                        };
                        if (
                          item.Discounts.every(
                            (d) =>
                              d.BenefitType !== BenefitTypes.Free &&
                              d.OfferType !== OfferTypes.Free
                          )
                        ) {
                          item.Discounts.push(discount);
                        }
                        item.DiscountTotal = item.Discounts.map(
                          (i) => i.DiscountTotal
                        ).reduce((a, b) => a + b);
                      });
                    tempServiceItems =
                      recalculateTaxAmountOnDiscountApply(tempServiceItems);
                    break;
                }
                break;
              case CampaignTypes.Event:
                switch (campaign.offerType) {
                  case OfferTypes.Free:
                    if (!Utils.IsNullOrEmptyArray(campaign.productsY)) {
                      campaign.productsY.forEach((prodYItem) => {
                        if (
                          !Utils.IsNullOrEmptyObject(
                            masterServiceItemsList.find(
                              (s) =>
                                prodYItem.value === s.ProductId ||
                                prodYItem.value === s.ProductCode
                            )
                          )
                        ) {
                          var itemIndex = tempServiceItems.findIndex(
                            (s) =>
                              prodYItem.value === s.ProductId ||
                              prodYItem.value === s.ProductCode
                          );
                          tempServiceItems[itemIndex].Quantity =
                            campaign.offerValue > 0
                              ? campaign.offerValue
                              : tempServiceItems[itemIndex].Quantity;
                          var discount = {
                            CampaignId: campaign.id,
                            CampaignType: campaign.type,
                            Code: tempServiceItems[itemIndex].ProductId,
                            Description: campaign.name,
                            DescriptionDetail:
                              "Free " + tempServiceItems[itemIndex].Description,
                            DiscountTotal:
                              tempServiceItems[itemIndex].Price *
                              tempServiceItems[itemIndex].Quantity,
                            OffersClassificationType:
                              offer.OffersClassificationType,
                            DiscountType: OfferTypes.Free,
                            DiscountValue: offer.DiscountValue,
                            Quantity: tempServiceItems[itemIndex].Quantity,
                          };
                          tempServiceItems[itemIndex].Discounts = [];
                          tempServiceItems[itemIndex].Discounts.push(discount);
                          tempServiceItems[itemIndex].DiscountTotal =
                            discount.DiscountTotal;
                          tempServiceItems[itemIndex].TaxAmount = 0;
                        } else {
                          var serviceItem = getServiceItemFromProduct(
                            productsInventory.find(
                              (p) =>
                                p.id === prodYItem.value ||
                                p.code === prodYItem.value
                            )
                          );
                          if (!Utils.IsNullOrEmptyObject(serviceItem)) {
                            serviceItem.Quantity =
                              campaign.offerValue > 0
                                ? campaign.offerValue
                                : tempServiceItems[itemIndex].Quantity;
                            serviceItem.Sequence =
                              Utils.GetLastArrayElement(tempServiceItems)
                                .Sequence + 1;
                            var discount = {
                              CampaignId: campaign.id,
                              CampaignType: campaign.type,
                              Code: serviceItem.ProductId,
                              Description: campaign.name,
                              DescriptionDetail:
                                "Free " + serviceItem.Description,
                              DiscountTotal:
                                serviceItem.Price * serviceItem.Quantity,
                              OffersClassificationType:
                                offer.OffersClassificationType,
                              DiscountType: OfferTypes.Free,
                              DiscountValue: offer.DiscountValue,
                              Quantity: serviceItem.Quantity,
                            };
                            serviceItem.Discounts.push(discount);
                            serviceItem.DiscountTotal = discount.DiscountTotal;
                            serviceItem.TaxAmount = 0;
                            tempServiceItems.push(serviceItem);
                          }
                        }
                      });
                    }
                    break;
                  case OfferTypes.Cash:
                    var totalSum = tempServiceItems
                      .filter(
                        (t) =>
                          Utils.IsNullOrEmptyArray(t.Discounts) ||
                          t.Discounts.some(
                            (d) => d.DiscountType !== OfferTypes.Free
                          )
                      )
                      .map((i) => i.TotalPrice)
                      .reduce((a, b) => a + b);
                    var totalDiscountSum = tempServiceItems
                      .filter(
                        (t) =>
                          Utils.IsNullOrEmptyArray(t.Discounts) ||
                          t.Discounts.some(
                            (d) => d.DiscountType !== OfferTypes.Free
                          )
                      )
                      .map((i) => i.DiscountTotal)
                      .reduce((a, b) => a + b);
                    var updatedCashValue =
                      (offer.DiscountValue / (totalSum - totalDiscountSum)) *
                      100;
                    tempServiceItems
                      .filter(
                        (t) =>
                          Utils.IsNullOrEmptyArray(t.Discounts) ||
                          t.Discounts.some(
                            (d) => d.DiscountType !== OfferTypes.Free
                          )
                      )
                      .forEach((item) => {
                        var itemTotalPrice = item.Price * item.Quantity;
                        var discountTotal =
                          ((itemTotalPrice - item.DiscountTotal) *
                            updatedCashValue) /
                          100;
                        var discount = {
                          CampaignId: campaign.id,
                          CampaignType: campaign.type,
                          Code: "Cash Back",
                          Description: campaign.name,
                          DescriptionDetail:
                            Utils.GetCurrency(
                              offer.DiscountValue,
                              countryInfo
                            ) + " Cash Back",
                          DiscountTotal: discountTotal,
                          OffersClassificationType:
                            offer.OffersClassificationType,
                          DiscountType: campaign.offerType,
                          DiscountValue: offer.DiscountValue,
                          Quantity: item.Quantity,
                          OrderLevel: true,
                        };
                        if (
                          item.Discounts.every(
                            (d) =>
                              d.BenefitType !== BenefitTypes.Free &&
                              d.OfferType !== OfferTypes.Free
                          )
                        ) {
                          item.Discounts.push(discount);
                        }
                        item.DiscountTotal = item.Discounts.map(
                          (i) => i.DiscountTotal
                        ).reduce((a, b) => a + b);
                      });
                    tempServiceItems =
                      recalculateTaxAmountOnDiscountApply(tempServiceItems);
                    break;
                  case OfferTypes.PercentDiscount:
                    tempServiceItems
                      .filter(
                        (t) =>
                          Utils.IsNullOrEmptyArray(t.Discounts) ||
                          t.Discounts.some(
                            (d) => d.DiscountType !== OfferTypes.Free
                          )
                      )
                      .forEach((item) => {
                        var discountTotal =
                          (item.Price * item.Quantity - item.DiscountTotal) *
                          offer.DiscountValue;
                        var discount = {
                          CampaignId: campaign.id,
                          CampaignType: campaign.type,
                          Code: "Percent Discount",
                          Description: campaign.name,
                          DescriptionDetail:
                            item.Description + campaign.offerValue + " %",
                          DiscountTotal: discountTotal,
                          OffersClassificationType:
                            offer.OffersClassificationType,
                          DiscountType: campaign.offerType,
                          DiscountValue: offer.DiscountValue,
                          Quantity: item.Quantity,
                          OrderLevel: true,
                        };
                        if (
                          item.Discounts.every(
                            (d) =>
                              d.BenefitType !== BenefitTypes.Free &&
                              d.OfferType !== OfferTypes.Free
                          )
                        ) {
                          item.Discounts.push(discount);
                        }
                        item.DiscountTotal = item.Discounts.map(
                          (i) => i.DiscountTotal
                        ).reduce((a, b) => a + b);
                      });
                    tempServiceItems =
                      recalculateTaxAmountOnDiscountApply(tempServiceItems);
                    break;
                }
                break;
            }
            tempOrderLevelDiscounts = getOrderLevelDiscounts(
              tempServiceItems,
              tempOrderLevelDiscounts
            );
          }
        }
        break;
      case OfferClassificationTypes.AMC:
        if (Utils.IsNullOrEmptyArray(applicableItems)) {
          applicableItems = [...applicableServiceItems];
        }
        var selectedApplicableItem = applicableItems.find(
          (c) => c.BenefitId === offer.BenefitId
        );
        if (Utils.IsNullOrEmptyObject(selectedApplicableItem)) {
          return {
            offer: offer,
            serviceItems: tempServiceItems,
            orderDiscounts: tempOrderLevelDiscounts,
          };
        }
        //Remove Logic
        if (!offer.Selected) {
          switch (offer.BenefitType) {
            case BenefitTypes.Free:
              selectedApplicableItem.Items.forEach((item) => {
                if (
                  !Utils.IsNullOrEmptyObject(
                    masterServiceItemsList.find(
                      (c) => c.ProductId === item.itemId
                    )
                  )
                ) {
                  var i = tempServiceItems.findIndex(
                    (i) => i.ProductId === item.itemId
                  );
                  var discountIndex = tempServiceItems[i].Discounts.findIndex(
                    (c) =>
                      c.CampaignId === offer.CampaignId &&
                      c.BenefitId === offer.BenefitId
                  );
                  tempServiceItems[i].Discounts.splice(discountIndex, 1);
                  tempServiceItems[i].DiscountTotal = 0;
                } else {
                  var removeIndex = tempServiceItems.findIndex(
                    (i) => i.ProductId === item.itemId
                  );
                  tempServiceItems.splice(removeIndex, 1);
                }
              });
              break;
            default:
              tempServiceItems
                .filter(
                  (t) =>
                    !Utils.IsNullOrEmptyArray(t.Discounts) &&
                    t.Discounts.some(
                      (d) =>
                        d.CampaignId === offer.CampaignId &&
                        d.BenefitId === offer.BenefitId
                    )
                )
                .forEach((item) => {
                  var indexToRemove = item.Discounts.findIndex(
                    (d) =>
                      d.CampaignId === offer.CampaignId &&
                      d.BenefitId === offer.BenefitId
                  );
                  item.Discounts.splice(indexToRemove, 1);
                  var discountsTotalList = item.Discounts.map(
                    (d) => d.DiscountTotal
                  );
                  item.DiscountTotal = 0;
                  if (!Utils.IsNullOrEmptyArray(discountsTotalList)) {
                    item.DiscountTotal = discountsTotalList.reduce(
                      (a, b) => a + b
                    );
                  }
                });
              tempServiceItems =
                recalculateTaxAmountOnDiscountApply(tempServiceItems);
              break;
          }
        }
        //Add Logic
        else {
          switch (offer.BenefitType) {
            case BenefitTypes.DiscountPercentage:
              selectedApplicableItem.Items.forEach((item) => {
                var index = tempServiceItems.findIndex(
                  (s) => s.ProductId === item
                );
                var discountTotal =
                  ((tempServiceItems[index].TotalPrice -
                    tempServiceItems[index].DiscountTotal) *
                    offer.DiscountValue) /
                  100;
                var discount = {
                  CampaignId: offer.CampaignId,
                  BenefitId: offer.BenefitId,
                  BenefitType: BenefitTypes.DiscountPercentage,
                  CampaignType: CampaignTypes.Advertisement,
                  Code: "Percent Discount",
                  Description: offer.Description,
                  DescriptionDetail:
                    "Discount " + offer.DiscountValue + "%" + " off",
                  DiscountTotal: discountTotal,
                  OffersClassificationType: offer.OffersClassificationType,
                  DiscountType: OfferTypes.PercentDiscount,
                  DiscountValue: offer.DiscountValue,
                  Quantity: 1,
                  OrderLevel: false,
                };
                tempServiceItems[index].Discounts.push(discount);
                tempServiceItems[index].DiscountTotal = tempServiceItems[
                  index
                ].Discounts.map((d) => d.DiscountTotal).reduce((a, b) => a + b);
              });
              tempServiceItems =
                recalculateTaxAmountOnDiscountApply(tempServiceItems);
              break;
            case BenefitTypes.Free:
              selectedApplicableItem.Items.forEach((item) => {
                if (
                  !Utils.IsNullOrEmptyObject(
                    masterServiceItemsList.find((s) => s.ProductId === item)
                  )
                ) {
                  var index = tempServiceItems.findIndex(
                    (s) => s.ProductId === item
                  );
                  var discount = {
                    CampaignId: offer.CampaignId,
                    BenefitId: offer.BenefitId,
                    BenefitType: BenefitTypes.Free,
                    CampaignType: CampaignTypes.Advertisement,
                    Code: tempServiceItems[index].ProductCode,
                    Description: offer.Description,
                    DescriptionDetail: "Free",
                    DiscountTotal: tempServiceItems[index].Price,
                    OffersClassificationType: offer.OffersClassificationType,
                    DiscountType: OfferTypes.PercentDiscount,
                    DiscountValue: offer.DiscountValue,
                    Quantity: 1,
                    OrderLevel: false,
                  };
                  tempServiceItems[index].Discounts = [];
                  tempServiceItems[index].Discounts.push(discount);
                  tempServiceItems[index].DiscountTotal =
                    tempServiceItems[index].Price;
                } else {
                  var serviceItem = getServiceItemFromProduct(
                    productsInventory.find((p) => p.id === item)
                  );
                  if (!Utils.IsNullOrEmptyObject(serviceItem)) {
                    serviceItem.Sequence =
                      Utils.GetLastArrayElement(tempServiceItems).Sequence + 1;
                    var discount = {
                      CampaignId: offer.CampaignId,
                      BenefitId: offer.BenefitId,
                      CampaignType: CampaignTypes.Advertisement,
                      Code: serviceItem.ProductCode,
                      BenefitType: BenefitTypes.Free,
                      Description: offer.Description,
                      DescriptionDetail: serviceItem.Description + " Free",
                      DiscountTotal: serviceItem.Price,
                      OffersClassificationType: offer.OffersClassificationType,
                      DiscountType: OfferTypes.PercentDiscount,
                      DiscountValue: offer.DiscountValue,
                      Quantity: 1,
                      OrderLevel: false,
                    };
                    serviceItem.Discounts = [];
                    serviceItem.Discounts.push(discount);
                    serviceItem.DiscountTotal = serviceItem.Price;
                    tempServiceItems.push(serviceItem);
                  }
                }
              });
              break;
            case BenefitTypes.FlatDiscount:
              selectedApplicableItem.Items.forEach((item) => {
                var index = tempServiceItems.findIndex(
                  (s) => s.ProductId === item
                );
                var discount = {
                  CampaignId: offer.CampaignId,
                  BenefitId: offer.BenefitId,
                  BenefitType: BenefitTypes.FlatDiscount,
                  CampaignType: CampaignTypes.Advertisement,
                  Code: tempServiceItems[index].ProductCode,
                  Description: offer.Description,
                  DescriptionDetail:
                    Utils.GetCurrency(offer.DiscountValue, countryInfo) +
                    " Cashback",
                  DiscountTotal: offer.DiscountValue,
                  OffersClassificationType: offer.OffersClassificationType,
                  DiscountType: OfferTypes.PercentDiscount,
                  DiscountValue: offer.DiscountValue,
                  Quantity: 1,
                  OrderLevel: false,
                  Complimentary: false,
                };
                tempServiceItems[index].Discounts.push(discount);
                tempServiceItems[index].DiscountTotal = tempServiceItems[
                  index
                ].Discounts.map((d) => d.DiscountTotal).reduce((a, b) => a + b);
              });
              tempServiceItems =
                recalculateTaxAmountOnDiscountApply(tempServiceItems);
              break;
          }
        }
        break;
    }
    return {
      offer: offer,
      serviceItems: tempServiceItems,
      orderDiscounts: tempOrderLevelDiscounts,
    };
  };

  // To Address
  const [isOpenedDifferentToAddress, setIsOpenedDifferentToAddress] =
    useState(false);
  function toggleToAddress() {
    setShippingToAddressDefaultEnabled(true);
    setIsOpenedDifferentToAddress(false);
  }
  function toggleDifferentToAddress() {
    setShippingToAddressDefaultEnabled(false);
    setIsOpenedDifferentToAddress(true);
  }

  const addFrom = async () => {
    var currentAddress = shippingFromNewAddress;
    if (shippingFromAddressDefaultEnabled) {
      currentAddress = shippingFromDefaultAddress;
    }
    if (Utils.IsNullOrEmpty(currentAddress)) {
      return null;
    }
    var geocodeResponse = await getGeocodeAddress(currentAddress);
    if (
      Utils.IsNullOrEmptyObject(geocodeResponse) ||
      geocodeResponse.status !== "OK"
    ) {
      return null;
    }
    var locality = "";
    var county = "";
    var state = "";
    var country = "";
    var zipcode = "";
    var streetNumber = "";
    var route = "";
    var address = "";
    address = geocodeResponse.results[0].formatted_address;
    if (shippingFromAddressDefaultEnabled) {
      address = shippingFromDefaultAddress;
    }
    if (
      geocodeResponse.results[0].address_components
        .map((c) => c.types)
        .reduce((a, b) => a.concat(b))
        .includes("street_number") === true
    ) {
      var ac = geocodeResponse.results[0].address_components.find((c) =>
        c.types.includes("street_number")
      );
      if (!Utils.IsNullOrEmptyObject(ac)) {
        streetNumber = ac.long_name;
      }
    }

    if (
      geocodeResponse.results[0].address_components
        .map((c) => c.types)
        .reduce((a, b) => a.concat(b))
        .includes("route") === true
    ) {
      var ac = geocodeResponse.results[0].address_components.find((c) =>
        c.types.includes("route")
      );
      if (!Utils.IsNullOrEmptyObject(ac)) {
        route = ac.long_name;
      }
    }

    if (
      geocodeResponse.results[0].address_components
        .map((c) => c.types)
        .reduce((a, b) => a.concat(b))
        .includes("locality") === true
    ) {
      var ac = geocodeResponse.results[0].address_components.find((c) =>
        c.types.includes("locality")
      );
      if (!Utils.IsNullOrEmptyObject(ac)) {
        locality = ac.long_name;
      }
    }

    if (
      geocodeResponse.results[0].address_components
        .map((c) => c.types)
        .reduce((a, b) => a.concat(b))
        .includes("administrative_area_level_1") === true
    ) {
      var ac = geocodeResponse.results[0].address_components.find((c) =>
        c.types.includes("administrative_area_level_1")
      );
      if (!Utils.IsNullOrEmptyObject(ac)) {
        state = ac.long_name;
      }
    }

    if (
      geocodeResponse.results[0].address_components
        .map((c) => c.types)
        .reduce((a, b) => a.concat(b))
        .includes("administrative_area_level_2") === true
    ) {
      var ac = geocodeResponse.results[0].address_components.find((c) =>
        c.types.includes("administrative_area_level_2")
      );
      if (!Utils.IsNullOrEmptyObject(ac)) {
        county = ac.long_name;
      }
    }

    if (
      geocodeResponse.results[0].address_components
        .map((c) => c.types)
        .reduce((a, b) => a.concat(b))
        .includes("country") === true
    ) {
      var ac = geocodeResponse.results[0].address_components.find((c) =>
        c.types.includes("country")
      );
      if (!Utils.IsNullOrEmptyObject(ac)) {
        country = ac.short_name;
      }
    }

    if (
      geocodeResponse.results[0].address_components
        .map((c) => c.types)
        .reduce((a, b) => a.concat(b))
        .includes("postal_code") === true
    ) {
      var ac = geocodeResponse.results[0].address_components.find((c) =>
        c.types.includes("postal_code")
      );
      if (!Utils.IsNullOrEmptyObject(ac)) {
        zipcode = ac.long_name;
      }
    }
    var res = {
      Address: address,
      EmailId: shippingFromEmailId,
      PhoneNumber: shippingFromPhoneNumber,
      State: state,
      StateCode: shippingFromStateCode,
    };
    setShippingFromAddressObject(res);
  };

  const addTo = async () => {
    var currentAddress = shippingToNewAddress;
    if (shippingToAddressDefaultEnabled) {
      currentAddress = shippingToDefaultAddress;
    }
    if (Utils.IsNullOrEmpty(currentAddress)) {
      return;
    }
    var geocodeResponse = await getGeocodeAddress(currentAddress);
    if (
      Utils.IsNullOrEmptyObject(geocodeResponse) ||
      geocodeResponse.status !== "OK"
    ) {
      return;
    }
    var locality = "";
    var county = "";
    var state = "";
    var country = "";
    var zipcode = "";
    var streetNumber = "";
    var route = "";
    var address = "";
    address = geocodeResponse.results[0].formatted_address;
    if (shippingToAddressDefaultEnabled) {
      address = shippingToDefaultAddress;
    }
    if (
      geocodeResponse.results[0].address_components
        .map((c) => c.types)
        .reduce((a, b) => a.concat(b))
        .includes("street_number") === true
    ) {
      var ac = geocodeResponse.results[0].address_components.find((c) =>
        c.types.includes("street_number")
      );
      if (!Utils.IsNullOrEmptyObject(ac)) {
        streetNumber = ac.long_name;
      }
    }

    if (
      geocodeResponse.results[0].address_components
        .map((c) => c.types)
        .reduce((a, b) => a.concat(b))
        .includes("route") === true
    ) {
      var ac = geocodeResponse.results[0].address_components.find((c) =>
        c.types.includes("route")
      );
      if (!Utils.IsNullOrEmptyObject(ac)) {
        route = ac.long_name;
      }
    }

    if (
      geocodeResponse.results[0].address_components
        .map((c) => c.types)
        .reduce((a, b) => a.concat(b))
        .includes("locality") === true
    ) {
      var ac = geocodeResponse.results[0].address_components.find((c) =>
        c.types.includes("locality")
      );
      if (!Utils.IsNullOrEmptyObject(ac)) {
        locality = ac.long_name;
      }
    }

    if (
      geocodeResponse.results[0].address_components
        .map((c) => c.types)
        .reduce((a, b) => a.concat(b))
        .includes("administrative_area_level_1") === true
    ) {
      var ac = geocodeResponse.results[0].address_components.find((c) =>
        c.types.includes("administrative_area_level_1")
      );
      if (!Utils.IsNullOrEmptyObject(ac)) {
        state = ac.long_name;
      }
    }

    if (
      geocodeResponse.results[0].address_components
        .map((c) => c.types)
        .reduce((a, b) => a.concat(b))
        .includes("administrative_area_level_2") === true
    ) {
      var ac = geocodeResponse.results[0].address_components.find((c) =>
        c.types.includes("administrative_area_level_2")
      );
      if (!Utils.IsNullOrEmptyObject(ac)) {
        county = ac.long_name;
      }
    }

    if (
      geocodeResponse.results[0].address_components
        .map((c) => c.types)
        .reduce((a, b) => a.concat(b))
        .includes("country") === true
    ) {
      var ac = geocodeResponse.results[0].address_components.find((c) =>
        c.types.includes("country")
      );
      if (!Utils.IsNullOrEmptyObject(ac)) {
        country = ac.short_name;
      }
    }

    if (
      geocodeResponse.results[0].address_components
        .map((c) => c.types)
        .reduce((a, b) => a.concat(b))
        .includes("postal_code") === true
    ) {
      var ac = geocodeResponse.results[0].address_components.find((c) =>
        c.types.includes("postal_code")
      );
      if (!Utils.IsNullOrEmptyObject(ac)) {
        zipcode = ac.long_name;
      }
    }
    var res = {
      Address: address,
      EmailId: shippingToEmailId,
      PhoneNumber: shippingToPhoneNumber,
      State: state,
      StateCode: shippingToStateCode,
    };
    setShippingToAddressObject(res);
  };

  const clickOpenPaymentType = async () => {
    setIsLoading(true);
    await addFrom();
    await addTo();
    setIsLoading(false);
    setOpenAddressSelector(false);
    showPartialPayConfirmation();
  };

  const clickOpenTip = () => {
    openTipModal(true);
  };

  const clickCloseTip = () => {
    openTipModal(false);
  };

  const clickOpenComplimentary = (ind = 0, orderLevel = true) => {
    if (!orderLevel) {
      var i = serviceItems.findIndex((s) => s.Sequence === ind);
      setMaxPrice(serviceItems[i].TotalPrice - serviceItems[i].DiscountTotal);
    } else {
      setMaxPrice(Utils.ConvertToFloat(subTotal));
    }
    setExistItemComp({
      index: ind,
      orderLevel: orderLevel,
    });
    openComplimentaryModal(true);
  };

  const modelHandleChange = (event) => {
    setVehicleModel(event.target.value);
  };

  const fuelHandleChange = (event) => {
    setVehicleFuelType(event.target.value);
  };

  function toggleProfit() {
    setIsOpened((wasOpened) => !wasOpened);
  }

  const tipValue = (tip) => {
    var discountTotal = serviceItems
      .map((c) => c.DiscountTotal)
      .reduce((a, b) => a + b);
    var subTotal = serviceItems
      .map((c) => c.TotalPrice)
      .reduce((a, b) => a + b);
    subTotal = subTotal - discountTotal;
    var tax = serviceItems.map((c) => c.TaxAmount).reduce((a, b) => a + b);
    var updatedTotal = subTotal + tax;
    setOrginalTotal(updatedTotal + (updatedTotal * tip) / 10);
    setTipAmount((updatedTotal * tip) / 100);
  };

  const tipInputValue = (tipInput) => {
    var discountTotal = serviceItems
      .map((c) => c.DiscountTotal)
      .reduce((a, b) => a + b);
    var subTotal = serviceItems
      .map((c) => c.TotalPrice)
      .reduce((a, b) => a + b);
    subTotal = subTotal - discountTotal;
    var tax = serviceItems.map((c) => c.TaxAmount).reduce((a, b) => a + b);
    var updatedTotal = subTotal + tax;
    setOrginalTotal(updatedTotal + parseInt(tipInput));
    setTipAmount(parseInt(tipInput));
  };

  const clickOpenPaymentTypeCash = () => {
    openPaymentTypeCashModal(true);
  };

  const clickClosePaymentTypeCash = () => {
    openPaymentTypeCashModal(false);
  };

  // Card

  const clickOpenPaymentTypeCard = () => {
    openPaymentTypeCardModal(true);
  };

  const clickClosePaymentTypeCard = () => {
    openPaymentTypeCardModal(false);
  };

  // Upi

  const clickOpenPaymentTypeUpi = () => {
    openPaymentTypeUpiModal(true);
  };

  const clickClosePaymentTypeUpi = () => {
    openPaymentTypeUpiModal(false);
  };

  const clickCloseComplimentary = () => {
    openComplimentaryModal(false);
  };

  const sendSMSInvoice = async () => {
    if (smsSent) {
      return;
    }
    var mobileNumbers = customerMobileNumber.split(",");
    mobileNumbers = mobileNumbers.filter((i) => !Utils.IsNullOrEmpty(i));
    if (
      Utils.IsNullOrEmptyArray(mobileNumbers) ||
      !mobileNumbers.every(
        (i) => i.length === CountryHelper.GetMobileNumberMaxLength()
      )
    ) {
      setAlert("Please enter valid mobile number");
      return;
    }
    setIsLoading(true);
    var request = {
      BusinessId: business.id,
      CompanyName: business.companyName,
      CountryCode: branch.countryCode,
      CustomerId: Utils.IsNullOrEmptyObject(pass) ? "" : pass.customerId,
      CustomerName: customerName,
      Address: customerAddress,
      MobileNumbers: mobileNumbers,
      ServiceUrl: Utils.GetServiceUrl(),
      VisitId: visit.id,
      SendInvoice: sendInvoiceEnable,
    };

    let result = await postAsync("Customer/SMSInvoice", request);
    setIsLoading(false);
    if (result.error) {
      setIsNavigateToLogin(result.isNavigateToLogin);
      setAlert(result.errorMessage);
      return;
    }
    setSMSSent(true);
    setAlert("SMS Sent Successfully");
  };

  const emailReceipt = async () => {
    if (emailSent) {
      return;
    }
    var emailIds = customerEmailId.split(",");
    emailIds = emailIds.filter((i) => !Utils.IsNullOrEmpty(i));
    if (
      Utils.IsNullOrEmptyArray(emailIds) ||
      !emailIds.every((id) => Utils.IsEmailValid(id))
    ) {
      setAlert("Please Enter Valid EmailId");
      return;
    }
    setIsLoading(true);
    var visitIds = [];
    visitIds.push(visit.id);
    var target =
      branch.country === India.ShortName && sendInvoiceEnable
        ? "Customer/EmailInvoice"
        : "Customer/EmailReceipt";
    var request = {
      BusinessId: business.id,
      CompanyName: business.companyName,
      CustomerName: customerName,
      Address: customerAddress,
      EmailIds: emailIds,
      ServiceUrl: Utils.GetServiceUrl(),
      VisitIds: visitIds,
    };

    let result = await postAsync(target, request);
    setIsLoading(false);
    if (result.error) {
      setAlert(result.errorMessage);
      setIsNavigateToLogin(result.isNavigateToLogin);
      return;
    }
    setEmailSent(true);
    setAlert("Email Sent Successfully");
  };

  const sendWhatsAppReceipt = async () => {
    var mobileNumbers = customerMobileNumber.split(",");
    mobileNumbers = mobileNumbers.filter((i) => !Utils.IsNullOrEmpty(i));
    if (
      Utils.IsNullOrEmptyArray(mobileNumbers) ||
      !mobileNumbers.every(
        (i) => i.length === CountryHelper.GetMobileNumberMaxLength()
      )
    ) {
      setAlert("Please enter valid mobile number");
      return;
    }
    setIsLoading(true);
    var request = {
      BusinessId: business.id,
      CompanyName: business.companyName,
      CountryCode: branch.countryCode,
      CustomerId: Utils.IsNullOrEmptyObject(pass) ? "" : pass.customerId,
      CustomerName: customerName,
      MobileNumbers: mobileNumbers,
      ServiceUrl: Utils.GetServiceUrl(),
      VisitId: visit.id,
    };

    let result = await postAsync("Customer/WhatsAppInvoice", request);
    setIsLoading(false);
    if (result.error) {
      setIsNavigateToLogin(result.isNavigateToLogin);
      setAlert(result.errorMessage);
      return;
    }
    setAlert("Message Sent Successfully");
  };

  const vehicleMakeHandleChange = (make) => {
    setVehicleMake(make);
    setVehicleMakeItem(vehicleMakeModelList.find((v) => v.make === make).model);
  };

  const toggleStaffSelection = (item) => {
    setSelectedServiceItem(item);
    setOpenStaffSelection(true);
  };

  const onChangeStaff = (staffId) => {
    var tempServiceItems = [...serviceItems];
    var itemIndex = tempServiceItems.findIndex(
      (c) => c.ProductId === selectedServiceItem.ProductId
    );
    var newStaffs = [];
    var staff = serviceStaffs.find((s) => s.Id === staffId);
    newStaffs.push(staff);
    tempServiceItems[itemIndex].ServiceItemStaffs = newStaffs;
    setServiceItems(tempServiceItems);
    setSelectedServiceItem({});
    setOpenStaffSelection(false);
  };

  const onCloseStaffSelection = () => {
    setOpenStaffSelection(false);
  };

  const AddCommentSharp = () => {
    var compDesc = existItemComp.orderLevel
      ? "Order Complimentary"
      : "Item Complimentary";
    var discountType =
      compType === "Discount" ? OfferTypes.PercentDiscount : OfferTypes.Cash;
    var compDescDetailed = "";
    var compId = Utils.CreateGuid();
    var discountTotal = 0;
    var discount = {};
    var tempServiceItems = [...serviceItems];
    var itemTotalPrice = 0;
    var newCompValue = Utils.ConvertToFloat(compValue);
    var serviceItemIndex = tempServiceItems.findIndex(
      (t) => t.Sequence === existItemComp.index
    );
    if (newCompValue > 0) {
      switch (discountType) {
        case OfferTypes.PercentDiscount:
          compDescDetailed = "Discount Percentage " + compValue + "%";
          if (existItemComp.orderLevel) {
            tempServiceItems.forEach((item) => {
              itemTotalPrice = item.Price * item.Quantity;
              discountTotal =
                ((itemTotalPrice - item.DiscountTotal) * newCompValue) / 100;
              discount = {
                CampaignId: compId,
                BenefitId: "",
                CampaignType: "Advertisement",
                OffersClassificationType: "Campaign",
                BenefitType: "DiscountPercentage",
                Code: compDescDetailed,
                Description: compDesc,
                DescriptionDetail: compDescDetailed,
                Quantity: 0.0,
                Complimentary: true,
                OrderLevel: true,
                PointsRedemption: false,
                DiscountType: discountType,
                DiscountValue: newCompValue,
                DiscountTotal: discountTotal,
              };
              item.Discounts.push(discount);
              item.DiscountTotal = item.Discounts.map(
                (i) => i.DiscountTotal
              ).reduce((a, b) => a + b);
            });
          } else {
            itemTotalPrice =
              tempServiceItems[serviceItemIndex].Price *
              tempServiceItems[serviceItemIndex].Quantity;
            discountTotal =
              ((itemTotalPrice -
                tempServiceItems[serviceItemIndex].DiscountTotal) *
                newCompValue) /
              100;
            discount = {
              CampaignId: compId,
              BenefitId: "",
              CampaignType: "Advertisement",
              OffersClassificationType: "Campaign",
              BenefitType: "DiscountPercentage",
              Code: tempServiceItems[serviceItemIndex].ProductCode,
              Description: compDesc,
              DescriptionDetail: compDescDetailed,
              Quantity: 0.0,
              Complimentary: true,
              OrderLevel: false,
              PointsRedemption: false,
              DiscountType: discountType,
              DiscountValue: newCompValue,
              DiscountTotal: discountTotal,
            };
            tempServiceItems[serviceItemIndex].Discounts.push(discount);
            tempServiceItems[serviceItemIndex].DiscountTotal = tempServiceItems[
              serviceItemIndex
            ].Discounts.map((i) => i.DiscountTotal).reduce((a, b) => a + b);
          }
          break;
        case OfferTypes.Cash:
          compDescDetailed =
            "Cash Back " + Utils.GetCurrency(compValue, countryInfo);
          var updatedCompValue = 0;
          if (existItemComp.orderLevel) {
            var totalSum = tempServiceItems
              .map((i) => i.TotalPrice)
              .reduce((a, b) => a + b);
            var totalDiscountSum = tempServiceItems
              .map((i) => i.DiscountTotal)
              .reduce((a, b) => a + b);
            updatedCompValue =
              (newCompValue / (totalSum - totalDiscountSum)) * 100;
            tempServiceItems.forEach((item) => {
              itemTotalPrice = item.Price * item.Quantity;
              discountTotal =
                ((itemTotalPrice - item.DiscountTotal) * updatedCompValue) /
                100;
              discount = {
                CampaignId: compId,
                BenefitId: "",
                CampaignType: "Advertisement",
                OffersClassificationType: "Campaign",
                BenefitType: "DiscountPercentage",
                Code: "Cash " + newCompValue,
                Description: compDesc,
                DescriptionDetail: compDescDetailed,
                Quantity: 0.0,
                Complimentary: true,
                OrderLevel: true,
                PointsRedemption: false,
                DiscountType: discountType,
                DiscountValue: updatedCompValue,
                DiscountTotal: discountTotal,
              };
              item.Discounts.push(discount);
              item.DiscountTotal = item.Discounts.map(
                (i) => i.DiscountTotal
              ).reduce((a, b) => a + b);
            });
          } else {
            itemTotalPrice =
              tempServiceItems[serviceItemIndex].Price *
              tempServiceItems[serviceItemIndex].Quantity;
            discountTotal =
              ((itemTotalPrice -
                tempServiceItems[serviceItemIndex].DiscountTotal) *
                compValue) /
              100;
            discount = {
              CampaignId: compId,
              BenefitId: "",
              CampaignType: "Advertisement",
              OffersClassificationType: "Campaign",
              BenefitType: "DiscountPercentage",
              Code: tempServiceItems[serviceItemIndex].ProductCode,
              Description: compDesc,
              DescriptionDetail: compDescDetailed,
              Quantity: 0.0,
              Complimentary: true,
              OrderLevel: false,
              PointsRedemption: false,
              DiscountType: discountType,
              DiscountValue: newCompValue,
              DiscountTotal: newCompValue,
            };
            tempServiceItems[serviceItemIndex].Discounts.push(discount);
            tempServiceItems[serviceItemIndex].DiscountTotal = tempServiceItems[
              serviceItemIndex
            ].Discounts.map((i) => i.DiscountTotal).reduce((a, b) => a + b);
          }
          break;
      }
      tempServiceItems = recalculateTaxAmountOnDiscountApply(tempServiceItems);
      var itemDiscounts = [];
      tempServiceItems.forEach((item) => {
        if (!Utils.IsNullOrEmptyArray(item.Discounts)) {
          item.Discounts.forEach((discount) => {
            if (discount.CampaignId === compId) {
              itemDiscounts.push(discount);
            }
          });
        }
      });
      var newDiscount = !Utils.IsNullOrEmptyArray(itemDiscounts)
        ? itemDiscounts.map((d) => d.DiscountTotal).reduce((a, b) => a + b)
        : 0;
      var newComplimentary = {
        orderLevel: existItemComp.orderLevel,
        productId: !existItemComp.orderLevel
          ? tempServiceItems[serviceItemIndex].ProductId
          : "",
        value: compValue,
        type: discountType,
        discount: !Utils.IsNullOrEmptyArray(itemDiscounts)
          ? itemDiscounts.map((d) => d.DiscountTotal).reduce((a, b) => a + b)
          : 0,
        compId: compId,
      };
      const tempOrderComplimentaryList = [...orderComplimentaryList];
      const tempItemComplimentaryList = [...itemComplimentaryList];
      if (newComplimentary.orderLevel) {
        tempOrderComplimentaryList.push(newComplimentary);
        setOrderComplimentaryList(tempOrderComplimentaryList);
      } else {
        newComplimentary = {
          ...newComplimentary,
          index: tempServiceItems[serviceItemIndex].Sequence,
        };
        tempItemComplimentaryList.push(newComplimentary);
        setItemComplimentaryList(tempItemComplimentaryList);
      }
      var tempOrderLevelDiscounts = [];
      tempOrderLevelDiscounts = getOrderLevelDiscounts(
        tempServiceItems,
        tempOrderLevelDiscounts
      );
      setOrderLevelDiscounts(tempOrderLevelDiscounts);
      setServiceItems(tempServiceItems);
    }
  };

  const recalculateTaxAmountOnDiscountApply = (serviceItemsToCalculate) => {
    if (Utils.IsNullOrEmptyArray(serviceItemsToCalculate)) {
      return serviceItemsToCalculate;
    }
    serviceItemsToCalculate.forEach((item) => {
      var subTotal = item.Price * item.Quantity - item.DiscountTotal;
      var grandTotal = (subTotal * (item.TaxPercent + 100)) / 100;
      item.TaxAmount = grandTotal - subTotal;
    });
    return serviceItemsToCalculate;
  };

  async function removeCompItem(index, comp) {
    var indexOfRemoval = 0;
    if (!existItemComp.orderLevel) {
      index = itemComplimentaryList.indexOf(comp);
    }
    var orderLevel = comp.orderLevel;
    var discount = {};
    var tempServiceItems = [...serviceItems];
    if (!orderLevel) {
      var serviceItem = tempServiceItems.find(
        (s) =>
          !Utils.IsNullOrEmptyObject(
            s.Discounts.find((d) => d.CampaignId === comp.compId)
          )
      );
      var i = tempServiceItems.findIndex(
        (t) => t.ProductId === serviceItem.ProductId
      );
      discount = tempServiceItems[i].Discounts.find(
        (d) => d.CampaignId === comp.compId
      );
      indexOfRemoval = tempServiceItems[i].Discounts.findIndex(
        (d) => d.CampaignId === discount.CampaignId
      );
      tempServiceItems[i].Discounts.splice(indexOfRemoval, 1);
      if (
        (tempServiceItems[i].TotalPrice !== 0 &&
          tempServiceItems[i].TotalPriceIncludingTax !== 0) ||
        itemComplimentaryList.length === 1
      ) {
        var totalPrice =
          tempServiceItems[i].TotalPrice + discount.DiscountTotal;
        var discountTotal = 0;
        if (!Utils.IsNullOrEmptyArray(tempServiceItems[i].Discounts)) {
          discountTotal = tempServiceItems[i].Discounts.map(
            (d) => d.DiscountTotal
          ).reduce((a, b) => a + b);
        }
        tempServiceItems[i].DiscountTotal = discountTotal;
      }
      var tempitemlist = [...itemComplimentaryList];
      tempitemlist.splice(index, 1);
      setItemComplimentaryList(tempitemlist);
    } else {
      const tempOrderLevelDiscounts = [...orderLevelDiscounts];
      tempOrderLevelDiscounts.splice(
        tempOrderLevelDiscounts.findIndex((t) => t.CampaignId === comp.compId),
        1
      );
      tempServiceItems.forEach((item) => {
        discount = item.Discounts.find((d) => d.CampaignId === comp.compId);
        if (!Utils.IsNullOrEmptyObject(discount)) {
          indexOfRemoval = item.Discounts.findIndex(
            (d) => d.CampaignId === discount.CampaignId
          );
          item.Discounts.splice(indexOfRemoval, 1);
          if (
            (item.TotalPrice !== 0 && item.TotalPriceIncludingTax !== 0) ||
            orderComplimentaryList.length === 1
          ) {
            var updatedTotalPrice = item.TotalPrice + discount.DiscountTotal;
            var newDiscountTotal = 0;
            if (!Utils.IsNullOrEmptyArray(item.Discounts)) {
              newDiscountTotal = item.Discounts.map(
                (d) => d.DiscountTotal
              ).reduce((a, b) => a + b);
            }
            item.DiscountTotal = newDiscountTotal;
          }
        }
      });
      var temporderlist = [...orderComplimentaryList];
      temporderlist.splice(index, 1);
      setOrderComplimentaryList(temporderlist);
      setOrderLevelDiscounts(tempOrderLevelDiscounts);
    }
    tempServiceItems = recalculateTaxAmountOnDiscountApply(tempServiceItems);
    setServiceItems(tempServiceItems);
  }

  const closeAddressSelector = () => {
    setOpenAddressSelector(false);
  };

  const closePaymentSelector = () => {
    setOpenPaymentSelector(false);
  };

  const showPartialPayConfirmation = () => {
    if (branch.allowMultiplePayments) {
      setShowConfirmDialogForPartialPay(true);
      return;
    }
    //show payment selector
    setOpenPaymentSelector(true);
  };

  const beginBook = async () => {
    if (!anonymous) {
      //show Address types
      var request = { Id: pass.customerId, BusinessId: pass.customerId };
      var result = await postAsync("Customer/GetCustomer", request, "", "Get");
      var shippingToDefault = "";
      if (!result.error) {
        shippingToDefault = result.data.homeLocation;
        setCustomerAddress(result.data.homeLocation);
      }
      var shippingFromDefault =
        branch.address +
        "," +
        branch.addressLine2 +
        "," +
        branch.addressLine3 +
        "," +
        branch.city +
        "," +
        branch.state +
        "," +
        branch.country;
      setShippingFromDefaultAddress(shippingFromDefault);
      setShippingToDefaultAddress(shippingToDefault);
      showPartialPayConfirmation();
      // if(CountryHelper.IsBusinessCountryIndia(countryInfo))
      // {
      //   setOpenAddressSelector(true);
      // }
      // else{

      // }
    } else {
      var discountTotal = serviceItems
        .map((i) => i.DiscountTotal)
        .reduce((a, b) => a + b);
      var total = serviceItems.map((i) => i.TotalPrice).reduce((a, b) => a + b);
      if (total - discountTotal > 0) {
        //show Payment Types
        showPartialPayConfirmation();
      } else {
        //process payments
        setCash(true);
      }
    }
  };

  const closePaymentType = (paymentType) => {
    var paymentAmount = isPartialPaySelected
      ? paidByCustomerAmount
      : outstandingAmount;
    setAutoPay(false);
    setCash(false);
    setNetBanking(false);
    setPayTerminal(false);
    setUPI(false);
    setPayLater(false);
    setPayNow(false);
    setCardPay(false);
    switch (paymentType) {
      case PaymentTypes.Cash:
        setCash(true);
        setOpenPaymentSelector(false);
        break;
      case PaymentTypes.CardPay:
        setCardPay(true);
        setOpenPaymentSelector(false);
        setShowCardDetails(true);
        break;
      case PaymentTypes.NetBanking:
        setNetBanking(true);
        setOpenPaymentSelector(false);
        break;
      case PaymentTypes.UPI:
        var newUpiValue =
          "upi://pay?pa=" +
          branch.upi.upiId +
          "&pn=" +
          branch.upi.upiPayeeName +
          "&cu=INR&am=" +
          paymentAmount;
        if (!Utils.IsNullOrEmpty(newUpiValue)) {
          setOpenPaymentSelector(false);
          setUPIValue(newUpiValue);
          setUPI(true);
          setShowUPIDetails(true);
        }
        break;
      case PaymentTypes.PaymentLink:
        setPaymentLinkAmount(paymentAmount);
        setPaymentLinkMobileNumber(
          Utils.IsNullOrEmptyObject(pass) ? "" : pass.mobileNumber
        );
        setOpenPaymentSelector(false);
        setPaymentLink(true);
        setShowPaymentLinkDetails(true);
        break;
      default:
        setOpenPaymentSelector(false);
        if (isPartialPaySelected) {
          setShowPartialPayEntryDialog(true);
        }
        // else if (!anonymous && CountryHelper.IsBusinessCountryIndia(countryInfo)) {
        //   setOpenAddressSelector(true);
        // }
        break;
    }
  };

  useEffect(() => {
    canProceedPayment();
  }, [cash, upi, cardPay, netBanking]);

  const canProceedPayment = async () => {
    if (cash || upi || cardPay || netBanking) {
      if (cash || netBanking) {
        await processPayment();
        return;
      }
    }
  };

  const updateVisitOnOfferApplied = async (orderDiscounts, orderComps) => {
    var request = {
      VisitId: Utils.IsNullOrEmptyObject(visit) ? "" : visit.id,
      BranchId: branch.branchId,
      CustomerTierLevel: Utils.IsNullOrEmptyObject(pass) ? "" : pass.tierLevel,
      Comps: Utils.IsNullOrEmptyArray(orderComps) ? null : orderComps,
      Offers: Utils.IsNullOrEmptyArray(orderDiscounts) ? null : orderDiscounts,
      ServiceItems: serviceItems,
      IsTaxIncluded: !Utils.IsNullOrEmptyObject(selectedInvoiceLabel) &&
      selectedInvoiceLabel.taxIncluded
    };
    var result = await postAsync("Customer/UpdateVisitOnOfferChange", request);
  };

  const getCampaignOffers = () => {
    var orderDiscounts = [];
    var selectedOffers = offersViewModel.filter(
      (o) => o.Selected && !Utils.IsNullOrEmpty(o.CampaignId)
    );
    selectedOffers.forEach((selectedOffer) => {
      var allDiscounts = serviceItems.map((c) => c.Discounts);
      allDiscounts.forEach((disc) => {
        if (
          !disc.Complimentary &&
          !disc.OrderLevel &&
          disc.CampaignId === selectedOffer.CampaignId
        ) {
          return;
        }
        var newOffer = {
          CampaignId: selectedOffer.CampaignId,
          CampaignType: selectedOffer.CampaignType,
          Description: selectedOffer.Description,
          DescriptionDetail: selectedOffer.Type,
          DiscountTotal: selectedOffer.DiscountTotal,
          DiscountType: selectedOffer.DiscountType,
          DiscountValue: selectedOffer.DiscountValue,
        };
        orderDiscounts.push(newOffer);
      });
    });
    return orderDiscounts;
  };

  const getOrderComps = () => {
    var orderComps = [];
    orderComplimentaryList
      .filter((o) => o.orderLevel)
      .forEach((comp) => {
        var orderComp = {
          Complimentary: true,
          Description: "Order Complimentary",
          DescriptionDetail:
            comp.type === OfferTypes.PercentDiscount
              ? "Discount Percentage " + comp.value + "%"
              : "Cash Back " + Utils.GetCurrency(comp.value, countryInfo),
          DiscountTotal: comp.discount,
          DiscountValue: comp.value,
          DiscountType: comp.type,
        };
        orderComps.push(orderComp);
      });
    orderComps
      .filter((d) => d.DiscountType === OfferTypes.PercentDiscount)
      .forEach((orderComp) => {
        var tp = serviceItems.map((d) => d.TotalPrice).reduce((a, b) => a + b);
        var dp = serviceItems
          .map((d) => d.DiscountTotal)
          .reduce((a, b) => a + b);
        orderComp.DiscountTotal = ((tp - dp) * orderComp.DiscountValue) / 100;
      });
    return orderComps;
  };

  const [transactionFailureResult, setTransactionFailureResult] = useState("");

  const processPayment = async () => {
    setIsLoading(true);
    var orderDiscounts = getCampaignOffers();
    var orderComps = getOrderComps();
    if (
      !Utils.IsNullOrEmptyObject(visit) &&
      BusinessHelper.IsAutomobile(business) &&
      (isBusinessCountryIndia || isBusinessCountryUAE) &&
      visit.visitType === VisitTypeEnum.Jobcard &&
      !processingPartialPay
    ) {
      //upfateVisit on offer Apply
      await updateVisitOnOfferApplied(orderDiscounts, orderComps);
    }
    var paymentMethod = PaymentMethodTypes.Cash;
    if (cash) {
      paymentMethod = PaymentMethodTypes.Cash;
    }
    if (upi) {
      paymentMethod = PaymentMethodTypes.UPI;
    }
    if (netBanking) {
      paymentMethod = PaymentMethodTypes.NetBanking;
    }
    if (cardPay && (isBusinessCountryIndia || isBusinessCountryUAE)) {
      paymentMethod = PaymentMethodTypes.Card;
    }

    if (paymentLink && isBusinessCountryIndia) {
      paymentMethod = PaymentMethodTypes.PaymentLink;
    }
    var redemptions = [];
    var selectedAMCOffers = offersViewModel.filter(
      (o) =>
        o.Selected &&
        o.OffersClassificationType === OfferClassificationTypes.AMC
    );
    if (!Utils.IsNullOrEmptyArray(selectedAMCOffers)) {
      selectedAMCOffers.forEach((amcOffer) => {
        var discountTotal = 0;
        var filteredDiscounts = [];
        serviceItems.forEach((item) => {
          var discounts = [];
          if (!Utils.IsNullOrEmptyArray(item.Discounts)) {
            discounts = item.Discounts.filter(
              (d) =>
                d.CampaignId === amcOffer.CampaignId &&
                d.BenefitId === amcOffer.BenefitId &&
                d.OffersClassificationType === OfferClassificationTypes.AMC
            );
          }
          if (!Utils.IsNullOrEmptyArray(discounts)) {
            discounts.forEach((d) => {
              filteredDiscounts.push(d);
            });
          }
        });
        if (!Utils.IsNullOrEmptyArray(filteredDiscounts)) {
          discountTotal = filteredDiscounts
            .map((d) => d.DiscountTotal)
            .reduce((a, b) => a + b);
        }
        if (discountTotal > 0) {
          var currentDiscountAppliedItems = [];
          serviceItems.forEach((serviceItem) => {
            if (!Utils.IsNullOrEmptyArray(serviceItem.Discounts)) {
              serviceItem.Discounts.forEach((disc) => {
                if (
                  disc.CampaignId === amcOffer.CampaignId &&
                  disc.BenefitId === amcOffer.BenefitId &&
                  disc.OffersClassificationType === OfferClassificationTypes.AMC
                ) {
                  currentDiscountAppliedItems.push(serviceItem);
                }
              });
            }
          });
          var itemTotal = 0;
          if (!Utils.IsNullOrEmptyArray(currentDiscountAppliedItems)) {
            itemTotal = currentDiscountAppliedItems
              .map((s) => s.TotalPrice)
              .reduce((a, b) => a + b);
          }
          var redemptionDictionary = {
            BenefitId: amcOffer.BenefitId,
            Redemption: {
              RedemptionDT: moment().format(),
              DiscountPrice: discountTotal,
              TotalPrice: itemTotal,
            },
          };
          redemptions.push(redemptionDictionary);
        }
      });
    }
    var taxIncluded =
      !Utils.IsNullOrEmptyObject(selectedInvoiceLabel) &&
      selectedInvoiceLabel.taxIncluded;
    const taxExcludedItems = [];
    if (!taxIncluded) {
      var newItems = [...serviceItems];
      newItems.forEach((item) => {
        item.TaxPercent = 0;
        taxExcludedItems.push(item);
      });
    }
    var request = {
      AutoPay: autoPay,
      CardPay: cardPay && isBusinessCountryUS,
      BookingRef: Utils.CreateGuid(),
      BusinessId: business.id,
      Comments: comments,
      PaymentReceivedBy: paymentReceivedBy,
      Comps: Utils.IsNullOrEmptyArray(orderComps) ? null : orderComps,
      ConfirmationMessageId: "",
      CustomerId:
        Utils.IsNullOrEmptyObject(pass) || pass.isBusiness
          ? ""
          : pass.customerId,
      IncludeFees: false,
      Offers: Utils.IsNullOrEmptyArray(orderDiscounts) ? null : orderDiscounts,
      Paid: cash || upi || cardPay,
      PassId: Utils.IsNullOrEmptyObject(pass) || pass.isBusiness ? "" : pass.id,
      PaymentMethod: paymentMethod,
      PayNow: payNow,
      //PointsRedemption:[],
      ServiceItems: taxIncluded ? serviceItems : taxExcludedItems,
      TaxFree: false,
      Tip: tipAmount,
      Upi: upi,
      NetBanking: netBanking,
      IndianCard: cardPay && isBusinessCountryIndia,
      NameOnCard: nameOnCard,
      CardLast4: lastFourDigits,
      VisitId: Utils.IsNullOrEmptyObject(visit) ? "" : visit.id,
      ShippingFromAddress: shippingFromAddressObject,
      ShippingToAddress: shippingToAddressObject,
      ReverseChargeAppilicable: reverseChargeApplicable,
      InvoiceLabelDescription: !Utils.IsNullOrEmptyObject(selectedInvoiceLabel)
        ? selectedInvoiceLabel.description
        : "",
      BranchId: branch.branchId,
      IsTaxIncluded:
        !Utils.IsNullOrEmptyObject(selectedInvoiceLabel) &&
        selectedInvoiceLabel.taxIncluded,
      ActivatePlanNow: true,
      ConflictingPlansFound: false,
      SelectedPlanId:
        Utils.IsNullOrEmptyArray(offersViewModel) ||
          Utils.IsNullOrEmptyObject(
            offersViewModel.find(
              (o) => o.OffersClassificationType === OfferClassificationTypes.AMC
            )
          )
          ? ""
          : offersViewModel.find(
            (o) => o.OffersClassificationType === OfferClassificationTypes.AMC
          ).CampaignId,
      Redemptions: redemptions,
      UpdateVisit: location.state.isEdit,
      PaidByCustomerAmount: paidByCustomerAmount,
      IsPartialPaymentSelected: isPartialPaySelected,
      PaymentAmount: Utils.ConvertToFloat(totalPrice),
    };
    if (paymentMethod === PaymentMethodTypes.PaymentLink) {
      setPaymentLinkSelected(true);
      var settleAcc = JSON.parse(settlementAccount);
      request = {
        ...request,
        PaymentLinkData: {
          MobileNumber: paymentLinkMobileNumber,
          PaymentAmount: Utils.ConvertToFloat(paymentLinkAmount),
          SettlementAccountId: Utils.IsNullOrEmptyObject(settleAcc)
            ? ""
            : settleAcc.payoutAccountId,
          SettlementAccountAlias: Utils.IsNullOrEmptyObject(settleAcc)
            ? ""
            : settleAcc.payoutAccountName,
        },
      };
    }
    if (
      !Utils.IsNullOrEmptyObject(vehicleDetails) &&
      !Utils.IsNullOrEmpty(vehicleDetails.number)
    ) {
      request = { ...request, VehicleDetails: vehicleDetails };
    }
    var result = await postAsync("Payment/Book", request);
    setIsLoading(false);
    if (result.error) {
      setIsNavigateToLogin(result.isNavigateToLogin);
      setAlert(result.errorMessage);
      return;
    }
    switch (result.data.errorType) {
      case PartialPaymentErrorTypes.None:
        setUpdatedBusiness(result.data.businessEntity, dispatch);
        setUpdatedBranch(
          result.data.businessEntity.branchLocations.find(
            (b) => b.branchId === branch.branchId
          ),
          dispatch
        );
        var newPass = result.data.pass;
        setAutoPay(false);
        setCash(false);
        setUPI(false);
        setCardPay(false);
        setPayTerminal(false);
        setNetBanking(false);
        setPayNow(false);
        setPayLater(false);
        setVisit(result.data.visit);
        if (!Utils.IsNullOrEmptyObject(newPass)) {
          setPass(newPass);
          if (!newPass.isBusiness) {
            setCustomerName(newPass.userName);
            setCustomerEmailId(newPass.emailId);
            setCustomerMobileNumber(newPass.mobileNumber);
          }
        }
        if (
          result.data.visit.status !== VisitStatuses.Delivered &&
          (paymentMethod === PaymentMethodTypes.PaymentLink ||
            isPartialPaySelected === true)
        ) {
          var newState = {
            ...location.state,
            navigationStack: [OverviewPage.Path],
            visit: result.data.visit,
            pass: newPass,
            activeMenu: activeMenu,
          };
          navigate(PaymentsPage.Path, { state: newState });
        } else {
          setShowReceiptOptions(true);
        }
        break;
      case PartialPaymentErrorTypes.Completed:
        setTransactionFailureResult(PartialPaymentErrorTypes.Completed);
        setVisit(result.data.visit);
        setAlertTitle("Transaction Failed");
        setAlert(result.data.errorMessage);
        break;
      case PartialPaymentErrorTypes.AmountExceeds:
        setTransactionFailureResult(PartialPaymentErrorTypes.AmountExceeds);
        setVisit(result.data.visit);
        setAlertTitle("Transaction Failed");
        setAlert(result.data.errorMessage);
        break;
    }
  };

  const getVehicle = () => {
    var vehicleDetails = {};
    if (
      !Utils.IsNullOrEmpty(vehicleNumber) &&
      ((customMade &&
        !Utils.IsNullOrEmpty(vehicleCustomMake) &&
        !Utils.IsNullOrEmpty(vehicleCustomModel)) ||
        (!Utils.IsNullOrEmpty(vehicleMake) &&
          !Utils.IsNullOrEmpty(vehicleModel)))
    ) {
      var insurance = {
        ExpiryDate: moment().format(),
      };
      vehicleDetails = {
        Number: Utils.ConvertToUpperCase(vehicleNumber.trim()),
        Make: customMade ? vehicleCustomMake.trim() : vehicleMake.trim(),
        Model: customMade ? vehicleCustomModel.trim() : vehicleModel.trim(),
        VehicleType: branch.vehicleType,
        FuelType: vehicleFuelType,
        VehicleInsuranceDetails: insurance,
      };
    }
    return vehicleDetails;
  };

  const saveVehicle = async () => {
    var newVehicle = getVehicle();
    if (
      !Utils.IsNullOrEmptyObject(pass) &&
      !Utils.IsNullOrEmptyObject(newVehicle)
    ) {
      var request = {
        IsAutomobile: true,
        VisitId: !Utils.IsNullOrEmptyObject(visit) ? visit.id : "",
        PassId: !Utils.IsNullOrEmptyObject(pass) ? pass.id : "",
        VehicleDetail: newVehicle,
      };
      var result = await postAsync("Customer/SaveVehicleOnCheckout", request);
      return result;
    }
    return { error: false };
  };

  const onClickOkReceiptOptions = async () => {
    var mobileNumbers = customerMobileNumber.split(",");
    mobileNumbers = mobileNumbers.filter((i) => !Utils.IsNullOrEmpty(i));
    if (
      !Utils.IsNullOrEmptyArray(mobileNumbers) &&
      !mobileNumbers.every(
        (i) => i.length === CountryHelper.GetMobileNumberMaxLength()
      )
    ) {
      return;
    }
    if (isAutomobileWithoutVehicle && !anonymous) {
      var validVehicleDetails = isVehicleDetailsValid();
      if (!validVehicleDetails) {
        setAlert("Please enter valid vehicle details");
        return;
      }
      setIsLoading(true);
      var saved = await saveVehicle();
      setIsLoading(false);
      if (saved.error) {
        setIsNavigateToLogin(saved.isNavigateToLogin);
        setAlert(saved.errorMessage);
        return;
      }
    }
    if (anonymous) {
      if (!isVehicleDetailsValid()) {
        setAlert("Please enter valid vehicle details");
        return;
      }
      if (
        !Utils.IsNullOrEmpty(customerEmailId) ||
        ((isBusinessCountryIndia || isBusinessCountryUAE) &&
          !Utils.IsNullOrEmpty(customerMobileNumber))
      ) {
        setIsLoading(true);
        await saveCustomer();
        setIsLoading(false);
      }
    }

    // if(branch.sendAutomaticMessage && !Utils.IsNullOrEmpty(customerEmailId) && Utils.IsEmailValid(customerEmailId) && !emailSent)
    // {
    //   await emailReceipt();
    // }
    // if(branch.sendAutomaticMessage && !Utils.IsNullOrEmpty(customerMobileNumber) && customerMobileNumber.length === 10 && !smsSent)
    // {
    //   await sendSMSInvoice();
    // }
    goBack();
  };

  const goBack = (cancel = false) => {
    setShowReceiptOptions(false);
    setMasterServiceItemsList([]);
    setServiceItems([]);
    if (cancel) {
      navigate(Utils.GetLastArrayElement(location.state.navigationStack), {
        state: {
          ...location.state,
          navigationStack: Utils.RemoveLastElementFromArray(
            location.state.navigationStack
          ),
          visit: visit,
          activeMenu: activeMenu,
        },
      });
      // var navigateTo = location.state.checkout
      //   ? CheckOutPage.Path
      //   : location.state.AMCPlan
      //     ? JobCardDetailsPage.Path
      //     : AMCPlanPage.Path;
      // var newState = location.state.checkout
      //   ? {
      //     checkout: location.state.checkout,
      //     pass: location.state.pass,
      //     serviceItems: location.state.serviceItems,
      //     vehicleDetails: location.state.vehicleDetails,
      //     fromJobcard: location.state.fromJobcard,
      //     fromVisit: location.state.fromVisit,
      //     isEdit: location.state.isEdit,
      //     validVehiclesCount: location.state.validVehiclesCount,
      //     searchText:
      //       location.state && location.state.searchText
      //         ? location.state.searchText
      //         : "",
      //   }
      //   : location.state.visit
      //     ? {
      //       visit: location.state.visit,
      //       fromJobcard: location.state.fromJobcard,
      //       fromVisit: location.state.fromVisit,
      //       isEdit: location.state.isEdit,
      //       validVehiclesCount: location.state.validVehiclesCount,
      //       searchText:
      //         location.state && location.state.searchText
      //           ? location.state.searchText
      //           : "",
      //     }
      //     : {
      //       pass: pass,
      //       serviceItems: serviceItems,
      //       vehicleDetails: vehicleDetails,
      //       fromJobcard: location.state.fromJobcard,
      //       fromAMCPlan: true,
      //       fromVisit: location.state.fromVisit,
      //       isEdit: location.state.isEdit,
      //       validVehiclesCount: location.state.validVehiclesCount,
      //       searchText:
      //         location.state && location.state.searchText
      //           ? location.state.searchText
      //           : "",
      //     };
      // navigate(navigateTo, { state: newState });
      return;
    }
    var navigationStack = [];
    if (processingPartialPay) {
      navigationStack = Utils.RemoveLastElementFromArray(
        location.state.navigationStack
      );
    }
    navigate(location.state.onSuccess, {
      state: {
        ...location.state,
        navigationStack: navigationStack,
        visit: visit,
        activeMenu: activeMenu,
      },
    });
    // if (anonymous) {
    //   navigate(OverviewPage.Path);
    //   return;
    // }
    // if (location.state.fromVisit) {
    //   navigate(VisitsPage.Path);
    //   return;
    // }
    // if (location.state.fromAMCPlan) {
    //   navigate(AMCPlanPage.Path);
    //   return;
    // }
    // navigate(CustomersPage.Path, {
    //   state: {
    //     fromJobcard: location.state.fromJobcard,
    //     searchText:
    //       location.state && location.state.searchText
    //         ? location.state.searchText
    //         : "",
    //   },
    // });
  };

  const isVehicleDetailsValid = () => {
    if (
      (Utils.IsNullOrEmpty(vehicleNumber) &&
        !customMade &&
        Utils.IsNullOrEmpty(vehicleMake) &&
        Utils.IsNullOrEmpty(vehicleModel)) ||
      (Utils.IsNullOrEmpty(vehicleNumber) &&
        customMade &&
        Utils.IsNullOrEmpty(vehicleCustomMake) &&
        Utils.IsNullOrEmpty(vehicleCustomModel)) ||
      (!Utils.IsNullOrEmpty(vehicleNumber) &&
        customMade &&
        !Utils.IsNullOrEmpty(vehicleCustomMake) &&
        !Utils.IsNullOrEmpty(vehicleCustomModel)) ||
      (!Utils.IsNullOrEmpty(vehicleMake) && !Utils.IsNullOrEmpty(vehicleModel))
    ) {
      return true;
    }
    return false;
  };

  const saveCustomer = async () => {
    var addressDetails = null;
    if (!Utils.IsNullOrEmpty(addressDetails_Address)) {
      var geocodeResponse = await getGeocodeAddress(addressDetails_Address);
      if (
        !Utils.IsNullOrEmptyObject(geocodeResponse) &&
        geocodeResponse.status === "OK"
      ) {
        var locality = "";
        var county = "";
        var state = "";
        var country = "";
        var zipcode = "";
        var streetNumber = "";
        var route = "";
        var address = "";
        var lng = 0;
        var lat = 0;
        address = geocodeResponse.results[0].formatted_address;
        if (
          geocodeResponse.results[0].address_components
            .map((c) => c.types)
            .reduce((a, b) => a.concat(b))
            .includes("street_number") === true
        ) {
          var ac = geocodeResponse.results[0].address_components.find((c) =>
            c.types.includes("street_number")
          );
          if (!Utils.IsNullOrEmptyObject(ac)) {
            streetNumber = ac.long_name;
          }
        }

        if (
          geocodeResponse.results[0].address_components
            .map((c) => c.types)
            .reduce((a, b) => a.concat(b))
            .includes("route") === true
        ) {
          var ac = geocodeResponse.results[0].address_components.find((c) =>
            c.types.includes("route")
          );
          if (!Utils.IsNullOrEmptyObject(ac)) {
            route = ac.long_name;
          }
        }

        if (
          geocodeResponse.results[0].address_components
            .map((c) => c.types)
            .reduce((a, b) => a.concat(b))
            .includes("locality") === true
        ) {
          var ac = geocodeResponse.results[0].address_components.find((c) =>
            c.types.includes("locality")
          );
          if (!Utils.IsNullOrEmptyObject(ac)) {
            locality = ac.long_name;
          }
        }

        if (
          geocodeResponse.results[0].address_components
            .map((c) => c.types)
            .reduce((a, b) => a.concat(b))
            .includes("administrative_area_level_1") === true
        ) {
          var ac = geocodeResponse.results[0].address_components.find((c) =>
            c.types.includes("administrative_area_level_1")
          );
          if (!Utils.IsNullOrEmptyObject(ac)) {
            state = ac.long_name;
          }
        }

        if (
          geocodeResponse.results[0].address_components
            .map((c) => c.types)
            .reduce((a, b) => a.concat(b))
            .includes("administrative_area_level_2") === true
        ) {
          var ac = geocodeResponse.results[0].address_components.find((c) =>
            c.types.includes("administrative_area_level_2")
          );
          if (!Utils.IsNullOrEmptyObject(ac)) {
            county = ac.long_name;
          }
        }

        if (
          geocodeResponse.results[0].address_components
            .map((c) => c.types)
            .reduce((a, b) => a.concat(b))
            .includes("country") === true
        ) {
          var ac = geocodeResponse.results[0].address_components.find((c) =>
            c.types.includes("country")
          );
          if (!Utils.IsNullOrEmptyObject(ac)) {
            country = ac.short_name;
          }
        }

        if (
          geocodeResponse.results[0].address_components
            .map((c) => c.types)
            .reduce((a, b) => a.concat(b))
            .includes("postal_code") === true
        ) {
          var ac = geocodeResponse.results[0].address_components.find((c) =>
            c.types.includes("postal_code")
          );
          if (!Utils.IsNullOrEmptyObject(ac)) {
            zipcode = ac.long_name;
          }
        }
        lat = geocodeResponse.results[0].geometry.location.lat;
        lng = geocodeResponse.results[0].geometry.location.lng;
        addressDetails = {
          HomeLocation: addressDetails_Address,
          Address: streetNumber + " " + route,
          City: locality,
          State: state,
          Country: country,
          County: county,
          ZipCode: zipcode,
          Locality: locality,
          Latitude: Utils.ConvertToFloat(lat),
          Longitude: Utils.ConvertToFloat(lng),
        };
      } else {
        setIsLoading(false);
        setAlert("Invalid address");
        return customerSaveType.Error;
      }
    }
    var newNehicle = getVehicle();
    var request = {
      BusinessId: getBusiness().id,
      BranchId: branch.branchId,
      CountryCode: Utils.IsNullOrEmptyObject(countryInfo)
        ? getCountry().Code
        : countryInfo.Code,
      CustomerVisit: Utils.IsNullOrEmptyObject(visit) ? null : visit,
      EmailId: !Utils.IsNullOrEmpty(customerEmailId)
        ? customerEmailId.trim()
        : "",
      CustomerName: !Utils.IsNullOrEmpty(customerName)
        ? customerName.trim()
        : "",
      MobileNumber: !Utils.IsNullOrEmpty(customerMobileNumber)
        ? customerMobileNumber.trim()
        : "",
      AddressDetails: addressDetails,
      VehicleDetail: !Utils.IsNullOrEmptyObject(newNehicle) ? newNehicle : null,
      EnterpriseId: getEnterpriseId(),
    };
    var result = await postAsync("Customer/SaveAnonymousCustomer", request);
    if (result.error) {
      setIsNavigateToLogin(result.isNavigateToLogin);
      setAlert(result.errorMessage);
      return customerSaveType.Error;
    }
    if (showCustomerSetupDialogForPartialPay) {
      setAddresDetails_Address("");
      setCustomerName("");
      setAnonymous(
        Utils.IsNullOrEmptyObject(result.data.pass) ||
        result.data.pass.isBusiness
      );
      setPass(result.data.pass);
    }
    setUpdatedBusiness(result.data.businessEntity, dispatch);
    return customerSaveType.Success;
  };

  useEffect(() => {
    checkCardDetailsFilled();
  }, [nameOnCard, lastFourDigits]);

  const checkCardDetailsFilled = () => {
    var trueCondition =
      !Utils.IsNullOrEmpty(nameOnCard) &&
      !Utils.IsNullOrEmpty(lastFourDigits) &&
      lastFourDigits.length === 4;
    setCardDetailsDisabled(!trueCondition);
  };

  const onClickCloseCardDetails = () => {
    setShowCardDetails(false);
    setOpenPaymentSelector(true);
  };

  const onClickOkCardDetails = async () => {
    setShowCardDetails(false);
    await processPayment();
    //process payment
  };

  const onClickCloseUPIDetails = () => {
    setShowUPIDetails(false);
    setOpenPaymentSelector(true);
  };

  const onClickOkUPIDetails = async () => {
    setShowUPIDetails(false);
    await processPayment();
    //process payment
  };

  const onClickCancel = () => {
    setShowCancelConfirmation(true);
  };

  const confirmCancel = async (confirmResult) => {
    setShowCancelConfirmation(false);
    switch (confirmResult) {
      case ConfirmTypes.YES:
        if (!Utils.IsNullOrEmptyObject(visit) && visit.paidAmount === 0) {
          if (business.category !== BusinessCategories.Automobile) {
            setIsLoading(true);
            var request = {
              BusinessId: business.id,
              VisitId: visit.id,
            };
            var result = await postAsync("Payment/Cancel", request);
            setIsLoading(false);
            if (result.error) {
              setIsNavigateToLogin(result.isNavigateToLogin);
              setAlert(result.errorMessage);
              return;
            }
            setAlert(result.data);
          }
        }
        goBack(true);
        break;
      default:
        return;
    }
  };

  const handleAlertClose = () => {
    setAlertTitle("");
    setShowAlert(false);
    if (isNavigateToLogin) {
      UserHelper.LogOut(dispatch);
      navigate(LoginPage.Path);
      return;
    }
    if (processingPartialPay && paymentLinkSelected) {
      setPaymentLinkSelected(false);
      setShowPaymentLinkDetails(true);
      return;
    }
    switch (transactionFailureResult) {
      case PartialPaymentErrorTypes.Completed:
        setTransactionFailureResult("");
        goBack();
        break;
      case PartialPaymentErrorTypes.AmountExceeds:
        setTransactionFailureResult("");
        setShowPartialPayEntryDialog(true);
        break;
    }
  };

  const confirmPartialPay = (action) => {
    setShowConfirmDialogForPartialPay(false);
    switch (action) {
      case partialPayActions.FullPay:
        setOpenPaymentSelector(true);
        break;
      case partialPayActions.PartialPayWithoutCustomer:
        //open cutomer setup
        setShowCustomerSetupDialogForPartialPay(true);
        break;
      case partialPayActions.PartialPay:
        //open partial pay entry
        setShowPartialPayEntryDialog(true);
        break;
      case partialPayActions.Close:
        // if(CountryHelper.IsBusinessCountryIndia() && !anonymous)
        // {
        //   setOpenAddressSelector(true);
        // }
        break;
    }
  };

  const closePartialPayAmountEntry = () => {
    setShowPartialPayEntryDialog(false);
    if (processingPartialPay) {
      goBack(true);
      return;
    }
    if (branch.allowMultiplePayments) {
      setShowConfirmDialogForPartialPay(true);
    }
    // if(CountryHelper.IsBusinessCountryIndia(countryInfo))
    // {
    //   setOpenAddressSelector(true);
    // }
  };

  useEffect(() => {
    setBalanceAmount(outstandingAmount - paidByCustomerAmount);
  }, [paidByCustomerAmount]);

  const proceedPartialPayment = () => {
    setShowPartialPayEntryDialog(false);
    setIsPartialPaySelected(true);
    setOpenPaymentSelector(true);
  };

  const validateAndSetPaymentAmountEnteredByCustomer = (e) => {
    var newValue = e.target.value;
    var floatValue = Utils.ConvertToFloat(newValue);
    if (floatValue === 0) {
      setIsPartialPayProceedButtonDisabled(true);
      setPaidByCustomerAmountString(newValue);
      setPaidByCustomerAmount(floatValue);
      return;
    }
    if (floatValue <= outstandingAmount && floatValue > 0) {
      setPaidByCustomerAmountString(newValue);
      setPaidByCustomerAmount(floatValue);
      setIsPartialPayProceedButtonDisabled(false);
    }
  };

  const closeCustomerSetup = () => {
    setShowCustomerSetupDialogForPartialPay(false);
    setShowConfirmDialogForPartialPay(true);
  };

  useEffect(() => {
    checkAndValidateCustomerDetails();
  }, [customerName, customerMobileNumber]);

  const checkAndValidateCustomerDetails = () => {
    var isEnabled =
      !Utils.IsNullOrEmpty(customerName) &&
      Utils.IsMobileValid(customerMobileNumber);
    setIsCustomerSaveDisabled(!isEnabled);
  };

  const onClickClosePaymentLinkDetails = () => {
    setShowPaymentLinkDetails(false);
    setOpenPaymentSelector(true);
    setPaymentLink(false);
    setPaymentLinkAmount("");
    setPaymentLinkMobileNumber("");
  };

  const onClickOkPaymentLinkDetails = async () => {
    setShowPaymentLinkDetails(false);
    setPaymentLink(false);
    await processPayment();
  };

  const proceedSaveCustomer = async () => {
    var result = await saveCustomer();
    if (result === customerSaveType.Success) {
      setShowCustomerSetupDialogForPartialPay(false);
      setShowPartialPayEntryDialog(true);
    }
  };

  const onChangeSettlementAccount = (e) => {
    var selectedSettlementAccount = JSON.parse(e.target.value);
    setSelecteSettleAccountName(selectedSettlementAccount.accountAlias);
    setSettlementAccount(e.target.value);
  };

  useEffect(() => {
    checkPaymentLinkDetails();
  }, [paymentLinkAmount, settlementAccount, paymentLinkMobileNumber]);

  const checkPaymentLinkDetails = () => {
    var isSettleAccountEmptyObject =
      Utils.IsNullOrEmptyObject(settlementAccount);
    var isEnabled =
      paymentLinkAmount > 0 &&
      paymentLinkAmount <= totalPrice &&
      !isSettleAccountEmptyObject &&
      Utils.IsMobileValid(paymentLinkMobileNumber);
    if (isEnabled) {
      var acc = JSON.parse(settlementAccount);
      isEnabled = isEnabled && !Utils.IsNullOrEmptyObject(acc);
    }
    setPaymentLinkDisabled(!isEnabled);
  };

  return (
    <Container maxWidth="false" className="bg-color p-0">
      {/* Loader */}
      <Loader open={isLoading} />

      {/*Partial pay amount entry dialog*/}
      <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        className="cs-modal-sec"
        onClose={() => closePartialPayAmountEntry()}
        open={showPartialPayEntryDialog}
      >
        <DialogContent>
          <Stack
            spacing={2}
            className="justify-content-between card mt-1 font-size-13"
            padding="5px"
          >
            <Stack
              direction="row"
              alignItems="baseline"
              justifyContent="space-between"
              spacing={2}
            >
              <label htmlFor="amount" style={{ float: "left" }}>
                Amount
              </label>
              <TextField
                id="amount"
                variant="outlined"
                type="number"
                value={paidByCustomerAmountString}
                style={{ float: "right" }}
                onChange={(e) =>
                  validateAndSetPaymentAmountEnteredByCustomer(e)
                }
              />
            </Stack>
            <Stack
              direction="row"
              alignItems="baseline"
              justifyContent="space-between"
              spacing={2}
            >
              <Typography variant="subtitle1" style={{ float: "left" }}>
                Amount to be Paid
              </Typography>
              <Typography variant="body1" style={{ float: "right" }}>
                {outstandingAmount}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              alignItems="baseline"
              justifyContent="space-between"
              spacing={2}
            >
              <Typography variant="subtitle1" style={{ float: "left" }}>
                Balance
              </Typography>
              <AmountTypography
                value={balanceAmount}
                country={countryInfo}
                variant="body1"
                style={{ float: "right" }}
              />
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            className="btn btn-primary white-text"
            variant="contained"
            color="primary"
            disabled={isPartialPayProceedButtonDisabled}
            onClick={() => proceedPartialPayment()}
          >
            Pay
          </Button>
          <Button
            autoFocus
            className="btn btn-secondary"
            variant="contained"
            style={{ backgroundColor: "gray" }}
            onClick={() => closePartialPayAmountEntry()}
          >
            Cancel
          </Button>
        </DialogActions>
      </BootstrapDialog>

      {/*Customer setup*/}
      <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        className="cs-modal-sec"
        open={showCustomerSetupDialogForPartialPay}
      >
        <DialogTitle
          display="flex"
          justifyContent="center"
          alignItems="baseline"
          className="dialog-title"
        >
          <Typography
            level="h2"
            component="h2"
            fontWeight="600"
            fontSize="1.6rem"
            style={{ padding: " 10px 0" }}
          >
            Enter details to proceed payment
          </Typography>
        </DialogTitle>
        <DialogContent style={{ "padding-top": "10px" }}>
          <Grid container spacing={2} className="font-size-13">
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Name"
                variant="outlined"
                name="name"
                required
                value={customerName}
                onChange={(e) => setCustomerName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Email"
                variant="outlined"
                type="email"
                name="email"
                value={customerEmailId}
                onChange={(e) => setCustomerEmailId(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <NumericTextField
                fullWidth
                label="Mobile Number"
                variant="outlined"
                name="mobileNumber"
                required
                inputProps={{
                  maxLength:
                    CountryHelper.GetMobileNumberMaxLength(countryInfo),
                }}
                value={customerMobileNumber}
                setValue={setCustomerMobileNumber}
              />
            </Grid>
            <Grid item xs={12}>
              <AddressField
                id="csProductName"
                variant="outlined"
                label="Address"
                countryISoCode={addressISOCode}
                value={addressDetails_Address}
                setValue={setAddresDetails_Address}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            className="btn btn-primary white-text"
            variant="contained"
            color="primary"
            disabled={isCustomerSaveDisabled}
            onClick={() => proceedSaveCustomer()}
          >
            Save
          </Button>
          <Button
            autoFocus
            className="btn btn-secondary"
            variant="contained"
            style={{ backgroundColor: "gray" }}
            onClick={() => closeCustomerSetup()}
          >
            Close
          </Button>
        </DialogActions>
      </BootstrapDialog>

      {/* Partial Pay Dialog */}
      <Dialog
        sx={{ "& .MuiDialog-paper": { width: "50%", maxHeight: 300 } }}
        maxWidth="xs"
        open={showConfirmDialogForPartialPay}
      >
        <DialogTitle
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <h4>Partial Payment</h4>
          <IconButton
            aria-label="close"
            onClick={() => confirmPartialPay(partialPayActions.Close)}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            <Stack direction="column" alignItems="center">
              <img alt="Split Pay" width={"50px"} src={Split_Icon} />
              <Typography
                variant="h5"
                className="py-2"
                style={{ color: "#111111" }}
              >
                Do you want to make a partial payment?
              </Typography>
            </Stack>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            className="btn btn-primary white-text"
            variant="contained"
            color="primary"
            onClick={() =>
              confirmPartialPay(
                anonymous === true
                  ? partialPayActions.PartialPayWithoutCustomer
                  : partialPayActions.PartialPay
              )
            }
          >
            Yes
          </Button>
          <Button
            autoFocus
            className="btn btn-secondary"
            variant="contained"
            style={{ backgroundColor: "gray" }}
            onClick={() => confirmPartialPay(partialPayActions.FullPay)}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>

      {/* Alert */}
      <MessagePopup
        msgOpen={showAlert}
        msgText={alertMessage}
        msgTitle={alertTitle}
        onMsgClose={handleAlertClose}
      />

      {/* Confirm */}
      <ConfirmDialog
        open={showCancelConfirmation}
        onClose={confirmCancel}
        message="Do you wish to cancel?"
        yesText="Yes"
        noText="No"
      />

      {/* Shipping Address */}
      <BootstrapDialog
        onClose={closeAddressSelector}
        aria-labelledby="customized-dialog-title"
        className="cs-modal-sec"
        open={openAddressSelector}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={closeAddressSelector}
        >
          <Typography
            level="h3"
            component="h3"
            fontWeight="600"
            fontSize="2rem"
            className="h3"
          >
            Shipping Address
          </Typography>
        </BootstrapDialogTitle>
        <DialogContent dividers className="font-size-16">
          <Stack
            spacing={2}
            className="justify-content-between card mt-1"
            padding="10px"
          >
            <Typography
              level="h3"
              component="h3"
              fontWeight="600"
              fontSize="1.5rem"
              className="h3"
            >
              From:
            </Typography>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
            >
              <FormControlLabel
                value={shippingFromDefaultAddress}
                control={
                  <Radio
                    name="shippingFromAddress"
                    checked={shippingFromAddressDefaultEnabled}
                  />
                }
                label={shippingFromDefaultAddress}
                onClick={toggleFromAddress}
              />
              <FormControlLabel
                value={0}
                control={
                  <Radio
                    name="shippingFromAddress"
                    checked={!shippingFromAddressDefaultEnabled}
                  />
                }
                label="Use different address"
                onClick={toggleDifferentFromAddress}
              />
            </RadioGroup>
            {isOpenedDifferentFromAddress && (
              <Grid spacing={2} className="fontSize12">
                <FormControl fullWidth>
                  {/* <TextField
                    type="text"
                    id="fromDiffAddress"
                    label="Address"
                    variant="outlined"
                    required
                    InputLabelProps={{ shrink: true }}
                    value={shippingFromNewAddress}
                    onChange={(e) => setShippingFromNewAddress(e.target.value)}
                  /> */}
                  <AddressField
                    id="fromDiffAddress"
                    variant="outlined"
                    label="Address"
                    required
                    countryISoCode={addressISOCode}
                    value={shippingFromNewAddress}
                    setValue={setShippingFromNewAddress}
                  />
                </FormControl>
                <FormControl fullWidth className="mt-1">
                  <TextField
                    type="text"
                    id="fromDiffStatecode"
                    label="GSTN State Code"
                    variant="outlined"
                    required
                    InputLabelProps={{ shrink: true }}
                    value={shippingFromStateCode}
                    onChange={(e) => setShippingFromStateCode(e.target.value)}
                  />
                </FormControl>
                <FormControl fullWidth className="mt-1">
                  <TextField
                    type="text"
                    id="fromDiffPhone"
                    label="Phone"
                    variant="outlined"
                    required
                    InputLabelProps={{ shrink: true }}
                    value={shippingFromPhoneNumber}
                    onChange={(e) => setShippingFromPhoneNumber(e.target.value)}
                  />
                </FormControl>
                <FormControl fullWidth className="mt-1">
                  <TextField
                    type="text"
                    id="fromDiffEmail"
                    label="Email"
                    variant="outlined"
                    required
                    InputLabelProps={{ shrink: true }}
                    value={shippingFromEmailId}
                    onChange={(e) => setShippingFromEmailId(e.target.value)}
                  />
                </FormControl>
              </Grid>
            )}
          </Stack>
          <Stack
            spacing={2}
            className="justify-content-between card mt-1"
            padding="10px"
          >
            <Typography
              level="h3"
              component="h3"
              fontWeight="600"
              fontSize="1.5rem"
              className="h3"
            >
              To:
            </Typography>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
            >
              <FormControlLabel
                value={shippingToDefaultAddress}
                control={
                  <Radio
                    name="shippingToAddress"
                    checked={shippingToAddressDefaultEnabled}
                  />
                }
                label={shippingToDefaultAddress}
                onClick={toggleToAddress}
              />
              <FormControlLabel
                value={0}
                control={
                  <Radio
                    name="shippingToAddress"
                    checked={!shippingToAddressDefaultEnabled}
                  />
                }
                label="Use different address"
                onClick={toggleDifferentToAddress}
              />
            </RadioGroup>
            {isOpenedDifferentToAddress && (
              <Grid spacing={2} className="fontSize12">
                <FormControl fullWidth>
                  <AddressField
                    id="toDiffAddress"
                    variant="outlined"
                    label="Address"
                    required
                    countryISoCode={addressISOCode}
                    value={shippingToNewAddress}
                    setValue={setShippingToNewAddress}
                  />
                </FormControl>
                <FormControl fullWidth className="mt-1">
                  <TextField
                    type="text"
                    id="toDiffStatecode"
                    label="GSTN State Code"
                    variant="outlined"
                    required
                    InputLabelProps={{ shrink: true }}
                    value={shippingToStateCode}
                    onChange={(e) => setShippingToStateCode(e.target.value)}
                  />
                </FormControl>
                <FormControl fullWidth className="mt-1">
                  <TextField
                    type="text"
                    id="toDiffPhone"
                    label="Phone"
                    variant="outlined"
                    required
                    InputLabelProps={{ shrink: true }}
                    value={shippingToPhoneNumber}
                    onChange={(e) => setShippingToPhoneNumber(e.target.value)}
                  />
                </FormControl>
                <FormControl fullWidth className="mt-1">
                  <TextField
                    type="text"
                    id="toDiffEmail"
                    label="Email"
                    variant="outlined"
                    required
                    InputLabelProps={{ shrink: true }}
                    value={shippingToEmailId}
                    onChange={(e) => setShippingToEmailId(e.target.value)}
                  />
                </FormControl>
              </Grid>
            )}
          </Stack>
          <Stack
            spacing={2}
            className="justify-content-between card mt-1"
            padding="10px"
          >
            <Typography
              level="h4"
              component="h4"
              fontWeight="600"
              fontSize="1rem"
            >
              Reverse charge applicable
            </Typography>
            <Stack direction="row" spacing={1} name="radio-buttons-group">
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
              >
                <FormControlLabel
                  value="Yes"
                  control={
                    <Radio
                      name="reverseCharge"
                      checked={reverseChargeApplicable}
                    />
                  }
                  label="Yes"
                  onChange={(e) => setReverseChargeApplicable(true)}
                />
                <FormControlLabel
                  value="To"
                  control={
                    <Radio
                      name="reverseCharge"
                      checked={!reverseChargeApplicable}
                    />
                  }
                  label="No"
                  onChange={(e) => setReverseChargeApplicable(false)}
                />
              </RadioGroup>
            </Stack>
          </Stack>
          <Stack
            direction="row"
            spacing={2}
            padding="10px 0 0px 0"
            justifyContent="flex-end"
          >
            <FormControl>
              <Button
                type="submit"
                className="btn btn-primary"
                variant="contained"
                onClick={clickOpenPaymentType}
              >
                Proceed
              </Button>
            </FormControl>
          </Stack>
        </DialogContent>
      </BootstrapDialog>

      {/* Payment Type */}
      <BootstrapDialog
        onClose={() => closePaymentType()}
        aria-labelledby="customized-dialog-title"
        open={openPaymentSelector}
        className=""
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => closePaymentType()}
        >
          <Typography
            level="h3"
            component="h3"
            fontWeight="600"
            fontSize="2rem"
            className="h3"
          >
            Payment Type
          </Typography>
        </BootstrapDialogTitle>
        <DialogContent dividers className="font-size-14">
          {/*Cash Option*/}
          <Stack
            direction="row"
            spacing={2}
            className="card justify-content-between"
            padding="10px"
            onClick={() => {
              closePaymentType(PaymentTypes.Cash);
            }}
          >
            <Stack direction="row">
              <img alt="Cash Pay" width={"50px"} src={Cash_Icon} />
              <Stack
                direction="column"
                spacing={1}
                name="radio-buttons-group"
                className="pl-1"
              >
                <Typography
                  level="h4"
                  component="h4"
                  fontWeight="600"
                  fontSize="1.5rem"
                >
                  Cash Pay
                </Typography>
                <Typography level="p" component="p" fontSize="1rem">
                  Customer uses cash to pay
                </Typography>
              </Stack>
            </Stack>
            <NavigateNextIcon className="align-center" />
          </Stack>

          {/*Card Option*/}
          {!isBusinessCountryUS && (
            <Stack
              direction="row"
              spacing={2}
              className="card justify-content-between mt-1"
              padding="10px"
              onClick={() => closePaymentType(PaymentTypes.CardPay)}
            >
              <Stack direction="row">
                <img alt="Cash Pay" width={"50px"} src={Card_Icon} />
                <Stack
                  direction="column"
                  spacing={1}
                  name="radio-buttons-group"
                  className="pl-1"
                >
                  <Typography
                    level="h4"
                    component="h4"
                    fontWeight="600"
                    fontSize="1.5rem"
                  >
                    Card Pay
                  </Typography>
                  <Typography level="p" component="p" fontSize="1rem">
                    Customer presents physical card
                  </Typography>
                </Stack>
              </Stack>
              <NavigateNextIcon className="align-center" />
            </Stack>
          )}

          {/*NetBanking option */}
          {isBusinessCountryIndia && (
            <Stack
              direction="row"
              spacing={2}
              className="card justify-content-between mt-1"
              padding="10px"
              onClick={() => closePaymentType(PaymentTypes.NetBanking)}
            >
              <Stack direction="row">
                <img
                  alt="Net Banking Pay"
                  width={"50px"}
                  src={Netbanking_Icon}
                />
                <Stack
                  direction="column"
                  spacing={1}
                  name="radio-buttons-group"
                  className="pl-1"
                >
                  <Typography
                    level="h4"
                    component="h4"
                    fontWeight="600"
                    fontSize="1.5rem"
                  >
                    Net Banking Pay
                  </Typography>
                  <Typography level="p" component="p" fontSize="1rem">
                    Customer uses net banking to pay
                  </Typography>
                </Stack>
              </Stack>
              <NavigateNextIcon className="align-center" />
            </Stack>
          )}

          {/*UPI Option*/}
          {isBusinessCountryIndia && (
            <Stack
              direction="row"
              spacing={2}
              className="card justify-content-between mt-1"
              padding="10px"
              onClick={() => closePaymentType(PaymentTypes.UPI)}
            >
              <Stack direction="row">
                <img alt="UPI Pay" width={"50px"} src={Upi_Icon} />
                <Stack
                  direction="column"
                  spacing={1}
                  name="radio-buttons-group"
                  className="pl-1"
                >
                  <Typography
                    level="h4"
                    component="h4"
                    fontWeight="600"
                    fontSize="1.5rem"
                  >
                    UPI Pay
                  </Typography>
                  <Typography level="p" component="p" fontSize="1rem">
                    Customer uses UPI app to scan QR code and pay
                  </Typography>
                </Stack>
              </Stack>
              <NavigateNextIcon className="align-center" />
            </Stack>
          )}

          {/* Payment Link*/}
          {isBusinessCountryIndia && CanRaisePaymentLink && (
            <Stack
              direction="row"
              spacing={2}
              className="card justify-content-between mt-1"
              padding="10px"
              onClick={() => closePaymentType(PaymentTypes.PaymentLink)}
            >
              <Stack direction="row">
                <img alt="Payment link" width={"50px"} src={PaymentLink_Icon} />
                <Stack
                  direction="column"
                  spacing={1}
                  name="radio-buttons-group"
                  className="pl-1"
                >
                  <Typography
                    level="h4"
                    component="h4"
                    fontWeight="600"
                    fontSize="1.5rem"
                  >
                    Payment Link
                  </Typography>
                  <Typography level="p" component="p" fontSize="1rem">
                    Customer will receive a link in sms to make payments online
                  </Typography>
                </Stack>
              </Stack>
              <NavigateNextIcon className="align-center" />
            </Stack>
          )}

          {/*Close Option */}
          <Stack
            direction="row"
            spacing={2}
            padding="10px 0 0px 0"
            justifyContent="flex-end"
          >
            <FormControl>
              <Button
                className="btn btn-secondary"
                type="submit"
                variant="contained"
                onClick={() => closePaymentType()}
              >
                Close
              </Button>
            </FormControl>
          </Stack>
        </DialogContent>
      </BootstrapDialog>

      {/* Card */}
      <BootstrapDialog
        onClose={onClickCloseCardDetails}
        aria-labelledby="customized-dialog-title"
        open={showCardDetails}
        className=""
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={onClickCloseCardDetails}
        >
          <Typography
            level="h3"
            component="h3"
            fontWeight="600"
            fontSize="2rem"
            className="h3"
          >
            Card Details
          </Typography>
        </BootstrapDialogTitle>
        <DialogContent dividers className="font-size-14">
          <FormControl fullWidth>
            <TextField
              id="csProductName"
              label="Name on Card"
              variant="outlined"
              onChange={(e) => setNameOnCard(e.target.value)}
              required
            />
          </FormControl>
          <FormControl fullWidth className="mt-1">
            <NumericTextField
              value={lastFourDigits}
              setValue={setLastFourDigits}
              id="csProductName"
              label="Enter Last 4 Digit"
              variant="outlined"
              inputProps={{ maxLength: 4 }}
              required
            />
          </FormControl>
          <Stack
            direction="row"
            spacing={2}
            padding="10px 0 0px 0"
            justifyContent="flex-end"
          >
            <FormControl>
              <Button
                className="btn btn-secondary"
                variant="outlined"
                onClick={onClickCloseCardDetails}
              >
                Cancel
              </Button>
            </FormControl>
            <FormControl>
              <Button
                className="btn btn-primary"
                type="submit"
                variant="contained"
                onClick={onClickOkCardDetails}
                disabled={cardDetailsDisabled}
              >
                Pay
              </Button>
            </FormControl>
          </Stack>
        </DialogContent>
      </BootstrapDialog>

      {/* On Choose Payment Link */}
      <BootstrapDialog
        onClose={onClickClosePaymentLinkDetails}
        area-labelledby="customized-dialog-title"
        open={showPaymentLinkDetails}
      >
        <BootstrapDialogTitle onClose={onClickClosePaymentLinkDetails}>
          <Typography
            level="h3"
            component="h3"
            fontWeight="600"
            fontSize="2rem"
            className="h3"
          >
            Payment Link Details
          </Typography>
        </BootstrapDialogTitle>
        <DialogContent deviders className="font-size-14">
          <Stack
            direction="column"
            spacing={2}
            className="card justify-content-between mt-1"
            padding="10px"
            justifyContent="flex-end"
          >
            <FormControl fullWidth>
              <NumericTextField
                id="csProductName"
                label="Payment Amount"
                variant="outlined"
                disabled
                value={paymentLinkAmount}
                setValue={setPaymentLinkAmount}
                required
              />
            </FormControl>
            <FormControl fullWidth>
              <NumericTextField
                id="csProductName"
                label="Mobile Number"
                variant="outlined"
                inputProps={{ maxLength: 10 }}
                value={paymentLinkMobileNumber}
                setValue={setPaymentLinkMobileNumber}
                required
              />
            </FormControl>
            <FormControl fullWidth variant="outlined" margin="0">
              <InputLabel id="Category-label">Settlement Account</InputLabel>
              <Select
                inputProps={{ style: { fontSize: 13 } }}
                labelId="Category-label"
                id="Category-select"
                value={settlementAccount || ""}
                onChange={(e) => onChangeSettlementAccount(e)}
                InputLabelProps={{ shrink: true }}
                label="Settlement Account"
                style={{ height: "51.5px" }}
              >
                {!Utils.IsNullOrEmptyArray(settlementAccounts) &&
                  settlementAccounts.map((item) => (
                    <MenuItem value={JSON.stringify(item)}>
                      {item.accountAlias}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Stack>
          <Stack
            direction="row"
            spacing={2}
            padding="50px 0 0px 0"
            justifyContent="end"
          >
            <FormControl>
              <Button
                className="btn btn-secondary"
                variant="outlined"
                onClick={() => onClickClosePaymentLinkDetails()}
              >
                Cancel
              </Button>
            </FormControl>
            <FormControl>
              <Button
                className="btn btn-primary"
                type="submit"
                variant="contained"
                disabled={paymentLinkDisabled}
                onClick={() => onClickOkPaymentLinkDetails()}
              >
                Ok
              </Button>
            </FormControl>
          </Stack>
        </DialogContent>
      </BootstrapDialog>

      {/* Upi */}
      <BootstrapDialog
        onClose={onClickCloseUPIDetails}
        aria-labelledby="customized-dialog-title"
        open={showUPIDetails}
        className=""
      >
        <DialogContent dividers className="font-size-14">
          <Stack className="text-center">
            {!Utils.IsNullOrEmpty(upiValue) && (
              <QRCode
                size={256}
                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                value={upiValue}
                viewBox={`0 0 256 256`}
              />
            )}
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              justifyContent="center"
            >
              <Typography
                level="h3"
                component="h3"
                fontWeight="600"
                fontSize="1rem"
                className="h3"
              >
                Paying
              </Typography>
              <AmountTypography
                value={
                  isPartialPaySelected
                    ? paidByCustomerAmount
                    : outstandingAmount
                }
                country={countryInfo}
                level="h3"
                component="h3"
                fontWeight="600"
                fontSize="1rem"
                className="h3"
              />
            </Stack>
          </Stack>
          <Stack
            direction="row"
            spacing={2}
            padding="50px 0 0px 0"
            justifyContent="center"
          >
            <FormControl>
              <Button
                className="btn btn-secondary"
                variant="outlined"
                onClick={onClickCloseUPIDetails}
              >
                Cancel
              </Button>
            </FormControl>
            <FormControl>
              <Button
                className="btn btn-primary"
                type="submit"
                variant="contained"
                onClick={onClickOkUPIDetails}
              >
                Ok
              </Button>
            </FormControl>
          </Stack>
        </DialogContent>
      </BootstrapDialog>

      {/* Add Tip Modal */}
      <BootstrapDialog
        onClose={clickCloseTip}
        aria-labelledby="customized-dialog-title"
        open={tipModal}
        className=""
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={clickCloseTip}
        >
          <Typography
            level="h3"
            component="h3"
            fontWeight="600"
            fontSize="2rem"
            className="h3 text-center"
          >
            Tip
          </Typography>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Stack
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
            direction="column"
            className="font-size-14"
          >
            <RadioGroup row style={{ "justify-content": "space-between" }}>
              <FormControlLabel
                value="0"
                control={<Radio name="tipAmount" />}
                label="0%"
                onChange={(e) => tipValue(e.target.value)}
              />
              <FormControlLabel
                value="5"
                control={<Radio name="tipAmount" />}
                label="5%"
                onChange={(e) => tipValue(e.target.value)}
              />
              <FormControlLabel
                value="10"
                control={<Radio name="tipAmount" />}
                label="10%"
                onChange={(e) => tipValue(e.target.value)}
              />
              <FormControlLabel
                value="15"
                control={<Radio name="tipAmount" />}
                label="15%"
                onChange={(e) => tipValue(e.target.value)}
              />
              <FormControlLabel
                value="20"
                control={<Radio name="tipAmount" />}
                label="20%"
                onChange={(e) => tipValue(e.target.value)}
              />
              <FormControlLabel
                value="25"
                control={<Radio name="tipAmount" />}
                label="25%"
                onChange={(e) => tipValue(e.target.value)}
              />
            </RadioGroup>
            <Typography
              level="p"
              component="p"
              fontWeight="600"
              fontSize="14px"
              className="h3 text-center"
              padding="10px"
            >
              or
            </Typography>
            <FormControl fullWidth>
              <TextField
                type="number"
                label="Add Tip"
                variant="outlined"
                value={tipAmount}
                onChange={(e) => tipInputValue(e.target.value)}
              />
            </FormControl>
          </Stack>
        </DialogContent>
      </BootstrapDialog>

      {/* Add Comp Modal */}
      <ComplimentaryPopup
        open={ComplimentaryModal}
        compType={compType}
        compTypeSetter={setCompType}
        existItemComp={existItemComp}
        itemComplimentaryList={itemComplimentaryList}
        orderComplimentaryList={orderComplimentaryList}
        closeComplimentary={clickCloseComplimentary}
        countryInfo={countryInfo}
        serviceItems={serviceItems}
        addComp={AddCommentSharp}
        removeCompItem={removeCompItem}
        compValueSetter={setCompValue}
        maxLimit={maxPrice}
      />

      {/* Receipt Options */}
      <BootstrapDialog
        onClose={onClickOkReceiptOptions}
        aria-labelledby="customized-dialog-title"
        className="cs-modal-sec"
        open={showReceiptOptions}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={onClickOkReceiptOptions}
        >
          <Typography
            level="h3"
            component="h3"
            fontWeight="600"
            fontSize="2rem"
            className="h3"
          >
            Receipt Options
          </Typography>
        </BootstrapDialogTitle>
        <DialogContent dividers className="font-size-14">
          <Stack
            direction="row"
            spacing={2}
            className="card justify-content-between"
            padding="10px"
            onClick={clickOpenPaymentTypeCash}
          >
            <FormControlLabel
              control={<Switch checked={sendInvoiceEnable} />}
              label="Send Invoice"
              value="Yes"
              onChange={(e) => setSendInvoiceEnable(e.target.value)}
            />
          </Stack>
          <Stack
            direction="row"
            spacing={2}
            className="justify-content-between mt-1"
          >
            <FormControl fullWidth>
              <TextField
                id="csProductName"
                label="Name"
                variant="outlined"
                value={customerName}
                onChange={(e) => setCustomerName(e.target.value)}
              />
            </FormControl>
            <Link
              href="mailto:name1@mail.com?subject=Share%20Item&body=Dear%20Customer%2C%0D%0A%0D%0A%0D%0A%0D%0AThank%20you%20for%20your%20purchase%20with%20Test%20Company%20.%20We%20truly%20appreciate%20your%20business%20and%20look%20forward%20to%20serving%20you%20again.%0D%0A%0D%0A%0D%0A%0D%0ATotal%3A%20%E2%82%B9%20330.00%0D%0A%0D%0A%0D%0A%0D%0AClick%20the%20below%20link%20to%20view%20detailed%20Invoice%0D%0A%0D%0Ahttps%3A%2F%2Fwww.xuvi.com%2FInfopoint%2Fhome%2Finvoice%3Fculture%3Den-IN%26bid%3DB20230%26cid%3DB20230001%26vid%3Dvis-B20230001-20230626-110506641%0D%0A"
              className="btn btn-secondary btn-sm align-stretch"
              variant="outlined"
              style={{ padding: "18px 8px 0 8px" }}
            >
              <ShareIcon fontSize="large" />
            </Link>
          </Stack>
          <Stack
            direction="row"
            spacing={2}
            className="justify-content-between mt-1"
          >
            <FormControl fullWidth>
              <TextField
                id="csProductName"
                label="Email"
                value={customerEmailId}
                variant="outlined"
                onChange={(e) => setCustomerEmailId(e.target.value)}
              />
            </FormControl>
            <Button
              className="btn btn-secondary btn-sm align-stretch"
              variant="outlined"
              style={{ padding: "2px 8px" }}
              onClick={emailReceipt}
            >
              <EmailIcon fontSize="large" />
            </Button>
          </Stack>
          <Stack
            direction="row"
            spacing={2}
            className="justify-content-between mt-1"
          >
            <FormControl fullWidth>
              <NumericTextField
                id="csProductName"
                label="Mobile Number"
                inputProps={{
                  maxLength:
                    CountryHelper.GetMobileNumberMaxLength(countryInfo),
                }}
                value={customerMobileNumber}
                variant="outlined"
                setValue={setCustomerMobileNumber}
              />
            </FormControl>
            <Button
              className="btn btn-secondary btn-sm align-stretch"
              variant="outlined"
              style={{ padding: "2px 8px" }}
              onClick={sendSMSInvoice}
            >
              <SmsIcon fontSize="large" />
            </Button>
            {isBusinessCountryIndia && (
              <Button
                className="btn btn-secondary btn-sm align-stretch"
                variant="outlined"
                style={{ padding: "2px 8px" }}
                onClick={sendWhatsAppReceipt}
              >
                <WhatsAppIcon fontSize="large" />
              </Button>
            )}
          </Stack>
          {anonymous && (
            <Stack
              direction="column"
              spacing={2}
              className="justify-content-between mt-1"
            >
              <FormControl fullWidth className="mt-1">
                <AddressField
                  id="csProductName"
                  variant="outlined"
                  label="Address"
                  required
                  countryISoCode={addressISOCode}
                  value={addressDetails_Address}
                  setValue={setAddresDetails_Address}
                />
              </FormControl>
              {/* <FormControl fullWidth className="mt-1">
                <TextField
                  id="csProductName"
                  label="City"
                  value={addressDetails_City}
                  variant="outlined"
                  onChange={(e) => setAddresDetails_City(e.target.value)}
                />
              </FormControl>
              <Stack
                direction="row"
                spacing={2}
                className="justify-content-between mt-1"
              >
                <FormControl fullWidth className="mt-1">
                  <TextField
                    id="csProductName"
                    label={isBusinessCountryUAE ? "Emirate" : "State"}
                    value={addressDetails_State}
                    variant="outlined"
                    onChange={(e) => setAddresDetails_State(e.target.value)}
                  />
                </FormControl>
                <FormControl fullWidth className="mt-1">
                  <TextField
                    id="csProductName"
                    label="Pincode"
                    value={addressDetails_Pincode}
                    variant="outlined"
                    onChange={(e) => setAddressDetails_Pincode(e.target.value)}
                  />
                </FormControl>
              </Stack> */}
            </Stack>
          )}

          {isAutomobileWithoutVehicle && (
            <div>
              <Typography
                level="h3"
                component="h3"
                fontWeight="600"
                fontSize="1.5rem"
                className="h3 mt-2"
              >
                Vehicle Details
              </Typography>
              <Stack
                direction="row"
                spacing={2}
                className="justify-content-between mt-1"
              >
                <FormControl fullWidth className="mt-1">
                  <TextField
                    id="csProductName"
                    label="Vehicle Number"
                    variant="outlined"
                    onChange={(e) => setVehicleNumber(e.target.value)}
                  />
                </FormControl>
                <FormControl fullWidth className="mt-1">
                  <InputLabel id="demo-simple-select-label">
                    Fuel Type
                  </InputLabel>
                  <Select
                    id="jobcardFuelType"
                    value={vehicleFuelType}
                    label="Fuel Type"
                    variant="outlined"
                    onChange={fuelHandleChange}
                  >
                    <MenuItem value="Petrol">Petrol</MenuItem>
                    <MenuItem value="Diesel">Diesel</MenuItem>
                    <MenuItem value="CNG">CNG</MenuItem>
                    <MenuItem value="LPG">LPG</MenuItem>
                    <MenuItem value="Electric">Electric</MenuItem>
                  </Select>
                </FormControl>
              </Stack>
              <Stack
                direction="row"
                spacing={2}
                className="mt-2"
                style={{ width: "100%" }}
              >
                <FormControl onClick={() => setCustomMade(!customMade)}>
                  <FormControlLabel
                    control={<Switch />}
                    label="Custom Select"
                  />
                </FormControl>
                {customMade ? (
                  <Stack direction="row" style={{ width: "70%" }}>
                    <FormControl fullWidth>
                      <TextField
                        id="vehicleMakeSelect"
                        label="Make"
                        variant="outlined"
                        value={vehicleCustomMake}
                        onChange={(e) => setVehicleCustomMake(e.target.value)}
                        InputLabelProps={{ shrink: true }}
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <TextField
                        id="vehicleModelSelect"
                        label="Model"
                        variant="outlined"
                        value={vehicleCustomModel}
                        onChange={(e) => setVehicleCustomModel(e.target.value)}
                        InputLabelProps={{ shrink: true }}
                      />
                    </FormControl>
                  </Stack>
                ) : (
                  <Stack direction="row" style={{ width: "70%" }}>
                    <FormControl fullWidth style={{ "margin-right": "15px" }}>
                      <InputLabel id="demo-simple-select-label">
                        Make
                      </InputLabel>
                      <Select
                        id="vehicleMake"
                        value={vehicleMake}
                        label="Make"
                        variant="outlined"
                        onChange={(e) =>
                          vehicleMakeHandleChange(e.target.value)
                        }
                      >
                        {vehicleMakeModelList.map((data) => (
                          <MenuItem value={data.make}>{data.make}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Model
                      </InputLabel>
                      <Select
                        id="vehicleModel"
                        value={vehicleModel}
                        label="Model"
                        variant="outlined"
                        onChange={(e) => setVehicleModel(e.target.value)}
                      >
                        {vehicleMakeItem.map((data) => (
                          <MenuItem value={data}>{data}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Stack>
                )}
              </Stack>
            </div>
          )}
        </DialogContent>
        <Stack
          direction="row"
          spacing={2}
          padding="10px"
          justifyContent="flex-end"
        >
          <FormControl>
            <Button
              type="submit"
              className="btn btn-primary"
              variant="contained"
              onClick={onClickOkReceiptOptions}
            >
              Ok
            </Button>
          </FormControl>
        </Stack>
      </BootstrapDialog>

      {/* Service Staffs */}
      <BootstrapDialog
        onClose={onCloseStaffSelection}
        aria-labelledby="customized-dialog-title"
        open={openStaffSelection}
        className=""
      >
        <DialogContent dividers className="cs-form">
          <Stack direction="row" spacing={2}>
            {isSalon &&
              !Utils.IsNullOrEmptyArray(serviceStaffs) &&
              !Utils.IsNullOrEmptyObject(selectedServiceItem) && (
                <RadioGroup
                  style={{ width: "100%" }}
                  value={compType}
                  onChange={(e) => onChangeStaff(e.target.value)}
                >
                  {serviceStaffs.map((staff) => (
                    <FormControlLabel
                      value={staff.Id}
                      control={
                        <Radio
                          checked={
                            !Utils.IsNullOrEmptyArray(
                              selectedServiceItem.ServiceItemStaffs
                            ) &&
                            selectedServiceItem.ServiceItemStaffs[0].Id ===
                            staff.Id
                          }
                        />
                      }
                      label={staff.Name}
                    />
                  ))}
                </RadioGroup>
              )}
          </Stack>
          <FormControl>
            <Button
              className="btn btn-secondary"
              variant="outlined"
              onClick={() => setOpenStaffSelection(false)}
            >
              Close
            </Button>
          </FormControl>
        </DialogContent>
      </BootstrapDialog>

      <Box>
        <Grid container m={0}>
          <SideBarMenuItems selectedTab={activeMenu} />
          <Grid xs={12} className="content-sec">
            <Grid container direction="row" className="">
              <Grid flex="1" spacing={2} padding="20px">
                <Grid container className="title-sec">
                  <Typography
                    level="h2"
                    component="h2"
                    fontWeight="600"
                    fontSize="2rem"
                    className="page-title text-center"
                  >
                    Payment
                  </Typography>
                  <Stack
                    direction="row"
                    spacing={2}
                    style={{ "align-self": "center", "min-width": "200px" }}
                    className="font-size-14"
                    paddingRight="15px"
                  >
                    {!Utils.IsNullOrEmptyArray(invoiceLabelsList) &&
                      invoiceLabelsList.length > 1 && (
                        <FormControl style={{ width: "100%" }}>
                          <InputLabel id="demo-simple-select-label">
                            Invoice Label
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={invoiceLabel || ""}
                            label="Invoice Label"
                            onChange={(e) =>
                              onChangeInvoiceLabel(e.target.value)
                            }
                          >
                            {invoiceLabelsList.map((i) => (
                              <MenuItem value={JSON.stringify(i)}>
                                {i.description}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                  </Stack>
                </Grid>
                <Stack
                  spacing={2}
                  className="justify-content-between card font-size-14"
                  padding="10px"
                >
                  <Stack
                    direction="row"
                    spacing={2}
                    className="justify-content-between"
                  >
                    <Typography
                      level="h4"
                      component="h4"
                      fontWeight="600"
                      fontSize="1.5rem"
                    >
                      Available Offers
                    </Typography>
                    <Stack direction="row" spacing={1}>
                      {/* <Button
                        className="btn btn-primary btn-sm white-text align-self-start"
                        variant="contained"
                        style={{ padding: "2px 8px" }}
                        onClick={clickOpenTip}
                      >
                        Add Tip
                      </Button> */}
                      <Button
                        className="btn btn-primary btn-sm white-text align-self-start"
                        variant="contained"
                        style={{ padding: "2px 8px" }}
                        onClick={() => clickOpenComplimentary()}
                      >
                        Complimentary
                      </Button>
                    </Stack>
                  </Stack>
                  <Box style={{ "max-height": "200px", overflow: "auto" }}>
                    {!Utils.IsNullOrEmptyArray(offersViewModel) &&
                      offersViewModel.map((offer, i) => (
                        <Stack
                          spacing={2}
                          className="justify-content-between"
                          padding={1}
                        >
                          <Stack direction="row" spacing={2}>
                            <input
                              type="checkbox"
                              checked={offer.Selected}
                              onClick={() => handleOfferSelection(offer, i)}
                            />
                            <Typography level="p" component="p">
                              {offer.Description}
                            </Typography>
                            {(offer.DiscountType === OfferTypes.Free ||
                              offer.BenefitType === BenefitTypes.Free) && (
                                <Typography
                                  level="h6"
                                  component="h6"
                                  fontWeight="600"
                                  fontSize="14px"
                                  className="h6 text-right"
                                >
                                  Free
                                </Typography>
                              )}
                            {offer.DiscountType !== OfferTypes.Free &&
                              offer.BenefitType !== BenefitTypes.Free && (
                                <Typography level="p" component="p">
                                  {offer.Selected
                                    ? Utils.GetCurrency(
                                      offer.DiscountTotal,
                                      !Utils.IsNullOrEmptyObject(countryInfo)
                                        ? countryInfo
                                        : getCountry(),
                                      2
                                    )
                                    : "-"}
                                </Typography>
                              )}
                          </Stack>
                        </Stack>
                      ))}
                  </Box>
                </Stack>

                <Stack className="card mt-1 font-size-14">
                  <Grid container xs={12} padding="10px">
                    <Grid xs={1} alignSelf="center">
                      {isSalon && (
                        <Typography
                          level="p"
                          component="p"
                          className="p text-center"
                        >
                          Staff
                        </Typography>
                      )}
                    </Grid>
                    <Grid xs={1} alignSelf="center">
                      <Typography level="h3" component="h3" className="h3">
                        #
                      </Typography>
                    </Grid>
                    <Grid xs={4}>
                      <Typography level="h3" component="h3" className="h3">
                        Description
                      </Typography>
                    </Grid>
                    <Grid xs={2}>
                      <Typography
                        level="h3"
                        component="h3"
                        className="h3 text-right"
                      >
                        Unit Price
                      </Typography>
                    </Grid>
                    <Grid xs={2}>
                      <Typography
                        level="h3"
                        component="h3"
                        className="h3 text-right"
                      >
                        Qty
                      </Typography>
                    </Grid>
                    <Grid xs={2}>
                      <Typography
                        level="h3"
                        component="h3"
                        className="h3 text-right"
                      >
                        Total Price
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider />
                  {!Utils.IsNullOrEmptyArray(serviceItems) &&
                    serviceItems.map((row, ind) => (
                      <div>
                        <Grid container xs={12} padding="10px 10px">
                          <Grid xs={1} alignSelf="center">
                            {isSalon && row.Service && (
                              <Button onClick={() => toggleStaffSelection(row)}>
                                <Box
                                  component="img"
                                  alt="Assign"
                                  src={Assign_Icon}
                                  className="staff-icon"
                                />
                                {!Utils.IsNullOrEmptyArray(
                                  row.ServiceItemStaffs
                                )
                                  ? row.ServiceItemStaffs[0].Name
                                  : ""}
                              </Button>
                            )}
                          </Grid>
                          <Grid xs={1} alignSelf="center">
                            <Typography
                              level="h6"
                              component="h6"
                              className="h6"
                              onClick={() =>
                                clickOpenComplimentary(row.Sequence, false)
                              }
                            >
                              {ind + 1}
                            </Typography>
                          </Grid>
                          <Grid xs={4} alignSelf="center">
                            <Typography
                              level="h6"
                              component="h6"
                              fontWeight="600"
                              fontSize="14px"
                              className="h6 m-0 text-left"
                              onClick={() =>
                                clickOpenComplimentary(row.Sequence, false)
                              }
                            >
                              {row.Description}
                            </Typography>
                          </Grid>
                          <Grid xs={2} alignSelf="center">
                            <Typography
                              level="h6"
                              component="h6"
                              fontWeight="600"
                              fontSize="14px"
                              className="h6 text-right"
                              onClick={() =>
                                clickOpenComplimentary(row.Sequence, false)
                              }
                            >
                              {Utils.GetCurrency(
                                row.Price,
                                !Utils.IsNullOrEmptyObject(countryInfo)
                                  ? countryInfo
                                  : getCountry(),
                                2
                              )}
                            </Typography>
                          </Grid>
                          <Grid xs={2} alignSelf="center">
                            <Typography
                              level="h6"
                              component="h6"
                              className="h6 text-right"
                              onClick={() =>
                                clickOpenComplimentary(row.Sequence, false)
                              }
                            >
                              {row.Quantity}
                            </Typography>
                          </Grid>
                          <Grid
                            xs={2}
                            direction="column"
                            onClick={() =>
                              clickOpenComplimentary(row.Sequence, false)
                            }
                            alignSelf="center"
                          >
                            <Typography
                              level="h6"
                              component="h6"
                              fontWeight="600"
                              fontSize="14px"
                              className="h6 text-right"
                            >
                              {Utils.GetCurrency(
                                row.TotalPriceIncludingTax,
                                !Utils.IsNullOrEmptyObject(countryInfo)
                                  ? countryInfo
                                  : getCountry(),
                                2
                              )}
                            </Typography>
                            {!Utils.IsNullOrEmptyObject(selectedInvoiceLabel) &&
                              selectedInvoiceLabel.taxIncluded && (
                                <Typography
                                  level="h6"
                                  component="h6"
                                  fontSize="10px"
                                  className="h6 text-right"
                                >
                                  {row.TaxPercent}% Tax
                                </Typography>
                              )}
                          </Grid>
                        </Grid>

                        {!Utils.IsNullOrEmptyArray(row.Discounts) &&
                          !Utils.IsNullOrEmptyArray(
                            row.Discounts.filter((c) => !c.OrderLevel)
                          ) &&
                          row.Discounts.filter((c) => !c.OrderLevel).map(
                            (discount) => (
                              <ItemDiscountComponent
                                discount={discount}
                                countryInfo={countryInfo}
                              />
                            )
                          )}
                      </div>
                    ))}

                  {/* Order Complimentary */}
                  {!Utils.IsNullOrEmptyArray(orderLevelDiscounts) &&
                    orderLevelDiscounts.map((discount) => (
                      <Grid
                        container
                        xs={12}
                        className="order-comp"
                        padding="5px 10px"
                      >
                        <Grid xs={2}></Grid>
                        <Grid xs={8} direction="column">
                          <Typography
                            level="h6"
                            component="h6"
                            fontSize="12px"
                            className="h6 m-0"
                          >
                            {discount.Description}
                          </Typography>
                          <Typography
                            level="h6"
                            component="h6"
                            fontWeight="600"
                            fontSize="14px"
                            className="h6 m-0"
                          >
                            {discount.DescriptionDetail}
                          </Typography>
                        </Grid>
                        <Grid xs={2}>
                          <Typography
                            level="h6"
                            component="h6"
                            fontWeight="600"
                            fontSize="14px"
                            className="h6 text-right"
                          >
                            -
                            {Utils.GetCurrency(
                              discount.DiscountTotal,
                              !Utils.IsNullOrEmptyObject(countryInfo)
                                ? countryInfo
                                : getCountry(),
                              2
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                    ))}
                </Stack>

                <Stack
                  direction="column"
                  spacing={2}
                  className="justify-content-between text-right"
                  padding={1}
                >
                  <Typography
                    level="h6"
                    component="h6"
                    fontWeight="600"
                    fontSize="12px"
                    className="h6 m-0"
                  >
                    Total:
                    {Utils.GetCurrency(
                      subTotal,
                      !Utils.IsNullOrEmptyObject(countryInfo)
                        ? countryInfo
                        : getCountry(),
                      2
                    )}
                  </Typography>
                  {isBusinessCountryUS && (
                    <TaxComponent
                      currentTax={tax}
                      country={
                        !Utils.IsNullOrEmptyObject(countryInfo)
                          ? countryInfo
                          : getCountry()
                      }
                    />
                  )}
                  {isBusinessCountryUAE &&
                    !Utils.IsNullOrEmptyObject(selectedInvoiceLabel) &&
                    selectedInvoiceLabel.taxIncluded && (
                      <TaxComponent
                        currentTax={tax}
                        country={
                          !Utils.IsNullOrEmptyObject(countryInfo)
                            ? countryInfo
                            : getCountry()
                        }
                      />
                    )}
                  {!isBusinessCountryUS &&
                    !isBusinessCountryUAE &&
                    !Utils.IsNullOrEmptyObject(selectedInvoiceLabel) &&
                    selectedInvoiceLabel.taxIncluded && (
                      <div>
                        <Typography
                          level="h6"
                          component="h6"
                          fontWeight="600"
                          fontSize="12px"
                          className="h6 m-0"
                        >
                          CGST:
                          {Utils.GetCurrency(
                            tax / 2,
                            !Utils.IsNullOrEmptyObject(countryInfo)
                              ? countryInfo
                              : getCountry(),
                            2
                          )}
                        </Typography>
                        <Typography
                          level="h6"
                          component="h6"
                          fontWeight="600"
                          fontSize="12px"
                          className="h6 m-0"
                        >
                          SGST:
                          {Utils.GetCurrency(
                            tax / 2,
                            !Utils.IsNullOrEmptyObject(countryInfo)
                              ? countryInfo
                              : getCountry(),
                            2
                          )}
                        </Typography>
                      </div>
                    )}
                  {/*<Typography
                    level="h6"
                    component="h6"
                    fontWeight="600"
                    fontSize="12px"
                    className="h6 m-0"
                  >
                    Tips: {Utils.GetCurrency(tipAmount, countryInfo)}
                          </Typography>*/}
                  <Typography
                    level="h6"
                    component="h6"
                    fontWeight="600"
                    fontSize="12px"
                    className="h6 m-0"
                  >
                    Paid: {Utils.GetCurrency(paidAmount, countryInfo)}
                  </Typography>
                  <Typography
                    level="h4"
                    component="h6"
                    fontWeight="600"
                    fontSize="18px"
                    className="h6 m-0"
                  >
                    {Utils.GetCurrency(
                      totalPrice,
                      !Utils.IsNullOrEmptyObject(countryInfo)
                        ? countryInfo
                        : getCountry(),
                      2
                    )}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  spacing={2}
                  style={{ "justify-content": "space-between" }}
                  className="xs-column"
                >
                  <Stack
                    padding="10px 0 0px 0"
                    justifyContent="flex-start"
                    direction="row"
                    spacing={2}
                    className="font-size-13"
                  >
                    <FormControl>
                      <TextField
                        label="Payment Received By"
                        value={paymentReceivedBy}
                        onChange={(e) => setPaymentReceivedBy(e.target.value)}
                      />
                    </FormControl>
                    <FormControl>
                      <TextField
                        value={comments}
                        placeholder="Comments..."
                        label="Comments"
                        multiline
                        rows={1}
                        onChange={(e) => setComments(e.target.value)}
                        style={{ width: "400px" }}
                      />
                    </FormControl>
                  </Stack>

                  <Stack
                    padding="10px 0 0px 0"
                    justifyContent="flex-end"
                    direction="row"
                    spacing={2}
                  >
                    <FormControl>
                      <Button
                        className="btn btn-secondary"
                        variant="outlined"
                        onClick={onClickCancel}
                      >
                        Cancel
                      </Button>
                    </FormControl>
                    <FormControl>
                      <Button
                        className="btn btn-primary"
                        type="submit"
                        variant="contained"
                        onClick={() => beginBook()}
                      >
                        Pay
                      </Button>
                    </FormControl>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default Payment;
