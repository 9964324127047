import React, { useState, useEffect } from "react";
import "../Style.css";
import "../bootstrap.min.css";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import validator from "validator";
import {
  Input,
  TextField,
  Button,
  Container,
  Box,
  Grid,
  Stack,
  Typography,
  FormControl,
  FormControlLabel,
  InputLabel,
  styled,
  Switch,
  Radio,
  RadioGroup,
  IconButton,
  Divider,
  Select,
  MenuItem,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Avatar,
  TableBody,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { ConfirmDialog as PickVehicleConfirmation } from "./common/ConfirmDialog";
import DialogPopup from "./common/DialogPopup";
import { getGeocodeAddress, postAsync } from "../core/serviceClient";
import { connectToStore } from "../data/store";
import {
  getBusiness,
  getBranch,
  getEnterpriseId,
  getCountry,
  getAuthentication,
} from "../data/localSettingsActions";
import Utils from "../core/Utils";
import AutomobileHelper from "../core/AutomobileHelper";
import {
  ConfirmTypes,
  MaxFileSizeLimit,
  SideBarMenuNames,
} from "../core/Constants";
import MessagePopup from "./common/MessagePopUp";
import ConfirmPopup from "./common/ConfirmPopup";
import SideBarMenuItems from "./SideBarMenuItems";
import UserHelper from "../core/UserHelper";
import { getMakeModels } from "../data/localSettingsActions";

// Images
import JobCardIcon from "@mui/icons-material/FactCheck";
import EditIcon from "@mui/icons-material/Edit";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import CloseIcon from "@mui/icons-material/Close";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import DeleteIcon from "@mui/icons-material/Delete";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import Profit_Icon from "../images/profit.png";
import Revenue_Icon from "../images/revenue.png";
import Visits_Icon from "../images/visits.png";
import Points_Icon from "../images/points.png";

import Loader from "./common/Loader";
import NumericTextField from "./common/controls/NumericTextField";
import WaterDropIcon from "@mui/icons-material/WaterDrop";
import LocalGasStationIcon from "@mui/icons-material/LocalGasStation";
import PropaneIcon from "@mui/icons-material/Propane";
import EvStationIcon from "@mui/icons-material/EvStation";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";

// Menu
import moment from "moment";
import 'moment/locale/en-in';
import CountryHelper from "../core/CountryHelper";
import { Regexes } from "../core/Regexes";
import BusinessHelper from "../core/BusinessHelper";
import { CanRaiseJobcard } from "../core/ModuleHelper";
import { ImageEnlarge } from "./common/ImageEnlarge";
import SearchIcon from "@mui/icons-material/Search";
import {
  LoginPage,
  CheckOutPage,
  CustomersPage,
  JobCardDetailsPage,
  VehiclesPage,
  VisitsPage,
} from "../core/PageConstants";
import AddressField from "./common/controls/AddressField";

const customerActionTypes = {
  RaiseJobcard: "RaiseJobcard",
  DeleteCustomer: "DeleteCustomer",
  DeleteVehicle: "DeleteVehicle",
  SaveCustomer: "SaveCustomer",
  SaveVehicle: "SaveVehicle",
  RaiseJobcardOnCustomerSave: "RaiseJobcardOnCustomerSave",
  DeleteInsuranceImage: "DeleteInsuranceImage",
  DeleteRCImage: "DeleteRCImage",
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

const Customers = (props) => {
  document.title = "Customers";
  moment.locale("en-in");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState(
    !Utils.IsNullOrEmptyObject(location.state) &&
      !Utils.IsNullOrEmpty(location.state.searchText)
      ? location.state.searchText
      : ""
  );
  const [localSettings, setLocalSettings] = useState({});

  const [showDialog, setShowDialog] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [isAutomobileBusiness, setIsAutomobileBusiness] = useState(false);

  const [customerId, setCustomerId] = useState();
  const [businessId, setBusinessId] = useState();
  const [isCustomerEdit, setIsCustomerEdit] = useState(false);
  const [customerKey, setCustomerKey] = useState();
  const [customerFirstName, setCustomerFirstName] = useState();
  const [customerLastName, setCustomerLastName] = useState();
  const [customerMobileNumber, setCustomerMobileNumber] = useState();
  const [customerEmailId, setCustomerEmailId] = useState("");

  const [customerAddress, setCustomerAddress] = useState();
  const [customerCustomAddress, setCustomerCustomAddress] = useState("");
  const [customerCity, setCustomerCity] = useState("");
  const [customerState, setCustomerState] = useState("");
  const [customerPincode, setCustomerPincode] = useState("");

  const [customerBusinessName, setCustomerBusinessName] = useState("");
  const [customerBusinessGSTIN, setCustomerBusinessGSTIN] = useState("");
  const [customerGSTINCode, setCustomerGSTINCode] = useState("");
  const [customerVehicleList, setCustomerVehicleList] = useState([]);

  const [customerDOB, setCustomerDOB] = useState(moment("1001-01-01T00:00:00"));
  const [customerGender, setCustomerGender] = useState();
  const [useCustomAddress, setUseCustomAddress] = useState(true);
  const [msgOpen, setMsgOpen] = useState(false);
  const [msgText, setMsgText] = useState("");
  const [isNavigateToLogin, setIsNavigateToLogin] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmText, setConfirmText] = useState("");

  const [customerList, setCustomerList] = useState([]);
  const [pageTitle, setPageTitle] = useState("");
  const [modalTitle, setModalTitle] = useState("Add Vehicle");
  const [countryInfo, setCountryInfo] = useState({});

  const [makeModelList, setMakeModelList] = useState([]);
  const [makeList, setMakeList] = useState([]);
  const [modelList, setModelList] = useState([]);
  const [vehicleNumber, setVehicleNumber] = useState("");
  const [vehicleYear, setVehicleYear] = useState("");
  const [vehicleColor, setVehicleColor] = useState("");

  const [vehicleInsPolicyNumber, setVehicleInsPolicyNumber] = useState("");
  const [vehicleInsPlaceOfIssue, setVehicleInsPlaceOfIssue] = useState("");
  const [vehicleInsExpiryDate, setVehicleInsExpiryDate] = useState(moment());
  const [vehicleInsCompany, setVehicleInsCompany] = useState("");

  const [vehicleMake, setVehicleMake] = useState("");
  const [vehicleModel, setVehicleModel] = useState("");
  const [vehicleFuelType, setVehicleFuelType] = useState("Petrol");
  const [vehicleFuelTypes, setVehicleFuelTypes] = useState([]);
  const [rcImage, setRCImage] = useState("");
  const [insuranceImage, setInsuranceImage] = useState("");
  const [vehicleSubmitCat, setVehicleSubmitCat] = useState("");
  const [addItems, setAddItems] = useState(false);
  const [addVehicle, setAddVehicle] = useState(false);

  const [isOpenedCustomAddress, setIsOpenedCustomAddress] = useState(false);
  const [vehicleType, setVehicleType] = useState("");
  const [openCustomMakeModel, setOpenCustomMakeModel] = useState(false);
  const [openDOB, setOpenDOB] = useState(true);
  const [isVehicleEdit, setIsVehicleEdit] = useState(false);
  const [selectedPass, setSelectedPass] = useState({});
  const [saveVehiclesDisabled, setSaveVehiclesDisabled] = useState(false);
  const [saveCustomerDisabled, setSaveCustomerDisabled] = useState(false);
  const [regIdLength, setRegIdLength] = useState(0);
  const [gst, setGST] = useState(false);
  const [showStateCode, setShowStateCode] = useState(false);
  const [isIndianBusiness, setIsIndianBusiness] = useState(false);
  const [isUAEBusiness, setIsUAEBusiness] = useState(false);
  const [isUSBusiness, setIsUSBusiness] = useState(false);
  const [registrationValidated, setRegistrationValidated] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [selectedVehicle, setSelectedVehicle] = useState({});
  const [insuranceGSTIN, setInsuranceGSTIN] = useState("");
  const [insuranceBranchAddress, setInsuranceBranchAddress] = useState("");
  const [customerAction, setCustomerAction] = useState("");
  const [enlargeImage, setEnlargeImage] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [alertTitle, setAlertTitle] = useState("");
  const [addressISOCode, setAddressISOCode] = useState("");
  const [selectedDate, setSelectedDate] = useState([
    Utils.GetStartDate(),
    Utils.GetEndDate(),
  ]);

  useEffect(() => {
    let localSetting = connectToStore();
    if (UserHelper.CheckPermission(CustomersPage, navigate, localSetting)) {
      setLocalSettings(localSetting);
      if (!Utils.IsNullOrEmptyObject(localSetting.business)) {
        setIsAutomobileBusiness(
          BusinessHelper.IsAutomobile(localSetting.business)
        );
      }
      var country = getCountry();
      setAddressISOCode(country.ISOCode);
      setCountry(country);
      var makeModels = getMakeModels();
      var makes = makeModels.map((c) => c.make);
      setMakeList(makes);
      setMakeModelList(makeModels);
      if (!Utils.IsNullOrEmpty(searchText)) customerSearchSubmit();
    }
  }, []);

  useEffect(() => {
    registrationChanged();
  }, [customerBusinessGSTIN]);

  const registrationChanged = () => {
    setRegistrationValidated(false);
    checkCustomerDetails(false);
  };

  useEffect(() => {
    checkCustomerDetails();
  }, [
    customerFirstName,
    customerLastName,
    customerMobileNumber,
    customerAddress,
    useCustomAddress,
    isOpenedCustomAddress,
    customerCustomAddress,
    customerCity,
    customerPincode,
    customerState,
  ]);

  const checkCustomerDetails = async (regValid = true) => {
    var validRegistrationId = Utils.IsNullOrEmpty(customerBusinessGSTIN);
    if (!validRegistrationId && isIndianBusiness) {
      validRegistrationId =
        customerBusinessGSTIN.length === regIdLength &&
        Regexes.GST.test(customerBusinessGSTIN);
    } else if (!validRegistrationId) {
      validRegistrationId = customerBusinessGSTIN.length === regIdLength;
    }
    var isAddressValid =
      (isOpenedCustomAddress && !Utils.IsNullOrEmpty(customerCity)) ||
      (!isOpenedCustomAddress && !Utils.IsNullOrEmpty(customerAddress));
    var mobileNumberValid = Utils.IsMobileValid(customerMobileNumber);
    var isDetailsFilled =
      !Utils.IsNullOrEmpty(customerFirstName) &&
      (!isUSBusiness || !Utils.IsNullOrEmpty(customerLastName)) &&
      isAddressValid &&
      validRegistrationId &&
      mobileNumberValid;
    if (
      !regValid &&
      !Utils.IsNullOrEmpty(customerBusinessGSTIN) &&
      validRegistrationId
    ) {
      var result = await postAsync("Business/ValidateRegistration", {
        GSTIN: gst,
        RegistrationId: Utils.ConvertToUpperCase(customerBusinessGSTIN.trim()),
      });
      if (!Utils.IsNullOrEmptyObject(result)) {
        isDetailsFilled = isDetailsFilled && result.data.valid;
        if (!Utils.IsNullOrEmpty(result.data.companyName)) {
          setCustomerBusinessName(result.data.companyName);
        }
      } else {
        isDetailsFilled = false;
      }
    }
    setSaveCustomerDisabled(!isDetailsFilled);
  };

  const setCountry = (country) => {
    setCountryInfo(country);
    var isIndia = CountryHelper.IsBusinessCountryIndia(country);
    var isUS = CountryHelper.IsBusinessCountryUSA(country);
    var isUAE = CountryHelper.IsBusinessCountryUAE(country);
    if (isIndia) {
      setShowStateCode(true);
      setGST(true);
      setIsIndianBusiness(true);
      setRegIdLength(15);
      return;
    }
    if (isUS) {
      setRegIdLength(9);
      setIsUSBusiness(true);
      return;
    }
    if (isUAE) {
      setRegIdLength(15);
      setIsUAEBusiness(true);
      return;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    customerSearchSubmit();
  };

  const deleteCustomer = (pass) => {
    setCustomerAction(customerActionTypes.DeleteCustomer);
    setSelectedPass(pass);
    setConfirmText(
      "Are you sure you want to delete the customer " + pass.userName + "?"
    );
    setConfirmOpen(true);
  };

  const customerFormDelete = async () => {
    if (!Utils.IsNullOrEmptyObject(selectedPass)) {
      let response = await postAsync(
        "Customer/Delete",
        {
          BusinessId: selectedPass.businessId,
          CustomerId: selectedPass.customerId,
          Delete: true,
        },
        "",
        "Delete"
      );
      setIsNavigateToLogin(response.isNavigateToLogin);
      if (response.error) {
        setMsgText(response.errorMessage);
        setMsgOpen(true);
      } else {
        setMsgText("Customer deleted successfully.");
        setMsgOpen(true);
      }
    }
  };

  async function customerSearchSubmit() {
    if (Utils.IsNullOrEmpty(searchText) == true) return;
    setIsLoading(true);
    const business = getBusiness();
    let response = await postAsync("Customer/Search", {
      BusinessId: business.id,
      SearchText: searchText,
      IsAutomobileBusiness: business.category == "Automobile",
    });
    setIsLoading(false);
    setIsNavigateToLogin(response.isNavigateToLogin);
    if (response.error) {
      setMsgOpen(true);
      setMsgText(response.errorMessage);
      return;
    }
    if (!Utils.IsNullOrEmptyArray(response.data.passList)) {
      setCustomerList(
        response.data.passList.sort((a, b) =>
          a.userName > b.userName ? 1 : -1
        )
      );
    } else {
      setCustomerList([]);
    }
  }

  const handleMsgClose = () => {
    setAlertTitle("");
    setMsgOpen(false);
    if (isNavigateToLogin) {
      UserHelper.LogOut(dispatch);
      navigate(LoginPage.Path);
    }
  };

  const handleConfirmClose = async (value) => {
    setConfirmOpen(false);
    switch (value) {
      case ConfirmTypes.OK:
        switch (customerAction) {
          case customerActionTypes.DeleteVehicle:
            if (!Utils.IsNullOrEmptyObject(selectedVehicle)) {
              var vList = [...customerVehicleList];
              var filteredList = vList.filter(
                (c) => c.id !== selectedVehicle.id
              );
              setCustomerVehicleList(filteredList);
            }
            break;
          case customerActionTypes.DeleteCustomer:
            await customerFormDelete();
            await customerSearchSubmit();
            break;
          case customerActionTypes.SaveCustomer:
            await proceedSaveCustomer();
            break;
          case customerActionTypes.SaveVehicle:
            proceedSaveVehicle();
            break;
          case customerActionTypes.RaiseJobcardOnCustomerSave:
          case customerActionTypes.RaiseJobcard:
            raiseJobcard();
            break;
          case customerActionTypes.DeleteInsuranceImage:
            await processPhotoDeletion(false);
            break;
          case customerActionTypes.DeleteRCImage:
            await processPhotoDeletion(true);
            break;
        }
        break;
      default:
        if (customerAction === customerActionTypes.RaiseJobcardOnCustomerSave) {
          closeCustomerSetup();
          await customerSearchSubmit();
          setCustomerAction("");
        }
        break;
    }
  };

  const vehicleSetupSubmit = () => {
    var year = Utils.ConvertToInteger(vehicleYear);
    var currentYear = moment().year();
    if (
      !Utils.IsNullOrEmpty(vehicleYear) &&
      (vehicleYear.toString().length !== 4) || (year > currentYear)
    ) {
      var msg =
        year > currentYear
          ? "Please enter the current or a past year."
          : "Please enter a valid year.";
      setMsgText(msg);
      setMsgOpen(true);
      return;
    }
    setConfirmText("Save vehicle?");
    setConfirmOpen(true);
    setCustomerAction(customerActionTypes.SaveVehicle);
  };

  const proceedSaveVehicle = () => {
    var vehicleList = [...customerVehicleList];
    var year = Utils.ConvertToInteger(vehicleYear);
    var vId = 0;
    if (Utils.IsNullOrEmptyArray(vehicleList)) {
      vId = 1;
    } else {
      var vIds = vehicleList.map((i) => i.id);
      var reversed = vIds.reverse();
      vId = reversed[reversed.length - 1] + 1;
    }
    var vehicleDetailsObj = {
      id: Utils.IsNullOrEmptyObject(selectedVehicle) ? vId : selectedVehicle.id,
      vehicleType: Utils.IsNullOrEmptyObject(selectedVehicle)
        ? localSettings.branch.vehicleType
        : selectedVehicle.vehicleType,
      number: Utils.ConvertToUpperCase(vehicleNumber.trim()),
      make: vehicleMake,
      model: vehicleModel,
      year: year,
      color: vehicleColor,
      fuelType: vehicleFuelType,
      registrationCertificateData: rcImage,
      vehicleInsuranceDetails: {
        policyNumber: vehicleInsPolicyNumber,
        placeOfIssue: vehicleInsPlaceOfIssue,
        expiryDate: Utils.GetISODateTimeString(vehicleInsExpiryDate),
        company: vehicleInsCompany,
        insuranceData: insuranceImage,
        gstin: insuranceGSTIN,
        branchAddress: insuranceBranchAddress,
      },
    };

    if (!Utils.IsNullOrEmptyObject(selectedVehicle)) {
      var indexToRemove = vehicleList.findIndex(
        (i) => i.id === selectedVehicle.id
      );
      vehicleList.splice(indexToRemove, 1);
    }
    vehicleList.push(vehicleDetailsObj);
    setCustomerVehicleList(vehicleList);
    closeVehicleSetup();
  };

  const closeVehicleSetup = () => {
    setVehicleNumber("");
    setVehicleYear("");
    setVehicleColor("");
    setVehicleInsPolicyNumber("");
    setVehicleInsPlaceOfIssue("");
    setVehicleInsExpiryDate(moment());
    setVehicleInsCompany("");
    setVehicleMake("");
    setVehicleModel("");
    setOpenCustomMakeModel("");
    setVehicleType("");
    setVehicleFuelType("Petrol");
    setRCImage("");
    setInsuranceImage("");
    setInsuranceBranchAddress("");
    setInsuranceGSTIN("");
    setSelectedVehicle({});
    setIsVehicleEdit(false);
    setAddVehicle(false);
  };

  const handleVisitClick = (customer) => {
    navigate(VisitsPage.Path, {
      state: {
        navigationStack: [CustomersPage.Path],
        onSuccess: CustomersPage.Path,
        selectedCustomer: customer,
        searchText: searchText,
        isfromCustomer: true,
        startDT: Utils.GetISODateTimeString(selectedDate[0]),
        endDT: Utils.GetISODateTimeString(selectedDate[1])
      },
    });
  };

  const customerSaveSubmit = () => {
    if (customerDOB > moment()) {
      setMsgText("Please enter a valid date of birth");
      setMsgOpen(true);
      return;
    }
    setCustomerAction(customerActionTypes.SaveCustomer);
    setConfirmText("Save customer?");
    setConfirmOpen(true);
  };

  const proceedSaveCustomer = async () => {
    setIsLoading(true);
    var business = localSettings.business;
    var tier = Utils.IsNullOrEmpty(customerId)
      ? business.tiers.find((c) => c.default)
      : business.tiers.find((c) => c.level === selectedPass.tierLevel);
    if (Utils.IsNullOrEmptyObject(tier)) {
      tier = business.tiers.filter((c) => c.default)[0];
    }
    var addressSegment1 = !Utils.IsNullOrEmpty(customerCustomAddress)
      ? customerCustomAddress + ","
      : "";
    var addressSegment2 = !Utils.IsNullOrEmpty(customerCity)
      ? customerCity + ","
      : "";
    var addressSegment3 = !Utils.IsNullOrEmpty(customerState)
      ? customerState + ","
      : "";
    var homeLocation = (
      addressSegment1 +
      addressSegment2 +
      addressSegment3 +
      getBranch().country
    ).trim();
    var addressDetails = null;
    if (!Utils.IsNullOrEmpty(customerAddress, homeLocation)) {
      var geocodeResponse = await getGeocodeAddress(
        customerAddress,
        homeLocation
      );
      if (
        !Utils.IsNullOrEmptyObject(geocodeResponse) &&
        geocodeResponse.status === "OK"
      ) {
        var locality = "";
        var county = "";
        var state = "";
        var country = "";
        var zipcode = "";
        var streetNumber = "";
        var route = "";
        var address = "";
        var lng = 0;
        var lat = 0;
        address = geocodeResponse.results[0].formatted_address;
        if (
          geocodeResponse.results[0].address_components
            .map((c) => c.types)
            .reduce((a, b) => a.concat(b))
            .includes("street_number") === true
        ) {
          var ac = geocodeResponse.results[0].address_components.find((c) =>
            c.types.includes("street_number")
          );
          if (!Utils.IsNullOrEmptyObject(ac)) {
            streetNumber = ac.long_name;
          }
        }

        if (
          geocodeResponse.results[0].address_components
            .map((c) => c.types)
            .reduce((a, b) => a.concat(b))
            .includes("route") === true
        ) {
          var ac = geocodeResponse.results[0].address_components.find((c) =>
            c.types.includes("route")
          );
          if (!Utils.IsNullOrEmptyObject(ac)) {
            route = ac.long_name;
          }
        }

        if (
          geocodeResponse.results[0].address_components
            .map((c) => c.types)
            .reduce((a, b) => a.concat(b))
            .includes("locality") === true
        ) {
          var ac = geocodeResponse.results[0].address_components.find((c) =>
            c.types.includes("locality")
          );
          if (!Utils.IsNullOrEmptyObject(ac)) {
            locality = ac.long_name;
          }
        }

        if (
          geocodeResponse.results[0].address_components
            .map((c) => c.types)
            .reduce((a, b) => a.concat(b))
            .includes("administrative_area_level_1") === true
        ) {
          var ac = geocodeResponse.results[0].address_components.find((c) =>
            c.types.includes("administrative_area_level_1")
          );
          if (!Utils.IsNullOrEmptyObject(ac)) {
            state = ac.long_name;
          }
        }

        if (
          geocodeResponse.results[0].address_components
            .map((c) => c.types)
            .reduce((a, b) => a.concat(b))
            .includes("administrative_area_level_2") === true
        ) {
          var ac = geocodeResponse.results[0].address_components.find((c) =>
            c.types.includes("administrative_area_level_2")
          );
          if (!Utils.IsNullOrEmptyObject(ac)) {
            county = ac.long_name;
          }
        }

        if (
          geocodeResponse.results[0].address_components
            .map((c) => c.types)
            .reduce((a, b) => a.concat(b))
            .includes("country") === true
        ) {
          var ac = geocodeResponse.results[0].address_components.find((c) =>
            c.types.includes("country")
          );
          if (!Utils.IsNullOrEmptyObject(ac)) {
            country = ac.short_name;
          }
        }

        if (
          geocodeResponse.results[0].address_components
            .map((c) => c.types)
            .reduce((a, b) => a.concat(b))
            .includes("postal_code") === true
        ) {
          var ac = geocodeResponse.results[0].address_components.find((c) =>
            c.types.includes("postal_code")
          );
          if (!Utils.IsNullOrEmptyObject(ac)) {
            zipcode = ac.long_name;
          }
        }
        lat = geocodeResponse.results[0].geometry.location.lat;
        lng = geocodeResponse.results[0].geometry.location.lng;
        addressDetails = {
          HomeLocation: customerAddress,
          Address: streetNumber + " " + route,
          City: locality,
          State: state,
          Country: country,
          County: county,
          ZipCode: zipcode,
          Locality: locality,
          Latitude: Utils.ConvertToFloat(lat),
          Longitude: Utils.ConvertToFloat(lng),
        };
      } else {
        setIsLoading(false);
        setMsgOpen(true);
        setMsgText("Invalid address");
        return;
      }
    }
    var currentAddress = "";
    var currentHomeLocation = "";
    var currentLocality = "";
    var currentCountry = "";
    var currentState = "";
    var currentCounty = "";
    var currentZipCode = "";
    var currentLng = 0;
    var currentLat = 0;

    if (isOpenedCustomAddress) {
      currentHomeLocation = homeLocation;
      currentAddress = customerCustomAddress;
      currentCountry = getBranch().country;
      currentLocality = customerCity;
      currentState = customerState;
      currentZipCode = customerPincode;
    } else {
      currentAddress = addressDetails.Address;
      currentHomeLocation = addressDetails.HomeLocation;
      currentState = addressDetails.State;
      currentZipCode = addressDetails.ZipCode;
      currentCountry = addressDetails.Country;
      currentCounty = addressDetails.County;
      currentLocality = addressDetails.Locality;
      currentLat = addressDetails.Latitude;
      currentLng = addressDetails.Longitude;
    }

    var newCustomer = {};
    if (Utils.IsNullOrEmptyObject(selectedCustomer)) {
      newCustomer = {
        Id: customerId,
        CustomerKey: customerKey,
        FirstName: customerFirstName,
        LastName: customerLastName,
        MobileNumber: customerMobileNumber,
        EmailId: customerEmailId,
        UsedCustomAddress: isOpenedCustomAddress,
        Address: currentAddress,
        CountryCode: countryInfo.Code,
        City: currentLocality,
        State: currentState,
        ZipCode: currentZipCode,
        Locality: currentLocality,
        Country: currentCountry,
        County: currentCounty,
        HomeLocation: currentHomeLocation,
        CompanyName: customerBusinessName,
        RegistrationId: customerBusinessGSTIN,
        StateCode: customerGSTINCode,
        Latitude: Utils.ConvertToFloat(currentLat),
        Longitude: Utils.ConvertToFloat(currentLng),
        EnterpriseId: getEnterpriseId(),
        Dob: Utils.GetISODateTimeString(customerDOB),
        Gender: customerGender,
      };
    } else {
      selectedCustomer.customerKey = customerKey;
      selectedCustomer.firstName = customerFirstName;
      selectedCustomer.lastName = customerLastName;
      selectedCustomer.mobileNumber = customerMobileNumber;
      selectedCustomer.emailId = customerEmailId;
      selectedCustomer.address = currentAddress;
      selectedCustomer.homeLocation = currentHomeLocation;
      selectedCustomer.usedCustomAddress = isOpenedCustomAddress;
      selectedCustomer.city = currentLocality;
      selectedCustomer.locality = currentLocality;
      selectedCustomer.state = currentState;
      selectedCustomer.zipCode = currentZipCode;
      selectedCustomer.country = currentCountry;
      selectedCustomer.county = currentCounty;
      selectedCustomer.latitude = currentLat;
      selectedCustomer.longitude = currentLng;
      selectedCustomer.companyName = customerBusinessName;
      selectedCustomer.registrationId = customerBusinessGSTIN;
      selectedCustomer.stateCode = customerGSTINCode;
      selectedCustomer.dob = Utils.GetISODateTimeString(customerDOB);
      selectedCustomer.gender = customerGender;
      newCustomer = selectedCustomer;
    }
    var request = {
      BusinessId: business.id,
      Customer: newCustomer,
      VehicleDetails: customerVehicleList,
      PointsEarnRatio: tier.pointsEarnRatio,
      PointsRedemptionRatio: tier.pointsRedemptionRatio,
      SearchText: searchText,
      TierLevel: tier.level,
      TierName: tier.name,
      TierCardColor: tier.cardColor,
    };
    let result = await postAsync("Customer/Save", request);
    setIsNavigateToLogin(result.isNavigateToLogin);
    if (result.error) {
      setIsLoading(false);
      setMsgOpen(true);
      setMsgText(result.errorMessage);
      return;
    }
    setIsLoading(false);
    if (result.data.ifCustomerExists == false) {
      if (isAutomobileBusiness) {
        setSelectedPass(
          result.data.passes.find(
            (c) => c.customerId === result.data.customerId
          )
        );
        setCustomerAction(customerActionTypes.RaiseJobcardOnCustomerSave);
        setConfirmText(
          "Customer saved successfully. Do you wish to raise jobcard?"
        );
        setConfirmOpen(true);
      } else {
        setMsgText("Customer details saved successfully.");
        setMsgOpen(true);
        closeCustomerSetup();
        await customerSearchSubmit();
      }
    } else if (result.data.ifCustomerExists == true) {
      setMsgText("Customer exists already.");
      setMsgOpen(true);
      return;
    } else {
      setMsgText("Sorry! Please try again.");
      setMsgOpen(true);
    }
  };

  const closeCustomerSetup = () => {
    setCustomerId("");
    setBusinessId("");
    setCustomerKey("");
    setCustomerFirstName("");
    setCustomerLastName("");
    setCustomerMobileNumber("");
    setCustomerEmailId("");
    setCustomerAddress("");
    //for now set to true
    setUseCustomAddress(true);
    setCustomerCustomAddress("");
    setCustomerCity("");
    setCustomerState("");
    setCustomerPincode("");
    setCustomerDOB(moment("1001-01-01T00:00:00"));
    setCustomerGender("");
    setCustomerGSTINCode("");
    setCustomerBusinessGSTIN("");
    setCustomerBusinessName("");
    setCustomerVehicleList([]);
    setSelectedPass({});
    setSelectedCustomer({});
    setIsCustomerEdit(false);
    setAddItems(false);
  };

  const openCustomerSetup = async (pass = {}) => {
    setPageTitle("New Customer");
    if (!Utils.IsNullOrEmptyObject(pass)) {
      setIsCustomerEdit(true);
      setSelectedPass(pass);
      setIsLoading(true);
      let result = await postAsync(
        "Customer/GetCustomer",
        {
          BusinessId: pass.businessId,
          Id: pass.customerId,
        },
        "",
        "Get"
      );
      setIsLoading(false);
      setIsNavigateToLogin(result.isNavigateToLogin);
      if (result.error) {
        setMsgOpen(true);
        setMsgText(result.errorMessage);
        return;
      }
      var customer = result.data;
      var isUsedCustomAddress = Utils.IsNullOrEmpty(customer.usedCustomAddress);
      setSelectedCustomer(customer);
      setUseCustomAddress(isUsedCustomAddress);
      setIsOpenedCustomAddress(isUsedCustomAddress);
      setCustomerId(customer.id);
      setBusinessId(customer.businessId);
      setCustomerKey(customer.customerKey);
      setCustomerFirstName(customer.firstName);
      setCustomerLastName(customer.lastName);
      setCustomerMobileNumber(customer.mobileNumber);
      setCustomerEmailId(customer.emailId);
      if (!isUsedCustomAddress) {
        setCustomerAddress(customer.homeLocation);
      } else {
        setCustomerCustomAddress(customer.address);
        setCustomerCity(customer.city);
        setCustomerState(customer.state);
        setCustomerPincode(customer.zipCode);
      }
      setCustomerBusinessName(customer.companyName);
      setCustomerDOB(moment(customer.dob));
      setCustomerGender(customer.gender);
      setCustomerVehicleList(pass.vehicleDetails);
      setCustomerGSTINCode(customer.stateCode);
      setCustomerBusinessGSTIN(customer.registrationId);
      setCustomerBusinessName(customer.companyName);
      setPageTitle("Update Customer " + pass.userName);
    }
    setAddItems(true);
  };

  function toggleCustomAddress() {
    setIsOpenedCustomAddress((wasOpened) => !wasOpened);
  }

  const makeHandleChange = (event) => {
    setVehicleMake(event.target.value);
  };

  const modelHandleChange = (event) => {
    setVehicleModel(event.target.value);
  };

  const fuelHandleChange = (event) => {
    setVehicleFuelType(event.target.value);
  };

  const vehicleMakeHandleChange = (e) => {
    var make = e.target.value;
    var models = makeModelList.find((c) => c.make === make).model;
    setVehicleMake(make);
    setModelList(models);
  };

  const vehicleMakeHandleChangeEdit = (e) => {
    var modelObj = makeModelList.find((c) => c.make === e);
    if (Utils.IsNullOrEmptyObject(modelObj)) {
      setOpenCustomMakeModel(true);
      return;
    }
    setModelList(modelObj.model);
  };

  function toggleCustomMakeModel(event) {
    if ((event === true)) {
      setOpenCustomMakeModel(true);
      setVehicleMake("");
      setVehicleModel("");
    } else {
      setOpenCustomMakeModel(false);
      setVehicleMake("");
      setVehicleModel("");
      setModelList([]);
    }
  }

  function toggleCustomDOB() {
    setOpenDOB((wasOpened) => !wasOpened);
  }

  const deleteVehicleDetail = (index) => {
    setCustomerAction(customerActionTypes.DeleteVehicle);
    setSelectedVehicle(customerVehicleList[index]);
    setConfirmText("Do you wish to delete?");
    setConfirmOpen(true);
  };

  const processPhotoDeletion = async (isRegistraion) => {
    var imageUrl = isRegistraion ? rcImage : insuranceImage;
    if (Utils.IsNullOrEmpty(imageUrl)) {
      return;
    }
    setIsLoading(true);
    let result = await postAsync("Customer/RemoveVehicleDocuments", {
      ImageUrl: imageUrl,
    });
    setIsLoading(false);
    setIsNavigateToLogin(result.isNavigateToLogin);
    if (result.error) {
      setMsgOpen(true);
      setMsgText(result.errorMessage);
      return;
    }
    if (result.data.isDeleted) {
      if (isRegistraion) {
        setRCImage("");
      } else {
        setInsuranceImage("");
      }
      setCustomerAction("");
    }
  };

  const deletePhoto = (isRegistraion) => {
    setCustomerAction(
      isRegistraion
        ? customerActionTypes.DeleteRCImage
        : customerActionTypes.DeleteInsuranceImage
    );
    setConfirmText("Deleted images cannot be restored. Are you sure?");
    setConfirmOpen(true);
  };

  const UploadReturnImage = async (event, isRegistraion) => {
    var file = event.target.files[0];
    if (file.size > MaxFileSizeLimit) {
      setAlertTitle("Warning");
      setMsgText(
        "Unable to upload. File size exceeds the maximum limit, please ensure that it's less than 10 MB."
      );
      setMsgOpen(true);
      return;
    }
    var base64 = await convertBase64(file);
    setIsLoading(true);
    let response = await postAsync("Customer/UploadVehicleDocuments", {
      DocumentImageData: Utils.GetStringFromBase64(base64),
      IsRegistraion: isRegistraion,
      VehicleNumber: vehicleNumber,
    });
    setIsLoading(false);
    setIsNavigateToLogin(response.isNavigateToLogin);
    if (response.error) {
      setMsgOpen(true);
      setMsgText(response.errorMessage);
      return;
    } else {
      if (isRegistraion == true) {
        setRCImage(response.data.documentImageUrl);
      } else {
        setInsuranceImage(response.data.documentImageUrl);
      }
    }
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const openVehicleSetup = (vehicle = {}) => {
    if (saveCustomerDisabled) {
      setMsgText("Please fill in mandatory details before proceeding");
      setMsgOpen(true);
      return;
    }
    var fuelTypes = AutomobileHelper.GetFuelTypes();
    setVehicleFuelTypes(fuelTypes);
    setModalTitle("New Vehicle");
    if (!Utils.IsNullOrEmptyObject(vehicle)) {
      setSelectedVehicle(vehicle);
      setIsVehicleEdit(true);
      setModalTitle("Update Vehicle " + vehicle.number);
      setVehicleNumber(vehicle.number);
      setVehicleMake(vehicle.make);
      setVehicleModel(vehicle.model);
      setVehicleColor(vehicle.color);
      setVehicleType(vehicle.vehicleType);
      setVehicleFuelType(vehicle.fuelType);
      setVehicleYear(vehicle.year);
      vehicleMakeHandleChangeEdit(vehicle.make);
      setInsuranceImage(vehicle.vehicleInsuranceDetails.insuranceData);
      setRCImage(vehicle.registrationCertificateData);
      setVehicleInsCompany(vehicle.vehicleInsuranceDetails.company);
      setInsuranceGSTIN(vehicle.vehicleInsuranceDetails.gstin);
      setVehicleInsPolicyNumber(vehicle.vehicleInsuranceDetails.policyNumber);
      setVehicleInsPlaceOfIssue(vehicle.vehicleInsuranceDetails.placeOfIssue);
      setVehicleInsExpiryDate(
        moment(vehicle.vehicleInsuranceDetails.expiryDate)
      );
      setInsuranceBranchAddress(vehicle.vehicleInsuranceDetails.branchAddress);
    } else {
      setModelList([])
    }
    setAddVehicle(true);
  };

  useEffect(() => {
    checkVehicleDetails();
  }, [
    vehicleNumber,
    vehicleMake,
    vehicleModel,
    vehicleFuelType,
    insuranceGSTIN,
  ]);

  const checkVehicleDetails = () => {
    var isDetailsFilled =
      !Utils.IsNullOrEmpty(vehicleNumber) &&
      !Utils.IsNullOrEmpty(vehicleMake) &&
      !Utils.IsNullOrEmpty(vehicleModel) &&
      !Utils.IsNullOrEmpty(vehicleFuelType) &&
      (Utils.IsNullOrEmpty(insuranceGSTIN) || Regexes.GST.test(insuranceGSTIN));
    setSaveVehiclesDisabled(!isDetailsFilled);
  };

  const navigateToCheckout = (customerPass) => {
    setSelectedPass(customerPass);
    var validVehicles = [];
    if (isAutomobileBusiness) {
      if (!Utils.IsNullOrEmptyArray(customerPass.vehicleDetails)) {
        validVehicles = customerPass.vehicleDetails.filter(
          (v) => v.vehicleType === getBranch().vehicleType
        );
      }
    }
    if (isAutomobileBusiness && !Utils.IsNullOrEmptyArray(validVehicles)) {
      setShowConfirm(true);
      return;
    }
    navigate(CheckOutPage.Path, {
      state: {
        navigationStack: [CustomersPage.Path],
        to: CheckOutPage.Path,
        onSuccess: CustomersPage.Path,
        checkout: true,
        pass: customerPass,
        fromJobcard: location.state && location.state.fromJobcard,
        searchText: searchText,
      },
    });
  };

  const navigateToJobcard = (customerPass) => {
    raiseJobcard(customerPass);
  };

  const raiseJobcard = (pass = {}) => {
    if (!CanRaiseJobcard(localSettings.business)) {
      setMsgText("Please contact our team to enable the jobcard feature");
      setMsgOpen(true);
      return;
    }
    var currentPass = Utils.IsNullOrEmptyObject(pass) ? selectedPass : pass;
    if (Utils.IsNullOrEmptyObject(currentPass)) {
      setMsgText("Please select a valid customer");
      setMsgOpen(true);
      return;
    }
    var validVehicles = [];
    if (!Utils.IsNullOrEmptyArray(currentPass.vehicleDetails)) {
      validVehicles = currentPass.vehicleDetails.filter(
        (v) => v.vehicleType === getBranch().vehicleType
      );
    }
    if (Utils.IsNullOrEmptyArray(validVehicles)) {
      setMsgText("Please add vehicle before proceeding");
      setMsgOpen(true);
      return;
    }
    if (validVehicles.length === 1) {
      navigate(JobCardDetailsPage.Path, {
        state: {
          checkout: false,
          navigationStack: [CustomersPage.Path],
          to: JobCardDetailsPage.Path,
          onSuccess: CustomersPage.Path,
          pass: currentPass,
          fromVisit: false,
          fromJobcard: location.state.fromJobcard,
          vehicleDetails: validVehicles[0],
          validVehiclesCount: 1,
          searchText: searchText,
        },
      });
      return;
    }
    navigate(VehiclesPage.Path, {
      state: {
        checkout: false,
        pass: currentPass,
        navigationStack: [CustomersPage.Path],
        to: JobCardDetailsPage.Path,
        onSuccess: CustomersPage.Path,
        fromVisit: false,
        fromJobcard: location.state.fromJobcard,
        validVehiclesCount: validVehicles.length,
        searchText: searchText,
      },
    });
  };

  const onCloseDialog = () => {
    setShowDialog(false);
  };

  const navigateToCheckoutWithVehicle = () => {
    var validVehicles = [];
    if (!Utils.IsNullOrEmptyArray(selectedPass.vehicleDetails)) {
      validVehicles = selectedPass.vehicleDetails.filter(
        (v) => v.vehicleType === getBranch().vehicleType
      );
    }
    if (Utils.IsNullOrEmptyArray(validVehicles)) {
      setMsgText("Please add vehicle before proceeding");
      setMsgOpen(true);
      return;
    }
    if (validVehicles.length === 1) {
      navigate(CheckOutPage.Path, {
        state: {
          checkout: true,
          pass: selectedPass,
          navigationStack: [CustomersPage.Path],
          to: CheckOutPage.Path,
          onSuccess: CustomersPage.Path,
          fromJobcard: location.state && location.state.fromJobcard,
          fromVisit: false,
          vehicleDetails: validVehicles[0],
          validVehiclesCount: validVehicles.length,
          searchText: searchText,
        },
      });
      return;
    }
    navigate(VehiclesPage.Path, {
      state: {
        checkout: true,
        pass: selectedPass,
        navigationStack: [CustomersPage.Path],
        to: CheckOutPage.Path,
        onSuccess: CustomersPage.Path,
        fromJobcard: location.state && location.state.fromJobcard,
        fromVisit: false,
        validVehiclesCount: validVehicles.length,
        searchText: searchText,
      },
    });
  };

  const onClosePickVehicleConfirmation = (confirmResult) => {
    setShowConfirm(false);
    switch (confirmResult) {
      case ConfirmTypes.YES:
        navigateToCheckoutWithVehicle();
        break;
      case ConfirmTypes.NO:
        navigate(CheckOutPage.Path, {
          state: {
            checkout: true,
            navigationStack: [CustomersPage.Path],
            onSuccess: CustomersPage.Path,
            pass: selectedPass,
            fromJobcard: location.state && location.state.fromJobcard,
            searchText: searchText,
          },
        });
        break;
      case ConfirmTypes.CANCEL:
        setSelectedPass({});
        return;
    }
  };

  const handleEnlargeImage = (isReg) => {
    setImageUrl(isReg ? rcImage : insuranceImage);
    setEnlargeImage(true);
  };

  const closeImage = () => {
    setImageUrl("");
    setEnlargeImage(false);
  };

  const mobileNumberHelperText = () => {
    if (!customerMobileNumber) {
      return "";
    } else if (
      customerMobileNumber.length !== 10 ||
      !/^\d+$/.test(customerMobileNumber)
    ) {
      return "Please enter a valid mobile number";
    }
  };
  return (
    <Container maxWidth="false" className="bg-color p-0">
      <Loader open={isLoading} />
      <MessagePopup
        msgTitle={alertTitle}
        msgOpen={msgOpen}
        msgText={msgText}
        onMsgClose={handleMsgClose}
      />
      <ConfirmPopup
        confirmOpen={confirmOpen}
        confirmText={confirmText}
        onConfirmClose={handleConfirmClose}
      />
      <PickVehicleConfirmation
        open={showConfirm}
        onClose={onClosePickVehicleConfirmation}
        message="Do you wish to select vehicle?"
        yesText="Yes"
        noText="No"
      />
      <DialogPopup
        open={showDialog}
        onClose={onCloseDialog}
        isConfirm={false}
        okText="Ok"
        title="Error"
      />
      <ImageEnlarge
        open={enlargeImage}
        imgSrc={imageUrl}
        onClose={closeImage}
      />
      <Box>
        {/* Vehicle Setup */}
        <BootstrapDialog
          onClose={closeVehicleSetup}
          aria-labelledby="customized-dialog-title"
          open={addVehicle}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={closeVehicleSetup}
          >
            <Typography
              level="h3"
              component="h3"
              fontWeight="600"
              fontSize="2rem"
              className="h3"
            >
              {modalTitle}
            </Typography>
          </BootstrapDialogTitle>
          <DialogContent dividers className="modal-dialog-content font-size-14">
            <TextField type="hidden" value={vehicleSubmitCat} />
            <Typography
              level="h3"
              component="h3"
              fontWeight="600"
              fontSize="1.3rem"
              className="h3"
            >
              Vehicles Details
            </Typography>
            <FormControl fullWidth className="mt-2">
              <TextField
                id="vehicleNumber"
                label="Vehicle Number"
                error={!vehicleNumber}
                variant="outlined"
                value={vehicleNumber}
                inputProps={{ maxLength: 16 }}
                onChange={(e) =>
                  setVehicleNumber(Utils.ConvertToUpperCase(e.target.value))
                }
                InputLabelProps={{ shrink: true }}
              />
            </FormControl>
            <Stack
              direction="row"
              spacing={2}
              className="mt-2"
              style={{ width: "100%" }}
            >
              <FormControl>
                <FormControlLabel
                  control={<Switch
                    onChange={(e) =>
                      toggleCustomMakeModel(e.target.checked)
                    } />}
                  label="Custom Select"
                />
              </FormControl>
              {openCustomMakeModel ? (
                <Stack direction="row" style={{ width: "70%" }}>
                  <FormControl fullWidth style={{ "margin-right": "15px" }}>
                    <TextField
                      id="vehicleMakeSelect"
                      label="Make"
                      variant="outlined"
                      error={!vehicleMake}
                      onChange={(e) => setVehicleMake(e.target.value)}
                      style={{ "width": "160px" }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <TextField
                      id="vehicleModelSelect"
                      label="Model"
                      variant="outlined"
                      error={!vehicleModel}
                      onChange={(e) => setVehicleModel(e.target.value)}
                      style={{ "width": "160px" }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </FormControl>
                </Stack>
              ) : (
                <Stack direction="row" style={{ width: "70%" }}>
                  <FormControl fullWidth
                    style={{ "margin-right": "15px" }}
                  >
                    <InputLabel id="demo-simple-select-label">Make </InputLabel>
                    <Select
                      id="vehicleMake"
                      value={vehicleMake}
                      error={!vehicleMake}
                      label="Make"
                      variant="outlined"
                      onChange={(e) => vehicleMakeHandleChange(e)}
                      style={{ "width": "160px" }}
                      InputLabelProps={{ shrink: true }}
                    >
                      {makeList.map((data) => (
                        <MenuItem value={data}>{data}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Model </InputLabel>
                    <Select
                      id="vehicleModel"
                      required
                      value={vehicleModel}
                      error={!vehicleModel}
                      label="Model"
                      variant="outlined"
                      onChange={modelHandleChange}
                      style={{ "width": "160px" }}
                      InputLabelProps={{ shrink: true }}
                    >
                      {modelList.map((data) => (
                        <MenuItem value={data}>{data}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Stack>
              )}
            </Stack>
            <Stack direction="row" spacing={2} className="mt-2">
              <FormControl fullWidth>
                <NumericTextField
                  id="vehicleYear"
                  label="Year"
                  variant="outlined"
                  value={vehicleYear}
                  setValue={setVehicleYear}
                  inputProps={{ maxLength: 4 }}
                  InputLabelProps={{ shrink: true }}
                />
              </FormControl>
              <FormControl fullWidth>
                <TextField
                  id="vehicleColor"
                  label="Color"
                  variant="outlined"
                  value={vehicleColor}
                  onChange={(e) => setVehicleColor(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                />
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Fuel Type</InputLabel>
                <Select
                  id="jobcardFuelType"
                  label="Fuel Type"
                  variant="outlined"
                  onChange={fuelHandleChange}
                  value={vehicleFuelType}
                >
                  {!Utils.IsNullOrEmptyArray(vehicleFuelTypes) &&
                    vehicleFuelTypes.map((item, index) => (
                      <MenuItem value={item}>{item}</MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Stack>
            <Stack className="hide">
              <Typography
                level="h3"
                component="h3"
                fontWeight="600"
                fontSize="1.3rem"
                className="h3 mt-2"
              >
                Insurance Details
              </Typography>
              <Stack direction="row" spacing={2} className="mt-2">
                <FormControl fullWidth>
                  <TextField
                    id="vehicleInsPolicyNumber"
                    label="Policy Number"
                    variant="outlined"
                    value={vehicleInsPolicyNumber}
                    onChange={(e) => setVehicleInsPolicyNumber(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="vehicleInsPlaceOfIssue"
                    label="Place of issue"
                    variant="outlined"
                    value={vehicleInsPlaceOfIssue}
                    onChange={(e) => setVehicleInsPlaceOfIssue(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                  />
                </FormControl>
              </Stack>
              <Stack direction="row" spacing={2} className="mt-2">
                <FormControl fullWidth>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      id="businessOpen"
                      label="Expiry Date"
                      value={vehicleInsExpiryDate}
                      onChange={(newValue) => setVehicleInsExpiryDate(newValue)}
                      closeOnSelect={true}
                    />
                  </LocalizationProvider>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="vehicleInsCompany"
                    label="Company"
                    variant="outlined"
                    value={vehicleInsCompany}
                    onChange={(e) => setVehicleInsCompany(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                  />
                </FormControl>
              </Stack>
              <Stack direction="row" spacing={2} className="mt-2">
                <FormControl fullWidth>
                  <TextField
                    id="vehicleInsCompany"
                    label="Insurance GSTIN"
                    variant="outlined"
                    value={insuranceGSTIN}
                    onChange={(e) => setInsuranceGSTIN(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="vehicleInsCompany"
                    label="Insurance Branch Address"
                    variant="outlined"
                    value={insuranceBranchAddress}
                    onChange={(e) => setInsuranceBranchAddress(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                  />
                </FormControl>
              </Stack>
            </Stack>
            <Stack className="hide">
              <Stack
                direction="row"
                spacing={2}
                className="justify-content-between mt-2"
              >
                <Typography
                  level="h3"
                  component="h3"
                  fontWeight="600"
                  fontSize="2rem"
                  className="h3"
                >
                  Registration Certificate
                </Typography>
                {Utils.IsNullOrEmpty(rcImage) && (
                  <Stack direction="row" spacing={2}>
                    <label>
                      <AddAPhotoIcon
                        className="icon-btn icon20"
                        title="Upload files"
                      />
                      <Input
                        type="file"
                        name="myImage"
                        className="hide"
                        inputProps={{
                          accept: "image/png, image/jpeg, image/ico, image/jpg",
                        }}
                        disabled={saveVehiclesDisabled}
                        onChange={(e) => UploadReturnImage(e, true)}
                      />
                    </label>
                  </Stack>
                )}
              </Stack>
              <Divider />

              <Grid>
                {!Utils.IsNullOrEmpty(rcImage) && (
                  <div className="card imgBox">
                    <img
                      alt="not found"
                      width={"150px"}
                      src={rcImage}
                      onClick={() => handleEnlargeImage(true)}
                    />
                    <br />
                    <button
                      className="p-0 mt-1 btn btn-default"
                      onClick={() => deletePhoto(true)}
                    >
                      <DeleteIcon />
                    </button>
                  </div>
                )}
              </Grid>
              <Stack
                direction="row"
                spacing={2}
                className="justify-content-between mt-2"
              >
                <Typography
                  level="h3"
                  component="h3"
                  fontWeight="600"
                  fontSize="2rem"
                  className="h3"
                >
                  Insurance
                </Typography>
                {Utils.IsNullOrEmpty(insuranceImage) && (
                  <Stack direction="row" spacing={2}>
                    <label>
                      <AddAPhotoIcon
                        className="icon-btn icon20"
                        title="Upload files"
                      />
                      <Input
                        type="file"
                        disabled={saveVehiclesDisabled}
                        name="myImage"
                        className="hide"
                        inputProps={{
                          accept: "image/png, image/jpeg, image/ico, image/jpg",
                        }}
                        onChange={(event) => UploadReturnImage(event, false)}
                      />
                    </label>
                  </Stack>
                )}
              </Stack>
              <Divider />
              <Grid>
                {!Utils.IsNullOrEmpty(insuranceImage) && (
                  <div className="card imgBox">
                    <img
                      alt="not found"
                      width={"150px"}
                      src={insuranceImage}
                      onClick={() => handleEnlargeImage(false)}
                    />
                    <br />
                    <button
                      className="p-0 mt-1 btn btn-default"
                      onClick={() => deletePhoto(false)}
                    >
                      <DeleteIcon className="icon20" />
                    </button>
                  </div>
                )}
              </Grid>
            </Stack>
            <br></br>
          </DialogContent>
          <DialogActions className="modal-footer">
            <Stack direction="row" spacing={2} justifyContent="flex-end">
              <FormControl>
                <Button
                  type="submit"
                  onClick={closeVehicleSetup}
                  variant="contained"
                  className="btn btn-secondary"
                >
                  Cancel
                </Button>
              </FormControl>
              {isVehicleEdit ? (
                <FormControl>
                  <Button
                    type="submit"
                    onClick={vehicleSetupSubmit}
                    variant="contained"
                    disabled={saveVehiclesDisabled}
                    className="btn btn-primary"
                  >
                    Update
                  </Button>
                </FormControl>
              ) : (
                <FormControl>
                  <Button
                    type="submit"
                    onClick={vehicleSetupSubmit}
                    variant="contained"
                    disabled={saveVehiclesDisabled}
                    className="btn btn-primary"
                  >
                    Add
                  </Button>
                </FormControl>
              )}
            </Stack>
          </DialogActions>
        </BootstrapDialog>

        {/* Customer Setup */}
        <BootstrapDialog
          onClose={closeCustomerSetup}
          aria-labelledby="customized-dialog-title"
          open={addItems}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={closeCustomerSetup}
          >
            <Typography
              level="h3"
              component="h3"
              fontWeight="600"
              fontSize="2rem"
              className="h3"
            >
              {pageTitle}
            </Typography>
          </BootstrapDialogTitle>
          <DialogContent dividers className="modal-dialog-content font-size-14">
            <TextField id="businessId" type="hidden" value={businessId} />
            <TextField id="customerId" type="hidden" value={customerId} />
            <Stack sx={{ minWidth: 120 }} spacing={2}>
              {/* <FormControl fullWidth>
                <TextField
                  id="customerKey"
                  label="Customer Key"
                  disabled={isCustomerEdit}
                  variant="outlined"
                  value={customerKey}
                  onChange={(e) => setCustomerKey(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                />
              </FormControl> */}
              <Stack direction="row" spacing={2}>
                <FormControl fullWidth>
                  <TextField
                    error={!customerFirstName}
                    id="customerFirstName"
                    label="First Name"
                    variant="outlined"
                    value={customerFirstName}
                    onChange={(e) => setCustomerFirstName(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="customerLastName"
                    label="Last Name"
                    variant="outlined"
                    value={customerLastName}
                    onChange={(e) => setCustomerLastName(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                    required={isUSBusiness}
                  />
                </FormControl>
              </Stack>
              <Stack direction="row" spacing={2}>
                <FormControl fullWidth>
                  <NumericTextField
                    id="mobileNumber"
                    label="Mobile Number"
                    variant="outlined"
                    error={
                      !customerMobileNumber ||
                      !(
                        customerMobileNumber.length === 10 &&
                        /^\d+$/.test(customerMobileNumber)
                      )
                    }
                    disabled={isCustomerEdit}
                    helperText={mobileNumberHelperText()}
                    value={customerMobileNumber}
                    setValue={setCustomerMobileNumber}
                    inputProps={{
                      maxLength: CountryHelper.GetMobileNumberMaxLength(),
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="emailId"
                    label="Email"
                    variant="outlined"
                    value={customerEmailId}
                    onChange={(e) => setCustomerEmailId(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                  />
                </FormControl>
              </Stack>
              {/* <TextField
                    id="customerAddress"
                    label="Address"
                    variant="outlined"
                    value={customerAddress}
                    onChange={(e) => setCustomerAddress(e.target.value)}
                    required
                    InputLabelProps={{ shrink: true }}
                  /> */}
              {/* <Stack
                    direction="row"
                    spacing={2}
                    className="justify-content-between"
                  >
                    <Typography
                      level="h3"
                      component="h3"
                      fontWeight="600"
                      fontSize="2rem"
                      className="h3"
                    >
                      Use Custom Address
                    </Typography>
                    <Switch edge="end" onClick={toggleCustomAddress} />
                  </Stack> */}
              {isOpenedCustomAddress ? (<Grid>
                <Stack
                  direction="row"
                  spacing={2}
                  className="justify-content-between"
                >
                  <Typography
                    level="h3"
                    component="h3"
                    fontWeight="600"
                    fontSize="2rem"
                    className="h3"
                  >
                    Use Custom Address
                  </Typography>
                  <Switch checked={isOpenedCustomAddress} edge="end" onClick={toggleCustomAddress} />
                </Stack>
                <FormControl fullWidth>
                  <TextField
                    id="customerCustomAddress"
                    label="Address"
                    variant="outlined"
                    value={customerCustomAddress}
                    onChange={(e) => setCustomerCustomAddress(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                  />
                </FormControl>
                <Stack direction="row" spacing={2} className="m-t-2">
                  <FormControl fullWidth>
                    <TextField
                      id="customerCity"
                      label="City"
                      variant="outlined"
                      error={!customerCity}
                      value={customerCity}
                      onChange={(e) => setCustomerCity(e.target.value)}
                      InputLabelProps={{ shrink: true }}
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <TextField
                      id="customerState"
                      label={isUAEBusiness ? "Emirate" : "State"}
                      variant="outlined"
                      value={customerState}
                      onChange={(e) => setCustomerState(e.target.value)}
                      InputLabelProps={{ shrink: true }}
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <TextField
                      id="customerPincode"
                      label="Pincode"
                      variant="outlined"
                      value={customerPincode}
                      onChange={(e) => setCustomerPincode(e.target.value)}
                      InputLabelProps={{ shrink: true }}
                    />
                  </FormControl>
                </Stack>
              </Grid>
              ) : (
                <>
                  <AddressField
                    id="branchAddress"
                    variant="outlined"
                    label="Branch Address"
                    required
                    isValidate={true}
                    countryISoCode={addressISOCode}
                    value={customerAddress}
                    setValue={setCustomerAddress}
                  />
                  <Stack
                    direction="row"
                    spacing={2}
                    className="justify-content-between"
                  >
                    <Typography
                      level="h3"
                      component="h3"
                      fontWeight="600"
                      fontSize="2rem"
                      className="h3"
                    >
                      Use Custom Address
                    </Typography>
                    <Switch
                      checked={isOpenedCustomAddress}
                      edge="end"
                      onClick={toggleCustomAddress}
                    />
                  </Stack>
                </>
              )}

              {/* {isOpenedCustomAddress && (
                  <Grid>
                    <FormControl fullWidth>
                      <TextField
                        id="customerCustomAddress"
                        label="Address"
                        variant="outlined"
                        value={customerCustomAddress}
                        onChange={(e) =>
                          setCustomerCustomAddress(e.target.value)
                        }
                        required
                        InputLabelProps={{ shrink: true }}
                      />
                    </FormControl>
                    <Stack direction="row" spacing={2} className="m-t-2">
                      <FormControl fullWidth>
                        <TextField
                          id="customerCity"
                          label="City"
                          variant="outlined"
                          value={customerCity}
                          required
                          onChange={(e) => setCustomerCity(e.target.value)}
                          InputLabelProps={{ shrink: true }}
                        />
                      </FormControl>
                      <FormControl fullWidth>
                        <TextField
                          id="customerState"
                          label="State"
                          variant="outlined"
                          required
                          value={customerState}
                          onChange={(e) => setCustomerState(e.target.value)}
                          InputLabelProps={{ shrink: true }}
                        />
                      </FormControl>
                      <FormControl fullWidth>
                        <TextField
                          id="customerPincode"
                          label="Pincode"
                          variant="outlined"
                          value={customerPincode}
                          onChange={(e) => setCustomerPincode(e.target.value)}
                          required
                          InputLabelProps={{ shrink: true }}
                        />
                      </FormControl>
                    </Stack>
                  </Grid>
                )} */}
              <Typography
                level="h3"
                component="h3"
                fontWeight="600"
                fontSize="2rem"
                className="h3"
              >
                Business Details :
              </Typography>
              <Stack direction="row" spacing={2} className="xs-column">
                <FormControl fullWidth className="my-xs-1">
                  <TextField
                    id="customerBusinessName"
                    label="Company Name"
                    variant="outlined"
                    value={customerBusinessName}
                    onChange={(e) => setCustomerBusinessName(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                  />
                </FormControl>
                <FormControl fullWidth className="my-xs-1">
                  <TextField
                    id="customerBusinessGSTIN"
                    label={
                      isIndianBusiness
                        ? "Company GSTIN"
                        : isUSBusiness
                          ? "Company Tax Id"
                          : "Tax Id"
                    }
                    variant="outlined"
                    inputProps={{ maxLength: regIdLength }}
                    value={customerBusinessGSTIN}
                    onChange={(e) => setCustomerBusinessGSTIN(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                  />
                </FormControl>
                {gst && (
                  <FormControl fullWidth className="my-xs-1">
                    <TextField
                      id="customerGSTINCode"
                      label="GSTIN Code"
                      variant="outlined"
                      value={customerGSTINCode}
                      onChange={(e) => setCustomerGSTINCode(e.target.value)}
                      InputLabelProps={{ shrink: true }}
                    />
                  </FormControl>
                )}
              </Stack>
              <Stack direction="row" spacing={4}>
                <FormControl>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      id="businessOpen"
                      label="Date of Birth"
                      disabled={openDOB}
                      value={
                        openDOB ? customerDOB : moment("1001-01-01T00:00:00")
                      }
                      onChange={(newValue) => setCustomerDOB(newValue)}
                      closeOnSelect={true}
                      minDate={moment("1001-01-01T00:00:00")}
                    />
                  </LocalizationProvider>
                </FormControl>
                <FormControl style={{ marginTop: "10px" }}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={openDOB}
                        onChange={(e) => toggleCustomDOB(e.target.checked)}
                      />
                    }
                    label="Don't Share Date of Birth"
                  />
                </FormControl>
                <FormControl>
                  <Stack direction="row" alignItems={"center"} spacing={3}>
                    <Typography
                      row
                      level="h3"
                      component="h3"
                      fontWeight="600"
                      fontSize="2rem"
                      className="h3"
                    >
                      Gender:
                    </Typography>
                    <RadioGroup
                      row
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="Male"
                      name="radio-buttons-group"
                    >
                      <FormControlLabel
                        value="Male"
                        control={<Radio />}
                        label="Male"
                        onChange={(e) => setCustomerGender(e.target.value)}
                      />
                      <FormControlLabel
                        value="Female"
                        control={<Radio />}
                        label="Female"
                        onChange={(e) => setCustomerGender(e.target.value)}
                      />
                    </RadioGroup>
                  </Stack>
                </FormControl>
              </Stack>
              {isAutomobileBusiness && (
                <Stack direction="column">
                  <Stack
                    direction="row"
                    spacing={2}
                    className="justify-content-between"
                  >
                    <Typography
                      level="h3"
                      component="h3"
                      fontWeight="600"
                      fontSize="2rem"
                      className="h3"
                    >
                      Vehicles
                    </Typography>
                    {customerVehicleList.length <= 30 && (
                      <Button
                        className="btn btn-secondary refresh-icon"
                        variant="contained"
                        onClick={() => openVehicleSetup()}
                        style={{ padding: "1px 10px", "margin-bottom": "4px" }}
                      >
                        <AddCircleIcon
                          fontSize="Large"
                          title="Upload files"
                          style={{ color: "#588ce9" }}
                        />
                        <span className="hidden-xs ml-h">Add</span>
                      </Button>
                    )}
                  </Stack>
                  <Divider />
                  <Grid container xs={12} className="mt-1 font-size-12">
                    {customerVehicleList.map((item, i) => (
                      <Grid xs={12} md={6} lg={6} className="mb-1">
                        <Stack
                          className="card mr-1 m-xs-0"
                          padding="10px"
                          style={{ height: "100%" }}
                          direction="row"
                        >
                          <Stack>
                            <h3 className="mt-0">
                              <strong className="input-label" fontWeight="600">
                                {item.number}
                              </strong>
                            </h3>
                            <span className="input-label">{item.make}</span>
                            <span className="input-label">{item.model}</span>
                            {item.color && (
                              <span className="input-label">
                                {item.color}
                                <DirectionsCarIcon
                                  style={{
                                    color: item.color,
                                    "vertical-align": "middle",
                                  }}
                                />
                              </span>
                            )}
                            <span>
                              {item.fuelType} &nbsp;
                              {item.fuelType == "Petrol" && (
                                <WaterDropIcon
                                  style={{
                                    color: "gold",
                                    "vertical-align": "middle",
                                  }}
                                />
                              )}
                              {item.fuelType == "Diesel" && (
                                <WaterDropIcon
                                  style={{
                                    color: "#cddc39",
                                    "vertical-align": "middle",
                                  }}
                                />
                              )}
                              {item.fuelType == "CNG" && (
                                <PropaneIcon
                                  style={{
                                    color: "#03a9f4",
                                    "vertical-align": "middle",
                                  }}
                                />
                              )}
                              {item.fuelType == "LPG" && (
                                <LocalGasStationIcon
                                  style={{
                                    color: "red",
                                    "vertical-align": "middle",
                                  }}
                                />
                              )}
                              {item.fuelType == "Electric" && (
                                <EvStationIcon
                                  style={{
                                    color: "green",
                                    "vertical-align": "middle",
                                  }}
                                />
                              )}
                            </span>
                          </Stack>
                          <Grid
                            container
                            className="text-right"
                            justifyContent="end"
                          >
                            <Stack direction="column">
                              <Button
                                className="btn btn-secondary"
                                style={{ padding: "3px", "min-width": "auto" }}
                              >
                                <EditIcon
                                  onClick={() => openVehicleSetup(item)}
                                  className="icon-btn icon20"
                                  title="Edit"
                                />
                              </Button>
                              <Button
                                className="btn btn-secondary mt-1"
                                style={{
                                  padding: "3px",
                                  "min-width": "auto",
                                }}
                              >
                                <DeleteIcon
                                  onClick={() => deleteVehicleDetail(i)}
                                  className="icon-btn icon20"
                                  title="Delete"
                                />
                              </Button>
                            </Stack>
                          </Grid>
                        </Stack>
                      </Grid>
                    ))}
                  </Grid>
                </Stack>
              )}
            </Stack>
          </DialogContent>
          <DialogActions className="modal-footer">
            <FormControl>
              <Button
                type="submit"
                variant="contained"
                className="btn btn-secondary"
                onClick={closeCustomerSetup}
              >
                Cancel
              </Button>
            </FormControl>
            <FormControl>
              <Button
                type="submit"
                variant="contained"
                className="btn btn-primary"
                onClick={() => customerSaveSubmit()}
                disabled={saveCustomerDisabled}
              >
                Save
              </Button>
            </FormControl>
          </DialogActions>
        </BootstrapDialog>

        <Grid container m={0}>
          <SideBarMenuItems
            selectedTab={
              location.state && location.state.fromJobcard
                ? SideBarMenuNames.JobCard
                : SideBarMenuNames.Customers
            }
          />
          <Grid xs={12} className="content-sec tl-header">
            <Grid container direction="row" className="">
              <Grid flex="1" spacing={2} className="p-lg-2">
                <Grid container className="title-sec">
                  <Typography
                    level="h2"
                    component="h2"
                    fontWeight="600"
                    fontSize="2rem"
                    className="page-title text-center"
                  >
                    Customers
                  </Typography>
                  <form
                    method="post"
                    className="search-form"
                    onSubmit={handleSubmit}
                    autoComplete="off"
                    alignSelf="center"
                  >
                    <Stack
                      direction="row"
                      alignSelf="center"
                      spacing={1}
                      style={{ width: "100%" }}
                    >
                      <Grid flex="1">
                        <TextField
                          className="form-control font-size-14"
                          placeholder="Search customer..."
                          value={searchText}
                          onChange={(e) => setSearchText(e.target.value)}
                          style={{ "min-width": "300px" }}
                        />
                      </Grid>
                      <Grid>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          size="large"
                          className="submitBtn cs-btn"
                          fullWidth="true"
                        >
                          <SearchIcon sx={{ fontSize: 24 }} />
                          <span className="btn-text">Search</span>
                        </Button>
                      </Grid>
                    </Stack>
                  </form>
                  <Stack paddingRight="15px" alignSelf="center">
                    <Button
                      className="btn btn-secondary refresh-icon"
                      variant="contained"
                      onClick={() => openCustomerSetup()}
                    >
                      <PersonAddIcon
                        fontSize="large"
                      />
                      <span className="hidden-xs ml-h">Add New</span>
                    </Button>
                  </Stack>
                </Grid>
                <TableContainer
                  component={Paper}
                  className="visit-table customer-table"
                  style={{ height: "calc(100vh - 100px)" }}
                >
                  <Table
                    stickyHeader
                    area-lang="simple table"
                    className="font-size-14"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Contact</TableCell>
                        <TableCell className="text-right">Profit</TableCell>
                        <TableCell className="text-right">Revenue</TableCell>
                        <TableCell className="text-right">Visits</TableCell>
                        <TableCell className="text-right">Points</TableCell>
                        <TableCell style={{ width: "40px" }}></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {customerList.map((customerPass, index) => (
                        <TableRow>
                          <TableCell
                            style={{
                              "justify-content": "center",
                            }}
                          >
                            <strong>{customerPass.userName}</strong>
                          </TableCell>
                          <TableCell>
                            <div
                              style={{
                                width: "100%",
                                "flex-direction": "column",
                                display: "flex",
                              }}
                              className="pt-xs-1"
                            >
                              <p
                                style={{
                                  display: "flex",
                                  "justify-content": "space-between",
                                }}
                                className="m-0"
                              >
                                <strong className="mb-txt">
                                  Mobile &nbsp;
                                </strong>
                                <span>{customerPass.mobileNumber}</span>
                              </p>
                              <p
                                style={{
                                  display: "flex",
                                  "justify-content": "space-between",
                                }}
                                className="m-0"
                              >
                                <strong className="mb-txt">Email &nbsp;</strong>
                                <span>{customerPass.emailId}</span>
                              </p>
                            </div>
                          </TableCell>
                          <TableCell className="text-right">
                            <strong className="mb-txt">Profit &nbsp;</strong>
                            <span>
                              {Utils.GetCurrency(
                                customerPass.profit,
                                localSettings.country
                              )}
                            </span>
                          </TableCell>
                          <TableCell className="text-right">
                            <strong className="mb-txt">Revenue &nbsp;</strong>
                            <span>
                              {Utils.GetCurrency(
                                customerPass.revenue,
                                localSettings.country
                              )}
                            </span>
                          </TableCell>
                          <TableCell className="text-right">
                            <strong className="mb-txt">Visits &nbsp;</strong>
                            <Button
                              className="visit-btn"
                              onClick={() => handleVisitClick(customerPass)}
                              style={{ "min-width": "auto" }}
                            >
                              <Typography fontWeight="bold" variant="h1">
                                {customerPass.visits}
                              </Typography>
                            </Button>
                          </TableCell>
                          <TableCell className="text-right">
                            <strong className="mb-txt">Points &nbsp;</strong>
                            <span>{customerPass.points}</span>
                          </TableCell>
                          <TableCell>
                            {!customerPass.isBusiness ? (
                              <div className="menuList">
                                <ul>
                                  {Utils.IsNullOrEmptyObject(location.state) ||
                                    (!location.state.fromJobcard && (
                                      <li
                                        onClick={() =>
                                          navigateToCheckout(customerPass)
                                        }
                                      >
                                        <ShoppingCartIcon
                                          fontSize="large"
                                          style={{ color: "#8BC34A" }}
                                        />
                                        Checkout
                                      </li>
                                    ))}

                                  {isAutomobileBusiness &&
                                    !Utils.IsNullOrEmptyArray(
                                      customerPass.vehicleDetails
                                    ) &&
                                    !Utils.IsNullOrEmptyObject(
                                      customerPass.vehicleDetails.find(
                                        (v) =>
                                          v.vehicleType == getBranch().vehicleType
                                      )
                                    ) ? (
                                    <li
                                      onClick={() =>
                                        navigateToJobcard(customerPass)
                                      }
                                    >
                                      <JobCardIcon
                                        fontSize="large"
                                        style={{ color: "#673ab7" }}
                                      />
                                      Job Card
                                    </li>
                                  ) : (
                                    <span></span>
                                  )}
                                  <li
                                    onClick={() =>
                                      openCustomerSetup(customerPass)
                                    }
                                  >
                                    <EditIcon
                                      className="icon20"
                                    />
                                    Edit
                                  </li>
                                  <li
                                    onClick={() => deleteCustomer(customerPass)}
                                  >
                                    <DeleteIcon
                                      className="icon20"
                                      style={{ color: "#ff0000" }}
                                    />
                                    Delete
                                  </li>
                                </ul>
                              </div>
                            ) : (
                              <div></div>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default Customers;
