import React, { useState, useEffect } from "react";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import Utils from "../core/Utils";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { FormControlLabel, Switch } from "@mui/material";
import Button from "@mui/material/Button";
import RadioGroup from "@mui/material/RadioGroup";
import Stack from "@mui/material/Stack";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import { getBusiness } from "../data/localSettingsActions";
import BusinessHelper from "../core/BusinessHelper";
import DecimalEntryField from "./common/controls/DecimalEntryField";
import { InputLabel } from "@mui/material";

const CustomProduct = ({ open, onClose, onAdd }) => {
  const [measuringUnit, setMeasuringUnit] = useState("Number");
  const [productType, setProductType] = useState("Spare");
  const [quantity, setQuantity] = useState("");
  const [productName, setProductName] = useState("");
  const [sellingPrice, setSellingPrice] = useState("");
  const [purchasingCost, setPurchasingCost] = useState("");
  const [taxPercent, setTaxPercent] = useState("");
  const [showLabour, setShowLabour] = useState(false);
  const [addDisabled, setAddDisabled] = useState(true);
  const [isPurchasingCostRequired, setIsPurchasingCostRequired] = useState(
    productType === "Spare"
  );
  const [isAutomobileBusiness, setIsAutomobileBusiness] = useState(false);
  const [inclusiveOfTax, setInclusiveOfTax] = useState(false);
  const [totalPrice, setTotalPrice] = useState("");
  const [taxAmount, setTaxAmount] = useState("");

  useEffect(() => {
    setBusinessCatrgory();
  }, []);

  const setBusinessCatrgory = () => {
    var bus = getBusiness();
    setIsAutomobileBusiness(BusinessHelper.IsAutomobile(bus));
  };

  useEffect(() => {
    onSelectService();
  }, [productType]);

  useEffect(() => {
    checkDetailsFilled();
  }, [productName, quantity, productType]);

  const resetValues = () => {
    setProductName("");
    setQuantity("");
    setMeasuringUnit("Number");
    setProductType("Spare");
    setShowLabour(false);
    setSellingPrice("");
    setPurchasingCost("");
    setTaxPercent("");
    setMeasuringUnit("Number");
    setTotalPrice("");
    setTaxAmount("0.00");
  };

  const checkDetailsFilled = () => {
    var disable =
      Utils.IsNullOrEmpty(productName) ||
      Utils.ConvertToFloat(sellingPrice) === 0 ||
      Utils.ConvertToFloat(quantity) === 0;
    setAddDisabled(disable);
  };

  const onSelectService = () => {
    switch (productType) {
      case "Spare":
        setShowLabour(false);
        setIsPurchasingCostRequired(true);
        break;
      case "Service":
        setShowLabour(isAutomobileBusiness);
        setIsPurchasingCostRequired(false);
        break;
      case "Labour":
        setShowLabour(isAutomobileBusiness);
        setIsPurchasingCostRequired(false);
        break;
      case "Consumable":
        setShowLabour(false);
        setIsPurchasingCostRequired(false);
        break;
    }
  };

  const getServiceItemObject = () => {
    var _purchasingCost = Utils.ConvertToFloat(purchasingCost);
    var _quantity = Utils.ConvertToFloat(quantity);
    var _sellingPrice = Utils.ConvertToFloat(sellingPrice);
    var _taxPercent = Utils.ConvertToFloat(taxPercent);
    var totalPrice = _sellingPrice * _quantity;
    var totalpriceIncludingTax = (totalPrice * (_taxPercent + 100)) / 100;
    var taxamount = totalpriceIncludingTax - totalPrice;
    var serviceItem = {
      Sequence: 0,
      Cost: _purchasingCost * _quantity,
      Description: productName,
      ProductHSN: "",
      DiscountTotal: 0,
      Price: _sellingPrice,
      ProductClass: "",
      ProductCode: "",
      ProductDepartment: "",
      ProductFineline: "",
      ProductId: Utils.CreateGuid(),
      Quantity: _quantity,
      RetailPrice: 0,
      ServiceTime: 0,
      MeasuringUnit: measuringUnit,
      Service: productType === "Service",
      Labour: productType === "Labour",
      Material: productType === "Consumable",
      TaxPercent: _taxPercent,
      TotalPrice: totalPrice,
      IsAnonymous: true,
      TotalPriceIncludingTax: totalpriceIncludingTax,
      TaxAmount: taxamount,
      Discounts: [],
      ServiceItemStaffs: [],
    };
    return serviceItem;
  };

  const save = () => {
    if (addDisabled) {
      return;
    }
    var item = getServiceItemObject();
    if (!Utils.IsNullOrEmptyObject(item)) {
      onAdd(item);
      resetValues();
    }
  };

  const onForceClose = () => {
    onClose();
  };

  const onMeasuringUnitChange = (event) => {
    setMeasuringUnit(event.target.value);
  };

  const handleInclusiveOfTax = (event) => {
    const checked = event.target.checked;
    setInclusiveOfTax(checked);
    if (!checked) {
      var _sellingPrice = Utils.ConvertToFloat(sellingPrice);
      if (_sellingPrice === 0 || _sellingPrice === 0.0) {
        setSellingPrice("");
      }
    } else {
      var _totalPrice = Utils.ConvertToFloat(totalPrice);
      if (_totalPrice === 0 || _totalPrice === 0) {
        setSellingPrice("0.00");
        setTotalPrice("");
      }
    }
  };

  useEffect(() => {
    onChangeSellingPrice();
  }, [sellingPrice]);

  useEffect(() => {
    onChangeTotalPrice();
  }, [totalPrice]);

  useEffect(() => {
    onChangeTax();
  }, [taxPercent]);

  const onChangeSellingPrice = () => {
    if (Utils.IsNullOrEmpty(sellingPrice)) {
      return;
    }
    if (!inclusiveOfTax) {
      var _sellingPrice = Utils.ConvertToFloat(sellingPrice);
      var _taxPercent = Utils.ConvertToFloat(taxPercent);
      var _taxAmount = (_taxPercent / 100) * _sellingPrice;
      setTaxAmount(_taxAmount.toFixed(2).toString());
      var _totalPrice = _sellingPrice + _taxAmount;
      setTotalPrice(_totalPrice.toFixed(2).toString());
    }
    checkDetailsFilled();
  };

  const onChangeTotalPrice = () => {
    if (Utils.IsNullOrEmpty(totalPrice)) {
      if (inclusiveOfTax) {
        setSellingPrice("0.00");
      }
      setTaxAmount("0.00");
      return;
    }
    if (inclusiveOfTax) {
      var _taxPercent = Utils.ConvertToFloat(taxPercent);
      var _totalPrice = Utils.ConvertToFloat(totalPrice);
      var _taxAmount = (_taxPercent / 100) * _totalPrice;
      var _sellingPrice = _totalPrice - _taxAmount;
      setTaxAmount(_taxAmount.toFixed(2).toString());
      setSellingPrice(_sellingPrice.toFixed(2).toString());
    }
    checkDetailsFilled();
  };

  const onChangeTax = () => {
    if (Utils.IsNullOrEmpty(taxPercent)) {
      return;
    }
    var _totalPrice = Utils.ConvertToFloat(totalPrice);
    var _taxPercent = Utils.ConvertToFloat(taxPercent);
    var _sellingPrice = Utils.ConvertToFloat(sellingPrice);
    if (inclusiveOfTax) {
      var _taxAmount = (_taxPercent / 100) * _totalPrice;
      setTaxAmount(_taxAmount.toFixed(2).toString());
      _sellingPrice = _totalPrice - _taxAmount;
      setSellingPrice(_sellingPrice.toFixed(2).toString());
    } else {
      var _taxAmount = (_taxPercent / 100) * _sellingPrice;
      setTaxAmount(_taxAmount.toFixed(2).toString());
      _totalPrice = _sellingPrice + _taxAmount;
      setTotalPrice(_totalPrice.toFixed(2).toString());
    }

    checkDetailsFilled();
  };

  return open === true ? (
    <Modal
      open={open}
      onClose={() => onForceClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="cs-modal">
        <DialogTitle id="customized-dialog-title" onClose={onForceClose}>
          <Typography
            level="h3"
            component="h3"
            fontWeight="600"
            fontSize="2rem"
            className="h3"
          >
            Custom Product
          </Typography>
        </DialogTitle>
        <DialogContent dividers className="font-size-14">
          <Stack sx={{ minWidth: 120 }} spacing={2}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                value={productType}
                onChange={(e) => setProductType(e.target.value)}
              >
                <FormControlLabel
                  value="Spare"
                  control={<Radio />}
                  label={isAutomobileBusiness ? "Spare" : "Product"}
                />
                <FormControlLabel
                  value="Service"
                  control={<Radio />}
                  label="Service"
                />
                {showLabour && (
                  <FormControlLabel
                    value="Labour"
                    control={<Radio />}
                    label="Labour"
                  />
                )}
                <FormControlLabel
                  value="Consumable"
                  control={<Radio />}
                  label={isAutomobileBusiness ? "Consumable" : "Material"}
                />
              </RadioGroup>
            </FormControl>
            <FormControl fullWidth>
              <TextField
                id="csProductName"
                label="Name"
                value={productName}
                error={!productName}
                variant="outlined"
                onChange={(e) => setProductName(e.target.value)}
              />
            </FormControl>
            <FormControlLabel
              value="start"
              control={
                <Switch
                  color="primary"
                  checked={inclusiveOfTax}
                  onChange={(e) => handleInclusiveOfTax(e)}
                />
              }
              label="Inclusive of Tax"
              labelPlacement="start"
            />
            <Stack direction="row" spacing={2}>
              <FormControl fullWidth>
                <DecimalEntryField
                  value={quantity}
                  setError={true}
                  setValue={setQuantity}
                  label="Quantity"
                  variant="outlined"
                />
              </FormControl>
              <FormControl fullWidth>
                <DecimalEntryField
                  value={sellingPrice}
                  setError={true}
                  setValue={setSellingPrice}
                  label="Selling Price"
                  variant="outlined"
                />
              </FormControl>
            </Stack>
            <Stack direction="row" spacing={2}>
              <FormControl fullWidth>
                <DecimalEntryField
                  value={purchasingCost}
                  setValue={setPurchasingCost}
                  label="Purchasing Cost"
                  variant="outlined"
                />

              </FormControl>
              <FormControl fullWidth>
                <DecimalEntryField
                  value={taxPercent}
                  setValue={setTaxPercent}
                  label="Tax Percent"
                  variant="outlined"
                />
              </FormControl>
            </Stack>
            {(inclusiveOfTax === true &&
              <Stack direction="row" spacing={2}>
                <FormControl fullWidth>
                  <TextField
                    value={sellingPrice}
                    label="Price Exclusive of Tax"
                    variant="outlined"
                    disabled
                  />
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    value={taxAmount}
                    label="Tax Amount"
                    variant="outlined"
                    disabled
                  />
                </FormControl>
              </Stack>
            )}
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Measuring Unit
              </InputLabel>
              <Select
                id="measuringUnit"
                value={measuringUnit}
                required
                label="Measuring Unit"
                variant="outlined"
                onChange={(e) => onMeasuringUnitChange(e)}
              >
                <MenuItem value="Number">Number</MenuItem>
                <MenuItem value="Gram">Gram</MenuItem>
                <MenuItem value="Kilogram">Kilogram</MenuItem>
                <MenuItem value="Millilitre">Millilitre</MenuItem>
                <MenuItem value="Litre">Litre</MenuItem>
                <MenuItem value="Meter">Meter</MenuItem>
              </Select>
            </FormControl>
            <Stack direction="row" spacing={2} justifyContent="flex-end">
              <FormControl>
                <Button
                  variant="outlined"
                  className="btn btn-secondary"
                  onClick={onForceClose}
                >
                  Close
                </Button>
              </FormControl>
              <FormControl>
                <Button
                  variant="contained"
                  disabled={addDisabled}
                  className="btn btn-primary"
                  onClick={save}
                >
                  Add
                </Button>
              </FormControl>
            </Stack>
          </Stack>
        </DialogContent>
      </Box>
    </Modal>
  ) : null;
};

export default CustomProduct;
