import TextField from "@mui/material/TextField";
import Utils from "../../../core/Utils";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

const DecimalEntryField = ({ value, setValue, maxLength = 12, disabled = false, setError = false, convertToFloat = true, ...other }) => {
  const [others, setOthers] = useState({});
  const [displayValue, setDisplayValue] = useState("");
  useEffect(() => {
    setDisplayValue(value);
    var iprops = {}
    if (!Utils.IsNullOrEmptyObject(other) && !Utils.IsNullOrEmptyObject(other.inputProps)) {
      iprops = { ...other.inputProps, maxLength: maxLength }
    }
    else {
      iprops = { maxLength: maxLength }
    }
    setOthers({ ...other, type: "text", inputProps: iprops });
  }, []);

  useEffect(() => {
    handleChangeFromOutside();
  }, [value]);

  const handleChangeFromOutside = () => {
    setDisplayValue(value);
  }

  const handleOnChange = (currentValue) => {
    if (Utils.IsNullOrEmpty(currentValue)) {
      setDisplayValue(currentValue);
      setValue("");
    }
    if(currentValue === ".")
    {
      currentValue = "0.";
    }
    var val = currentValue;
    if (
      !isNaN(currentValue) &&
      !currentValue.includes("e") &&
      Utils.ConvertToInteger(currentValue) >= 0 &&
      (!currentValue.includes(".") ||
        Utils.IsNullOrEmpty(currentValue.split(".")[1]) ||
        currentValue.split(".")[1].length <= 2)
    ) {
      if(convertToFloat)
      {
        setValue(Utils.ConvertToFloat(val));
      }
      else
      {
        setValue(val);
      }
      setDisplayValue(val);
    }
  };
  if (setError) {
    return (
      <TextField {...others} disabled={disabled} error={!displayValue} value={displayValue} onChange={(e) => handleOnChange(e.target.value)} />
    );
  }
  return (
    <TextField {...others} disabled={disabled} value={displayValue} onChange={(e) => handleOnChange(e.target.value)} />
  );
};

export default DecimalEntryField;

DecimalEntryField.propTypes = {
  value: PropTypes.number.isRequired,
  setValue: PropTypes.func.isRequired
};