import React, { useState, useEffect } from "react";
import "../../Style.css";
import Utils from "../../core/Utils";
import UserHelper from "../../core/UserHelper";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { connectToStore } from "../../data/store";
import SideBarMenuItems from "../SideBarMenuItems";
import SettingsMenuItems from "../SettingsMenuItems";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import {
  SideBarMenuNames,
  SettingsMenuList,
  MaxDateLimit,
} from "../../core/Constants";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import MessagePopup from "../common/MessagePopUp";
import { Typography } from "@mui/material";
import { Grid, Box, Container, Stack } from "@mui/material";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import Loader from "../common/Loader";
import moment from "moment";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { ConsumptionReportPage, LoginPage } from "../../core/PageConstants";
import InputLabel from "@mui/material/InputLabel";
import { getBusiness } from "../../data/localSettingsActions";
import CountryHelper from "../../core/CountryHelper";

const ConsumptionReport = (props) => {
  document.title = "Consumption";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [msgOpen, setMsgOpen] = useState(false);
  const [msgText, setMsgText] = useState("");
  const [reportContent, setReportContent] = useState("");
  const [selectedDate, setSelectedDate] = useState([
    Utils.GetStartDate(),
    Utils.GetEndDate(),
  ]);
  const [localSettings, setLocalSettings] = useState(connectToStore());
  const [statementMethod, setStatementMethod] = useState("Summary");
  const [isNavigateToLogin, setIsNavigateToLogin] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState("");
  const [branches, setBranches] = useState([]);

  const handleMsgClose = () => {
    setMsgOpen(false);
    if (isNavigateToLogin) {
      UserHelper.LogOut(dispatch);
      navigate(LoginPage.Path);
    }
  };

  const handleShowReport = () => {
    setIsLoading(true);
    const branchId = selectedBranch !== null ? selectedBranch : "";
    const summary = statementMethod === "Summary" ? true : false;
    let url = "reports/GetStocksTransactions?";
    if (summary) {
      url = "reports/GetStocksSummary?";
    }
    var reportUrl =
      Utils.GetServiceUrl() +
      url +
      "&businessId=" +
      localSettings.business.id +
      "&branchId=" +
      branchId +
      "&startDate=" +
      Utils.GetISODateTimeString(Utils.ConvertToUTCDate(selectedDate[0])) +
      "&endDate=" +
      Utils.GetISODateTimeString(Utils.ConvertToUTCDate(selectedDate[1])) +
      "&token=" +
      localSettings.token +
      "&culture=" +
      localSettings.country.Culture;

    setReportContent(reportUrl);
    setTimeout(function() {
      setIsLoading(false);
    }.bind(this), 2000);
  };
  const onDateChange = (date) => {
    if (!Utils.IsNullOrEmptyArray(date)) {
      var dateArray = [moment(date[0]), moment(date[1])];
      let dateDiff = dateArray[1].diff(dateArray[0], "days");
      if (dateDiff > MaxDateLimit) {
        setMsgText(
          "Please note that the maximum date range you can select is 90 days. You can choose any start date within the past 90 days, but the end date cannot exceed this limit."
        );

        setMsgOpen(true);
      } else {
        setSelectedDate(dateArray);
      }
    }
  };
  const handleInputStatement = (e) => {
    setStatementMethod(e.target.value);
  };
  const checkAuthentication = () => {
    if (
      UserHelper.CheckPermission(ConsumptionReportPage, navigate, localSettings)
    ) {
      const business = getBusiness();
      if (
        !Utils.IsNullOrEmptyObject(business) &&
        !Utils.IsNullOrEmptyArray(business.branchLocations)
      ) {
        setBranches(business.branchLocations);
      }
    }
  };

  useEffect(() => {
    checkAuthentication();
  }, []);

  const handleSelect = (event) => {
    setSelectedBranch(event.target.value);
  };

  return (
    <Container maxWidth="false" className="bg-color p-0">
      <Loader open={isLoading} />
      <MessagePopup
        msgOpen={msgOpen}
        msgText={msgText}
        onMsgClose={handleMsgClose}
      />

      <Box>
        <Grid container m={0}>
          <SideBarMenuItems selectedTab={SideBarMenuNames.Settings} />
          <Grid xs={12} className="content-sec c-sec2">
            <Grid container direction="row" className="">
              <SettingsMenuItems
                selectedMenu={SettingsMenuList.StockReports.Consumption}
              />
              <Grid flex="1" spacing={2} className="form-sec">
                <form container spacing={2} onSubmit={handleShowReport}>
                  <Grid container className="title-sec title-xs">
                    <Grid xs={12} md={6} lg={6}>
                      <Typography
                        level="h1"
                        component="h2"
                        fontWeight="600"
                        fontSize="20px"
                      >
                        Consumption
                      </Typography>
                    </Grid>
                    <Grid
                      container
                      xs={12}
                      md={6}
                      lg={5}
                      style={{ "align-self": "stretch" }}
                      justifyContent="end"
                      paddingRight="15px"
                    >
                      <Stack
                        direction="row"
                        spacing={1}
                        style={{ height: "100%" }}
                      >
                        <DateRangePicker
                          showLeadingZeros={true}
                          onChange={onDateChange}
                          maxDate={new Date()}
                          value={selectedDate}
                          locale={CountryHelper.GetDateCulture(
                            localSettings.country.Culture
                          )}
                          clearIcon={null}
                          calendarIcon={
                            <CalendarMonthOutlinedIcon
                              style={{
                                "font-size": "24px",
                                "align-self": "center",
                                color: "#666666",
                              }}
                            />
                          }
                        />
                      </Stack>
                    </Grid>
                  </Grid>

                  <Stack
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    style={{ height: "50px" }}
                  >
                    <FormControl
                      fullWidth
                      variant="outlined"
                      margin="dense"
                      className="font-size-14"
                    >
                      <InputLabel id="Branch-label">Branch Name</InputLabel>
                      <Select
                        value={selectedBranch}
                        onChange={handleSelect}
                        label="Branch"
                        InputLabelProps={{ shrink: true }}
                        style={{
                          height: "50px",
                        }}
                      >
                        {branches.map((branch, index) => (
                          <MenuItem key={index} value={branch.branchId}>
                            {branch.branchName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Stack>

                  <Grid
                    spacing={2}
                    rowSpacing={2}
                    className="card"
                    padding={1}
                    style={{
                      border: "1px solid #cccccc",
                      "border-radius": "4px",
                      marginTop: "16px",
                    }}
                  >
                    <RadioGroup
                      value={statementMethod}
                      onChange={(e) => handleInputStatement(e)}
                      InputLabelProps={{ shrink: true }}
                    >
                      <Stack direction="row" spacing={40}>
                        <FormControlLabel
                          value={"Summary"}
                          control={<Radio />}
                          label="Summary"
                        />
                        <FormControlLabel
                          value={"Transactions"}
                          control={<Radio />}
                          label="Transactions"
                        />
                      </Stack>
                    </RadioGroup>
                  </Grid>
                </form>
                <iframe
                  className="reportIframe card"
                  src={reportContent}
                  style={{
                    width: "100%",
                    height: "calc(99vh - 260px)",
                  }}
                ></iframe>
                <br />

                <Grid
                  container
                  spacing={2}
                  style={{
                    justifyContent: "End",
                  }}
                >
                  <Grid
                    justifyContent="center"
                    alignItems="bottom"
                    style={{
                      marginTop: "15px",
                    }}
                  >
                    <FormControl fullWidth>
                      <Button
                        className="btn btn-primary"
                        variant="contained"
                        type="submit"
                        onClick={handleShowReport}
                      >
                        Show Report
                      </Button>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default ConsumptionReport;
