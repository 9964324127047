import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Stack,
  TextField,
  Autocomplete,
  Tooltip,
  Box,
} from "@mui/material";
import { getCountry } from "../../data/localSettingsActions";
import Utils from "../../core/Utils";

const AddProduct = ({ onAddItem, allProducts, isLoading }) => {
  const [countryInfo, setCountryInfo] = useState({});
  const [searchText, setSearchText] = useState("");
  useEffect(() => {
    setCountryInfo(getCountry());
  }, []);

  const filterProducts = (options, state) => {
    if (Utils.IsNullOrEmpty(state.inputValue)) {
      return options;
    }
    var upperCaseSearchText = state.inputValue.trim().toUpperCase();
    var parsedOptions = [];
    options.forEach((item) => {
      var parsedItem = JSON.parse(item);
      parsedOptions.push(parsedItem);
    });
    var newFilteredProducts = parsedOptions.filter(
      (p) =>
        Utils.ConvertToUpperCase(p.code).includes(upperCaseSearchText) ||
        Utils.ConvertToUpperCase(p.description).includes(upperCaseSearchText) ||
        Utils.ConvertToUpperCase(p.shortDesc).includes(upperCaseSearchText) ||
        Utils.ConvertToUpperCase(p.class).includes(upperCaseSearchText) ||
        Utils.ConvertToUpperCase(p.classDescription).includes(
          upperCaseSearchText
        ) ||
        Utils.ConvertToUpperCase(p.manufacturer).includes(
          upperCaseSearchText
        ) ||
        Utils.ConvertToUpperCase(p.department).includes(upperCaseSearchText) ||
        Utils.ConvertToUpperCase(p.departmentDescription).includes(
          upperCaseSearchText
        ) ||
        Utils.ConvertToUpperCase(p.fineline).includes(upperCaseSearchText) ||
        Utils.ConvertToUpperCase(p.finelineDescription).includes(
          upperCaseSearchText
        ) ||
        Utils.ConvertToUpperCase(p.sku).includes(upperCaseSearchText) ||
        Utils.ConvertToUpperCase(p.upc).includes(upperCaseSearchText) ||
        Utils.ConvertToUpperCase(p.vendor).includes(upperCaseSearchText)
    );
    var newProducts = [];
    if (!Utils.IsNullOrEmptyArray(newFilteredProducts)) {
      newFilteredProducts.forEach((item) => {
        newProducts.push(JSON.stringify(item));
      });
    }
    return newProducts;
  };

  const addItemToCart = (item) => {
    if (!Utils.IsNullOrEmpty(item)) {
      setSearchText("");
      onAddItem(JSON.parse(item));
    }
  };

  return (
    <Grid xs={12} style={{ padding: "0" }} className="productsListSec">
      <Autocomplete
        className="prod-input font-size-13"
        disablePortal
        value={searchText}
        loading={isLoading}
        loadingText="Loading..."
        onChange={(event, newValue) => {
          addItemToCart(newValue);
        }}
        inputValue={searchText}
        onInputChange={(event, newInputValue) => {
          setSearchText(newInputValue);
        }}
        options={allProducts}
        filterOptions={filterProducts}
        fullWidth
        disableCloseOnSelect
        renderOption={(props, item) => (
          <li
            style={{ width: "100%", "padding": "5px" }}
            {...props}
            className={(() => {
              const product = JSON.parse(item);
              if (product.service === true) {
                return "serviceItem";
              } else if (product.isMaterial === true) {
                return "Consumablesitem";
              } else {
                return "spareitem";
              }
            })()}
          >
            <Box
              sx={{ "&:": { flexShrink: 0 } }}
              style={{ overflow: "visible", width: "100%", backgroundcolor: "yellow" }}
              className="prod-wrap card"
            >
              <Tooltip
                placement="top"
                title={
                  Utils.IsNullOrEmpty(JSON.parse(item).class) &&
                    Utils.IsNullOrEmpty(JSON.parse(item).department) ? (
                    ""
                  ) : (
                    <React.Fragment>
                      <Stack direction="column">
                        {!Utils.IsNullOrEmpty(JSON.parse(item).class) && (
                          <Typography>{JSON.parse(item).class}</Typography>
                        )}
                        {!Utils.IsNullOrEmpty(JSON.parse(item).department) && (
                          <Typography>{JSON.parse(item).department}</Typography>
                        )}
                      </Stack>
                    </React.Fragment>
                  )
                }
                arrow
              >
                <Grid xs={12} lg={12} style={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography
                    padding="0px 10px 0px 0px"
                    level="strong"
                    component="strong"
                    fontWeight="600"
                    fontSize="18px"
                    className="text-left"
                  >
                    {JSON.parse(item).shortDesc}
                  </Typography>
                  <Typography
                    padding="2px 10px 0px 10px"
                    level="span"
                    component="span"
                    fontSize="13px"
                    className="card"
                    alignSelf="start"
                    style={{ "background-color": "#f4f4f4" }}
                  >
                    {JSON.parse(item).code}
                  </Typography>
                </Grid>
                <Stack
                  direction="row"
                  spacing={2}
                  justifyContent="space-between"
                  width="100%"
                  className="prod-item"
                >
                  <Grid container xs={12}>
                    <Grid xs={12} lg={6}>
                      <Typography
                        level="h6"
                        component="h6"
                        fontWeight="500"
                        fontSize="12px"
                        className="text-left"
                      >
                        {JSON.parse(item).description}
                      </Typography>
                    </Grid>
                    <Grid xs={6} lg={3}>
                      <Typography
                        level="h6"
                        component="h6"
                        fontWeight="600"
                        fontSize="14px"
                        className="text-right text-xs-left"
                      >
                        {Utils.GetCurrency(JSON.parse(item).price, countryInfo)}
                      </Typography>
                    </Grid>
                    <Grid xs={6} lg={3}>
                      <Typography
                        level="h6"
                        component="h6"
                        fontWeight="600"
                        fontSize="14px"
                        className="text-right"
                        justifyContent="flex-end"
                      ><small>Tax: &nbsp;</small>
                        {JSON.parse(item).taxPercent.toFixed(2) + "%"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Stack>
              </Tooltip>
            </Box>
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Add a product"
            inputProps={{
              ...params.inputProps,
              autoComplete: "off", // disable autocomplete and autofill
            }}
            className="lbl-text"
          />
        )}
      />
    </Grid>
  );
};

export default AddProduct;
