import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import Utils from "../../core/Utils";
import { serviceItemActionTypes } from "../reducers/serviceItem/serviceItemActionTypes";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import QuantityComponent from "./QuantityComponent";

const ProductItem = ({ serviceItem, index, handleAction }) => {
  const [quantity, setQuantity] = useState(Utils.ConvertToFloat(0));

  useEffect(() => {
    if (Utils.IsNullOrEmptyObject(serviceItem)) {
      return;
    }
    setQuantity(serviceItem.Quantity);
  }, [serviceItem]);


  const handleRemove = () => {
    var action = {
      type: serviceItemActionTypes.Delete,
      value: index,
    };
    handleAction(action);
  };

  const handleQuantity = (newValue) => {
    setQuantity(newValue);
    if (newValue === 0) {
      serviceItem.Quantity = "";
    } else {
      serviceItem.Quantity = newValue;
    }
    var action = {
      type: serviceItemActionTypes.Edit,
      value: serviceItem,
      index: index,
    };
    handleAction(action);
  };

  return (
    <Grid flex="1" spacing={2} padding={1} className="card mb-h">
      <Grid container xs={15} padding="0 10px 0 0">
        <Grid xs={2} lg={1} alignSelf="center" className="remove-icon">
          <Tooltip title="Remove" placement="bottom">
            <Button onClick={handleRemove} className="remove-btn">
              <DeleteForeverRoundedIcon style={{ color: "#ff0000" }} />
            </Button>
          </Tooltip>
        </Grid>
        <Grid xs={10} lg={4} alignSelf="center">
          <Typography
            level="h2"
            className="h2"
            component="h4"
            fontWeight="600"
            fontSize="16px"
          >
            {serviceItem.Description}
          </Typography>
        </Grid>
        <QuantityComponent quantity={quantity} handleActions={handleQuantity} />
        <Grid xs={2} lg={2} alignSelf="center" >
          <Stack
            direction="row" justifyContent="end"
          >
            <Typography
              level="h2"
              className="h2"
              component="h4"
              fontWeight="600"
              fontSize="16px"
            >
              {serviceItem.MeasuringUnit}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default ProductItem;
