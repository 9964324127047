import React, { useState, useEffect } from "react";
import "../../Style.css";
import Utils from "../../core/Utils";
import UserHelper from "../../core/UserHelper";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { connectToStore } from "../../data/store";
import SideBarMenuItems from "../SideBarMenuItems";
import SettingsMenuItems from "../SettingsMenuItems";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import {
  SideBarMenuNames,
  SettingsMenuList,
  MaxDateLimit,
} from "../../core/Constants";

import MessagePopup from "../common/MessagePopUp";
import {
  Typography,
  Switch,
  Select,
  InputLabel,
  MenuItem,
} from "@mui/material";
import { Grid, Box, Container, Stack } from "@mui/material";
import "react-calendar/dist/Calendar.css";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import Loader from "../common/Loader";
import moment from "moment";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { LoginPage, StatementReportPage } from "../../core/PageConstants";
import CountryHelper from "../../core/CountryHelper";

const StatementReport = (props) => {
  document.title = "Statement";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [msgOpen, setMsgOpen] = useState(false);
  const [msgText, setMsgText] = useState("");
  const [selectedDate, setSelectedDate] = useState([
    Utils.GetStartDate(),
    Utils.GetEndDate(),
  ]);
  const [isNavigateToLogin, setIsNavigateToLogin] = useState(false);
  const [statementMethod, setStatementMethod] = useState("Transactional");
  const [localSettings, setLocalSettings] = useState(connectToStore());
  const [reportContent, setReportContent] = useState("");
  const [invoiceLabels, setInvoiceLabels] = useState([]);
  const [selectedLabel, setSelectedLabel] = useState("");
  const [canFilterByInvoiceLabel, setCanFilterByInvoiceLabel] = useState(false);
  const [isInvoiceFilterVisible, setIsInvoiceFilterVisible] = useState(false);

  const checkAuthentication = () => {
    if (Utils.IsNullOrEmptyObject(localSettings)) {
      return;
    }
    UserHelper.CheckPermission(StatementReportPage, navigate, localSettings);
    var tempInvoiceLabels = [];
    if (!Utils.IsNullOrEmptyObject(localSettings.branch)) {
      if (!Utils.IsNullOrEmptyArray(localSettings.branch.invoiceLabels)) {
        localSettings.branch.invoiceLabels.forEach((label) => {
          tempInvoiceLabels.push(label.description);
        });
      }
      setInvoiceLabels(tempInvoiceLabels);
      setIsInvoiceFilterVisible(
        (CountryHelper.IsBusinessCountryIndia(localSettings.country) ||
          CountryHelper.IsBusinessCountryUAE(localSettings.country)) &&
        !Utils.IsNullOrEmptyArray(tempInvoiceLabels)
      );
    }
  };

  useEffect(() => {
    checkAuthentication();
  }, []);
  const handleInputStatement = (e) => {
    setStatementMethod(e.target.value);
  };

  const handleSubmit = () => {
    getStatementStatus(statementMethod);
  };

  const getStatementStatus = async (statementMethod) => {
    setIsLoading(true);
    let url = "";
    var selectInvoiceLabel = canFilterByInvoiceLabel ? selectedLabel : "";
    if (statementMethod === "Transactional") {
      url = "reports/GetSalesTransaction?";
    } else {
      url = "reports/GetSalesSummary?";
    }
    let reportUrl =
      Utils.GetServiceUrl() +
      url +
      "branchId=" +
      localSettings.branch.branchId +
      "&businessId=" +
      localSettings.business.id +
      "&startDate=" +
      Utils.GetISODateTimeString(selectedDate[0]) +
      "&endDate=" +
      Utils.GetISODateTimeString(selectedDate[1]) +
      "&invoiceLabel=" +
      selectInvoiceLabel +
      "&token=" +
      localSettings.token +
      "&culture=" +
      localSettings.country.Culture;
    setReportContent(reportUrl);

    setTimeout(function () {
      setIsLoading(false);
    }.bind(this), 2000);

  };
  const handleMsgClose = () => {
    setMsgOpen(false);
    if (isNavigateToLogin) {
      UserHelper.LogOut(dispatch);
      navigate(LoginPage.Path);
    }
  };

  const onDateChange = (date) => {
    if (!Utils.IsNullOrEmptyArray(date)) {
      var dateArray = [moment(date[0]), moment(date[1])];
      let dateDiff = dateArray[1].diff(dateArray[0], "days");
      if (dateDiff > MaxDateLimit) {
        setMsgText(
          "Please note that the maximum date range you can select is 90 days. You can choose any start date within the past 90 days, but the end date cannot exceed this limit."
        );

        setMsgOpen(true);
      } else {
        setSelectedDate(dateArray);
      }
    }
  };

  return (
    <Container maxWidth="false" className="bg-color p-0">
      <Loader open={isLoading} />
      <MessagePopup
        msgOpen={msgOpen}
        msgText={msgText}
        onMsgClose={handleMsgClose}
      />
      <Box>
        <Grid container m={0}>
          <SideBarMenuItems selectedTab={SideBarMenuNames.Settings} />
          <Grid xs={12} className="content-sec c-sec2">
            <Grid container direction="row" className="">
              <SettingsMenuItems
                selectedMenu={SettingsMenuList.BusinessReports.Statement}
              />
              <Grid flex="1" spacing={2} className="form-sec">
                <form container spacing={2} onSubmit={handleSubmit}>
                  <Grid container className="title-sec title-xs">
                    <Grid xs={12} md={6} lg={6}>
                      <Typography
                        level="h1"
                        component="h2"
                        fontWeight="600"
                        fontSize="20px"
                      >
                        Statement
                      </Typography>
                    </Grid>
                    <Grid
                      container
                      xs={12}
                      md={6}
                      lg={5}
                      style={{ "align-self": "stretch" }}
                      justifyContent="end"
                      paddingRight="15px"
                      className="date-picker-sec"
                    >
                      <Stack
                        direction="row"
                        spacing={1}
                        style={{ height: "100%" }}
                      >
                        <DateRangePicker
                          showLeadingZeros={true}
                          onChange={onDateChange}
                          maxDate={new Date()}
                          value={selectedDate}
                          locale={CountryHelper.GetDateCulture(
                            localSettings.country.Culture
                          )}
                          clearIcon={null}
                          calendarIcon={
                            <CalendarMonthOutlinedIcon
                              style={{
                                "font-size": "24px",
                                "align-self": "center",
                                color: "#666666",
                              }}
                            />
                          }
                        />
                      </Stack>
                    </Grid>
                  </Grid>

                  <Grid className="card" padding={1}>
                    {isInvoiceFilterVisible && (
                      <Stack direction="column">
                        <Stack direction="row" justifyContent="space-between">
                          <Typography
                            style={{ marginTop: "5px", fontSize: "17px" }}
                          >
                            Filter by invoice label
                          </Typography>
                          <Switch
                            checked={canFilterByInvoiceLabel}
                            onChange={() =>
                              setCanFilterByInvoiceLabel(
                                !canFilterByInvoiceLabel
                              )
                            }
                          />
                        </Stack>
                        {canFilterByInvoiceLabel && (
                          <Stack style={{ margin: "0" }}>
                            <FormControl
                              fullWidth
                              variant="outlined"
                              margin="dense"
                              className="font-size-14"
                            >
                              <InputLabel>Select Invoice Label</InputLabel>
                              <Select
                                id="invoiceLabelSelect"
                                value={selectedLabel}
                                label="Select Invoice Label"
                                onChange={(e) => setSelectedLabel(e.target.value)}
                              >
                                {!Utils.IsNullOrEmptyArray(invoiceLabels) &&
                                  invoiceLabels.map((label) => (
                                    <MenuItem value={label}>{label}</MenuItem>
                                  ))}
                              </Select>
                            </FormControl>
                          </Stack>
                        )}
                      </Stack>
                    )}
                    <RadioGroup
                      value={statementMethod}
                      onChange={(e) => handleInputStatement(e)}
                      InputLabelProps={{ shrink: true }}
                    >
                      <Stack direction="row">
                        <FormControlLabel
                          value={"Transactional"}
                          control={<Radio />}
                          label="Transactional"
                        />
                        <FormControlLabel
                          value={"Summary"}
                          control={<Radio />}
                          label="Summary"
                        />
                      </Stack>
                    </RadioGroup>
                  </Grid>
                </form>
                <iframe
                  className="reportIframe card"
                  src={reportContent}
                  style={{
                    width: "100%",
                    height: !canFilterByInvoiceLabel
                      ? "calc(95vh - 205px)"
                      : "calc(83vh - 183px)",
                  }}
                ></iframe>
                <br />
                <Stack direction="row" spacing={2} justifyContent="flex-end">
                  <FormControl>
                    <Button
                      className="btn btn-primary"
                      variant="contained"
                      type="submit"
                      onClick={handleSubmit}
                    >
                      Show Statement
                    </Button>
                  </FormControl>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default StatementReport;
