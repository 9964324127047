import React, { useEffect, useReducer, useState } from "react";
import "../Style.css";
import { useLocation } from "react-router-dom";
import Switch from "@mui/material/Switch";
import { Button, Divider, Modal } from "@mui/material";
import Loader from "./common/Loader";
import TextField from "@mui/material/TextField";
import { connectToStore } from "../data/store";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import Utils from "../core/Utils";
import { postAsync } from "../core/serviceClient";
import { useNavigate } from "react-router-dom";
import Input from "@mui/material/Input";
import { MaxFileSizeLimit } from "../core/Constants";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import DeleteIcon from "@mui/icons-material/Delete";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Checkbox from "@mui/material/Checkbox";
import { getBusiness } from "../data/localSettingsActions";
import { useDispatch } from "react-redux";
import UserHelper from "../core/UserHelper";
import SideBarMenuItems from "./SideBarMenuItems";
import { SideBarMenuNames } from "../core/Constants";
import SettingsMenuItems from "./SettingsMenuItems";
import MessagePopup from "./common/MessagePopUp";
import BusinessHelper from "../core/BusinessHelper";
import { ConfirmTypes } from "../core/Constants";
import { ImageEnlarge } from "./common/ImageEnlarge";
import ConfirmPopup from "./common/ConfirmPopup";
import DecimalEntryField from "./common/controls/DecimalEntryField";
import {
  LoginPage,
  AddNewServicePage,
  ServicesPage,
} from "../core/PageConstants";
import {
  convertProductItemObject,
  getServiceItemProductsFromProduct,
} from "../helpers/productHelper";
import { serviceItemReducer } from "./reducers/serviceItem/serviceItemReducer";
import ProductConfigComponent from "./common/ProductConfigComponent";
import { serviceItemActionTypes } from "./reducers/serviceItem/serviceItemActionTypes";
import { CanUseProductStockReport } from "../core/ModuleHelper";
import { GetValidId } from "../core/LoginHelper";

const AddNewService = (props) => {
  document.title = "AddNewService";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [localSettings, setLocalSettings] = useState({});
  const location = useLocation();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [serviceCode, setServiceCode] = useState("");
  const [serviceTime, setserviceTime] = useState("");
  const [postserviceTime, setpostserviceTime] = useState("");
  const [labour, setLabour] = useState(false);
  const [frequentlyused, setfrequentlyused] = useState(false);
  const [active, setActive] = useState(true);
  const [Allowreservation, setAllowreservation] = useState(false);
  const [Blockcalender, setblockcalender] = useState(false);
  const [servicecategory, setservicecategory] = useState("");
  const [categoryDescription, setCategoryDescription] = useState("");
  const [serviceTypeDescription, setserviceTypeDescription] = useState("");
  const [serviceType, setserviceType] = useState("");
  const [taxPercentage, setTaxPercentage] = useState("");
  const [taxAmount, setTaxAmount] = useState("");
  const [TotalPrice, setTotalPrice] = useState("");
  const [cost, setcost] = useState("");
  const [priceExclusive, setPriceExclusive] = useState("");
  const [originallist, setOriginalList] = useState([]);
  const [Typelist, setTypelist] = useState([]);
  const [Catlist, setCatlist] = useState([]);
  const [PriceModalopen, setPriceModalopen] = useState(false);
  const [ServiceList, setServiceList] = useState([]);
  const [filteredstafflist, setFilteredStafflist] = useState([]);

  const [isDropdown1, setIsDropdown1] = useState(false);
  const [isDropdown, setIsDropdown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]);
  const [msgOpen, setMsgOpen] = useState(false);
  const [msgText, setMsgText] = useState("");
  const [isNavigateToLogin, setIsNavigateToLogin] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [alertTitle, setAlertTitle] = useState("");
  const [confirmFor, setConfirmFor] = useState("");
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmText, setConfirmText] = useState("");
  const [enlargeImage, setEnlargeImage] = useState(false);
  const [isNavigateToService, setIsNavigateToService] = useState(false);
  const [isProductConfig, setIsProductConfig] = useState(false);
  const [selectedService, setselectedService] = useState(
    location.state && location.state.selectedService
      ? location.state.selectedService
      : {}
  );
  const [serviceItems, itemDispatch] = useReducer(serviceItemReducer, []);
  const [productStockReportAvailable, setProductStockReportAvailable] =
    useState(false);
  const getServiceItemObj = (item) => {
    return convertProductItemObject(item);
  };
  const populateServiceItems = (selectedService) => {
    if (!Utils.IsNullOrEmptyArray(selectedService)) {
      selectedService.forEach((service) => {
        var newItem = getServiceItemObj(service);
        if (!Utils.IsNullOrEmpty(newItem)) {
          itemDispatch({
            type: serviceItemActionTypes.Add,
            value: newItem,
          });
        }
      });
    }
  };

  const [showDialog, setShowDialog] = useState(false);
  const handleMsgClose = () => {
    setMsgOpen(false);
    if (isNavigateToLogin) {
      UserHelper.LogOut(dispatch);
      navigate(LoginPage.Path);
    } else if (isNavigateToService) navigate(ServicesPage.Path);
  };

  const useProductConfig = () => {
    if (productStockReportAvailable === true) {
      if (labour === true) {
        setIsProductConfig(false);
      } else {
        setIsProductConfig(true);
      }
    } else {
      setIsProductConfig(productStockReportAvailable);
    }
  };

  const handleTotalPriceChange = (value) => {
    const price = isNaN(parseFloat(value)) ? 0 : parseFloat(value);
    setTotalPrice(price);
    calculateTax(price, taxPercentage);
  };

  const handleTaxPercentageChange = (value) => {
    const percentage = isNaN(parseFloat(value)) ? 0 : parseFloat(value);
    setTaxPercentage(percentage);
    calculateTax(TotalPrice, percentage);
  };

  const calculateTax = (price, percentage, inclusive) => {
    if (inclusive) {
      let taxExcluded = (price / (percentage + 100)) * 100;
      let taxAmount = price - taxExcluded;

      setPriceExclusive(taxExcluded.toFixed(2));
      setTaxAmount(taxAmount.toFixed(2));
    } else {
      let taxExcluded = (price / (percentage + 100)) * 100;
      let taxAmount = price - taxExcluded;

      setPriceExclusive(taxExcluded.toFixed(2));
      setTaxAmount(taxAmount.toFixed(2));
    }
  };
  const handlepriceModal = () => {
    setPriceModalopen(true);
  };
  const handlepriceModalclose = () => {
    setPriceModalopen(false);
  };
  const handleCheckboxChange = (event, rows) => {
    var tempCheckedItems = [...checkedItems];
    if (event.target.checked) {
      tempCheckedItems.push(rows);
    } else {
      var itemToRemove = tempCheckedItems.findIndex((i) => i.id === rows.id);
      tempCheckedItems.splice(itemToRemove, 1);
    }
    setCheckedItems(tempCheckedItems);
  };

  const handleSwitchChange = (event) => {
    setIsDropdown1(event.target.checked);
  };
  const handleSwitchType = (event) => {
    setIsDropdown(event.target.checked);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    let errorMsg = "";

    if (Utils.IsNullOrEmpty(name)) {
      errorMsg += "Please enter a proper Name.<br />";
    }
    if (Utils.IsNullOrEmpty(description)) {
      errorMsg += "Please enter a proper Description.<br />";
    }
    if (Utils.IsNullOrEmpty(serviceCode)) {
      errorMsg += "Please enter a valid Service Code.<br />";
    }

    if (Utils.IsNullOrEmpty(priceExclusive)) {
      errorMsg += "Please enter Price Exclusive Tax.<br />";
    }
    if (
      serviceItems.some((c) => c.Quantity === 0 || c.Quantity === "")
    ) {
      errorMsg += "Quantity must be greater than 0.<br />";
    }

    if (!Utils.IsNullOrEmpty(errorMsg)) {
      setMsgText(errorMsg);
      setMsgOpen(true);
    } else {
      if (!Utils.IsNullOrEmptyObject(selectedService)) {
        setConfirmFor("Update");
      } else {
        setConfirmFor("Submit");
      }
      setConfirmText("Are you sure you want to save changes?");
      setConfirmOpen(true);
    }
  };
  const handleConfirmClose = (value) => {
    setConfirmOpen(false);
    if (value == ConfirmTypes.OK) {
      if (confirmFor == "Submit") {
        ServiceSubmit();
      }
      if (confirmFor == "Update") {
        editServiceDetails();
      }
    }
  };
  const handlesubmit2 = () => {
    setPriceExclusive(priceExclusive);
    setName(name);
    setDescription(description);
    setTotalPrice(TotalPrice);
    handlepriceModalclose();
  };

  useEffect(() => {
    let localSetting = connectToStore();
    if (UserHelper.CheckPermission(AddNewServicePage, navigate, localSetting)) {
      setLocalSettings(localSetting);
      var productStockReport = CanUseProductStockReport(localSetting.business);
      setProductStockReportAvailable(productStockReport);
    }
  }, []);
  useEffect(() => {
    useProductConfig();
  }, [labour, productStockReportAvailable]);

  useEffect(() => {
    getServiceStaffs();
    getService();
  }, [localSettings]);

  async function ServiceSubmit() {
    setIsLoading(true);
    var Service = {
      BusinessId: JSON.parse(localStorage.getItem("business-entity")).id,
      ShortDesc: name,
      Description: description,
      Active: active,
      Code: serviceCode,
      Cost: Utils.IsNullOrEmpty(cost) ? 0 : parseFloat(cost),
      Class: servicecategory,
      ClassDescription: categoryDescription,
      Fineline: serviceType,
      FinelineDescription: serviceTypeDescription,
      Price: Utils.IsNullOrEmpty(priceExclusive)
        ? 0
        : parseFloat(priceExclusive),
      TaxPercent: Utils.IsNullOrEmpty(taxPercentage)
        ? 0
        : parseFloat(taxPercentage),
      RetailPrice: Utils.IsNullOrEmpty(taxAmount) ? 0 : parseInt(taxAmount),
      Default: frequentlyused,
      imageUrl: imageUrl.startsWith("http")
        ? imageUrl
        : Utils.IsNullOrEmpty(imageUrl)
          ? ""
          : Utils.GetStringFromBase64(imageUrl),
      AvailableForReservation: Allowreservation,
      Labour: labour,
      Service: true,
      IsMaterial: false,
      ServiceTime: Utils.IsNullOrEmpty(serviceTime) ? 0 : parseInt(serviceTime),
      PostServiceTime: Utils.IsNullOrEmpty(postserviceTime)
        ? 0
        : parseInt(postserviceTime),

      Staff: checkedItems,
      BlockTimeOnCalendar: Blockcalender,
    };
    if (
      !labour &&
      productStockReportAvailable &&
      !Utils.IsNullOrEmptyArray(serviceItems)
    ) {
      Service = {
        ...Service,
        serviceItemProducts: serviceItems,
      };
    }
    let result2 = await postAsync(
      "Product/SaveProduct",
      {
        BusinessId: localSettings.business.id,
        NewProduct: true,
        Product: Service,
      },
      "",
      "Execute",
      "",
      []
    );
    if (result2 && result2.data) {
      setIsNavigateToService(true);
      setMsgText("Service Added Sucessfully");
      setMsgOpen(true);
    } else {
      if (result2.error && !Utils.IsNullOrEmpty(result2.errorMessage))
        setMsgText(result2.errorMessage);
      else
        setMsgText("Unable to process your request");
      setMsgOpen(true);
    }
    setIsLoading(false);
  }
  async function editServiceDetails() {
    setIsLoading(true);
    let ss = selectedService;
    ss["active"] = active;
    ss["description"] = description;
    ss["shortDesc"] = name;
    ss["code"] = serviceCode;
    ss["cost"] = Utils.IsNullOrEmpty(cost) ? 0 : parseFloat(cost);
    ss["price"] = Utils.IsNullOrEmpty(priceExclusive)
      ? 0
      : parseFloat(priceExclusive);
    ss["taxPercent"] = Utils.IsNullOrEmpty(taxPercentage)
      ? 0
      : parseFloat(taxPercentage);
    ss["default"] = frequentlyused;
    ss["imageUrl"] = Utils.IsNullOrEmpty(imageUrl) ? "" : imageUrl.startsWith("http")
      ? imageUrl
      : Utils.GetStringFromBase64(imageUrl);
    ss["retailPrice"] = taxAmount;
    ss["price"] = priceExclusive;
    ss["postServiceTime"] = postserviceTime;
    ss["serviceTime"] = serviceTime;
    ss["fineline"] = serviceType;
    ss["finelineDescription"] = serviceTypeDescription;
    ss["class"] = servicecategory;
    ss["classDescription"] = categoryDescription;
    ss["availableForReservation"] = Allowreservation;
    ss["staff"] = checkedItems;
    ss["labour"] = labour;
    ss["service"] = true;
    ss["isMaterial"] = false;
    ss["blockTimeOnCalendar"] = Blockcalender;
    if (
      !labour &&
      productStockReportAvailable &&
      !Utils.IsNullOrEmptyArray(serviceItems)
    ) {
      ss["serviceItemProducts"] = serviceItems;
    } else {
      ss["serviceItemProducts"] = null;
    }
    let result2 = await postAsync(
      "Product/SaveProduct",
      {
        BusinessId: localSettings.business.id,
        NewProduct: false,
        Product: ss,
      },
      "",
      "Execute",
      "",
      []
    );
    if (result2 && result2.data) {
      setIsNavigateToService(true);
      setMsgText("Changes Saved Sucessfully");
      setMsgOpen(true);
    } else {
      if (result2.error && !Utils.IsNullOrEmpty(result2.errorMessage))
        setMsgText(result2.errorMessage);
      else
        setMsgText("Unable to process your request");
      setMsgOpen(true);
    }
    setIsLoading(false);
  }
  async function getServiceStaffs() {
    if (localSettings.authenticated) {
      setIsLoading(true);
      var staffs = [];
      let result = await postAsync(
        "Account/GetAccount",
        {},
        "",
        "GetAll",
        "AllUsersForBusiness",
        [getBusiness().id]
      );
      staffs = result.data;
      setFilteredStafflist(staffs);
      setIsLoading(false);
    }
  }
  const getService = async () => {
    if (localSettings.authenticated) {
      setIsLoading(true);
      let result1 = await postAsync(
        "Product/GetProduct",
        {},
        "",
        "GetAll",
        "AllProductsAndMaterialsForBusiness",
        [localSettings.business.id]
      );
      setIsNavigateToLogin(result1.isNavigateToLogin);
      if (result1.error) {
        setMsgOpen(true);
        setMsgText(result1.errorMessage);
      } else {
        if (!Utils.IsNullOrEmptyArray(result1.data)) {
          var Service = result1.data.filter(
            (item) =>
              (item.isMaterial == false && item.service == true) ||
              (item.labour == true && item.labour == false)
          );

          setServiceList(Service);

          setOriginalList(Service);
          const temTypellist = [];
          const temCatllist = [];
          Service.forEach((element) => {
            if (
              !Utils.IsNullOrEmpty(element.fineline) &&
              !temTypellist.includes(element.fineline)
            ) {
              temTypellist.push(element.fineline);
            }
            if (
              !Utils.IsNullOrEmpty(element.class) &&
              !temCatllist.includes(element.class)
            ) {
              temCatllist.push(element.class);
            }
          });
          setTypelist(temTypellist);
          setCatlist(temCatllist);
        }
      }
      setIsLoading(false);
    }
  };
  const UploadServiceImage = async (event) => {
    var file = event.target.files[0];

    if (file.size > MaxFileSizeLimit) {
      setAlertTitle("Warning");
      setMsgText(
        "Unable to upload. File size exceeds the maximum limit, please ensure that it's less than 10 MB."
      );
      setMsgOpen(true);
      return;
    }
    var base64 = await convertBase64(file);
    setImageUrl(base64);
  };
  const handleServicePhoto = (event) => {
    const fileInput = event.target;
    const ext = fileInput.value.match(/\.(.+)$/)[1];

    switch (ext) {
      case "jpg":
      case "jpeg":
      case "png":
        UploadServiceImage(event);
        break;
      default:
        setMsgOpen(true);
        setMsgText("/jpg,/jpeg,/png file types only allowed .");
        fileInput.value = "";
    }
  };
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const deleteServicePhoto = () => {
    setMsgText("Service image has been removed");
    setMsgOpen(true);
    setImageUrl("");
  };

  const closeImage = () => {
    setEnlargeImage(false);
  };
  const openImage = () => {
    setEnlargeImage(true);
  };
  useEffect(() => {
    populateSpare();
  }, [selectedService]);

  const populateSpare = () => {
    if (Utils.IsNullOrEmptyObject(selectedService)) {
      return;
    }
    setDescription(selectedService.description);
    setServiceCode(selectedService.serviceCode);
    setserviceTime(selectedService.serviceTime);
    setServiceCode(selectedService.code);
    setName(selectedService.shortDesc);
    setActive(selectedService.active);
    setserviceType(selectedService.fineline);
    setserviceTypeDescription(selectedService.finelineDescription);
    setservicecategory(selectedService.class);
    setCategoryDescription(selectedService.classDescription);
    setAllowreservation(selectedService.availableForReservation);
    setTotalPrice(selectedService.cost);
    setcost(selectedService.cost);
    setTaxAmount(selectedService.retailPrice);
    setPriceExclusive(selectedService.price);
    setPriceExclusive(selectedService.price);
    setImageUrl(selectedService.imageUrl);
    setfrequentlyused(selectedService.default);
    setTaxPercentage(selectedService.taxPercent);
    setblockcalender(selectedService.blockTimeOnCalendar);
    setserviceTime(selectedService.serviceTime);
    setpostserviceTime(selectedService.postServiceTime);
    setCheckedItems(selectedService.staff);
    setLabour(selectedService.labour);
    populateServiceItems(selectedService.serviceItemProducts);
    getServiceStaffs(selectedService.staff);
  };
  const handleServiceItemActions = (action) => {
    if (
      action.type === serviceItemActionTypes.Clear ||
      (serviceItems.length === 1 &&
        action.type === serviceItemActionTypes.Delete)
    ) {
      setShowDialog(false);
    }
    itemDispatch(action);
  };
  const addItemToCart = (product, isCustomProduct = false) => {
    configureDialog();
    var serviceItem = isCustomProduct
      ? product
      : getServiceItemProductsFromProduct(product);
    if (Utils.IsNullOrEmptyArray(serviceItems)) {
      serviceItem.Sequence = 1;
    } else {
      serviceItem.Sequence = serviceItems[serviceItems.length - 1].Sequence + 1;
    }
    itemDispatch({
      type: serviceItemActionTypes.Add,
      value: serviceItem,
    });
  };
  const configureDialog = () => {
    if (!showDialog) {
      setShowDialog(true);
    }
  };

  return (
    <Container maxWidth="false" className="bg-color p-0">
      <Loader open={isLoading} />
      <MessagePopup
        msgOpen={msgOpen}
        msgText={msgText}
        onMsgClose={handleMsgClose}
      />
      <ConfirmPopup
        confirmOpen={confirmOpen}
        confirmText={confirmText}
        onConfirmClose={handleConfirmClose}
      />
      <ImageEnlarge
        open={enlargeImage}
        imgSrc={imageUrl}
        onClose={closeImage}
      />
      <Box>
        <Grid container m={0}>
          <SideBarMenuItems selectedTab={SideBarMenuNames.Settings} />
          <Grid xs={12} className="content-sec white-bg">
            <Grid container direction="row" className="">
              <SettingsMenuItems
                selectedMenu={BusinessHelper.GetServiceMenuName()}
              />
              <Grid flex="1" spacing={2} className="form-sec">
                <Grid container className="title-sec title-xs">
                  <Typography
                    level="h2"
                    component="h2"
                    fontWeight="600"
                    fontSize="2rem"
                    className="h2 text-center"
                  >
                    {BusinessHelper.GetServicePageTitle()}
                  </Typography>
                </Grid>

                <form
                  className="cs-form"
                  style={{ padding: "0 0 70px 0" }}
                  onSubmit={handleSubmit}
                >
                  <Stack direction="row" spacing={3}>
                    <Typography
                      level="h3"
                      component="h3"
                      fontWeight="600"
                      fontSize="2rem"
                      className="h3 align-center"
                    >
                      Upload Image
                    </Typography>
                    {Utils.IsNullOrEmpty(imageUrl) && (
                      <Stack direction="row" spacing={2}>
                        <label className="btn btn-default">
                          <AddAPhotoIcon fontSize="large" />
                          <Input
                            type="file"
                            name="myImage"
                            className="hide"
                            accept="image/png, image/jpeg, image/jpg"
                            onChange={(e) => handleServicePhoto(e)}
                          />
                        </label>
                      </Stack>
                    )}

                    <Grid>
                      {!Utils.IsNullOrEmpty(imageUrl) && (
                        <div className="card imgBox">
                          <img
                            alt="not found"
                            width={"150px"}
                            src={imageUrl}
                            onClick={openImage}
                          />
                          <br />
                          <button
                            className="p-0 mt-1 btn btn-default"
                            onClick={deleteServicePhoto}
                          >
                            <DeleteIcon />
                          </button>
                        </div>
                      )}
                    </Grid>
                  </Stack>
                  <br />
                  <Stack sx={{ minWidth: 120 }} spacing={2}>
                    <FormControl fullWidth>
                      <TextField
                        inputProps={{ style: { fontSize: 13 } }}
                        label="Name "
                        type="text"
                        value={name}
                        error={!name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </FormControl>

                    <FormControl fullWidth>
                      <TextField
                        inputProps={{ style: { fontSize: 13 } }}
                        label="Description "
                        type="text"
                        value={description}
                        error={!description}
                        onChange={(e) => setDescription(e.target.value)}
                      />
                    </FormControl>
                    <Stack direction="row" spacing={2}>
                      <FormControl fullWidth>
                        <TextField
                          maxRows={5}
                          inputProps={{
                            style: { fontSize: 13 },
                            maxLength: 5,
                          }}
                          label="Service Code "
                          type="text"
                          value={serviceCode}
                          error={!serviceCode}
                          onChange={(e) => setServiceCode(e.target.value)}
                        />
                      </FormControl>
                      <FormControl fullWidth>
                        <DecimalEntryField
                          inputProps={{ style: { fontSize: 13 } }}
                          type="text"
                          value={serviceTime}
                          label="Service Time (Min)"
                          setValue={setserviceTime}
                          maxLength={5}
                        />
                      </FormControl>
                      <FormControl fullWidth>
                        <DecimalEntryField
                          inputProps={{ style: { fontSize: 13 } }}
                          type="number"
                          value={postserviceTime}
                          label="Post Service Time (Min)"
                          setValue={setpostserviceTime}
                          maxLength={5}
                        />
                      </FormControl>
                    </Stack>
                    <br />
                    <Stack
                      direction="row"
                      spacing={5}
                      justifyContent="space-between"
                      style={{
                        border: "1px solid #cccccc",
                        "border-radius": "4px",
                      }}
                    >
                      {!BusinessHelper.IsSalon() && (
                        <FormControl fullWidth>
                          <Box>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={labour}
                                  onChange={(e) => setLabour(e.target.checked)}
                                  InputLabelProps={{ shrink: true }}
                                  name="Labour"
                                  color="primary"
                                />
                              }
                              value={labour}
                              label="Labour"
                              labelPlacement="start"
                            />
                          </Box>
                        </FormControl>
                      )}

                      <FormControl fullWidth>
                        <Box>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={frequentlyused}
                                onChange={(e) =>
                                  setfrequentlyused(e.target.checked)
                                }
                                InputLabelProps={{ shrink: true }}
                                name="frequently used"
                                color="primary"
                              />
                            }
                            value={frequentlyused}
                            label="Frequently Used"
                            labelPlacement="start"
                          />
                        </Box>
                      </FormControl>
                      <FormControl fullWidth>
                        <Box>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={active}
                                onChange={(e) => setActive(e.target.checked)}
                                InputLabelProps={{ shrink: true }}
                                name="Active"
                                color="primary"
                              />
                            }
                            label="Active"
                            fontSize="1.5rem"
                            labelPlacement="start"
                          />
                        </Box>
                      </FormControl>
                    </Stack>
                    <Stack
                      direction="row"
                      spacing={2}
                      justifyContent="space-between"
                      style={{
                        border: "1px solid #cccccc",
                        "border-radius": "4px",
                      }}
                    >
                      <FormControl fullWidth>
                        <Box>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={Allowreservation}
                                onChange={(e) =>
                                  setAllowreservation(e.target.checked)
                                }
                                InputLabelProps={{ shrink: true }}
                                name="Allow Reservation"
                                color="primary"
                              />
                            }
                            value={Allowreservation}
                            label="Allow Reservation"
                            labelPlacement="start"
                          />
                        </Box>
                      </FormControl>

                      <FormControl fullWidth>
                        <Box>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={Blockcalender}
                                onChange={(e) =>
                                  setblockcalender(e.target.checked)
                                }
                                InputLabelProps={{ shrink: true }}
                                name="Block calender"
                                color="primary"
                              />
                            }
                            value={Blockcalender}
                            label="Block Calender"
                            labelPlacement="start"
                          />
                        </Box>
                      </FormControl>
                    </Stack>

                    <Typography
                      level="h3"
                      component="h3"
                      fontWeight="600"
                      fontSize="2rem"
                      className="h3"
                    >
                      {" "}
                      Service Classification
                    </Typography>

                    <Stack
                      direction="row"
                      alignItems="baseline"
                      justifyContent="space-between"
                    >
                      <Typography
                        level="h3"
                        component="h3"
                        fontWeight="600"
                        fontSize="2rem"
                        className="h3"
                      >
                        Enter New Category
                      </Typography>
                      <FormControlLabel
                        style={{ margin: "0" }}
                        control={
                          <Switch
                            checked={isDropdown1}
                            onChange={handleSwitchChange}
                          />
                        }
                        InputLabelProps={{ shrink: true }}
                      />
                    </Stack>
                    <Stack
                      direction="row"
                      spacing={2}
                      className="justify-content-between"
                    >
                      {!isDropdown1 ? (
                        <FormControl fullWidth variant="outlined" margin="0">
                          <InputLabel id="Category-label">
                            Service Category
                          </InputLabel>
                          <Select
                            inputProps={{ style: { fontSize: 13 } }}
                            labelId="Category-label"
                            id="Category-select"
                            value={servicecategory}
                            onChange={(e) => setservicecategory(e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            label="Service Category"
                            style={{ height: "51.5px" }}
                          >
                            {!Utils.IsNullOrEmptyArray(Catlist) &&
                              Catlist.map((item) => (
                                <MenuItem value={item}>{item}</MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      ) : (
                        <TextField
                          inputProps={{ style: { fontSize: 13 } }}
                          fullWidth
                          variant="outlined"
                          margin="0"
                          label="Service Category"
                          value={servicecategory}
                          onChange={(e) => setservicecategory(e.target.value)}
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                      <FormControl fullWidth>
                        <TextField
                          inputProps={{ style: { fontSize: 13 } }}
                          fullWidth
                          variant="outlined"
                          margin="0"
                          label=" Service Category Description"
                          value={categoryDescription}
                          onChange={(e) =>
                            setCategoryDescription(e.target.value)
                          }
                        />
                      </FormControl>
                    </Stack>
                  </Stack>

                  <Stack
                    direction="row"
                    alignItems="baseline"
                    justifyContent="space-between"
                  >
                    <Typography
                      level="h3"
                      component="h3"
                      fontWeight="600"
                      fontSize="2rem"
                      className="h3"
                    >
                      Enter New Type
                    </Typography>
                    <FormControlLabel
                      style={{ margin: "0" }}
                      control={
                        <Switch
                          checked={isDropdown}
                          onChange={handleSwitchType}
                        />
                      }
                      InputLabelProps={{ shrink: true }}
                    />
                  </Stack>
                  <Stack
                    direction="row"
                    spacing={2}
                    className="justify-content-between"
                  >
                    {!isDropdown ? (
                      <FormControl fullWidth variant="outlined" margin="0">
                        <InputLabel id="Category-label">
                          Service Type
                        </InputLabel>
                        <Select
                          inputProps={{ style: { fontSize: 13 } }}
                          labelId="Servicetype-label"
                          id="Servicetype-select"
                          value={serviceType}
                          onChange={(e) => setserviceType(e.target.value)}
                          InputLabelProps={{ shrink: true }}
                          label="Service Type"
                          style={{ height: "51.5px" }}
                        >
                          {!Utils.IsNullOrEmptyArray(Typelist) &&
                            Typelist.map((item) => (
                              <MenuItem value={item}>{item}</MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    ) : (
                      <TextField
                        inputProps={{ style: { fontSize: 13 } }}
                        fullWidth
                        variant="outlined"
                        margin="0"
                        label="Service Type"
                        value={serviceType}
                        onChange={(e) => setserviceType(e.target.value)}
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                    <FormControl fullWidth variant="outlined" margin="0">
                      <TextField
                        inputProps={{ style: { fontSize: 13 } }}
                        fullWidth
                        variant="outlined"
                        margin="0"
                        label="Service Type Description"
                        value={serviceTypeDescription}
                        onChange={(e) =>
                          setserviceTypeDescription(e.target.value)
                        }
                      />
                    </FormControl>
                  </Stack>
                  <br />
                  <Stack
                    direction="row"
                    spacing={2}
                    alignItems="baseline"
                    justifyContent="space-between"
                  >
                    <Typography
                      level="h3"
                      component="h3"
                      fontWeight="600"
                      fontSize="2rem"
                      className="h3"
                    >
                      Sales Info
                    </Typography>
                    <Button
                      sx={{ justifyContent: "flex-end" }}
                      onClick={handlepriceModal}
                      variant="outlined"
                      fontSize="11px"
                      className="btn btn-secondary"
                    >
                      Inclusive of Tax
                    </Button>
                  </Stack>
                  <br />
                  <Stack direction="row" spacing={2}>
                    <FormControl fullWidth>
                      <DecimalEntryField
                        inputProps={{ style: { fontSize: 13 } }}
                        type="number"
                        value={cost}
                        setValue={setcost}
                        label="Cost"
                      />
                    </FormControl>

                    <FormControl fullWidth>
                      <DecimalEntryField
                        inputProps={{ style: { fontSize: 13 } }}
                        label=" Price Exclusive of Tax"
                        type="number"
                        setError={true}
                        value={priceExclusive}
                        setValue={setPriceExclusive}
                      />
                    </FormControl>

                    <FormControl fullWidth>
                      <DecimalEntryField
                        inputProps={{ style: { fontSize: 13 } }}
                        type="number"
                        value={taxPercentage}
                        setValue={setTaxPercentage}
                        label="Tax Percentage"
                        maxLength={5}
                      />
                    </FormControl>
                  </Stack>
                  {isProductConfig && (
                    <>
                      <br />
                      <Box>
                        <Typography
                          level="h3"
                          component="h3"
                          fontWeight="600"
                          fontSize="2rem"
                          className="h3"
                        >
                          Product Configuration
                        </Typography>
                        <br />
                        <Stack className="card">
                          <ProductConfigComponent
                            fromServiceSetup={true}
                            serviceItems={serviceItems}
                            handleItemAction={handleServiceItemActions}
                            onAddItem={addItemToCart}
                          />
                        </Stack>
                      </Box>
                    </>
                  )}
                  <Stack className="mt-2">
                    <Typography
                      level="h3"
                      component="h3"
                      fontWeight="600"
                      fontSize="2rem"
                      className="h3"
                    >
                      Service Staff
                    </Typography>

                    {!Utils.IsNullOrEmptyArray(filteredstafflist) &&
                      filteredstafflist.map((rows, index) => (
                        <div key={index}>
                          <Stack direction="row" justifyContent="space-between">
                            <Stack>
                              <FormControl>
                                <Typography
                                  level="h3"
                                  className="mt-1"
                                  component="h3"
                                  fontSize="18px"
                                  fontWeight="600"
                                >
                                  {rows.firstName}
                                </Typography>
                                <Typography
                                  level="p"
                                  className="mb-1"
                                  component="p"
                                  fontSize="13px"
                                >
                                  {GetValidId(rows.id)}
                                </Typography>
                              </FormControl>
                            </Stack>

                            <Stack
                              xs={2}
                              justifyContent="space-between"
                              alignContent="end"
                            >
                              <Checkbox
                                size="large"
                                checked={
                                  !Utils.IsNullOrEmptyObject(
                                    checkedItems.find((s) => s.id === rows.id)
                                  )
                                }
                                onChange={(e) => handleCheckboxChange(e, rows)}
                                InputLabelProps={{ shrink: true }}
                                input
                              />
                            </Stack>
                          </Stack>
                          <Divider />
                        </div>
                      ))}
                  </Stack>

                  <Modal open={PriceModalopen}>
                    <Box className="cs-modal" padding={2}>
                      <Stack direction="row" spacing={2}>
                        <FormControl xs={8} fullWidth>
                          Total Price:
                          <DecimalEntryField
                            inputProps={{ style: { fontSize: 13 } }}
                            type="number"
                            value={TotalPrice}
                            setValue={handleTotalPriceChange}
                          />
                        </FormControl>

                        <FormControl xs={8} fullWidth>
                          Tax Percentage:
                          <DecimalEntryField
                            inputProps={{ style: { fontSize: 13 } }}
                            type="number"
                            value={taxPercentage}
                            setValue={handleTaxPercentageChange}
                          />
                        </FormControl>
                      </Stack>
                      <br />
                      <Stack direction="row" spacing={2}>
                        <FormControl xs={8} fullWidth>
                          Price Excluded Tax:
                          <TextField
                            inputProps={{ style: { fontSize: 13 } }}
                            disabled
                            type="number"
                            value={priceExclusive}
                          ></TextField>
                        </FormControl>
                        <FormControl xs={8} fullWidth>
                          Tax Amount:
                          <TextField
                            inputProps={{ style: { fontSize: 13 } }}
                            disabled
                            type="number"
                            value={taxAmount}
                          ></TextField>
                        </FormControl>
                      </Stack>
                      <br />
                      <Stack
                        direction="row"
                        spacing={2}
                        justifyContent="flex-end"
                      >
                        <Button
                          className="btn btn-primary"
                          variant="contained"
                          type="close"
                          onClick={handlesubmit2}
                        >
                          Submit
                        </Button>
                        <Button
                          className="btn btn-secondary"
                          fullWidth
                          size="small"
                          type="close"
                          onClick={handlepriceModalclose}
                        >
                          Close
                        </Button>
                      </Stack>
                    </Box>
                  </Modal>
                  <br />
                  {UserHelper.CanEdit() && (
                    <Stack
                      direction="row"
                      spacing={2}
                      justifyContent="flex-end"
                      className="fixed-btn"
                    >
                      <FormControl>
                        <Button
                          className="btn btn-primary white-text"
                          fullWidth
                          variant="contained"
                          size="small"
                          color="success"
                          type="submit"
                        >
                          Save
                        </Button>
                      </FormControl>
                    </Stack>
                  )}
                </form>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Container >
  );
};

export default AddNewService;
