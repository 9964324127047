import Utils from "../core/Utils";
import { getBranch, getBusiness } from "../data/localSettingsActions";

export const generateTemporaryVisit = (
  serviceItems,
  customerId,
  visitType,
  vehicleDetails = null,
  vehicleServiceDetails = null,
  isTaxIncluded = true
) => {
  var visitDiscount = serviceItems
    .map((s) => s.DiscountTotal)
    .reduce((a, b) => a + b);
  var totalPrice = serviceItems
    .map((s) => s.TotalPrice)
    .reduce((a, b) => a + b);
  var taxAmount = serviceItems.map((s) => s.TaxAmount).reduce((a, b) => a + b);
  var paymentAmount = totalPrice - visitDiscount + taxAmount;
  var business = getBusiness();
  var branch = getBranch();
  var visit = {
    ServiceItems: serviceItems,
    Discount: visitDiscount,
    PaymentAmount: paymentAmount,
    Tax: taxAmount,
    VisitType: visitType,
    BusinessId: business.id,
    BranchId: branch.branchId,
    CreatedDT: Utils.GetUTC(),
    LastModifiedDT: Utils.GetUTC(),
    CheckOut: Utils.GetUTC(),
    InvoiceNumber: "N/A",
    IsTaxIncluded: isTaxIncluded,
    InvoiceLabelDescription: branch.invoiceLabels[0].description,
    CustomerId: customerId,
    VehicleDetails: { VehicleType: "None" },
    VehicleServiceDetails: { NextServiceInDays: 60 },
  };
  if (!Utils.IsNullOrEmptyObject(vehicleDetails)) {
    visit.VehicleDetails = vehicleDetails;
  }
  if (!Utils.IsNullOrEmptyObject(vehicleServiceDetails)) {
    visit.VehicleServiceDetails = vehicleServiceDetails;
  }
  return visit;
};
