import React, { useState, useEffect } from "react";
import "../Style.css";
import "../bootstrap.min.css";
import Switch from "@mui/material/Switch";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { useNavigate, Link as RouteLink } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import SearchSharpIcon from "@mui/icons-material/SearchSharp";
import LoopSharpIcon from "@mui/icons-material/LoopSharp";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useDispatch } from "react-redux";
import Loader from "./common/Loader";
import BusinessHelper from "../core/BusinessHelper";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
// Images
import Utils from "../core/Utils";
import CloseIcon from "@mui/icons-material/Close";
import {
  DataGrid,
  GridToolbarQuickFilter,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import { connectToStore } from "../data/store";
import { postAsync } from "../core/serviceClient";
import UserHelper from "../core/UserHelper";
import MessagePopup from "./common/MessagePopUp";
import SideBarMenuItems from "./SideBarMenuItems";
import SettingsMenuItems from "./SettingsMenuItems";
import { SettingsMenuList } from "../core/Constants";
import { SideBarMenuNames } from "../core/Constants";
import { ConfirmTypes } from "../core/Constants";
import ConfirmPopup from "./common/ConfirmPopup";
import IconButton from "@mui/material/IconButton";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  LoginPage,
  AddNewServicePage,
  ServicesPage,
} from "../core/PageConstants";
import { Tooltip } from '@mui/material'

const Services = (props) => {
  document.title = BusinessHelper.GetServicePageTitle();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [localSettings, setLocalSettings] = useState({});
  const [servicecategory, setservicecategory] = useState("");
  const [serviceType, setserviceType] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [ServiceList, setServiceList] = useState([]);
  const [selectedService, setSelectedService] = useState({});
  const [showFilters, setShowFilters] = useState(false);
  const [originalList, setoriginalList] = useState([]);
  const [typeList, setTypeList] = useState([]);
  const [catList, setcatList] = useState([]);
  const [msgOpen, setMsgOpen] = useState(false);
  const [msgText, setMsgText] = useState("");
  const [isNavigateToLogin, setIsNavigateToLogin] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmText, setConfrimText] = useState("");
  const columns = [
    {
      field: "shortDesc",
      headerName: "Name",
      headerClassName: "headerStyle",
      flex: 3,
      renderCell: (params) =>  (
        <Tooltip title={params.row.shortDesc} >
          <span className="text-ellipsis">{params.row.shortDesc}</span>
        </Tooltip>
      ),
      minWidth: 200
    },

    {
      field: "description",
      headerName: "Description",
      headerClassName: "headerStyle",
      flex: 3,
      renderCell: (params) =>  (
        <Tooltip title={params.row.description} >
          <span className="text-ellipsis">{params.row.description}</span>
        </Tooltip>
      ),
      minWidth: 200
    },
    {
      field: "price",
      valueGetter: (params) => {
        return params.row.price
      },
      renderCell: (params) => {
        return Utils.GetCurrency(params.row.price);
      },
      headerName: "Price",
      headerClassName: "headerStyle",
      headerAlign: 'right',
      align: "right",
      flex: 1,
    },
    {
      field: "taxPercentage",
      valueGetter: (params) => {
        return params.row.taxPercent;
      },
      renderCell: (params) => {
        return params.row.taxPercent + "%";
      },
      headerName: "Tax",
      headerClassName: "headerStyle",
      headerAlign: 'right',
      align: "right",
      flex: 1,
      minWidth: 40,
    },
    {
      field: "active",
      headerName: "Active",
      headerClassName: "headerStyle",
      flex: 1,
      type: "boolean",
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      headerClassName: "headerStyle",
      width: 100,
      cellClassName: "actions",
      getActions: ({ row }) => {
        return [
          UserHelper.CanEdit() ? (
            <>
              <GridActionsCellItem
                icon={<EditRoundedIcon className="icon20" />}
                label="Edit"
                className="textPrimary"
                onClick={() => handleEditService(row)}
                color="inherit"
              />

              <GridActionsCellItem
                icon={<DeleteOutlineIcon className="icon20" />}
                label="Delete"
                onClick={() => deleteService(row)}
                color="error"
              />
            </>
          ) : (
            <GridActionsCellItem
              icon={<VisibilityIcon className="icon20" />}
              label="View"
              onClick={() => handleEditService(row)}
              color="inherit"
            />
          ),
        ];
      },
    },
  ];
  const handleEmpty = () => {
    setservicecategory("");
  };
  const handleEmptyType = () => {
    setserviceType("");
  };
  const handleMsgClose = () => {
    setMsgOpen(false);
    if (isNavigateToLogin) {
      UserHelper.LogOut(dispatch);
      navigate(LoginPage.Path);
    }
  };
  const handleConfirmClose = (value) => {
    setConfirmOpen(false);
    if (value == ConfirmTypes.OK) {
      if (!Utils.IsNullOrEmptyObject(selectedService)) {
        UserFormDelete(selectedService);
      }
    }
  };
  const handleKeyUp = (event) => {
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      handleSearch();
    }
  };

  const handleRefresh = () => {
    setSearchInput("");
    getService();
  };

  useEffect(() => {
    let localSetting = connectToStore();
    if (UserHelper.CheckPermission(ServicesPage, navigate, localSetting)) {
      setLocalSettings(localSetting);
    }
  }, []);

  useEffect(() => {
    getService();
  }, [localSettings]);

  const handleSearch = () => {
    const filtered = originalList.filter(
      (item) =>
        item.shortDesc.toLowerCase().includes(searchInput.toLowerCase()) ||
        item.description.toLowerCase().includes(searchInput.toLowerCase()) ||
        item.code.toLowerCase().includes(searchInput.toLowerCase())
    );
    setServiceList(filtered);
  };
  const handleFilter = () => {
    setIsLoading(true);
    let filteredone = originalList.filter((item) => {
      if (serviceType && servicecategory) {
        return item.fineline == serviceType && item.class == servicecategory;
      } else if (servicecategory) {
        return item.class == servicecategory;
      } else if (serviceType) {
        return item.fineline == serviceType;
      }
    });
    setServiceList(filteredone);
    setIsLoading(false);
  };

  const getService = async () => {
    if (localSettings.authenticated) {
      setIsLoading(true);
      let result = await postAsync(
        "Product/GetProduct",
        {},
        "",
        "GetAll",
        "GetAllProductsByBusiness",
        [localSettings.business.id]
      );
      setIsNavigateToLogin(result.isNavigateToLogin);
      if (result.error) {
        setMsgOpen(true);
        setMsgText(result.errorMessage);
      } else {
        if (!Utils.IsNullOrEmptyArray(result.data)) {
          var Service = result.data.filter(
            (item) => item.isMaterial == false && item.service == true
          );

          setServiceList(Service);
          setoriginalList(Service);
          const temTypellist = [];
          const temCatllist = [];
          Service.forEach((element) => {
            if (
              !Utils.IsNullOrEmpty(element.fineline) &&
              !temTypellist.includes(element.fineline)
            ) {
              temTypellist.push(element.fineline);
            }
            if (
              !Utils.IsNullOrEmpty(element.class) &&
              !temCatllist.includes(element.class)
            ) {
              temCatllist.push(element.class);
            }
          });
          setTypeList(temTypellist);
          setcatList(temCatllist);
        }
      }
      setIsLoading(false);
    }
  };
  const deleteService = (Service) => {
    setSelectedService(Service);
    setConfrimText(
      "Are you sure you want to delete the " + Service.shortDesc + "?"
    );
    setConfirmOpen(true);
  };
  async function UserFormDelete(Service) {
    setIsLoading(true);
    let result = await postAsync(
      "Product/DeleteProduct",
      {
        Id: Service.id,
        product: Service,
        BusinessId: localSettings.business.id,
      },
      "",
      "Delete",
      "",
      []
    );
    if (result && result.data) {
      setMsgText("Deleted sucessfully");
      setMsgOpen(true);
      setSelectedService({});
      getService();
    } else {
      setMsgText("Unable to process your request");
      setMsgOpen(true);
    }
    setIsLoading(false);
  }

  const handleChange = (event) => {
    setShowFilters(event.target.checked);
  };

  const handleEditService = (rows) => {
    navigate(AddNewServicePage.Path, {
      state: {
        selectedService: rows,
      },
    });
  };

  return (
    <Container maxWidth="false" className="bg-color p-0">
      <Loader open={isLoading} />
      <MessagePopup
        msgOpen={msgOpen}
        msgText={msgText}
        onMsgClose={handleMsgClose}
      />
      <ConfirmPopup
        confirmOpen={confirmOpen}
        confirmText={confirmText}
        onConfirmClose={handleConfirmClose}
      />

      <Box>
        <Grid container m={0}>
          <SideBarMenuItems selectedTab={SideBarMenuNames.Settings} />
          <Grid xs={12} className="content-sec">
            <Grid container direction="row" className="">
              <SettingsMenuItems
                selectedMenu={BusinessHelper.GetServiceMenuName()}
              />
              <Grid flex="1" spacing={2} className="form-sec">
                <Grid container className="title-sec title-xs">
                  <Typography
                    level="h2"
                    component="h2"
                    fontWeight="600"
                    fontSize="2rem"
                    className="page-title text-center"
                  >
                    {BusinessHelper.GetServicePageTitle()}
                  </Typography>
                  <Stack
                    paddingRight="15px"
                    alignSelf="center"
                    justifyContent="end"
                    direction="row"
                    flex="1"
                  >
                    <IconButton aria-label="Refresh" onClick={handleRefresh}>
                      <RefreshIcon sx={{ fontSize: 25 }} />
                    </IconButton>
                    <Box alignSelf="center" className="ml-1">
                      <Typography
                        align="auto"
                        fontSize="1.2rem"
                        style={{ "white-space": "nowrap" }}
                      >
                        Filter
                        <Switch checked={showFilters} onChange={handleChange} />
                      </Typography>
                    </Box>
                  </Stack>
                  <Grid paddingRight="15px" alignSelf="center">
                    {UserHelper.CanAdd() && (
                      <RouteLink to={AddNewServicePage.Path}>
                        <Button
                          variant="contained"
                          className="btn btn-secondary"
                        >
                          <AddCircleIcon
                            fontSize="large"
                          />
                          <span className="hidden-xs ml-h">Add New</span>
                        </Button>
                      </RouteLink>
                    )}
                  </Grid>
                </Grid>
                {showFilters && (
                  <Stack sx={{ minWidth: 120 }} className="card empty-filter-sec" padding={1}>
                    <Stack direction="row" spacing={2}>
                      <FormControl fullWidth>
                        <Stack direction="row" justifyContent="space-between">
                          <label>Category</label>
                          <CloseIcon fontSize="large" onClick={handleEmpty} />
                        </Stack>
                        <Select
                          label=" service Category"
                          variant="outlined"
                          value={servicecategory}
                          onChange={(e) => setservicecategory(e.target.value)}
                        >
                          {!Utils.IsNullOrEmptyArray(catList) &&
                            catList.map((item) => (
                              <MenuItem value={item}>{item}</MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                      <FormControl fullWidth>
                        <Stack direction="row" justifyContent="space-between">
                          <label>Type</label>
                          <CloseIcon
                            fontSize="large"
                            onClick={handleEmptyType}
                          />
                        </Stack>
                        <Select
                          label="service Type"
                          variant="outlined"
                          value={serviceType}
                          onChange={(e) => setserviceType(e.target.value)}
                        >
                          {!Utils.IsNullOrEmptyArray(typeList) &&
                            typeList.map((item) => (
                              <MenuItem value={item}>{item}</MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                      <Stack style={{ paddingTop: "2.6rem" }}>
                        <Button
                          variant="contained"
                          className="btn btn-primary"
                          onClick={handleFilter}
                          style={{
                            maxWidth: "40px",
                            maxHeight: "100px",
                          }}
                        >
                          Filter
                        </Button>
                      </Stack>
                    </Stack>
                  </Stack>
                )}
                <Grid item xs={12} className="font-size-14 data-grid-box">
                  <Box
                    sx={{
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    <DataGrid
                      getRowId={(row) => row.id}
                      rows={ServiceList}
                      columns={columns}
                      disableColumnFilter={true}
                      disableColumnMenu={true}
                      rowSelection={false}
                      sx={{
                        boxShadow: 1,
                        border: 1,
                        borderColor: "primary.light",
                        "& .MuiDataGrid-cell:hover": {
                          color: "primary.main",
                        },
                      }}
                      slots={{ toolbar: CustomToolbar }}
                      slotProps={{
                        toolbar: {
                          showQuickFilter: true,
                          showFilters: false,
                        },
                      }}
                      className="data-grid"
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarQuickFilter className="searchbar" />
    </GridToolbarContainer>
  );
}

export default Services;
