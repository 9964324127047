import moment from "moment";
import validator from "validator";
import { getCountry } from "../data/localSettingsActions";
import CountryHelper from "../core/CountryHelper";
import { DefaultDate, UAE, VisitStatuses } from "./Constants";
import { Regexes } from "./Regexes";

class Utils {
  static IsNullOrEmpty(data) {
    if (!data) {
      return true;
    }
    return false;
  }

  static IsNullOrEmptyObject(data) {
    if (this.IsNullOrEmpty(data) || Object.keys(data).length === 0) {
      return true;
    }
    return false;
  }

  static IsNullOrEmptyArray(data) {
    if (this.IsNullOrEmpty(data) || data.length === 0) {
      return true;
    }
    return false;
  }

  static GetCurrentDate() {
    return moment();
  }

  static GetInputDateFormat(value) {
    return value.format("YYYY/MM/DD");
  }

  static ConvertToInteger(value) {
    if (!this.IsNullOrEmpty(value)) {
      return parseInt(value);
    }
    return 0;
  }

  static ConvertToFloat(value) {
    if (!this.IsNullOrEmpty(value)) {
      return parseFloat(value);
    }
    return 0;
  }

  static CreateGuid() {
    function _p8(s) {
      var p = (Math.random().toString(16) + "000000000").substr(2, 8);
      return s ? "-" + p.substr(0, 4) + "-" + p.substr(4, 4) : p;
    }
    return _p8() + _p8(true) + _p8(true) + _p8();
  }

  static CreateMomentFromString(value) {
    return moment(value);
  }

  static ConvertToUpperCase(value) {
    if (this.IsNullOrEmpty(value)) {
      return "";
    }
    return value.toUpperCase();
  }

  static ConvertToLowerCase(value) {
    if (this.IsNullOrEmpty(value)) {
      return "";
    }
    return value.toLowerCase();
  }

  static GetLastArrayElement(arrayValue) {
    if (this.IsNullOrEmptyArray(arrayValue)) {
      return "";
    }
    return arrayValue[arrayValue.length - 1];
  }

  static ConvertToString(value) {
    if (this.IsNullOrEmpty(value)) {
      return "";
    }
    return value.toString();
  }

  static ConvertToDate(value) {
    if (this.IsNullOrEmpty(value)) {
      return "";
    }
    return moment(value);
  }

  static ConvertToUTCDate(value) {
    if (this.IsNullOrEmpty(value) || this.IsNullOrEmptyObject(value)) {
      return "";
    }
    return moment(value).utc();
  }

  static GetGroupBy(xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }

  static GetGroupByArray(xs, key) {
    return xs.reduce(function (rv, x) {
      let v = key instanceof Function ? key(x) : x[key];
      let el = rv.find((r) => r && r.key === v);
      if (el) {
        el.values.push(x);
      } else {
        rv.push({ key: v, values: [x] });
      }
      return rv;
    }, []);
  }

  static GetServiceUrl() {
    // //Production
    return "https://infopoint.com/Services/";
    //Staging
    // return "https://www.xuvi.com/Infopoint/";
    // //Local
    //return "http://localhost:12750/";
  }

  static ConvertToLocalDT(value, country) {
    var dt = moment(value);
    return this.GetDate(dt.format(), country);
  }

  static GetDateFromUTC() {
    let country = getCountry();
    moment.locale(CountryHelper.GetDateCulture(country.Culture));
    return moment
      .utc()
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .format();
  }

  static GetCurrentDateFromLocal() {
    let country = getCountry();
    moment.locale(CountryHelper.GetDateCulture(country.Culture));
    return moment()
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .format();
  }

  static GetDateFromMoment(value) {
    return value
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .format();
  }

  static GetUTC() {
    let country = getCountry();
    moment.locale(CountryHelper.GetDateCulture(country.Culture));
    return moment.utc().format();
  }

  static GetStartDate() {
    let country = getCountry();
    moment.locale(CountryHelper.GetDateCulture(country.Culture));
    return moment()
      .startOf("month")
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  }

  static GetEndDate() {
    let country = getCountry();
    moment.locale(CountryHelper.GetDateCulture(country.Culture));
    return moment().set({ hour: 23, minute: 59, second: 59, millisecond: 999 });
  }

  static GetCurrency(value, country, maxFractionDigits = 2) {
    var tempCountry = country;
    if (this.IsNullOrEmptyObject(tempCountry)) {
      tempCountry = getCountry();
    }
    if (!this.IsNullOrEmptyObject(tempCountry))
      return new Intl.NumberFormat(tempCountry.Culture, {
        style: "currency",
        currency: tempCountry.Currency,
        maximumFractionDigits: maxFractionDigits,
      }).format(value);
  }

  static GetDate(value, country) {
    var tempCountry = country;
    if (this.IsNullOrEmptyObject(tempCountry)) {
      tempCountry = getCountry();
    }
    if (!this.IsNullOrEmptyObject(tempCountry))
      return new Intl.DateTimeFormat(
        CountryHelper.GetDateCulture(tempCountry.Culture),
        {
          day: "numeric",
          month: "short",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        }
      ).format(moment(value));
  }

  static RemoveLastElementFromArray(arr) {
    if (this.IsNullOrEmptyArray(arr)) {
      return [];
    }
    if (arr.length > 1) {
      arr.pop();
    }
    return arr;
  }

  static AddElementToArray(arr, value) {
    if (this.IsNullOrEmptyArray(arr)) {
      return [value];
    }
    arr.push(value);
    return arr;
  }

  static GetNavigationArray(arr) {
    if (this.IsNullOrEmptyArray(arr)) {
      return [];
    }
    return arr;
  }

  static GetSum(total, num) {
    return total + num;
  }

  static NumberSafety(value) {
    if (!this.IsNullOrEmpty(value)) {
      return value;
    }
    return 0;
  }

  static RemoveDuplicates(arr) {
    if (this.IsNullOrEmptyArray(arr)) {
      return [];
    }
    return arr.filter((value, index) => arr.indexOf(value) === index);
  }

  static Distinct(value, index, array) {
    return array.indexOf(value) === index;
  }

  static GetISODateTimeString(value) {
    return value.format("YYYY-MM-DDTHH:mm:ss");
  }

  static GetTimeOnly(value) {
    return value.format("HH:mm:ss");
  }

  static IsEmailValid(email) {
    if (!this.IsNullOrEmpty(email) && validator.isEmail(email)) return true;
    else return false;
  }
  static IsMobileValid(mobile) {
    var country = getCountry();
    var defaultLength = CountryHelper.GetMobileNumberMaxLength();
    var regexToValidate =
      country.Code === UAE.Code ? Regexes.UAEMobile : Regexes.OnlyNumeric;
    var isValid = !this.IsNullOrEmpty(mobile) && regexToValidate.test(mobile);
    if (!isValid) {
      return false;
    }
    switch (country.Code) {
      case UAE.Code:
        isValid =
          isValid === true &&
          (mobile.length === defaultLength || mobile.length === 9);
        break;
      default:
        isValid = isValid === true && mobile.length === defaultLength;
        break;
    }
    return isValid;
  }
  static IsURLValid(url) {
    if (!this.IsNullOrEmpty(url) && validator.isURL(url)) return true;
    else return false;
  }
  static IsTaxIdValid(taxid, locale) {
    if (!this.IsNullOrEmpty(taxid) && validator.isTaxID(taxid, locale))
      return true;
    else return false;
  }

  static FormatString(originalString, parameters) {
    if (this.IsNullOrEmpty(originalString)) {
      return "";
    }
    if (this.IsNullOrEmptyArray(parameters) || parameters.length === 0) {
      return originalString;
    }
    if (parameters.length > 1) {
      for (var i = 0; i <= parameters.length - 1; i++) {
        originalString = originalString.replace("{" + i + "}", parameters[i]);
      }
      return originalString;
    } else {
      return originalString.replace("{0}", parameters[0]);
    }
  }

  static MaskMobileNumber(number) {
    if (this.IsNullOrEmpty(number)) {
      return "";
    }
    return "********" + number.substring(number.Length - 2, 2);
  }

  static IsDefaultDate(value) {
    if (!this.IsNullOrEmpty(value) && value === DefaultDate) return true;
    else return false;
  }
  static GetIndex(value, arr, prop) {
    for (var i = 0; i < arr.length; i++) {
      if (arr[i][prop] === value) {
        return i;
      }
    }
    return -1; //to handle the case where the value doesn't exist
  }

  static GetDefaultDate() {
    return this.ConvertToDate("01/01/1001");
  }

  static GetStatusText(status) {
    let statusText = "";
    switch (status) {
      case VisitStatuses.Completed:
        statusText = "Completed & Payment Pending";
        break;
      case VisitStatuses.PaymentRefunded:
        statusText = "Payment Refunded";
        break;
      case VisitStatuses.ReservationCanceled:
        statusText = "Reservation Canceled";
        break;
      case VisitStatuses.WorkInProgress:
        statusText = "Work In Progress";
        break;
      case VisitStatuses.PartiallyPaid:
        statusText = "Partially Paid";
        break;
      default:
        statusText = status;
        break;
    }
    return statusText;
  }

  static GetStringFromBase64(base64) {
    if (this.IsNullOrEmpty(base64)) {
      return "";
    }
    if (!base64.includes(",")) {
      return base64;
    }
    return base64.split(",")[1];
  }

  static GetLastBookingAllowedMins() {
    return 30;
  }
}

export default Utils;
