import React, { useEffect, useState } from "react";
import "../Style.css";
import "../bootstrap.min.css";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useLocation, useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import Utils from "../core/Utils";
import FormControl from "@mui/material/FormControl";
import { getServiceItemAMCPlan, getBackPaymentPopup } from "../helpers/productHelper";
import Loader from "./common/Loader";
import Modal from "@mui/material/Modal";
import SideBarMenuItems from "./SideBarMenuItems";
import { AMCPlanStatus, SideBarMenuNames } from "../core/Constants";
import { useDispatch } from "react-redux";
import UserHelper from "../core/UserHelper";
import MessagePopup from "./common/MessagePopUp";
import { postAsync } from "../core/serviceClient";
import { getBusiness, getBranch } from "../data/localSettingsActions";
import CloseIcon from "@mui/icons-material/Close";
import {
    DataGrid,
    GridToolbarQuickFilter,
    GridToolbarContainer,
} from "@mui/x-data-grid";
import { LoginPage, AMCPlanPage, PaymentPage, } from "../core/PageConstants";
import "../DateRangePickerWithYear.css";
import "react-calendar/dist/Calendar.css";


const AMCPlan = (props) => {
    document.title = "AMC PLan";
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [msgOpen, setMsgOpen] = useState(false);
    const [msgText, setMsgText] = useState("");
    const [isNavigateToLogin, setIsNavigateToLogin] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [AMCPLanList, setAMCPLanList] = useState([]);
    const [vehicleDetailsList, setVehicleDetailsList] = useState([]);
    const [customerList, setCustomerList] = useState([]);
    const [selectedVehicleList, setSelectedVehicleList] = useState([]);
    const [customerModalPopup, setCustomerModalPopup] = useState(false);
    const [vehicleModelPopup, setVehicleModalPopup] = useState(false);
    const [paymentModelPopup, setPaymentModelPopup] = useState(false);
    const [paymentTotal, setPaymentTotal] = useState(0);
    const [paymentTax, setPaymentTax] = useState(0);
    const [selectedAMCPlan, setSelectedAMCPlan] = useState("");
    const [selectedCustomer, setSelectedCustomer] = useState("");
    const [selectedVehicleDetails, setSelectedVehicleDetails] = useState("");
    const location = useLocation();
    const [searchText, setSearchText] = useState(
        !Utils.IsNullOrEmptyObject(location.state) &&
            !Utils.IsNullOrEmpty(location.state.searchText)
            ? location.state.searchText
            : ""
    );

    useEffect(() => {
        if (UserHelper.CheckPermission(AMCPlanPage, navigate)) {
            fetchAMCPlansDetails();
            if (!Utils.IsNullOrEmptyObject(location.state) && !Utils.IsNullOrEmpty(location.state.serviceItems) && !Utils.IsNullOrEmpty(location.state.pass) && !Utils.IsNullOrEmptyObject(location.state.vehicleDetails)) {
                var AMCPLanDetails = location.state.serviceItems;
                var customerPassDetails = location.state.pass;
                var vehicleDetail = location.state.vehicleDetails;
                setSelectedCustomer(customerPassDetails)
                setSelectedVehicleDetails(vehicleDetail)
                var item = getBackPaymentPopup(AMCPLanDetails);
                setSelectedAMCPlan(item)
                setSelectedAMCPlan(item)
                setPaymentTax(item.paymentTax)
                setPaymentTotal(item.paymentTotal)

                setPaymentModelPopup(true);

            }
            if (!Utils.IsNullOrEmpty(searchText)) fetchCustomerSearchList();
        }
    }, []);

    const handleMsgClose = () => {
        setMsgOpen(false);
        if (isNavigateToLogin) {
            UserHelper.LogOut(dispatch);
            navigate(LoginPage.Path);
        }
    };
    const paymentProcess = () => {
        var item = getServiceItemAMCPlan(selectedAMCPlan, paymentTotal, paymentTax);
        navigate(PaymentPage.Path, {
            state: {
              onSuccess: AMCPlanPage.Path,
              navigationStack:[AMCPlanPage.Path],
              isEdit: false,
              fromVisit: false,
              fromAMCPlan: true,
              visit: null,
              checkout: null,
              serviceItems: [item],
              pass: selectedCustomer,
              vehicleDetails: selectedVehicleDetails,
              validVehiclesCount: 0,
              searchText: "",
            },
          });
    }

    async function fetchAMCPlansDetails() {
        setIsLoading(true);
        var business = getBusiness();
        let result = await postAsync(
            "AMC/GetList",
            business.id,
        );
        setIsNavigateToLogin(result.isNavigateToLogin);
        if (result.error) {
            setMsgOpen(true);
            setMsgText(result.errorMessage);
        }
        else if (!Utils.IsNullOrEmptyArray(result.data)) {
            getAMCPlans(result.data);
        }
        setIsLoading(false);
    }
    // customer list
    async function fetchCustomerSearchList() {
        if (Utils.IsNullOrEmpty(searchText) == true) return;
        setIsLoading(true);
        const business = getBusiness();
        let response = await postAsync("Customer/Search", {
            BusinessId: business.id,
            SearchText: searchText,
            IsAutomobileBusiness: business.category == "Automobile",
        });
        setIsLoading(false);
        setIsNavigateToLogin(response.isNavigateToLogin);
        if (response.error) {
            setMsgOpen(true);
            setMsgText(response.errorMessage);
        }
        if (!Utils.IsNullOrEmptyArray(response.data.passList)) {
            getCustomerList(
                response.data.passList.sort((a, b) =>
                    a.userName > b.userName ? 1 : -1
                )
            );
        } else {
            getCustomerList([]);
        }
    }
    // Refresh AMC plan
    const refreshVisits = () => {
        fetchAMCPlansDetails();
    };
    const handleSubmit = (e) => {
        fetchCustomerSearchList();
    };

    const onClickAMCPlan = (event) => {
        var plan = event.row;
        var taxAmount = plan.price * 1 * (plan.taxPercent / 100);
        var total = taxAmount + plan.price
        setPaymentTax(taxAmount);
        setPaymentTotal(total);
        setCustomerModalPopup(true);
        setSelectedAMCPlan(plan);

    }
    var onClickCustomer = (event) => {
        var customer = event.row
        setSelectedCustomer(customer);
        setMsgText("Add vehicle information before checkin");
        if (Utils.IsNullOrEmptyArray(customer.vehicleDetails)) {
            //Error Message
            setMsgOpen(true);
            return;
        }
        if (customer.vehicleDetails.length === 1) {
            setSelectedVehicleDetails(customer.vehicleDetails[0]);
            //Payment
            setPaymentModelPopup(true);
            setCustomerModalPopup(false);
        }
        else {
            setSelectedVehicleList(customer.vehicleDetails);
            setVehicleModalPopup(true);

        }
        getVehicleList(customer.vehicleDetails);
    }

    const onClickVehicleDetails = (event) => {
        setPaymentModelPopup(true);
        setSelectedVehicleDetails(event.row)
        setVehicleModalPopup(false);
        setCustomerModalPopup(false);

    }
    const AMCPlanColumns = [
        {
            field: "name",
            headerName: "AMCplan Name",
            headerClassName: "headerStyle",
            flex: 1,
        },
        {
            field: "benefits",
            headerName: "Benefits",
            headerClassName: "headerStyle",
            flex: 1, renderCell: (params) => (
                <span>{params.row.benefits ? params.row.benefits.length : 0}</span>
            ),
        },
        {
            field: "price",
            headerName: "Cost",
            headerClassName: "headerStyle",
            flex: 1,
        },

    ];
    async function getAMCPlans(data) {
        var selectBranch = getBranch();
        var result = data.filter((s) => s.active === true && s.status === AMCPlanStatus.Approved && s.applicableBranches.includes(selectBranch.branchId));
        setAMCPLanList(result);
    }
    // Customer List
    const customerListColumns = [
        {
            field: "userName",
            headerName: " Name",
            headerClassName: "headerStyle",
            flex: 1,
        },
        {
            field: "mobileNumber",
            headerName: "Contact",
            headerClassName: "headerStyle",
            flex: 1,
        },
        {
            field: "profit",
            headerName: "Profit",
            headerClassName: "headerStyle",
            flex: 1,
        },
        {
            field: "revenue",
            headerName: "Revenue",
            headerClassName: "headerStyle",
            flex: 1,
        },
        {
            field: "visits",
            headerName: "Visits",
            headerClassName: "headerStyle",
            flex: 1,
        },
        {
            field: "points",
            headerName: "Points",
            headerClassName: "headerStyle",
            flex: 1,
        },

    ];
    async function getCustomerList(passList) {
        setCustomerList(passList);
    }
    // vehicle list
    const vehicleListColumns = [
        {
            field: "number",
            headerName: "Vehicle",
            headerClassName: "headerStyle",
            flex: 1,
        },
        {
            field: "make",
            headerName: "Make",
            headerClassName: "headerStyle",
            flex: 1,
        },
        {
            field: "model",
            headerName: "Model",
            headerClassName: "headerStyle",
            flex: 1,
        },
        {
            field: "fuelType",
            headerName: "Fuel",
            headerClassName: "headerStyle",
            flex: 1,
        },
        {
            field: "chassisNumber",
            headerName: "Chassis No",
            headerClassName: "headerStyle",
            flex: 1,
        },
        {
            field: "engineNumber",
            headerName: "Engine No",
            headerClassName: "headerStyle",
            flex: 1,
        },

    ];
    async function getVehicleList(selectedVehicleList) {
        setVehicleDetailsList(selectedVehicleList);
    }

    var pageTitle = "AMC Plan";

    return (
        <>
            <Container maxWidth="false" className="bg-color p-0">
                <Loader open={isLoading} />
                <MessagePopup
                    msgOpen={msgOpen}
                    msgText={msgText}
                    onMsgClose={handleMsgClose}
                />
                <Box>
                    <Grid container m={0}>
                        <SideBarMenuItems selectedTab={SideBarMenuNames.AMCPlan} />
                        <Grid xs={12} className="content-sec c-sec2">
                            <Grid container direction="row" className="">
                                <Grid
                                    flex="1"
                                    spacing={2}
                                    padding="20px"
                                    className="form-sec xs-pd-0"
                                >
                                    <Grid container className="title-sec">
                                        <Typography
                                            level="h2"
                                            component="h2"
                                            fontWeight="600"
                                            fontSize="2rem"
                                            className="page-title text-center"
                                        >
                                            {pageTitle}
                                        </Typography>
                                        <Grid paddingRight="15px" alignSelf="center">
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="large"
                                                className="btn btn-secondary refresh-icon"
                                                fullWidth="true"
                                                onClick={refreshVisits}
                                            >
                                                <RefreshIcon sx={{ fontSize: 24 }} />
                                                <span className="btn-text ml-h">Refresh</span>
                                            </Button>
                                        </Grid>
                                    </Grid>

                                    {/* AMC plan table */}

                                    <Grid container xs={12} className="font-size-14 data-grid-box">
                                        <Box
                                            sx={{
                                                height: "100%",
                                                width: "100%",
                                            }}
                                        >
                                            <DataGrid
                                                getRowId={(row) => row.id}
                                                rows={AMCPLanList}
                                                onCellClick={onClickAMCPlan}
                                                columns={AMCPlanColumns}
                                                initialState={{
                                                    pagination: {
                                                        paginationModel: {
                                                            pageSize: 10,
                                                        },
                                                    },
                                                }}
                                                pageSizeOptions={[10]}
                                                className="data-grid font-size-14"
                                                slots={{ toolbar: CustomToolbar }}
                                            />


                                        </Box>
                                    </Grid>

                                    {/* customer table */}
                                    <Modal open={customerModalPopup}>
                                        <Box className="css-modal" padding={2}>
                                            <Stack direction="row" spacing={2}>
                                                <FormControl xs={8} fullWidth>
                                                    <h4 style={{ fontWeight: "bold" }}>Select Customer</h4>
                                                    <IconButton
                                                        aria-label="close"
                                                        onClick={() => setCustomerModalPopup(false)}
                                                        sx={{
                                                            position: "absolute",
                                                            right: 8,
                                                            top: 8,
                                                            color: (theme) => theme.palette.grey[500],
                                                        }}
                                                    >
                                                        <CloseIcon />
                                                    </IconButton>
                                                    <Stack
                                                        direction="row"
                                                        alignSelf="center"
                                                        spacing={1}
                                                        style={{ width: "100%" }}
                                                    >
                                                        <Grid flex="1">
                                                            <TextField
                                                                className="form-control font-size-14"
                                                                placeholder="Search customer..."
                                                                value={searchText}
                                                                onChange={(e) => setSearchText(e.target.value)}
                                                                style={{
                                                                    "min-width": "300px",
                                                                    "marginBottom": "15px",
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid>
                                                            <Button
                                                                type="submit"
                                                                variant="contained"
                                                                color="primary"
                                                                size="large"
                                                                onClick={handleSubmit}
                                                                className="submitBtn cs-btn"
                                                                fullWidth="true"
                                                                style={{ "height": "52px" }}
                                                            >
                                                                <SearchIcon sx={{ fontSize: 24 }} />
                                                                <span>Search</span>
                                                            </Button>
                                                        </Grid>
                                                    </Stack>
                                                    <Box
                                                        sx={{
                                                            height: "57%",
                                                        }}
                                                    >
                                                        <DataGrid
                                                            getRowId={(row) => row.id}
                                                            rows={customerList}
                                                            onCellClick={(e) => onClickCustomer(e)}
                                                            columns={customerListColumns}
                                                            initialState={{
                                                                pagination: {
                                                                    paginationModel: {
                                                                        pageSize: 10,
                                                                    },
                                                                },
                                                            }}
                                                            pageSizeOptions={[10]}
                                                            className="data-grid font-size-14"
                                                        />
                                                    </Box>
                                                </FormControl>
                                            </Stack>
                                        </Box>
                                    </Modal>
                                    <Modal open={vehicleModelPopup}>
                                        <Box className="css-modal " padding={2}>
                                            <Stack direction="row" spacing={2}>
                                                <FormControl xs={8} fullWidth>
                                                    <h4 style={{ fontWeight: "600" }}>Select Vehicle</h4>
                                                    <IconButton
                                                        aria-label="close"
                                                        onClick={() => setVehicleModalPopup(false)}
                                                        sx={{
                                                            position: "absolute",
                                                            right: 8,
                                                            top: 8,
                                                            color: (theme) => theme.palette.grey[500],
                                                        }}
                                                    >
                                                        <CloseIcon />
                                                    </IconButton>
                                                    <Box sx={{
                                                        height: "67%",
                                                    }}>
                                                        <DataGrid
                                                            getRowId={(row) => row.id}
                                                            rows={vehicleDetailsList}
                                                            onCellClick={(e) => onClickVehicleDetails(e)}
                                                            columns={vehicleListColumns}
                                                            initialState={{
                                                                pagination: {
                                                                    paginationModel: {
                                                                        pageSize: 10,
                                                                    },
                                                                },
                                                            }}
                                                            pageSizeOptions={[10]}
                                                            className="data-grid font-size-14  minHeight- 19px,"
                                                        />
                                                    </Box>
                                                </FormControl>
                                            </Stack>
                                        </Box>
                                    </Modal>
                                    <Modal open={paymentModelPopup}>
                                        <Box className="css-payment-modal" padding={2}>
                                            <Stack direction="row" spacing={2}>
                                                <FormControl xs={8} fullWidth>
                                                    <h4 style={{ fontWeight: "600", textAlign: "center" }}>Payment</h4>
                                                    <IconButton
                                                        aria-label="close"
                                                        onClick={() => setPaymentModelPopup(false)}
                                                        sx={{
                                                            position: "absolute",
                                                            right: 8,
                                                            top: 8,
                                                            color: (theme) => theme.palette.grey[500],
                                                        }}
                                                    >
                                                        <CloseIcon />
                                                    </IconButton>
                                                    <FormControl fullWidth className="payment-modal-sec">
                                                        <Typography variant="h5"
                                                            fontWeight="600"
                                                            className="text-ellipsis">AMCPlan:

                                                            <input type="text" name="name" value={selectedAMCPlan.name} />
                                                        </Typography>
                                                        <Typography variant="h5"
                                                            fontWeight="600"
                                                            className="text-ellipsis">
                                                            AMCPlan price:
                                                            <input type="text" name="name" value={selectedAMCPlan.price} />
                                                        </Typography>
                                                        <Typography variant="h5"
                                                            fontWeight="600"
                                                            className="text-ellipsis">
                                                            Selected customer:
                                                            <input type="text" name="name" value={selectedCustomer.userName} />
                                                        </Typography>
                                                        <Typography variant="h5"
                                                            fontWeight="600"
                                                            className="text-ellipsis">
                                                            Make & Model:
                                                            <input type="text" name="name" value={`${selectedVehicleDetails.make} ${selectedVehicleDetails.model}`} />
                                                        </Typography>
                                                        <Typography variant="h5"
                                                            fontWeight="600"
                                                            className="text-ellipsis">
                                                            Price:
                                                            <input type="text" name="name" value={selectedAMCPlan.price} />
                                                        </Typography>
                                                        <Typography variant="h5"
                                                            fontWeight="600"
                                                            className="text-ellipsis">
                                                            Tax:
                                                            <input type="text" name="name" value={paymentTax} />
                                                        </Typography>
                                                        <Typography variant="h5"
                                                            fontWeight="600"
                                                            className="text-ellipsis">
                                                            Total:
                                                            <input type="text" name="name" value={paymentTotal} />
                                                        </Typography>
                                                    </FormControl>
                                                    <Grid className="mt-2">
                                                        <Button
                                                            type="submit"
                                                            variant="contained"
                                                            color="primary"
                                                            size="large"
                                                            onClick={paymentProcess}
                                                            className="submitBtn cs-btn"
                                                            fullWidth="true"
                                                        >
                                                            <span>Payment</span>
                                                        </Button>
                                                    </Grid>
                                                </FormControl>
                                            </Stack>
                                        </Box>
                                    </Modal>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Container >
        </>
    );
};

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarQuickFilter className="searchbar" />
        </GridToolbarContainer>
    );
}

export default AMCPlan;
