import * as React from "react";
import PropTypes from "prop-types";
import { Button, Typography } from "@mui/material";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { ConfirmTypes } from "../../core/Constants";

export const ConfirmDialog = ({ open, onClose, message, yesText="Yes", noText = "No"}) => {
  const handleCancel = () => {
    onClose(ConfirmTypes.CANCEL);
  };

  const handleYes = () => {
    onClose(ConfirmTypes.YES);
  };

  const handleNo = () => {
    onClose(ConfirmTypes.NO);
  };

  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 300 } }}
      maxWidth="xs"
      open={open}
    >
      <DialogTitle
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        className="dialog-title"
      >
        Confirm
        <IconButton aria-label="close" onClick={handleCancel}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers className="font-size-14">
        <DialogContentText>
          <h6 className="py-2">{message}</h6>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          className="btn btn-primary white-text"
          variant="contained"
          color="primary"
          onClick={handleYes}
        >
          {yesText}
        </Button>
        <Button
          autoFocus
          className="btn btn-secondary"
          variant="contained"
          style={{ backgroundColor: "gray" }}
          onClick={handleNo}
        >
          {noText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmDialog.propTypes = {
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  yesText: PropTypes.string.isRequired,
  noText: PropTypes.string.isRequired
};
