import React, { useState, useEffect } from "react";
import "../Style.css";
import {
  Container,
  Grid,
  Stack,
  Card,
  CardContent,
  Typography,
  CardActionArea,
  Box,
} from "@mui/material";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch } from "react-redux";

import Utils from "../core/Utils";
import UserHelper from "../core/UserHelper";
// import DateRangePicker from "react-bootstrap-daterangepicker";
// import "../bootstrap.min.css";
// import "../daterangepicker.css";
import "../DateRangePickerWithYear.css";
import "react-calendar/dist/Calendar.css";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import moment from "moment";
import Tooltip from "@mui/material/Tooltip";
import { PieChart } from "@mui/x-charts/PieChart";
import { BarChart } from "@mui/x-charts/BarChart";

// Images
import Expense_Icon from "../images/expense.png";
import Discounts_Icon from "../images/discounts.png";
import Points_Icon from "../images/points.png";
import UPI_Icon from "../images/Upi.png";
import Profit_Icon from "../images/profit.png";
import Netbanking_Icon from "../images/Netbanking.png";
import Cash_Icon from "../images/cash.png";
import Outstanding_Icon from "../images/outstanding.png";
import Spares_Icon from "../images/spares.png";
import Service_Icon from "../images/service.png";
import Consumables_Icon from "../images/consumables.png";
import Revenue_Icon from "../images/revenue.png";
import Open_Icon from "../images/open.png";
import Assigned_Icon from "../images/assigned.png";
import InProgress_Icon from "../images/inprogress.png";
import Delayed_Icon from "../images/delay.png";
import Completed_Icon from "../images/job_card_completed.png";
import Delivered_Icon from "../images/deliver.png";
import Signups_Icon from "../images/signups.png";
import Active_Icon from "../images/active_users.png";
import Insurance_New_Icon from "../images/Insurance_New.png";
import Service_New_Icon from "../images/Service_New.png";
import Birthday_New_Icon from "../images/Birthday_New.png";
import Expense_New_Icon from "../images/Expense_New.png";
import SalonProduct_Icon from "../images/salon_product.png";
import SalonService_Icon from "../images/salon_services.png";
import SalonMaterials_Icon from "../images/salon_materials.png";
import CarServicesImage from "../images/car_services.jpg";
import Visits_Icon from "../images/visits.png";
import Link_Icon from "../images/link.png";
import Partial_Paid_Icon from "../images/partial-paid.png";
import IconButton from "@mui/material/IconButton";
import RefreshIcon from "@mui/icons-material/Refresh";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import MyJobCardsIcon from "@mui/icons-material/CreditScore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import PaidIcon from "@mui/icons-material/Paid";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import NoCrashOutlinedIcon from "@mui/icons-material/NoCrashOutlined";
import NoteAltOutlinedIcon from "@mui/icons-material/NoteAltOutlined";
import EventAvailableOutlinedIcon from "@mui/icons-material/EventAvailableOutlined";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Salon_Services_Pic from "../images/salon_services_pic.png";
import creditCardIcon from "../images/creditCard.png";
import taxIcon from "../images/tax.png";
import refundIcon from "../images/refund.png";
import { connectToStore } from "../data/store";
import { postAsync } from "../core/serviceClient";
import CountryHelper from "../core/CountryHelper";
import BusinessHelper from "../core/BusinessHelper";
import {
  SideBarMenuNames,
  UserRoles,
  VisitStatuses,
  PaymentMethodTypes,
  VisitTypes,
  MaxDateLimit,
  ProductDetailsTypes,
  India,
} from "../core/Constants";
import MessagePopup from "./common/MessagePopUp";
import ConfirmPopup from "./common/ConfirmPopup";
import SideBarMenuItems from "./SideBarMenuItems";
import OverviewDetails from "./OverviewDetails";
import OverviewProductDetails from "./OverviewProductDetails";
import Loader from "./common/Loader";
import {
  LoginPage,
  BirthdayPage,
  BusinessSetupPage,
  CheckOutPage,
  ExpensePage,
  InsurancePage,
  ServiceReminderPage,
  OverviewPage,
  VisitsPage,
  CustomersPage,
  PaymentLinksPage,
} from "../core/PageConstants";
import { useLocation } from "react-router-dom";
import { CanRaisePaymentLink } from "../core/ModuleHelper";
const ActionTypes = {
  Profit: "Profit",
  Revenue: "Revenue",
};

const Overview = (props) => {
  document.title = "Overview";
  document.body.classList.remove("login");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [localSettings, setLocalSettings] = useState({});
  const [spareTitle, setSpareTitle] = useState("");
  const [serviceTitle, setServiceTitle] = useState("");
  const [consumableTitle, setConsumableTitle] = useState("");
  const [isSalon, setIsSalon] = useState(false);
  const [actionType, setActionType] = useState("");
  // const [startDate, setStartDate] = useState(Utils.GetStartDate());
  // const [endDate, setEndDate] = useState(Utils.GetEndDate());
  const [selectedDate, setSelectedDate] = useState([
    Utils.GetStartDate(),
    Utils.GetEndDate(),
  ]);
  const location = useLocation();
  const [visitsStat, setVisitsStat] = useState("");
  const [profitLocked, setProfitLocked] = useState(false);
  const [revenueLocked, setRevenueLocked] = useState(false);
  const [totalExpenseStat, setTotalExpenseStat] = useState("");
  const [discountsStat, setDiscountsStat] = useState("");
  const [refundsStat, setRefundsStat] = useState("");
  const [taxStat, setTaxStat] = useState("");
  const [pointsStat, setPointsStat] = useState("");
  const [paymentLinkStat, setPaymentLinkStat] = useState(0);
  const [partiallyPaidStat, setPartiallyPaidStat] = useState(0);
  const [cardStat, setCardStat] = useState("");
  const [upiStat, setUPIStat] = useState("");
  const [cashStat, setCashStat] = useState("");
  const [outstandingStat, setOutstandingStat] = useState("");
  const [productRevenue, setProductRevenueStat] = useState("");
  const [serviceRevenueStat, setServiceRevenueStat] = useState("");
  const [materialRevenueStat, setMaterialRevenueStat] = useState("");
  const [revenueStat, setRevenueStat] = useState("");
  const [netbankingStat, setNetbankingStat] = useState("");
  const [profitStat, setProfitStat] = useState("");
  const [openedStat, setOpenedStat] = useState("");
  const [assignedStat, setAssignedStat] = useState("");
  const [inprogressStat, setInProgressStat] = useState("");
  const [delayedStat, setDelayedStat] = useState("");
  const [completedStat, setCompletedStat] = useState("");
  const [deliveredStat, setDeliveredStat] = useState("");
  const [signupsStat, setSignupsStat] = useState("");
  const [activeStat, setActiveStat] = useState("");
  const [feesStat, setFeesStat] = useState("");
  const [tipsStat, setTipsStat] = useState("");
  const [payoutSettledStat, setPayoutSettledStat] = useState("");
  const [payoutTotalStat, setPayoutTotalStat] = useState("");
  const [showStats, setShowStats] = useState(false);
  const [showPayouts, setShowPayouts] = useState(false);
  const [isUSABusiness, setIsUSABusiness] = useState(false);
  const [isOtherPaymentsAvailable, setIsOtherPaymentsAvailable] =
    useState(false);
  const [isAutomobile, setIsAutomobile] = useState(false);
  const [isTechnician, setIsTechnician] = useState(false);
  const [msgOpen, setMsgOpen] = useState(false);
  const [msgText, setMsgText] = useState("");
  const [isNavigateToLogin, setIsNavigateToLogin] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmRevenuePopup, setConfirmRevenuePopup] = useState(false);
  const [confirmText, setConfirmText] = useState("");
  const [revenueOpen, setRevenueOpen] = useState(false);
  const [revenueStatus, setRevenueStatus] = useState(false);
  const [productDetailsOpen, setProductDetailsOpen] = useState(false);
  const [productDetailsType, setProductDetailsType] = useState(
    ProductDetailsTypes.Services
  );
  const [isRevenue, setIsRevenue] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [canRaisePaymentLink, setCanRaisePaymentLink] = useState(false);

  const overviewStat = async (startDT, endDT) => {
    if (localSettings.authenticated) {
      setIsLoading(true);
      var sDate = Utils.GetISODateTimeString(moment(startDT));
      var eDate = Utils.GetISODateTimeString(moment(endDT));
      var request = {
        BranchId: localSettings.branch.branchId,
        BusinessId: localSettings.business.businessId,
        MessagingId: localSettings.branch.messagingId,
        ReservationMaxDays: localSettings.branch.reservationMaxDays,
        Start: sDate,
        End: eDate,
      };
      let response = await postAsync("Account/GetStats", request);
      setIsLoading(false);
      setIsNavigateToLogin(response.isNavigateToLogin);
      if (response.error) {
        setMsgOpen(true);
        setMsgText(response.errorMessage);
      } else if (response.data && response.data.stats) {
        setVisitsStat(response.data.stats.visits);
        setTotalExpenseStat(response.data.stats.totalExpenses);
        setPaymentLinkStat(response.data.stats.paymentLinks);
        setPartiallyPaidStat(response.data.stats.partiallyPaidVisits);
        setDiscountsStat(response.data.stats.discount);
        setPointsStat(response.data.stats.pointsAccrued);
        setCardStat(response.data.stats.card);
        setUPIStat(response.data.stats.upi);
        setNetbankingStat(response.data.stats.netBanking);
        setProfitStat(response.data.stats.profit);
        setCashStat(response.data.stats.cash);
        setOutstandingStat(response.data.stats.outstandingPayments);
        setProductRevenueStat(response.data.stats.productRevenue);
        setServiceRevenueStat(response.data.stats.serviceRevenue);
        setMaterialRevenueStat(response.data.stats.materialRevenue);
        setRevenueStat(response.data.stats.revenue);
        setOpenedStat(response.data.stats.jobcardsOpened);
        setAssignedStat(response.data.stats.jobcardsAssigned);
        setInProgressStat(response.data.stats.jobcardsInProgress);
        setDelayedStat(response.data.stats.jobcardsDelayed);
        setCompletedStat(response.data.stats.jobcardsCompleted);
        setDeliveredStat(response.data.stats.vehiclesDelivered);
        setSignupsStat(response.data.stats.signups);
        setActiveStat(response.data.stats.active);
        setFeesStat(response.data.stats.fees);
        setTipsStat(response.data.stats.tips);
        setRefundsStat(response.data.stats.refunds);
        setTaxStat(response.data.stats.tax);
        setCanRaisePaymentLink(CanRaisePaymentLink(localSettings.business));
        let isUSA = CountryHelper.IsBusinessCountryUSA(localSettings.country);
        setShowStats(
          localSettings.user &&
          localSettings.user.role &&
          (localSettings.user.role == UserRoles.GlobalAdmin ||
            localSettings.user.role == UserRoles.BranchAdmin)
        );
        setIsUSABusiness(isUSA);
        setIsOtherPaymentsAvailable(
          CountryHelper.IsOtherPaymentsAvailable(localSettings.country)
        );
        setIsAutomobile(BusinessHelper.IsAutomobile(localSettings.business));
        setShowPayouts(
          isUSA && localSettings.user.role == UserRoles.GlobalAdmin
        );
        setPayoutSettledStat(response.data.stats.payoutSettled);
        setPayoutTotalStat(response.data.stats.payoutTotal);
        setIsTechnician(localSettings.user.role == UserRoles.Technician);
      }
    }
  };

  useEffect(() => {
    let localSetting = connectToStore();
    if (UserHelper.CheckPermission(OverviewPage, navigate, localSetting)) {
      setLocalSettings(localSetting);
      let isSalonBusiness = BusinessHelper.IsSalon(localSetting.business);
      setSpareTitle(BusinessHelper.GetSparesTitle(isSalonBusiness));
      setServiceTitle(BusinessHelper.GetServicesTitle(isSalonBusiness));
      setConsumableTitle(BusinessHelper.GetConsumablesTitle(isSalonBusiness));
      setIsSalon(isSalonBusiness);
    }
  }, []);
  useEffect(() => {
    checkState();
  }, [localSettings]);

  const checkState = () => {
    if (
      !Utils.IsNullOrEmptyObject(location.state) &&
      !Utils.IsNullOrEmpty(location.state.startDate) &&
      !Utils.IsNullOrEmpty(location.state.endDate)
    ) {
      var startDate = moment(location.state.startDate);
      var endDate = moment(location.state.endDate);
      setSelectedDate([startDate, endDate]);
      overviewStat(startDate, endDate);
    } else {
      overviewStat(selectedDate[0], selectedDate[1]);
    }
  };

  const onDateChange = (date) => {
    if (!Utils.IsNullOrEmptyArray(date)) {
      var dateArray = [moment(date[0]), moment(date[1])];

      let dateDiff = dateArray[1].diff(dateArray[0], "days");
      if (dateDiff > MaxDateLimit) {
        setMsgText(
          "Please note that the maximum date range you can select is 90 days. You can choose any start date within the past 90 days, but the end date cannot exceed this limit."
        );

        setMsgOpen(true);
      } else {
        setSelectedDate(dateArray);
        overviewStat(dateArray[0], dateArray[1]);
      }
    }
  };
  const handleRefresh = () => {
    overviewStat(selectedDate[0], selectedDate[1]);
  };
  const handleProfit = () => {
    if (!profitLocked) {
      setActionType(ActionTypes.Profit);
      setConfirmText("Are you sure you want to view  Profit ?");
      setConfirmOpen(true);
    } else {
      setProfitLocked(false);
    }
  };
  const handleRevenue = () => {
    if (!revenueLocked) {
      setActionType(ActionTypes.Revenue);
      setConfirmText("Are you sure you want to view  Revenue ?");
      setConfirmOpen(true);
    } else {
      setRevenueLocked(false);
    }
  };

  const handleMyJobCards = () => {
    navigate(VisitsPage.Path, {
      state: {
        startDT: Utils.GetISODateTimeString(selectedDate[0]),
        endDT: Utils.GetISODateTimeString(selectedDate[1]),
        visitStatus: VisitTypes.MyJobCard,
      },
    });
  };

  const handleMsgClose = () => {
    setMsgOpen(false);
    if (isNavigateToLogin) {
      UserHelper.LogOut(dispatch);
      navigate(LoginPage.Path);
    }
  };

  const handleConfirmClose = (value) => {
    setConfirmOpen(false);
    setActionType("");
    if (value == "OK") {
      switch (actionType) {
        default:
          setIsRevenue(revenueStatus);
          setRevenueOpen(true);
          break;
        case ActionTypes.Profit:
          setProfitLocked(true);
          break;
        case ActionTypes.Revenue:
          setRevenueLocked(true);
          break;
      }
    }
  };

  const handleRevenueClose = (response) => {
    setRevenueOpen(false);
    if (!Utils.IsNullOrEmptyObject(response)) {
      setIsNavigateToLogin(response.isNavigateToLogin);
      if (response.error) {
        setMsgOpen(true);
        setMsgText(response.errorMessage);
      }
    }
  };
  const handleProductDetailsClose = (response) => {
    setProductDetailsOpen(false);
    if (!Utils.IsNullOrEmptyObject(response)) {
      setIsNavigateToLogin(response.isNavigateToLogin);
      if (response.error) {
        setMsgOpen(true);
        setMsgText(response.errorMessage);
      }
    }
  };

  const handleRevenueOpen = (isRevenue) => {
    if (isRevenue == false) {
      setRevenueStatus(false);
      setIsRevenue(false);
      setRevenueOpen(true);
    } else {
      setRevenueStatus(true);
      setIsRevenue(true);
      setRevenueOpen(true);
    }
  };

  const handleProductDetailsOpen = (detailsType) => {
    setProductDetailsType(detailsType);
    setProductDetailsOpen(true);
  };

  const valueFormatter = (value) => `${value}`;

  const chartSetting = {
    xAxis: [
      {
        scaleType: "band",
        dataKey: "jobcard",
        categoryGapRatio: 0.3,
        barGapRatio: 0.5,
      },
    ],
    series: [
      {
        dataKey: "status",
        valueFormatter,
        color: "#fdb462",
      },
    ],
    yAxis: [
      {
        tickMinStep: 1,
      },
    ],
    width: 550,
    height: 300,
  };
  const dataset = [
    {
      status: openedStat,
      jobcard: "Open",
    },
    {
      status: assignedStat,
      jobcard: "Assigned",
    },
    {
      status: inprogressStat,
      jobcard: "In Progress",
    },
    {
      status: completedStat,
      jobcard: "Completed",
    },
    {
      status: deliveredStat,
      jobcard: "Delivered",
    },
    {
      status: delayedStat,
      jobcard: "Delayed",
    },
  ];

  const [isPaymentVisible, setIsPaymentVisible] = useState(false);
  const handlePaymentSection = (event) => {
    if (event.target.checked) {
      setIsPaymentVisible(true);
    } else {
      setIsPaymentVisible(false);
    }
  };

  const [isViewMoreVisible, setIsViewMoreVisible] = useState(false);
  const handleViewMoreSection = (event) => {
    if (event.target.checked) {
      setIsViewMoreVisible(true);
    } else {
      setIsViewMoreVisible(false);
    }
  };

  return (
    <Container maxWidth="false" className="bg-color p-0">
      <MessagePopup
        msgOpen={msgOpen}
        msgText={msgText}
        onMsgClose={handleMsgClose}
      />
      <ConfirmPopup
        confirmOpen={confirmOpen}
        confirmText={confirmText}
        onConfirmClose={handleConfirmClose}
      />
      <ConfirmPopup
        confirmOpen={confirmOpen}
        confirmText={confirmText}
        onConfirmClose={handleConfirmClose}
      />
      <OverviewDetails
        open={revenueOpen}
        onClose={handleRevenueClose}
        isRevenue={isRevenue}
        startDT={selectedDate[0]}
        endDT={selectedDate[1]}
      />
      <OverviewProductDetails
        open={productDetailsOpen}
        onClose={handleProductDetailsClose}
        detailsType={productDetailsType}
        startDT={selectedDate[0]}
        endDT={selectedDate[1]}
      />
      <Loader open={isLoading} />
      <Box>
        <Grid container m={0}>
          <SideBarMenuItems selectedTab={SideBarMenuNames.Overview} />
          <Grid xs={12} className="content-sec tl-header">
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              paddingBottom="15px"
            >
              {/* Activity */}
              <Grid container>
                {/* Title Section */}
                <Grid container className="title-sec">
                  <Grid xs={12} md={8} lg={8}>
                    <Typography
                      level="h2"
                      component="h2"
                      fontWeight="600"
                      fontSize="2rem"
                      className="h1"
                    >
                      Activity Dashboard
                    </Typography>
                  </Grid>
                  <Grid
                    xs={12}
                    md={4}
                    lg={4}
                    className="form-inline text-right bt-xs-1"
                    style={{ "align-self": "inherit" }}
                  >
                    <Stack
                      direction="row"
                      justifyContent="end"
                      style={{ height: "100%", padding: "10px" }}
                    >
                      <IconButton
                        aria-label="Refresh"
                        onClick={handleRefresh}
                        style={{
                          "align-self": "center",
                          "margin-right": "10px",
                        }}
                        className="refresh-icon"
                      >
                        <RefreshIcon sx={{ fontSize: 25 }} />
                      </IconButton>
                      <DateRangePicker
                        showLeadingZeros={true}
                        onChange={onDateChange}
                        maxDate={new Date()}
                        value={selectedDate}
                        locale={CountryHelper.GetDateCulture(
                          Utils.IsNullOrEmptyObject(localSettings) ||
                            Utils.IsNullOrEmptyObject(localSettings.country)
                            ? India.Culture
                            : localSettings.country.Culture
                        )}
                        clearIcon={null}
                        calendarIcon={
                          <CalendarMonthOutlinedIcon
                            style={{
                              "font-size": "24px",
                              "align-self": "center",
                              color: "#666666",
                            }}
                          />
                        }
                      />
                    </Stack>
                  </Grid>
                </Grid>

                {/* Visits */}
                {showStats && (
                  <Grid padding={0.6} container justifyContent="Center">
                    <Grid
                      container
                      justifyContent="space-between"
                      className="stat-sec flex-wrap"
                      spacing={2}
                      padding={1.5}
                    >
                      <Grid
                        item
                        xs={12}
                        md={canRaisePaymentLink ? 4 : 6}
                        lg={canRaisePaymentLink ? 4 : 6}
                      >
                        <Card className="card">
                          <Link
                            to={VisitsPage.Path}
                            state={{
                              startDT: Utils.GetISODateTimeString(
                                selectedDate[0]
                              ),
                              endDT: Utils.GetISODateTimeString(
                                selectedDate[1]
                              ),
                              navigationStack: [OverviewPage.Path],
                            }}
                          >
                            <CardActionArea>
                              <Stack direction="row" style={{ width: "100%" }}>
                                <Box className="stat-icon">
                                  <Box
                                    className="flex-end"
                                    component="img"
                                    sx={{
                                      height: 46,
                                      width: 46,
                                    }}
                                    alt="Open"
                                    src={Visits_Icon}
                                  />
                                </Box>
                                <CardContent className="p-h">
                                  <Typography
                                    level="h3"
                                    component="h3"
                                    fontSize="2rem"
                                    fontWeight="600"
                                  >
                                    {visitsStat}
                                  </Typography>
                                  <Typography
                                    level="span"
                                    component="span"
                                    fontSize="1rem"
                                  >
                                    Visits
                                  </Typography>
                                </CardContent>
                              </Stack>
                            </CardActionArea>
                          </Link>
                        </Card>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={canRaisePaymentLink ? 4 : 6}
                        lg={canRaisePaymentLink ? 4 : 6}
                      >
                        <Card className="card">
                          <Link
                            to={VisitsPage.Path}
                            state={{
                              startDT: Utils.GetISODateTimeString(
                                selectedDate[0]
                              ),
                              endDT: Utils.GetISODateTimeString(
                                selectedDate[1]
                              ),
                              partiallyPaid: true,
                              navigationStack: [OverviewPage.Path],
                            }}
                          >
                            <CardActionArea>
                              <Stack direction="row" style={{ width: "100%" }}>
                                <Box className="stat-icon">
                                  <Box
                                    className="flex-end"
                                    component="img"
                                    sx={{
                                      height: 46,
                                      width: 46,
                                    }}
                                    alt="Open"
                                    src={Partial_Paid_Icon}
                                  />
                                </Box>
                                <CardContent className="p-h">
                                  <Typography
                                    level="h3"
                                    component="h3"
                                    fontSize="2rem"
                                    fontWeight="600"
                                  >
                                    {partiallyPaidStat}
                                  </Typography>
                                  <Typography
                                    level="span"
                                    component="span"
                                    fontSize="1rem"
                                  >
                                    Partially Paid
                                  </Typography>
                                </CardContent>
                              </Stack>
                            </CardActionArea>
                          </Link>
                        </Card>
                      </Grid>
                      {canRaisePaymentLink && (
                        <Grid item xs={12} md={4} lg={4}>
                          <Card className="card">
                            <Link
                              to={PaymentLinksPage.Path}
                              state={{
                                startDT: Utils.GetISODateTimeString(
                                  selectedDate[0]
                                ),
                                endDT: Utils.GetISODateTimeString(
                                  selectedDate[1]
                                ),
                                navigationStack: [OverviewPage.Path],
                                activeMenu: SideBarMenuNames.Overview,
                              }}
                            >
                              <CardActionArea>
                                <Stack
                                  direction="row"
                                  style={{ width: "100%" }}
                                >
                                  <Box className="stat-icon">
                                    <Box
                                      className="flex-end"
                                      component="img"
                                      sx={{
                                        height: 46,
                                        width: 46,
                                      }}
                                      alt="Open"
                                      src={Link_Icon}
                                    />
                                  </Box>
                                  <CardContent className="p-h align-center">
                                    <Typography
                                      level="h3"
                                      component="h3"
                                      fontSize="2rem"
                                      fontWeight="600"
                                    >
                                      {paymentLinkStat}
                                    </Typography>
                                    <Typography
                                      level="span"
                                      component="span"
                                      fontSize="1rem"
                                      style={{ display: "flex" }}
                                    >
                                      Payment Link
                                    </Typography>
                                  </CardContent>
                                </Stack>
                              </CardActionArea>
                            </Link>
                          </Card>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                )}

                {/* Job Cards */}
                {isAutomobile && (
                  <Grid container padding="15px 15px 0 15px">
                    <Grid container xs={12}>
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyItems="space-between"
                      >
                        <Grid xs>
                          <Typography
                            level="h2"
                            component="h2"
                            fontWeight="600"
                            fontSize="1.6rem"
                            className="h1"
                          >
                            Job Cards
                          </Typography>
                        </Grid>
                        {isTechnician && (
                          <Grid xs={4} className="text-end form-inline">
                            <IconButton
                              aria-label="MyJobCards"
                              onClick={handleMyJobCards}
                            >
                              <MyJobCardsIcon
                                sx={{ fontSize: 30, color: "orange" }}
                              />
                            </IconButton>
                          </Grid>
                        )}
                      </Stack>
                      <Grid
                        container
                        justifyContent="space-between"
                        marginTop="1px"
                        className="stat-sec flex-wrap"
                        spacing={2}
                      >
                        <Grid item xs={12} md={4} lg={4}>
                          <Card className="card">
                            <Link
                              to={VisitsPage.Path}
                              state={{
                                startDT: Utils.GetISODateTimeString(
                                  selectedDate[0]
                                ),
                                endDT: Utils.GetISODateTimeString(
                                  selectedDate[1]
                                ),
                                visitStatus: VisitStatuses.Open,
                              }}
                            >
                              <CardActionArea>
                                <Stack
                                  direction="row"
                                  style={{ width: "100%" }}
                                >
                                  <Box className="stat-icon">
                                    <Box
                                      className="flex-end"
                                      component="img"
                                      sx={{
                                        height: 46,
                                        width: 46,
                                      }}
                                      alt="Open"
                                      src={Open_Icon}
                                    />
                                  </Box>
                                  <CardContent className="p-h">
                                    <Typography
                                      level="h3"
                                      component="h3"
                                      fontSize="2rem"
                                      fontWeight="600"
                                    >
                                      {openedStat}
                                    </Typography>
                                    <Typography
                                      level="span"
                                      component="span"
                                      fontSize="1rem"
                                    >
                                      Open
                                    </Typography>
                                  </CardContent>
                                </Stack>
                              </CardActionArea>
                            </Link>
                          </Card>
                        </Grid>
                        <Grid item xs={12} md={4} lg={4}>
                          <Card className="card">
                            <Link
                              to={VisitsPage.Path}
                              state={{
                                startDT: Utils.GetISODateTimeString(
                                  selectedDate[0]
                                ),
                                endDT: Utils.GetISODateTimeString(
                                  selectedDate[1]
                                ),
                                visitStatus: VisitStatuses.Assigned,
                              }}
                            >
                              <CardActionArea>
                                <Stack
                                  direction="row"
                                  style={{ width: "100%" }}
                                >
                                  <Box className="stat-icon">
                                    <Box
                                      className="flex-end"
                                      component="img"
                                      sx={{
                                        height: 46,
                                        width: 46,
                                      }}
                                      alt="Assigned"
                                      src={Assigned_Icon}
                                    />
                                  </Box>
                                  <CardContent className="p-h">
                                    <Typography
                                      level="h3"
                                      component="h3"
                                      fontSize="2rem"
                                      fontWeight="600"
                                      color="#2196f3;"
                                    >
                                      {assignedStat}
                                    </Typography>
                                    <Typography
                                      level="span"
                                      component="span"
                                      fontSize="1rem"
                                    >
                                      Assigned
                                    </Typography>
                                  </CardContent>
                                </Stack>
                              </CardActionArea>
                            </Link>
                          </Card>
                        </Grid>
                        <Grid item xs={12} md={4} lg={4}>
                          <Card className="card">
                            <Link
                              to={VisitsPage.Path}
                              state={{
                                startDT: Utils.GetISODateTimeString(
                                  selectedDate[0]
                                ),
                                endDT: Utils.GetISODateTimeString(
                                  selectedDate[1]
                                ),
                                visitStatus: VisitStatuses.WorkInProgress,
                              }}
                            >
                              <CardActionArea>
                                <Stack
                                  direction="row"
                                  style={{ width: "100%" }}
                                >
                                  <Box className="stat-icon">
                                    <Box
                                      className="flex-end"
                                      component="img"
                                      sx={{
                                        height: 46,
                                        width: 46,
                                      }}
                                      alt="InProgress"
                                      src={InProgress_Icon}
                                    />
                                  </Box>
                                  <CardContent className="p-h">
                                    <Typography
                                      level="h3"
                                      component="h3"
                                      fontSize="2rem"
                                      fontWeight="600"
                                      color="orange"
                                    >
                                      {inprogressStat}
                                    </Typography>
                                    <Typography
                                      level="span"
                                      component="span"
                                      fontSize="1rem"
                                    >
                                      In Progress
                                    </Typography>
                                  </CardContent>
                                </Stack>
                              </CardActionArea>
                            </Link>
                          </Card>
                        </Grid>
                        <Grid item xs={12} md={4} lg={4}>
                          <Card className="card">
                            <Link
                              to={VisitsPage.Path}
                              state={{
                                startDT: Utils.GetISODateTimeString(
                                  selectedDate[0]
                                ),
                                endDT: Utils.GetISODateTimeString(
                                  selectedDate[1]
                                ),
                                visitStatus: VisitStatuses.Completed,
                              }}
                            >
                              <CardActionArea>
                                <Stack
                                  direction="row"
                                  style={{ width: "100%" }}
                                >
                                  <Box className="stat-icon">
                                    <Box
                                      className="flex-end"
                                      component="img"
                                      sx={{
                                        height: 46,
                                        width: 46,
                                      }}
                                      alt="Completed"
                                      src={Completed_Icon}
                                    />
                                  </Box>
                                  <CardContent className="p-h">
                                    <Typography
                                      level="h3"
                                      component="h3"
                                      fontSize="2rem"
                                      fontWeight="600"
                                      color="purple"
                                    >
                                      {completedStat}
                                    </Typography>
                                    <Typography
                                      level="span"
                                      component="span"
                                      fontSize="1rem"
                                    >
                                      Completed
                                    </Typography>
                                  </CardContent>
                                </Stack>
                              </CardActionArea>
                            </Link>
                          </Card>
                        </Grid>
                        <Grid item xs={12} md={4} lg={4}>
                          <Card className="card">
                            <Link
                              to={VisitsPage.Path}
                              state={{
                                startDT: Utils.GetISODateTimeString(
                                  selectedDate[0]
                                ),
                                endDT: Utils.GetISODateTimeString(
                                  selectedDate[1]
                                ),
                                visitStatus: VisitStatuses.Delivered,
                              }}
                            >
                              <CardActionArea>
                                <Stack
                                  direction="row"
                                  style={{ width: "100%" }}
                                >
                                  <Box className="stat-icon">
                                    <Box
                                      className="flex-end"
                                      component="img"
                                      sx={{
                                        height: 46,
                                        width: 46,
                                      }}
                                      alt="Delivered"
                                      src={Delivered_Icon}
                                    />
                                  </Box>
                                  <CardContent className="p-h">
                                    <Typography
                                      level="h3"
                                      component="h3"
                                      fontSize="2rem"
                                      fontWeight="600"
                                      color="green"
                                    >
                                      {deliveredStat}
                                    </Typography>
                                    <Typography
                                      level="span"
                                      component="span"
                                      fontSize="1rem"
                                    >
                                      Delivered
                                    </Typography>
                                  </CardContent>
                                </Stack>
                              </CardActionArea>
                            </Link>
                          </Card>
                        </Grid>
                        <Grid item xs={12} md={4} lg={4}>
                          <Card className="card">
                            <Link
                              to={VisitsPage.Path}
                              state={{
                                startDT: Utils.GetISODateTimeString(
                                  selectedDate[0]
                                ),
                                endDT: Utils.GetISODateTimeString(
                                  selectedDate[1]
                                ),
                                visitStatus: VisitTypes.Delayed,
                              }}
                            >
                              <CardActionArea>
                                <Stack
                                  direction="row"
                                  style={{ width: "100%" }}
                                >
                                  <Box className="stat-icon">
                                    <Box
                                      className="flex-end"
                                      component="img"
                                      sx={{
                                        height: 46,
                                        width: 46,
                                      }}
                                      alt="Delayed"
                                      src={Delayed_Icon}
                                    />
                                  </Box>
                                  <CardContent className="p-h">
                                    <Typography
                                      level="h3"
                                      component="h3"
                                      fontSize="2rem"
                                      fontWeight="600"
                                      color="red"
                                    >
                                      {delayedStat}
                                    </Typography>
                                    <Typography
                                      level="span"
                                      component="span"
                                      fontSize="1rem"
                                    >
                                      Delayed
                                    </Typography>
                                  </CardContent>
                                </Stack>
                              </CardActionArea>
                            </Link>
                          </Card>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                {/* Stat Section */}
                {showStats ? (
                  <Grid
                    container
                    padding="15px"
                    className="stat-sec"
                    style={{ marginTop: "15px" }}
                  >
                    <Grid xs={12} item>
                      <Grid
                        container
                        xs={12}
                        item
                        spacing={2}
                        className="card-box"
                      >
                        <Grid
                          container
                          xs={12}
                          className="flex-wrap"
                          spacing={2}
                          style={{ marginLeft: "0px" }}
                        >
                          {/* Profit */}
                          <Grid
                            item
                            xs={12}
                            md={!isSalon ? 6 : 4}
                            lg={!isSalon ? 4 : 6}
                            className="protect-box"
                          >
                            <Card className="card">
                              <IconButton
                                aria-label="Lock"
                                onClick={() => {
                                  handleProfit();
                                }}
                                className="lock-icon"
                              >
                                {!profitLocked ? (
                                  <LockOutlinedIcon sx={{ fontSize: 25 }} />
                                ) : (
                                  <LockOpenOutlinedIcon sx={{ fontSize: 25 }} />
                                )}
                              </IconButton>
                              <Link
                                to="javascript:void(0)"
                                onClick={() => {
                                  if (profitLocked) {
                                    handleRevenueOpen(false);
                                  }
                                }}
                              >
                                <CardActionArea>
                                  <Stack direction="row">
                                    <Box className="stat-icon">
                                      <Box
                                        className="flex-end"
                                        component="img"
                                        sx={{
                                          height: 46,
                                          width: 46,
                                        }}
                                        alt="Profit"
                                        src={Profit_Icon}
                                      />
                                    </Box>
                                    <CardContent
                                      className="p-h"
                                      style={{ width: "calc(100% - 60px)" }}
                                    >
                                      {profitLocked ? (
                                        <Typography
                                          level="h3"
                                          component="h3"
                                          fontSize="2rem"
                                          fontWeight="600"
                                          color="green"
                                          className="text-ellipsis"
                                        >
                                          {Utils.GetCurrency(
                                            profitStat -
                                            totalExpenseStat -
                                            feesStat +
                                            tipsStat,
                                            localSettings.country,
                                            0
                                          )}
                                        </Typography>
                                      ) : (
                                        <Typography
                                          level="h3"
                                          component="h3"
                                          fontSize="2rem"
                                          fontWeight="600"
                                          color="green"
                                          className="text-ellipsis"
                                        >
                                          ******
                                        </Typography>
                                      )}

                                      <Typography
                                        level="span"
                                        component="span"
                                        fontSize="1rem"
                                      >
                                        Profit
                                      </Typography>
                                    </CardContent>
                                  </Stack>
                                </CardActionArea>
                              </Link>
                            </Card>
                          </Grid>
                          {/* Revenue */}
                          <Grid
                            item
                            xs={12}
                            md={!isSalon ? 6 : 4}
                            lg={!isSalon ? 4 : 6}
                            className="protect-box"
                          >
                            <Card className="card">
                              <IconButton
                                aria-label="Lock"
                                onClick={() => {
                                  handleRevenue();
                                }}
                                className="lock-icon"
                              >
                                {!revenueLocked ? (
                                  <LockOutlinedIcon sx={{ fontSize: 25 }} />
                                ) : (
                                  <LockOpenOutlinedIcon sx={{ fontSize: 25 }} />
                                )}
                              </IconButton>
                              <Link
                                to="javascript:void(0)"
                                onClick={() => {
                                  if (revenueLocked) {
                                    handleRevenueOpen(true);
                                  }
                                }}
                              >
                                <CardActionArea>
                                  <Stack
                                    direction="row"
                                    style={{ width: "100%" }}
                                  >
                                    <Box className="stat-icon">
                                      <Box
                                        className="flex-end"
                                        component="img"
                                        sx={{
                                          height: 46,
                                          width: 46,
                                        }}
                                        alt="Revenue"
                                        src={Revenue_Icon}
                                      />
                                    </Box>
                                    <CardContent
                                      className="p-h"
                                      style={{ width: "calc(100% - 60px)" }}
                                    >
                                      {revenueLocked ? (
                                        <Typography
                                          level="h3"
                                          component="h3"
                                          fontSize="2rem"
                                          fontWeight="600"
                                          className="text-ellipsis"
                                        >
                                          {Utils.GetCurrency(
                                            revenueStat,
                                            localSettings.country,
                                            0
                                          )}
                                        </Typography>
                                      ) : (
                                        <Typography
                                          level="h3"
                                          component="h3"
                                          fontSize="2rem"
                                          fontWeight="600"
                                          className="text-ellipsis"
                                        >
                                          ******
                                        </Typography>
                                      )}
                                      <Typography
                                        level="span"
                                        component="span"
                                        fontSize="1rem"
                                      >
                                        Revenue
                                      </Typography>
                                    </CardContent>
                                  </Stack>
                                </CardActionArea>
                              </Link>
                            </Card>
                          </Grid>
                          {/* Expense */}
                          <Grid
                            item
                            xs={12}
                            md={!isSalon ? 6 : 4}
                            lg={!isSalon ? 4 : 6}
                          >
                            <Tooltip
                              title={Utils.GetCurrency(
                                totalExpenseStat,
                                localSettings.country,
                                0
                              )}
                              arrow
                            >
                              <Card className="card">
                                <Link to="/Expense">
                                  <CardActionArea
                                    disabled={true}
                                    className="d-flex"
                                  >
                                    <Stack
                                      direction="row"
                                      style={{ width: "100%" }}
                                    >
                                      <Box className="stat-icon">
                                        <Box
                                          className="flex-end"
                                          component="img"
                                          sx={{
                                            height: 46,
                                            width: 46,
                                          }}
                                          alt="Expense"
                                          src={Expense_Icon}
                                        />
                                      </Box>
                                      <CardContent
                                        className="p-h"
                                        style={{ width: "calc(100% - 60px)" }}
                                      >
                                        <Typography
                                          level="h3"
                                          component="h3"
                                          fontSize="2rem"
                                          fontWeight="600"
                                          className="text-ellipsis"
                                        >
                                          {Utils.GetCurrency(
                                            totalExpenseStat,
                                            localSettings.country,
                                            0
                                          )}
                                        </Typography>
                                        <Typography
                                          level="span"
                                          component="span"
                                          fontSize="1rem"
                                        >
                                          Expense
                                        </Typography>
                                      </CardContent>
                                    </Stack>
                                  </CardActionArea>
                                </Link>
                              </Card>
                            </Tooltip>
                          </Grid>
                        </Grid>
                        <Grid container xs={12} justifyContent="Center">
                          <label className="btn" title="View more">
                            <input
                              type="checkbox"
                              onChange={handleViewMoreSection}
                              style={{ display: "none" }}
                            />
                            <MoreHorizIcon style={{ "font-size": "24px" }} />
                          </label>
                        </Grid>
                        {isViewMoreVisible && (
                          <Grid container xs={12} className="flex-wrap">
                            <Grid
                              container
                              xs={12}
                              className="flex-wrap m-0"
                              spacing={2}
                            >
                              {/* Spares */}
                              <Grid item xs={12} md={4} lg={4}>
                                <Tooltip
                                  title={Utils.GetCurrency(
                                    productRevenue,
                                    localSettings.country,
                                    0
                                  )}
                                  arrow
                                >
                                  <Card className="card">
                                    <Link
                                      to="javascript:void(0)"
                                      onClick={() =>
                                        handleProductDetailsOpen(
                                          ProductDetailsTypes.Spares
                                        )
                                      }
                                    >
                                      <CardActionArea disabled={true}>
                                        <Stack
                                          direction="row"
                                          style={{ width: "100%" }}
                                        >
                                          <Box className="stat-icon">
                                            <Box
                                              className="flex-end"
                                              component="img"
                                              sx={{
                                                height: 34,
                                                width: 34,
                                              }}
                                              alt={spareTitle}
                                              src={
                                                isSalon
                                                  ? SalonProduct_Icon
                                                  : Spares_Icon
                                              }
                                            />
                                          </Box>
                                          <CardContent
                                            className="p-h"
                                            style={{
                                              width: "calc(100% - 60px)",
                                            }}
                                          >
                                            <Typography
                                              level="h3"
                                              component="h3"
                                              fontSize="2rem"
                                              fontWeight="600"
                                              className="text-ellipsis"
                                            >
                                              {Utils.GetCurrency(
                                                productRevenue,
                                                localSettings.country,
                                                0
                                              )}
                                            </Typography>
                                            <Typography
                                              level="span"
                                              component="span"
                                              fontSize="1rem"
                                            >
                                              {spareTitle}
                                            </Typography>
                                          </CardContent>
                                        </Stack>
                                      </CardActionArea>
                                    </Link>
                                  </Card>
                                </Tooltip>
                              </Grid>
                              {/* Consumables */}
                              <Grid item xs={12} md={4} lg={4}>
                                <Tooltip
                                  title={Utils.GetCurrency(
                                    materialRevenueStat,
                                    localSettings.country,
                                    0
                                  )}
                                  arrow
                                >
                                  <Card className="card">
                                    <Link
                                      to="javascript:void(0)"
                                      onClick={() =>
                                        handleProductDetailsOpen(
                                          ProductDetailsTypes.Consumables
                                        )
                                      }
                                    >
                                      <CardActionArea disabled={true}>
                                        <Stack
                                          direction="row"
                                          style={{ width: "100%" }}
                                        >
                                          <Box className="stat-icon">
                                            <Box
                                              className="flex-end"
                                              component="img"
                                              sx={{
                                                height: 34,
                                                width: 34,
                                              }}
                                              alt={consumableTitle}
                                              src={
                                                isSalon
                                                  ? SalonMaterials_Icon
                                                  : Consumables_Icon
                                              }
                                            />
                                          </Box>
                                          <CardContent
                                            className="p-h"
                                            style={{
                                              width: "calc(100% - 60px)",
                                            }}
                                          >
                                            <Typography
                                              level="h3"
                                              component="h3"
                                              fontSize="2rem"
                                              fontWeight="600"
                                              className="text-ellipsis"
                                            >
                                              {Utils.GetCurrency(
                                                materialRevenueStat,
                                                localSettings.country,
                                                0
                                              )}
                                            </Typography>
                                            <Typography
                                              level="span"
                                              component="span"
                                              fontSize="1rem"
                                            >
                                              {consumableTitle}
                                            </Typography>
                                          </CardContent>
                                        </Stack>
                                      </CardActionArea>
                                    </Link>
                                  </Card>
                                </Tooltip>
                              </Grid>
                              {/* Services */}
                              <Grid item xs={12} md={4} lg={4}>
                                <Tooltip
                                  title={Utils.GetCurrency(
                                    serviceRevenueStat,
                                    localSettings.country,
                                    0
                                  )}
                                  arrow
                                >
                                  <Card className="card">
                                    <Link
                                      to="javascript:void(0)"
                                      onClick={() =>
                                        handleProductDetailsOpen(
                                          ProductDetailsTypes.Services
                                        )
                                      }
                                    >
                                      <CardActionArea disabled={true}>
                                        <Stack
                                          direction="row"
                                          style={{ width: "100%" }}
                                        >
                                          <Box className="stat-icon">
                                            <Box
                                              className="flex-end"
                                              component="img"
                                              sx={{
                                                height: 34,
                                                width: 34,
                                              }}
                                              alt={serviceTitle}
                                              src={
                                                isSalon
                                                  ? SalonService_Icon
                                                  : Service_Icon
                                              }
                                            />
                                          </Box>
                                          <CardContent
                                            className="p-h"
                                            style={{
                                              width: "calc(100% - 60px)",
                                            }}
                                          >
                                            <Typography
                                              level="h3"
                                              component="h3"
                                              fontSize="2rem"
                                              fontWeight="600"
                                              className="text-ellipsis"
                                            >
                                              {Utils.GetCurrency(
                                                serviceRevenueStat,
                                                localSettings.country,
                                                0
                                              )}
                                            </Typography>
                                            <Typography
                                              level="span"
                                              component="span"
                                              fontSize="1rem"
                                            >
                                              {serviceTitle}
                                            </Typography>
                                          </CardContent>
                                        </Stack>
                                      </CardActionArea>
                                    </Link>
                                  </Card>
                                </Tooltip>
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              xs={12}
                              className="flex-wrap m-0"
                              spacing={2}
                            >
                              {/* Discounts */}
                              <Grid item xs={12} md={3} lg={3}>
                                <Tooltip
                                  title={Utils.GetCurrency(
                                    discountsStat,
                                    localSettings.country,
                                    0
                                  )}
                                  arrow
                                >
                                  <Card className="card">
                                    <CardActionArea disabled={true}>
                                      <Stack
                                        direction="row"
                                        style={{ width: "100%" }}
                                      >
                                        <Box className="stat-icon">
                                          <Box
                                            className="flex-end"
                                            component="img"
                                            sx={{
                                              height: 34,
                                              width: 34,
                                            }}
                                            alt="Discounts"
                                            src={Discounts_Icon}
                                          />
                                        </Box>
                                        <CardContent
                                          className="p-h"
                                          style={{ width: "calc(100% - 60px)" }}
                                        >
                                          <Typography
                                            level="h3"
                                            component="h3"
                                            fontSize="2rem"
                                            fontWeight="600"
                                            className="text-ellipsis"
                                          >
                                            {Utils.GetCurrency(
                                              discountsStat,
                                              localSettings.country,
                                              0
                                            )}
                                          </Typography>
                                          <Typography
                                            level="span"
                                            component="span"
                                            fontSize="1rem"
                                          >
                                            Discounts
                                          </Typography>
                                        </CardContent>
                                      </Stack>
                                    </CardActionArea>
                                  </Card>
                                </Tooltip>
                              </Grid>
                              {/* Outstanding */}
                              {!isSalon && (
                                <Grid item xs={12} md={3} lg={3}>
                                  <Tooltip
                                    title={Utils.GetCurrency(
                                      outstandingStat,
                                      localSettings.country,
                                      0
                                    )}
                                    arrow
                                  >
                                    <Card className="card">
                                      <Link
                                        to={VisitsPage.Path}
                                        state={{
                                          startDT: Utils.GetISODateTimeString(
                                            selectedDate[0]
                                          ),
                                          endDT: Utils.GetISODateTimeString(
                                            selectedDate[1]
                                          ),
                                          paymentType:
                                            PaymentMethodTypes.UnPaid,
                                        }}
                                      >
                                        <CardActionArea>
                                          <Stack
                                            direction="row"
                                            style={{ width: "100%" }}
                                          >
                                            <Box className="stat-icon">
                                              <Box
                                                className="flex-end"
                                                component="img"
                                                sx={{
                                                  height: 34,
                                                  width: 34,
                                                }}
                                                alt="Outstanding"
                                                src={Outstanding_Icon}
                                              />
                                            </Box>
                                            <CardContent
                                              className="p-h"
                                              style={{
                                                width: "calc(100% - 60px)",
                                              }}
                                            >
                                              <Typography
                                                level="h3"
                                                component="h3"
                                                fontSize="2rem"
                                                fontWeight="600"
                                                color="orange"
                                                className="text-ellipsis"
                                              >
                                                {Utils.GetCurrency(
                                                  outstandingStat,
                                                  localSettings.country,
                                                  0
                                                )}
                                              </Typography>
                                              <Typography
                                                level="span"
                                                component="span"
                                                fontSize="1rem"
                                              >
                                                Outstanding
                                              </Typography>
                                            </CardContent>
                                          </Stack>
                                        </CardActionArea>
                                      </Link>
                                    </Card>
                                  </Tooltip>
                                </Grid>
                              )}

                              {/* Refunds */}
                              <Grid item xs={12} md={3} lg={3}>
                                <Tooltip
                                  title={Utils.GetCurrency(
                                    refundsStat,
                                    localSettings.country,
                                    0
                                  )}
                                  arrow
                                >
                                  <Card className="card">
                                    <CardActionArea disabled={true}>
                                      <Stack
                                        direction="row"
                                        style={{ width: "100%" }}
                                      >
                                        <Box className="stat-icon">
                                          <Box
                                            className="flex-end"
                                            component="img"
                                            sx={{
                                              height: 34,
                                              width: 34,
                                            }}
                                            alt="Refunds"
                                            src={refundIcon}
                                          />
                                        </Box>
                                        <CardContent
                                          className="p-h"
                                          style={{ width: "calc(100% - 60px)" }}
                                        >
                                          <Typography
                                            level="h3"
                                            component="h3"
                                            fontSize="2rem"
                                            fontWeight="600"
                                            className="text-ellipsis"
                                          >
                                            {Utils.GetCurrency(
                                              refundsStat,
                                              localSettings.country,
                                              0
                                            )}
                                          </Typography>
                                          <Typography
                                            level="span"
                                            component="span"
                                            fontSize="1rem"
                                          >
                                            Refunds
                                          </Typography>
                                        </CardContent>
                                      </Stack>
                                    </CardActionArea>
                                  </Card>
                                </Tooltip>
                              </Grid>
                              {/* Tax */}
                              <Grid item xs={12} md={3} lg={3}>
                                <Tooltip
                                  title={Utils.GetCurrency(
                                    taxStat,
                                    localSettings.country,
                                    0
                                  )}
                                  arrow
                                >
                                  <Card className="card">
                                    <CardActionArea
                                      disabled={true}
                                      className="d-flex"
                                    >
                                      <Stack
                                        direction="row"
                                        style={{ width: "100%" }}
                                      >
                                        <Box className="stat-icon">
                                          <Box
                                            className="flex-end"
                                            component="img"
                                            sx={{
                                              height: 34,
                                              width: 34,
                                            }}
                                            alt="Tax"
                                            src={taxIcon}
                                          />
                                        </Box>
                                        <CardContent
                                          className="p-h"
                                          style={{ width: "calc(100% - 60px)" }}
                                        >
                                          <Typography
                                            level="h3"
                                            component="h3"
                                            fontSize="2rem"
                                            fontWeight="600"
                                            className="text-ellipsis"
                                          >
                                            {Utils.GetCurrency(
                                              taxStat,
                                              localSettings.country,
                                              0
                                            )}
                                          </Typography>
                                          <Typography
                                            level="span"
                                            component="span"
                                            fontSize="1rem"
                                          >
                                            Tax
                                          </Typography>
                                        </CardContent>
                                      </Stack>
                                    </CardActionArea>
                                  </Card>
                                </Tooltip>
                              </Grid>
                            </Grid>

                            <Grid
                              container
                              xs={12}
                              style={{ padding: "0 0 40px" }}
                            >
                              <Grid
                                style={{ padding: "10px 10px 0" }}
                                className="mt-1"
                              >
                                <Stack
                                  direction="row"
                                  justifyContent="center"
                                  padding="0px 10px"
                                >
                                  <Typography
                                    level="h2"
                                    component="h2"
                                    fontWeight="600"
                                    fontSize="1.6rem"
                                    className="h1"
                                  >
                                    Payments
                                  </Typography>
                                </Stack>
                              </Grid>
                              <Grid
                                container
                                className="payment-card flex-wrap m-0"
                                item
                                spacing={2}
                              >
                                {/* Points */}
                                {isUSABusiness && (
                                  <Grid item xs={12} md={3} lg={3}>
                                    <Card className="card">
                                      <CardActionArea disabled={true}>
                                        <Box
                                          className="flex-end"
                                          component="img"
                                          sx={{
                                            height: 34,
                                            width: 34,
                                          }}
                                          alt="Points"
                                          src={Points_Icon}
                                        />
                                        <CardContent className="p-h">
                                          <Typography
                                            level="h3"
                                            component="h3"
                                            fontSize="2rem"
                                            fontWeight="600"
                                          >
                                            {Utils.GetCurrency(
                                              pointsStat,
                                              localSettings.country,
                                              0
                                            )}
                                          </Typography>
                                          <Typography
                                            level="span"
                                            component="span"
                                            fontSize="1rem"
                                          >
                                            Points
                                          </Typography>
                                        </CardContent>
                                      </CardActionArea>
                                    </Card>
                                  </Grid>
                                )}

                                {/* Card */}
                                <Grid item xs={12} md={3} lg={3}>
                                  <Tooltip
                                    title={Utils.GetCurrency(
                                      cardStat,
                                      localSettings.country,
                                      0
                                    )}
                                    arrow
                                  >
                                    <Card className="card bl-0">
                                      <Link
                                        to={
                                          !isUSABusiness
                                            ? VisitsPage.Path
                                            : "javascript:void(0)"
                                        }
                                        state={{
                                          startDT: Utils.GetISODateTimeString(
                                            selectedDate[0]
                                          ),
                                          endDT: Utils.GetISODateTimeString(
                                            selectedDate[1]
                                          ),
                                          paymentType: PaymentMethodTypes.Card,
                                        }}
                                      >
                                        <CardActionArea>
                                          <Stack
                                            direction="row"
                                            style={{ width: "100%" }}
                                          >
                                            <Box className="stat-icon">
                                              <Box
                                                className="flex-end"
                                                component="img"
                                                sx={{
                                                  height: 34,
                                                  width: 34,
                                                }}
                                                alt="Card"
                                                src={creditCardIcon}
                                              />
                                            </Box>
                                            <CardContent
                                              className="p-h"
                                              style={{
                                                width: "calc(100% - 60px)",
                                              }}
                                            >
                                              <Typography
                                                level="h3"
                                                component="h3"
                                                fontSize="2rem"
                                                fontWeight="600"
                                                className="text-ellipsis"
                                              >
                                                {Utils.GetCurrency(
                                                  cardStat,
                                                  localSettings.country,
                                                  0
                                                )}
                                              </Typography>
                                              <Typography
                                                level="span"
                                                component="span"
                                                fontSize="1rem"
                                                className="text-ellipsis"
                                              >
                                                Card
                                              </Typography>
                                            </CardContent>
                                          </Stack>
                                        </CardActionArea>
                                      </Link>
                                    </Card>
                                  </Tooltip>
                                </Grid>

                                {/* Cash */}
                                <Grid item xs={12} md={3} lg={3}>
                                  <Tooltip
                                    title={Utils.GetCurrency(
                                      cashStat,
                                      localSettings.country,
                                      0
                                    )}
                                    arrow
                                  >
                                    <Card className="card">
                                      <Link
                                        to={VisitsPage.Path}
                                        state={{
                                          startDT: Utils.GetISODateTimeString(
                                            selectedDate[0]
                                          ),
                                          endDT: Utils.GetISODateTimeString(
                                            selectedDate[1]
                                          ),
                                          paymentType: PaymentMethodTypes.Cash,
                                        }}
                                      >
                                        <CardActionArea>
                                          <Stack
                                            direction="row"
                                            style={{ width: "100%" }}
                                          >
                                            <Box className="stat-icon">
                                              <Box
                                                className="flex-end"
                                                component="img"
                                                sx={{
                                                  height: 34,
                                                  width: 34,
                                                }}
                                                alt="Cash"
                                                src={Cash_Icon}
                                              />
                                            </Box>
                                            <CardContent
                                              className="p-h"
                                              style={{
                                                width: "calc(100% - 60px)",
                                              }}
                                            >
                                              <Typography
                                                level="h3"
                                                component="h3"
                                                fontSize="2rem"
                                                fontWeight="600"
                                                className="text-ellipsis"
                                              >
                                                {Utils.GetCurrency(
                                                  cashStat,
                                                  localSettings.country,
                                                  0
                                                )}
                                              </Typography>
                                              <Typography
                                                level="span"
                                                component="span"
                                                fontSize="1rem"
                                              >
                                                Cash
                                              </Typography>
                                            </CardContent>
                                          </Stack>
                                        </CardActionArea>
                                      </Link>
                                    </Card>
                                  </Tooltip>
                                </Grid>

                                {/* UPI */}
                                {isOtherPaymentsAvailable && (
                                  <Grid item xs={12} md={3} lg={3}>
                                    <Tooltip
                                      title={Utils.GetCurrency(
                                        upiStat,
                                        localSettings.country,
                                        0
                                      )}
                                      arrow
                                    >
                                      <Card className="card bl-0">
                                        <Link
                                          to={VisitsPage.Path}
                                          state={{
                                            startDT: Utils.GetISODateTimeString(
                                              selectedDate[0]
                                            ),
                                            endDT: Utils.GetISODateTimeString(
                                              selectedDate[1]
                                            ),
                                            paymentType: PaymentMethodTypes.UPI,
                                          }}
                                        >
                                          <CardActionArea>
                                            <Stack
                                              direction="row"
                                              style={{ width: "100%" }}
                                            >
                                              <Box className="stat-icon">
                                                <Box
                                                  className="flex-end"
                                                  component="img"
                                                  sx={{
                                                    height: 34,
                                                    width: 34,
                                                  }}
                                                  alt="UPI"
                                                  src={UPI_Icon}
                                                />
                                              </Box>
                                              <CardContent
                                                className="p-h"
                                                style={{
                                                  width: "calc(100% - 60px)",
                                                }}
                                              >
                                                <Typography
                                                  level="h3"
                                                  component="h3"
                                                  fontSize="2rem"
                                                  fontWeight="600"
                                                  className="text-ellipsis"
                                                >
                                                  {Utils.GetCurrency(
                                                    upiStat,
                                                    localSettings.country,
                                                    0
                                                  )}
                                                </Typography>
                                                <Typography
                                                  level="span"
                                                  component="span"
                                                  fontSize="1rem"
                                                >
                                                  UPI
                                                </Typography>
                                              </CardContent>
                                            </Stack>
                                          </CardActionArea>
                                        </Link>
                                      </Card>
                                    </Tooltip>
                                  </Grid>
                                )}

                                {/* Net Banking */}
                                {isOtherPaymentsAvailable && (
                                  <Grid item xs={12} md={3} lg={3}>
                                    <Tooltip
                                      title={Utils.GetCurrency(
                                        netbankingStat,
                                        localSettings.country,
                                        0
                                      )}
                                      arrow
                                    >
                                      <Card className="card">
                                        <Link
                                          to={VisitsPage.Path}
                                          state={{
                                            startDT: Utils.GetISODateTimeString(
                                              selectedDate[0]
                                            ),
                                            endDT: Utils.GetISODateTimeString(
                                              selectedDate[1]
                                            ),
                                            paymentType:
                                              PaymentMethodTypes.NetBanking,
                                          }}
                                        >
                                          <CardActionArea>
                                            <Stack
                                              direction="row"
                                              style={{ width: "100%" }}
                                            >
                                              <Box className="stat-icon">
                                                <Box
                                                  className="flex-end"
                                                  component="img"
                                                  sx={{
                                                    height: 34,
                                                    width: 34,
                                                  }}
                                                  alt="Net Banking"
                                                  src={Netbanking_Icon}
                                                />
                                              </Box>
                                              <CardContent
                                                className="p-h"
                                                style={{
                                                  width: "calc(100% - 60px)",
                                                }}
                                              >
                                                <Typography
                                                  level="h3"
                                                  component="h3"
                                                  fontSize="2rem"
                                                  fontWeight="600"
                                                  className="text-ellipsis"
                                                >
                                                  {Utils.GetCurrency(
                                                    netbankingStat,
                                                    localSettings.country,
                                                    0
                                                  )}
                                                </Typography>
                                                <Typography
                                                  level="span"
                                                  component="span"
                                                  fontSize="1rem"
                                                >
                                                  Net Banking
                                                </Typography>
                                              </CardContent>
                                            </Stack>
                                          </CardActionArea>
                                        </Link>
                                      </Card>
                                    </Tooltip>
                                  </Grid>
                                )}
                              </Grid>

                              <Grid
                                className="flex-wrap"
                                style={{ display: "none" }}
                              >
                                <Grid
                                  container
                                  padding="60px 15px"
                                  xs={12}
                                  item
                                >
                                  {/* Quick Links */}
                                  <Stack
                                    direction="row"
                                    spacing={1}
                                    width="100%"
                                    justifyContent="center"
                                  >
                                    <Link
                                      href=""
                                      className="btn btn-secondary"
                                      variant="outlined"
                                    >
                                      <EventAvailableOutlinedIcon
                                        fontSize="20"
                                        style={{
                                          color: "#9C27B0",
                                          "vertical-align": "middle",
                                        }}
                                      />
                                      Bookings
                                      <NavigateNextIcon className="go-icon" />
                                    </Link>
                                    <Link
                                      to={CheckOutPage.Path}
                                      className="btn btn-secondary"
                                      variant="outlined"
                                    >
                                      <CheckCircleOutlineOutlinedIcon
                                        fontSize="20"
                                        style={{
                                          color: "#2196f3",
                                          "vertical-align": "middle",
                                        }}
                                      />
                                      Check-Out
                                      <NavigateNextIcon className="go-icon" />
                                    </Link>
                                    {isAutomobile && (
                                      <Link
                                        to={CustomersPage.Path}
                                        state={{ fromJobcard: true }}
                                        className="btn btn-secondary"
                                        variant="outlined"
                                      >
                                        <NoCrashOutlinedIcon
                                          fontSize="20"
                                          style={{
                                            color: "#ff0000",
                                            "vertical-align": "middle",
                                          }}
                                        />
                                        Job Card
                                        <NavigateNextIcon className="go-icon" />
                                      </Link>
                                    )}
                                    <Link
                                      to={BusinessSetupPage.Path}
                                      className="btn btn-secondary"
                                      variant="outlined"
                                    >
                                      <NoteAltOutlinedIcon
                                        fontSize="20"
                                        style={{
                                          color: "#8BC34A",
                                          "vertical-align": "middle",
                                        }}
                                      />
                                      Setup
                                      <NavigateNextIcon className="go-icon" />
                                    </Link>
                                  </Stack>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid
                    container
                    padding="15px"
                    className="stat-sec"
                    style={{ marginTop: "15px" }}
                  >
                    <Grid xs={12} item>
                      <Grid
                        container
                        xs={12}
                        item
                        spacing={2}
                        className="card-box"
                      >
                        <Grid
                          container
                          xs={12}
                          className="flex-wrap"
                          spacing={2}
                          style={{ marginLeft: "0px" }}
                        >
                          <Grid item xs={12}>
                            <Tooltip
                              title={Utils.GetCurrency(
                                totalExpenseStat,
                                localSettings.country,
                                0
                              )}
                              arrow
                            >
                              <Card className="card">
                                <Link to="/Expense">
                                  <CardActionArea
                                    disabled={true}
                                    className="d-flex"
                                  >
                                    <Stack
                                      direction="row"
                                      style={{ width: "100%" }}
                                    >
                                      <Box className="stat-icon">
                                        <Box
                                          className="flex-end"
                                          component="img"
                                          sx={{
                                            height: 46,
                                            width: 46,
                                          }}
                                          alt="Expense"
                                          src={Expense_Icon}
                                        />
                                      </Box>
                                      <CardContent
                                        className="p-h"
                                        style={{ width: "calc(100% - 60px)" }}
                                      >
                                        <Typography
                                          level="h3"
                                          component="h3"
                                          fontSize="2rem"
                                          fontWeight="600"
                                          className="text-ellipsis"
                                        >
                                          {Utils.GetCurrency(
                                            totalExpenseStat,
                                            localSettings.country,
                                            0
                                          )}
                                        </Typography>
                                        <Typography
                                          level="span"
                                          component="span"
                                          fontSize="1rem"
                                        >
                                          Expense
                                        </Typography>
                                      </CardContent>
                                    </Stack>
                                  </CardActionArea>
                                </Link>
                              </Card>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                {/* Chart */}
                <Grid container padding="15px" className="xs-pd-0 hide">
                  <Stack
                    justifyContent="space-between"
                    direction="row"
                    style={{ width: "100%" }}
                    spacing={1}
                    className="xs-column m-xs-0"
                  >
                    <Grid xs={12} md={6} lg={6} className="card">
                      <Grid container mt={2} justifyContent="center">
                        <Typography
                          level="h4"
                          component="h4"
                          fontWeight="600"
                          fontSize="1.6rem"
                          className="h1"
                        >
                          Revenue
                        </Typography>
                      </Grid>
                      <Stack justifyContent="center" className="customer-chart">
                        <PieChart
                          series={[
                            {
                              data: [
                                {
                                  id: 0,
                                  value: productRevenue,
                                  label: BusinessHelper.GetSparesTitle(isSalon),
                                },
                                {
                                  id: 1,
                                  value: materialRevenueStat,
                                  label:
                                    BusinessHelper.GetConsumablesTitle(isSalon),
                                },
                                {
                                  id: 2,
                                  value: serviceRevenueStat,
                                  label:
                                    BusinessHelper.GetServicesTitle(isSalon),
                                },
                              ],
                              innerRadius: 30,
                              outerRadius: 100,
                              paddingAngle: 2,
                              cornerRadius: 5,
                            },
                          ]}
                          width={400}
                          height={200}
                        />
                      </Stack>
                    </Grid>
                    {isAutomobile ? (
                      <Grid xs={12} md={6} lg={6} className="card">
                        <Grid container mt={2} justifyContent="center">
                          <Typography
                            level="h4"
                            component="h4"
                            fontWeight="600"
                            fontSize="1.6rem"
                            className="h1"
                          >
                            Job Cards
                          </Typography>
                        </Grid>
                        <Stack
                          justifyContent="center"
                          className="jobcard-chart"
                          style={{ "font-size": "10px" }}
                        >
                          {/* <LineChart
                            xAxis={[{ data: [1, 2, 3, 5, 8, 12] }]}
                            series={[
                              {
                                data: [20, 55, 20, 35, 95, 110],
                              },
                            ]}
                            width={600}
                            height={300}
                          />
                          <Typography
                            component="p"
                            alignSelf="center"
                            marginTop="-20px"
                            paddingBottom="20px"
                          >
                            Months
                          </Typography> */}
                          <BarChart dataset={dataset} {...chartSetting} />
                        </Stack>
                      </Grid>
                    ) : (
                      <Grid xs={12} md={6} lg={6} className="card text-center">
                        <Link
                          to={BusinessSetupPage.Path}
                          mx={1}
                          style={{ "justify-content": "center" }}
                        >
                          <Box
                            component="img"
                            src={Salon_Services_Pic}
                            style={{ height: "300px" }}
                          />
                        </Link>
                      </Grid>
                    )}
                  </Stack>
                </Grid>
              </Grid>

              <Grid xs={12} container padding="0 15px" mt={2} className="hide">
                <Stack
                  justifyContent="space-between"
                  direction="row"
                  style={{ width: "100%" }}
                  spacing={1}
                  className="xs-column"
                >
                  {/* Customers */}
                  <Grid xs={12} md={3} lg={3}>
                    <Grid container mt={2}>
                      <Typography
                        level="h2"
                        component="h2"
                        fontWeight="600"
                        fontSize="1.6rem"
                        className="h1"
                      >
                        Customers
                      </Typography>
                    </Grid>
                    <Grid container className="mt-1">
                      <Stack
                        justifyContent="space-between"
                        direction="column"
                        style={{ width: "100%" }}
                        spacing={1}
                        className="xs-row m-xs-0"
                      >
                        <Grid xs={6} md={12} lg={12} className="card">
                          <CardActionArea disabled={true}>
                            <Stack direction="row" style={{ width: "100%" }}>
                              <Box className="stat-icon">
                                <Box
                                  className="flex-end"
                                  component="img"
                                  sx={{
                                    height: 34,
                                    width: 34,
                                  }}
                                  alt="Sign Ups"
                                  src={Signups_Icon}
                                />
                              </Box>
                              <CardContent className="p-h">
                                <Typography
                                  level="h3"
                                  component="h3"
                                  fontSize="2rem"
                                  fontWeight="600"
                                >
                                  {signupsStat}
                                </Typography>
                                <Typography
                                  level="span"
                                  component="span"
                                  fontSize="1rem"
                                >
                                  Sign Ups
                                </Typography>
                              </CardContent>
                            </Stack>
                          </CardActionArea>
                        </Grid>
                        <Grid xs={6} md={12} lg={12} className="card">
                          <CardActionArea disabled={true}>
                            <Stack direction="row" style={{ width: "100%" }}>
                              <Box className="stat-icon">
                                <Box
                                  className="flex-end"
                                  component="img"
                                  sx={{
                                    height: 34,
                                    width: 34,
                                  }}
                                  alt="Active"
                                  src={Active_Icon}
                                />
                              </Box>
                              <CardContent className="p-h">
                                <Typography
                                  level="h3"
                                  component="h3"
                                  fontSize="2rem"
                                  fontWeight="600"
                                >
                                  {activeStat}
                                </Typography>
                                <Typography
                                  level="span"
                                  component="span"
                                  fontSize="1rem"
                                >
                                  Active
                                </Typography>
                              </CardContent>
                            </Stack>
                          </CardActionArea>
                        </Grid>
                      </Stack>
                    </Grid>
                  </Grid>
                  {/* Events */}
                  <Grid xs={12} md={6} lg={6} className="m-xs-0">
                    <Stack direction="column" mt={2} className="img-sec">
                      <Typography
                        level="h2"
                        component="h2"
                        fontWeight="600"
                        fontSize="1.6rem"
                        className="h1"
                      >
                        Events
                      </Typography>
                      <Grid container xs={12}>
                        <Stack
                          justifyContent="space-between"
                          direction="row"
                          style={{ width: "100%" }}
                          spacing={1}
                        >
                          {isAutomobile && (
                            <Grid xs={4}>
                              <Box
                                className="mt-1 text-center"
                                style={{
                                  background:
                                    "linear-gradient(to bottom, #018ec8, #53c3e6)",
                                  "border-radius": "4px",
                                }}
                              >
                                <Link to={InsurancePage.Path}>
                                  <Box
                                    className="flex-end"
                                    component="img"
                                    sx={{
                                      height: 168,
                                      borderRadius: 6,
                                    }}
                                    alt="Insurance"
                                    src={Insurance_New_Icon}
                                  />
                                </Link>
                              </Box>
                            </Grid>
                          )}
                          {isAutomobile && (
                            <Grid xs={4}>
                              <Box
                                className="mt-1 text-center"
                                style={{
                                  background: "#132f56",
                                  "border-radius": "4px",
                                }}
                              >
                                <Link
                                  to={ServiceReminderPage.Path}
                                  state={{
                                    startDT: Utils.GetISODateTimeString(
                                      selectedDate[0]
                                    ),
                                    endDT: Utils.GetISODateTimeString(
                                      selectedDate[1]
                                    ),
                                  }}
                                >
                                  <Box
                                    className="flex-end"
                                    component="img"
                                    sx={{
                                      height: 168,
                                      borderRadius: 6,
                                    }}
                                    alt="Service"
                                    src={Service_New_Icon}
                                  />
                                </Link>
                              </Box>
                            </Grid>
                          )}
                          <Grid xs={4}>
                            <Box
                              className="mt-1 text-center"
                              style={{
                                background: "#feac18",
                                "border-radius": "4px",
                              }}
                            >
                              <Link to={BirthdayPage.Path}>
                                <Box
                                  className="flex-end"
                                  component="img"
                                  sx={{
                                    height: 168,
                                    borderRadius: 6,
                                  }}
                                  alt="Birthday"
                                  src={Birthday_New_Icon}
                                />
                              </Link>
                            </Box>
                          </Grid>
                          {/* {isAutomobile && (
                      <Link to="/Service" mx={1}>
                        <Box
                          className="flex-end"
                          component="img"
                          sx={{
                            width: "100%",
                            height: 250,
                            borderRadius: 6,
                          }}
                          alt="Service"
                          src={Service_New_Icon}
                        />
                      </Link>
                    )} */}
                        </Stack>
                      </Grid>
                    </Stack>
                  </Grid>
                  { }
                  <Grid xs={12} md={3} lg={3} className="m-xs-0">
                    <Stack direction="column" mt={2} className="img-sec">
                      <Typography
                        mx={1}
                        level="h2"
                        component="h2"
                        fontWeight="600"
                        fontSize="1.6rem"
                        className="h1"
                      >
                        Manage
                      </Typography>
                      <Box
                        className="mt-1 text-center"
                        style={{
                          background: "#48a088",
                          "border-radius": "4px",
                        }}
                      >
                        <Link to={ExpensePage.Path} mx={1}>
                          <Box
                            className="flex-end"
                            component="img"
                            sx={{
                              height: 168,
                              borderRadius: 6,
                            }}
                            alt="Expense"
                            src={Expense_New_Icon}
                          />
                        </Link>
                      </Box>
                    </Stack>
                  </Grid>
                </Stack>
              </Grid>

              {/* Payouts */}
              {showPayouts && (
                <Grid container>
                  <Grid container mt={3}>
                    <Typography
                      level="h2"
                      component="h2"
                      fontWeight="600"
                      fontSize="1.6rem"
                      className="h1"
                    >
                      Payouts
                    </Typography>
                  </Grid>
                  <Grid container spacing={2} mt={1}>
                    <Grid item xs>
                      <Card className="card">
                        <Link href="/Overview/">
                          <CardActionArea>
                            <CardContent className="p-h">
                              <Typography
                                level="h3"
                                component="h3"
                                fontSize="2rem"
                                fontWeight="600"
                              >
                                {Utils.GetCurrency(
                                  payoutTotalStat - payoutSettledStat,
                                  localSettings.country,
                                  0
                                )}
                              </Typography>
                              <Typography
                                level="span"
                                component="span"
                                fontSize="1rem"
                              >
                                Pending
                              </Typography>
                            </CardContent>
                          </CardActionArea>
                        </Link>
                      </Card>
                    </Grid>
                    <Grid item xs>
                      <Card className="card">
                        <Link href="/Overview/">
                          <CardActionArea>
                            <CardContent className="p-h">
                              <Typography
                                level="h3"
                                component="h3"
                                fontSize="2rem"
                                fontWeight="600"
                              >
                                {Utils.GetCurrency(
                                  payoutSettledStat,
                                  localSettings.country,
                                  0
                                )}
                              </Typography>
                              <Typography
                                level="span"
                                component="span"
                                fontSize="1rem"
                              >
                                Paid
                              </Typography>
                            </CardContent>
                          </CardActionArea>
                        </Link>
                      </Card>
                    </Grid>
                    <Grid item xs>
                      <Card className="card">
                        <Link href="/Overview/">
                          <CardActionArea>
                            <CardContent className="p-h">
                              <Typography
                                level="h3"
                                component="h3"
                                fontSize="2rem"
                                fontWeight="600"
                              >
                                {Utils.GetCurrency(
                                  payoutTotalStat,
                                  localSettings.country,
                                  0
                                )}
                              </Typography>
                              <Typography
                                level="span"
                                component="span"
                                fontSize="1rem"
                              >
                                Total
                              </Typography>
                            </CardContent>
                          </CardActionArea>
                        </Link>
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default Overview;
