import Autocomplete, { usePlacesWidget } from "react-google-autocomplete";

const AddressField = ({ value, setValue, countryISoCode, label, isValidate = false }) => {
    const handleOnChange = (e) => {
        setValue(e);
    }

    return isValidate ? (
        <Autocomplete
            apiKey={"AIzaSyCk2uVaMZuNW--_uwSDwq8GxYWNDD7KN2Q"}
            onPlaceSelected={(place) => {
                setValue(place.formatted_address);
                const lat = place.geometry.location.lat();
                const lng = place.geometry.location.lng();
            }}
            id="branchAddress"
            class={!value ? "pac-target-input-first" : "pac-target-input"}
            label={label}
            variant="outlined"
            required
            options={{
                types: ["geocode", "establishment"],
                componentRestrictions: { country: countryISoCode },
            }}
            value={value}
            onChange={(e) => {
                handleOnChange(e.target.value);
            }}
        />) : (
        <Autocomplete
            apiKey={"AIzaSyCk2uVaMZuNW--_uwSDwq8GxYWNDD7KN2Q"}
            onPlaceSelected={(place) => {
                setValue(place.formatted_address);
                const lat = place.geometry.location.lat();
                const lng = place.geometry.location.lng();
            }}
            id="branchAddress"
            class="pac-target-input"
            label={label}
            variant="outlined"
            required
            options={{
                types: ["geocode", "establishment"],
                componentRestrictions: { country: countryISoCode },
            }}
            value={value}
            onChange={(e) => {
                handleOnChange(e.target.value);
            }}
        />);
}
export default AddressField;