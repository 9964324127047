import { SETTER, GETTER } from "./localSettingsTypes";
import Utils from "../core/Utils";
import { connectToStore } from "./store";

export const setLogin = (loginResponse, dispatch) => {
  dispatch({
    type: SETTER.LOGINRESPONSE,
    payload: loginResponse,
  });
};

export const setToken = (token, dispatch) => {
  dispatch({
    type: SETTER.TOKEN,
    payload: token,
  });
};

export const setBusiness = (business, dispatch) => {
  dispatch({
    type: SETTER.BUSINESS,
    payload: business,
  });
};

export const setBranch = (branch, dispatch) => {
  dispatch({
    type: SETTER.BRANCH,
    payload: branch,
  });
};

export const setProducts = (products, dispatch) => {
  dispatch({
    type: SETTER.PRODUCTS,
    payload: products,
  });
};

export const setCountry = async (country, dispatch) => {
  dispatch({
    type: SETTER.COUNTRY,
    payload: country,
  });
};

export const setSelectedCustomer = (pass, dispatch) => {
  dispatch({
    type: SETTER.SELECTEDCUSTOMER,
    payload: pass,
  });
};

export const setSelectedVehicle = (vehicle, dispatch) => {
  dispatch({
    type: SETTER.SELECTEDVEHICLE,
    payload: vehicle,
  });
};

export const setSelectedVisit = (visit, dispatch) => {
  dispatch({
    type: SETTER.SELECTEDVISIT,
    payload: visit,
  });
};

export const setNavigationState = (state, dispatch) => {
  dispatch({
    type: SETTER.NAVIGATIONSTATE,
    payload: state,
  });
};

export const setSelectedServiceItems = (serviceItems, dispatch) => {
  dispatch({
    type: SETTER.SELECTEDSERVICEITEMS,
    payload: serviceItems,
  });
};
export const setIsDarkMode = (state, dispatch) => {
  dispatch({
    type: SETTER.ISDARKMODE,
    payload: state,
  });
};

export const resetStore = (dispatch) => {
  dispatch({ type: SETTER.RESET });
};

export const getBusiness = () => {
  return getItemFromStore(GETTER.BUSINESS);
};

export const getIsDarkMode = () => {
  return getItemFromStore(GETTER.ISDARKMODE);
};

export const getBranch = () => {
  return getItemFromStore(GETTER.BRANCH);
};

export const getUser = () => {
  return getItemFromStore(GETTER.USER);
};

export const getEnterpriseId = () => {
  return getItemFromStore(GETTER.ENTERPRISEID);
};

export const getAuthentication = () => {
  return getItemFromStore(GETTER.AUTHENTICATION);
};

export const getProducts = () => {
  return getItemFromStore(GETTER.PRODUCTS);
};

export const getCountry = () => {
  return getItemFromStore(GETTER.COUNTRY);
};

export const getSelectedCustomer = () => {
  return getItemFromStore(GETTER.SELECTEDCUSTOMER);
};

export const getSelectedVehicle = () => {
  return getItemFromStore(GETTER.SELECTEDVEHICLE);
};

export const getSelectedVisit = () => {
  return getItemFromStore(GETTER.SELECTEDVISIT);
};

export const getNavigationState = () => {
  return getItemFromStore(GETTER.NAVIGATIONSTATE);
};

export const getCampaigns = () => {
  return getItemFromStore(GETTER.CAMPAIGNS);
};

export const getSelectedServiceItems = () => {
  return getItemFromStore(GETTER.SELECTEDSERVICEITEMS);
};

export const getMakeModels = () => {
  var makeAndModels = getItemFromStore(GETTER.MAKEMODEL);
  var branch = getBranch();
  if (!Utils.IsNullOrEmptyObject(branch) && !Utils.IsNullOrEmpty(branch.vehicleType) && !Utils.IsNullOrEmptyArray(makeAndModels)) {
    makeAndModels = makeAndModels.filter(
      (mm) => mm.type === branch.vehicleType
    );
  }
  return makeAndModels;
};
export const getAdmin = () => {
  return getItemFromStore(GETTER.XUVIADMIN);
};

const getItemFromStore = (item) => {
  if (Utils.IsNullOrEmpty(item) === true) {
    return "";
  }
  let store = connectToStore();
  switch (item) {
    case GETTER.BUSINESS:
      return store.business;
    case GETTER.BRANCH:
      return store.branch;
    case GETTER.USER:
      return store.user;
    case GETTER.ENTERPRISEID:
      return store.enterpriseId;
    case GETTER.AUTHENTICATION:
      return store.authenticated;
    case GETTER.PRODUCTS:
      return store.products;
    case GETTER.SELECTEDCUSTOMER:
      return store.selectedCustomerPass;
    case GETTER.SELECTEDVEHICLE:
      return store.selectedVehicle;
    case GETTER.SELECTEDVISIT:
      return store.selectedVisit;
    case GETTER.COUNTRY:
      return store.country;
    case GETTER.NAVIGATIONSTATE:
      return store.navigationState;
    case GETTER.CAMPAIGNS:
      return store.campaigns;
    case GETTER.SELECTEDSERVICEITEMS:
      return store.selectedServiceItems;
    case GETTER.MAKEMODEL:
      return store.makeAndModels.makeModel;
    case GETTER.XUVIADMIN:
      return store.admin;
    case GETTER.ISDARKMODE:
      return store.isDarkMode;
    default:
      return null;
  }
};
