import React, { useEffect, useState, useReducer } from "react";
import "../Style.css";
import "../bootstrap.min.css";

import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { useNavigate, useLocation } from "react-router-dom";

import Button from "@mui/material/Button";
import SideBarMenuItems from "./SideBarMenuItems";
import { useCallbackPrompt } from "./hooks/useCallbackPrompt";

import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";

import {
  getAuthentication,
  getCountry,
  getProducts,
} from "../data/localSettingsActions";
import Utils from "../core/Utils";
import { SideBarMenuNames, VisitTypeEnum } from "../core/Constants";
import { serviceItemReducer } from "./reducers/serviceItem/serviceItemReducer";
import { serviceItemActionTypes } from "./reducers/serviceItem/serviceItemActionTypes";
import { getServiceItemFromProduct } from "../helpers/productHelper";
import CartComponent from "./common/CartComponent";
import MessagePopup from "./common/MessagePopUp";
import PromptDialog from "./common/PromptDialog";
import UserHelper from "../core/UserHelper";
import { CheckOutPage, CustomersPage, OverviewPage, PaymentPage, VisitsPage } from "../core/PageConstants";

const CheckOut = () => {
  document.title = "CheckOut";
  const navigate = useNavigate();
  const location = useLocation();
  const [showDialog, setShowDialog] = useState(false);
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(showDialog);
  const [countryInfo, setCountryInfo] = useState({});
  const [totalPrice, setTotalPrice] = useState(0);
  const [frequentlyUsedProducts, setFrequentlyUsedProducts] = useState([]);
  const [filteredServiceItems, itemDispatch] = useReducer(
    serviceItemReducer,
    []
  );
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState(false);
  const [canProceedPayment, setCanProceedPayment] = useState(false);

  const setAlert = (msg) => {
    setAlertMessage(msg);
    setShowAlert(true);
  };

  useEffect(() => {
    if (UserHelper.CheckPermission(CheckOutPage, navigate)) {
      setCountryInfo(getCountry());
      var allProducts = getProducts();
      var defaultProducts = [];
      if (!Utils.IsNullOrEmptyArray(allProducts)) {
        defaultProducts = allProducts.filter((p) => p.default && p.active);
      }
      if (!Utils.IsNullOrEmptyArray(defaultProducts)) {
        setFrequentlyUsedProducts(defaultProducts);
      }
      if (
        !Utils.IsNullOrEmptyObject(location.state) &&
        !Utils.IsNullOrEmptyArray(location.state.serviceItems)
      ) {
        location.state.serviceItems.forEach((item) => {
          if (
            Utils.IsNullOrEmptyObject(
              filteredServiceItems.find((s) => s.ProductId === item.ProductId)
            )
          )
            itemDispatch({
              type: serviceItemActionTypes.Add,
              value: item,
            });
        });
      }
    }
  }, []);

  const clickOpenPayment = () => {
    if (Utils.IsNullOrEmptyArray(filteredServiceItems)) {
      setAlert("Please add service items before payment");
      return;
    }
    if (
      filteredServiceItems.some((c) => c.Quantity === 0 || c.Quantity === "")
    ) {
      setAlert("Quantity must be greater than 0");
      return;
    }
    setShowDialog(false);
    setCanProceedPayment(true);
  };

  useEffect(() => {
    navigateToPayment();
  }, [canProceedPayment]);

  const navigateToPayment = () => {
    if (canProceedPayment && !showDialog) {
      navigate(PaymentPage.Path, {
        state: {
          ...location.state,
          isEdit:
            Utils.IsNullOrEmpty(location.state) ||
            Utils.IsNullOrEmpty(location.state.isEdit)
              ? false
              : location.state.isEdit,
          fromVisit:
            Utils.IsNullOrEmpty(location.state) ||
            Utils.IsNullOrEmpty(location.state.fromVisit)
              ? false
              : location.state.fromVisit,
          visit:
            Utils.IsNullOrEmpty(location.state) ||
            Utils.IsNullOrEmptyObject(location.state.visit)
              ? null
              : location.state.visit,
          checkout: true,
          serviceItems: filteredServiceItems,
          pass:
            Utils.IsNullOrEmptyObject(location.state) ||
            Utils.IsNullOrEmptyObject(location.state.pass)
              ? null
              : location.state.pass,
          vehicleDetails:
            Utils.IsNullOrEmptyObject(location.state) ||
            Utils.IsNullOrEmptyObject(location.state.vehicleDetails)
              ? null
              : location.state.vehicleDetails,
          validVehiclesCount:
            Utils.IsNullOrEmpty(location.state) ||
            Utils.IsNullOrEmpty(location.state.validVehiclesCount)
              ? 0
              : location.state.validVehiclesCount,
          searchText:
            location.state && location.state.searchText
              ? location.state.searchText
              : "",
          navigationStack: Utils.AddElementToArray(
            location.state.navigationStack,
            CheckOutPage.Path
          ),
        },
      });
    }
  };

  const handleServiceItemActions = (action) => {
    if (
      action.type === serviceItemActionTypes.Clear ||
      (filteredServiceItems.length === 1 &&
        action.type === serviceItemActionTypes.Delete)
    ) {
      setShowDialog(false);
    }
    itemDispatch(action);
  };

  const addFrequentlyUsedItem = (product) => {
    if (
      !Utils.IsNullOrEmptyObject(
        filteredServiceItems.find((s) => s.ProductId === product.id)
      )
    ) {
      setAlert("Product already added");
      return;
    }
    var item = getServiceItemFromProduct(product);
    if (Utils.IsNullOrEmptyArray(filteredServiceItems)) {
      item.Sequence = 1;
    } else {
      item.Sequence =
        filteredServiceItems[filteredServiceItems.length - 1].Sequence + 1;
    }
    itemDispatch({
      type: serviceItemActionTypes.Add,
      value: item,
    });
    configureDialog();
  };

  const addItemToCart = (product, isCustomProduct = false) => {
    var serviceItem = isCustomProduct
      ? product
      : getServiceItemFromProduct(product);
    if (Utils.IsNullOrEmptyArray(filteredServiceItems)) {
      serviceItem.Sequence = 1;
    } else {
      serviceItem.Sequence =
        filteredServiceItems[filteredServiceItems.length - 1].Sequence + 1;
    }
    itemDispatch({
      type: serviceItemActionTypes.Add,
      value: serviceItem,
    });
    configureDialog();
  };

  const configureDialog = () => {
    if (!showDialog) {
      setShowDialog(true);
    }
  };

  const backNavigation = () => {
    navigate(Utils.GetLastArrayElement(location.state.navigationStack), {
      state: {
        ...location.state,
        navigationStack: Utils.RemoveLastElementFromArray(
          location.state.navigationStack
        ),
      },
    });
  };

  return (
    <Container maxWidth="false" className="bg-color p-0">
      <PromptDialog
        showDialog={showPrompt}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation}
      />

      <MessagePopup
        msgOpen={showAlert}
        msgText={alertMessage}
        onMsgClose={() => setShowAlert(false)}
      />

      <Box>
        <Grid container m={0}>
          <SideBarMenuItems
            selectedTab={
              !Utils.IsNullOrEmptyObject(location.state) &&
                !Utils.IsNullOrEmptyObject(location.state.pass)
                ? SideBarMenuNames.Customers
                : SideBarMenuNames.CheckOut
            }
          />
          <Grid xs={12} className="content-sec">
            <Grid container direction="row" className="">
              <Grid flex="1" spacing={2}>
                <Grid container className="title-sec">
                  <Typography
                    level="h2"
                    component="h2"
                    fontWeight="600"
                    fontSize="2rem"
                    className="page-title text-center"
                  >
                    {!Utils.IsNullOrEmptyObject(location.state) &&
                      !Utils.IsNullOrEmptyObject(location.state.pass) && (
                        <Button onClick={backNavigation} className="back-btn">
                          <NavigateBeforeIcon />
                        </Button>
                      )}
                    Check-Out
                  </Typography>
                </Grid>

                <Grid
                  xs={12}
                  style={{
                    position: "relative",
                    "min-height": "calc(100vh - 100px)",
                  }}
                >
                  <Grid container direction="column" className="">
                    <CartComponent
                      serviceItems={filteredServiceItems}
                      onAddItem={addItemToCart}
                      handleItemAction={handleServiceItemActions}
                      includePreview={true}
                      onChangeTotal={setTotalPrice}
                      customerId={
                        !Utils.IsNullOrEmptyObject(location.state) &&
                          !Utils.IsNullOrEmptyObject(location.state.pass)
                          ? location.state.pass.customerId
                          : ""
                      }
                      vehicle={
                        !Utils.IsNullOrEmptyObject(location.state) &&
                          !Utils.IsNullOrEmptyObject(
                            location.state.vehicleDetails
                          )
                          ? location.state.vehicleDetails
                          : null
                      }
                      visitType={VisitTypeEnum.Checkout}
                    />

                    <Grid flex="1" spacing={2} padding="0 20px">
                      <Grid container xs={12} padding="0 10px">
                        <Grid xs={1} alignSelf="center"></Grid>
                        <Grid xs={9} className=""></Grid>
                        <Grid xs={12} lg={2} className="text-right">
                          <Typography
                            level="h4"
                            component="h4"
                            className="my-1"
                            fontWeight="600"
                            fontSize="20px"
                          >
                            <small>Total:</small> &nbsp;
                            {Utils.GetCurrency(totalPrice, countryInfo)}
                          </Typography>
                          <Button
                            className="btn btn-secondary white-text align-self-start font-size-12"
                            variant="contained"
                            onClick={clickOpenPayment}
                          >

                            Proceed to payment
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid
                      className="mt-2 freq-wrap"
                      style={{ display: "none" }}
                    >
                      <Typography
                        level="h4"
                        className="mt-1"
                        component="h4"
                        fontWeight="600"
                        fontSize="16px"
                      >
                        Frequently used items:
                      </Typography>
                      <Stack
                        direction="row"
                        spacing={1}
                        className="mt-1 freq-items-sec"
                      >
                        {frequentlyUsedProducts.map((product) => (
                          <Grid
                            item
                            xs
                            onClick={() => addFrequentlyUsedItem(product)}
                          >
                            <Card sx={{ minWidth: 150 }}>
                              <CardContent style={{ padding: "5px 10px" }}>
                                <Typography
                                  sx={{ fontSize: 14 }}
                                  fontWeight="600"
                                  gutterBottom
                                >
                                  {product.shortDesc}
                                </Typography>
                                <Typography
                                  variant="p"
                                  component="div"
                                  margin={0}
                                >
                                  {Utils.GetCurrency(
                                    product.price,
                                    countryInfo
                                  )}
                                </Typography>
                              </CardContent>
                            </Card>
                          </Grid>
                        ))}
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};
export default CheckOut;
