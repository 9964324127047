import { getEnterpriseId } from "../data/localSettingsActions";
import { Enterprises, SpecialCharacterToDivideLoginId } from "./Constants";
import Utils from "./Utils";

export const BuildLoginId = (loginId, enterpriseId) => {
  if (Utils.IsNullOrEmpty(loginId)) {
    return loginId;
  }
  if (Utils.IsNullOrEmpty(enterpriseId)) {
    enterpriseId = getEnterpriseId();
  }
  if (enterpriseId === Enterprises.XUVI) {
    return loginId;
  }
  return enterpriseId + SpecialCharacterToDivideLoginId + loginId;
};

export const GetValidId = (Id, enterpriseId) => {
  if (Utils.IsNullOrEmpty(Id)) {
    return Id;
  }
  if (Utils.IsNullOrEmpty(enterpriseId)) {
    enterpriseId = getEnterpriseId();
  }
  if (enterpriseId === Enterprises.XUVI) {
    return Id;
  }
  var splitEmail = Id.split(SpecialCharacterToDivideLoginId);
  var value = splitEmail[1];
  return value;
};
